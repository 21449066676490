import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { MySqlVersionList } from "../../database/MySqlVersionList";
import { RedisVersionList } from "../../database/RedisVersionList";

type AnyDatabaseVersionList = MySqlVersionList | RedisVersionList;

export class DatabaseVersionListUI {
  private readonly databaseVersionList: AnyDatabaseVersionList;

  private constructor(databaseVersionList: AnyDatabaseVersionList) {
    this.databaseVersionList = databaseVersionList;
  }

  public static of(
    databaseVersionList: AnyDatabaseVersionList,
  ): DatabaseVersionListUI {
    return new DatabaseVersionListUI(databaseVersionList);
  }

  public getVersionSelectOptions(): SelectOptions<any, any> {
    return this.databaseVersionList.items.map((version) => ({
      label: { text: version.name },
      value: version.number,
    }));
  }
}
