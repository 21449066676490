import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { iconProjectRole } from "@mittwald/flow-icons/dist/projectRole";

import React, { FC } from "react";
import Onboarding from "../../../../../../model/onboarding/Onboarding";
import { OnboardingSection } from "../../components/OnboardingSection";
import { UnorderedList } from "../../components/UnorderedList";
import projectRolesImage1 from "../../img/project-roles-1.png";
import projectRolesImage2 from "../../img/project-roles-2.png";
import projectRolesImage3 from "../../img/project-roles-3.png";

export const ProjectRolesOnboarding: FC = () => {
  const form = useForm({
    onSubmit: () => {
      Onboarding.completeProjectRoles();
    },
  });

  return (
    <WizardModal
      form={form}
      steps={["roles", "owner", "external", "emailAdmin"]}
    >
      <WizardIntroStep
        description="rolesDescription"
        headline="roles"
        headlineIcon={iconProjectRole}
        id="roles"
        indicatorText="rolesIndicator"
      >
        <Section.Item>
          <Text i18n="advantagesDescription" />
          <UnorderedList length={4} translationKey="advantage" />
        </Section.Item>
      </WizardIntroStep>
      <WizardStep id="owner" indicatorText="ownerIndicator">
        <OnboardingSection headline="owner" image={projectRolesImage1}>
          <Text i18n="ownerDescription" />
          <UnorderedList length={10} translationKey="owner" />
        </OnboardingSection>
      </WizardStep>
      <WizardStep id="external" indicatorText="externalIndicator">
        <OnboardingSection headline="external" image={projectRolesImage2}>
          <Text i18n="externalDescription" />
          <UnorderedList length={8} translationKey="external" />
          <Text i18n="externalSubline" />
        </OnboardingSection>
      </WizardStep>
      <WizardStep id="emailAdmin" indicatorText="emailAdminIndicator">
        <OnboardingSection headline="emailAdmin" image={projectRolesImage3}>
          <Text i18n="emailAdminDescription" />
          <UnorderedList length={3} translationKey="emailAdmin" />
        </OnboardingSection>
      </WizardStep>
    </WizardModal>
  );
};

export default ProjectRolesOnboarding;
