import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { mittwaldApi } from "../../api/Mittwald";
import Extension from "./Extension";
import ExtensionInstanceList from "./ExtensionInstanceList";

export class ExtensionInstanceListUI {
  public readonly extensionInstanceList: ExtensionInstanceList;

  private constructor(extensionInstanceList: ExtensionInstanceList) {
    this.extensionInstanceList = extensionInstanceList;
  }

  public static of(
    extensionInstanceList: ExtensionInstanceList,
  ): ExtensionInstanceListUI {
    return new ExtensionInstanceListUI(extensionInstanceList);
  }

  public async getSelectOptions(optional?: boolean): Promise<SelectOptions> {
    const extensionIds = this.extensionInstanceList.items.map(
      (instance) => instance.data.extensionId,
    );

    const extensions = (
      await Promise.all(
        Array.from(new Set(extensionIds)).map((extensionId) =>
          mittwaldApi.extensionGetExtension.request({ path: { extensionId } }),
        ),
      )
    ).map((response) => {
      assertStatus(response, 200);
      return new Extension(response.content);
    });

    const options: SelectOptions = this.extensionInstanceList.items
      .map((instance) => ({
        ...instance,
        extension: extensions.find((e) => e.id === instance.extensionId)!,
      }))
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      .filter((instance) => instance.extension !== undefined)
      .map((instance) => ({
        ...instance,
        extension: instance.extension,
      }))
      .sort((a, b) => {
        return a.extension.name.localeCompare(b.extension.name);
      })
      .map((instance) => ({
        label: { text: instance.extension.name },
        value: instance.id,
      }));

    if (optional) {
      options.unshift({ label: "nothingSelected", value: undefined });
    }

    return options;
  }
}
