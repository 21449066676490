import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { iconCustomerRole } from "@mittwald/flow-icons/dist/customerRole";

import React, { FC } from "react";
import Onboarding from "../../../../../../model/onboarding/Onboarding";
import { OnboardingSection } from "../../components/OnboardingSection";
import { UnorderedList } from "../../components/UnorderedList";
import customerRolesImage1 from "../../img/customer-roles-1.png";
import customerRolesImage2 from "../../img/customer-roles-2.png";
import customerRolesImage3 from "../../img/customer-roles-3.png";

export const CustomerRolesOnboarding: FC = () => {
  const form = useForm({
    onSubmit: () => {
      Onboarding.completeCustomerRoles();
    },
  });

  return (
    <WizardModal form={form} steps={["roles", "owner", "member", "account"]}>
      <WizardIntroStep
        description="rolesDescription"
        headline="roles"
        headlineIcon={iconCustomerRole}
        id="roles"
        indicatorText="rolesIndicator"
      >
        <Section.Item>
          <Text i18n="advantagesDescription" />
          <UnorderedList length={4} translationKey="advantage" />
        </Section.Item>
      </WizardIntroStep>
      <WizardStep id="owner" indicatorText="ownerIndicator">
        <OnboardingSection headline="owner" image={customerRolesImage1}>
          <Text i18n="ownerDescription" />
          <UnorderedList length={8} translationKey="owner" />
        </OnboardingSection>
      </WizardStep>
      <WizardStep id="member" indicatorText="memberIndicator">
        <OnboardingSection headline="member" image={customerRolesImage2}>
          <Text i18n="memberDescription" />
          <UnorderedList length={5} translationKey="member" />
          <Text i18n="memberSubline" />
        </OnboardingSection>
      </WizardStep>
      <WizardStep id="account" indicatorText="accountantIndicator">
        <OnboardingSection headline="accountant" image={customerRolesImage3}>
          <Text i18n="accountantDescription" />
          <UnorderedList length={6} translationKey="accountant" />
        </OnboardingSection>
      </WizardStep>
    </WizardModal>
  );
};

export default CustomerRolesOnboarding;
