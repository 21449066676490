import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconTerminate } from "@mittwald/flow-icons/dist/terminate";
import invariant from "invariant";
import React, { FC } from "react";
import { strictToISO } from "../../../../../lib/strictToISO";
import { ContractTerminateInput } from "../../../../../model/contract/Contract";
import ContractItem from "../../../../../model/contract/ContractItem";
import ConfirmationStep from "./components/ConfirmationStep";
import { ConfirmationStepContent } from "./components/ConfirmationStepContent";
import DependencyStep from "./components/DependencyStep";
import SelectDateStep from "./components/SelectDateStep";

interface Props {
  contractItem: ContractItem;
}

export interface TerminationFormFields extends ContractTerminateInput {
  confirmation?: boolean;
}

export const ContractItemTerminationModal: FC<Props> = (props) => {
  const { contractItem } = props;

  const visibility = useVisibilityController(true);

  const freeTrialUntil = contractItem.freeTrialUntil;

  invariant(
    contractItem.nextPossibleTerminationDate,
    "nextPossibleTerminationDate musst be defined",
  );
  const nextTerminationDate = contractItem.nextPossibleTerminationDate;

  const form = useForm<TerminationFormFields>({
    translationKey: "terminateBaseItem",
    defaultValues: {
      terminationType: "nextDate",
      terminationTargetDate: strictToISO(nextTerminationDate),
    },
    onSubmit: async (values) => {
      await contractItem.terminate({
        ...values,
      });
    },
  });

  const terminationTargetDate = form.watch("terminationTargetDate");
  const isProSpace = contractItem.isProSpace;

  const modal = freeTrialUntil && (
    <DefaultModal
      description={{
        id: contractItem.isProSpace ? "proSpaceDescription" : "description",
        values: { server: contractItem.description },
      }}
      headline="terminateFreeTrial"
      headlineIcon={iconTerminate}
      primary={{
        action: [
          { form },
          visibility.hide,
          { link: { id: isProSpace ? "projects" : "servers", replace: true } },
        ],
        destructive: true,
      }}
      visibility={visibility}
    >
      <Form controller={form}>
        <ConfirmationStepContent
          contractItem={contractItem}
          terminationTargetDate={terminationTargetDate}
        />
      </Form>
    </DefaultModal>
  );

  const wizard = !freeTrialUntil && (
    <WizardModal
      form={form}
      steps={["terminationDate", "dependencies", "confirm"]}
    >
      <SelectDateStep contractItem={contractItem} />
      <DependencyStep contractItem={contractItem} />
      <ConfirmationStep contractItem={contractItem} />
    </WizardModal>
  );

  return (
    <TranslationProvider name="terminationWizard" type="modal">
      {wizard}
      {modal}
    </TranslationProvider>
  );
};

export default ContractItemTerminationModal;
