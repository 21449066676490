import {
  FormController,
  useForm,
} from "@mittwald/flow-components/dist/components/Form";
import { Render } from "@mittwald/flow-components/dist/components/Render";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import {
  Domain,
  HandleInputs,
  NewDomainInputs,
} from "../../../../../../model/domain/Domain";
import { ContactFields } from "../../../owner/components/ContactFields/ContactFields";

interface AdminStepProps {
  hostname: string;
  form: FormController<NewDomainInputs>;
}

export const AdminStep: FC<AdminStepProps> = (props) => {
  const { form, hostname } = props;
  const [currentAdminC, currentDomain] = form.watch(["adminC", "domain"]);

  const adminForm = useForm<HandleInputs>({
    defaultValues: {
      adminC: currentAdminC,
    },
    onSubmit: (values) => {
      form.setValue("adminC", values.adminC);
    },
    shouldUnregister: true,
  });

  return (
    <WizardStep form={adminForm} id="admin" indicatorText="adminStep">
      <Render>
        {() => {
          const watchedFormValues = adminForm.watch();
          const domainHandleInputs = Domain.useHandleUserInputs(
            hostname,
            watchedFormValues,
          );

          const adminCInputs = domainHandleInputs?.adminC?.items ?? [];

          return (
            <Section.Layout>
              <Section.Item headline="admin">
                <Text i18n="adminDescription" />
              </Section.Item>
              <Section.Item>
                <ContactFields
                  fieldNamePrefix="adminC"
                  hostname={currentDomain}
                  userInputs={adminCInputs}
                />
              </Section.Item>
            </Section.Layout>
          );
        }}
      </Render>
    </WizardStep>
  );
};

export default AdminStep;
