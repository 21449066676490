import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { iconMfa } from "@mittwald/flow-icons/dist/mfa";

import React, { FC } from "react";
import Onboarding from "../../../../../../model/onboarding/Onboarding";
import { OnboardingSection } from "../../components/OnboardingSection";
import mfaImage1 from "../../img/mfa-1.png";
import mfaImage2 from "../../img/mfa-2.png";

export const MfaOnboarding: FC = () => {
  const form = useForm({
    onSubmit: () => {
      Onboarding.completeMfa();
    },
  });

  return (
    <WizardModal form={form} steps={["mfa", "instructions"]}>
      <WizardIntroStep
        description="mfaDescription"
        headline="mfa"
        headlineIcon={iconMfa}
        id="mfa"
        indicatorText="mfaIndicator"
      >
        <OnboardingSection headline="reasons" image={mfaImage1}>
          <Text i18n="reasonsDescription" />
        </OnboardingSection>
      </WizardIntroStep>
      <WizardStep id="instructions" indicatorText="instructionsIndicator">
        <OnboardingSection headline="instruction" image={mfaImage2}>
          <Text i18n="instructionsDescription" />
        </OnboardingSection>
      </WizardStep>
    </WizardModal>
  );
};

export default MfaOnboarding;
