import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column";
import React from "react";
import ProjectMembership from "../../../../../../../model/project/ProjectMembership";
import ProjectUI from "../../../../../../../model/ui/project/ProjectUI";

export const MemberRoleColumn: ResourceListColumnRenderer<
  ProjectMembership
> = ({ resource }) => (
  <Column
    icon={ProjectUI.getRoleIcon(resource.role.name)}
    text={`${resource.role.name}ProjectRole`}
    tooltip="role"
  />
);
