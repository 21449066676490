import "@mittwald/flow-pro-icons";
import "./api/clients/init";
import "./errors/global";
import { AppBootstrap } from "@mittwald/flow-components/dist/components/AppBootstrap";
import { UniversalBoundary } from "@mittwald/flow-components/dist/components/UniversalBoundary";
import React, { FC } from "react";
import { createRoot } from "react-dom/client";
import { autorunAppInitialization } from "./autorun/autorunAppInitialization";
import { autorunSyncUserSettings } from "./autorun/autorunSyncUserSettings";
import FeedbackPoll from "./components/FeedbackPoll/FeedbackPoll";
import { AutoNotificationReader } from "./components/AutoNotificationReader/AutoNotificationReader";
import PageTitle from "./components/PageTitle/PageTitle";
import StatusBanderole from "./components/StatusBanderole/StatusBanderole";
import { printEmploymentAd } from "./employmentAd";
import history from "./history";
import { useCheckAuthenticated } from "./hooks/useCheckAuthenticated";
import defaultLanguage from "./i18n/de";
import "./model/actions/importActionsRegisters";
import "./model/actions/initRootModelResolver";
import { pages } from "./pages";
import sessionStore from "./store/session";
import userSettingsStore from "./store/userSettings";
import "regenerator-runtime";

autorunAppInitialization();
autorunSyncUserSettings();

printEmploymentAd();

const App: FC = () => {
  const authenticated = useCheckAuthenticated(false);

  return (
    <AppBootstrap
      compactBreakpoint={1180}
      defaultLanguage={defaultLanguage}
      environment={import.meta.env.MODE}
      featureFlags={["showAppActions", "showContainerFrontend"]}
      history={history}
      importLanguageFile={(language) => import(`./i18n/${language}.ts`)}
      name="mStudio"
      pageTree={pages}
      persistedStores={[
        ["userSettings", userSettingsStore],
        ["session", sessionStore],
      ]}
      sentryDsn={
        document.location.hostname !== "localhost"
          ? import.meta.env.VITE_APP_SENTRY_DSN
          : undefined
      }
      version={import.meta.env.VITE_APP_MSTUDIO_VERSION}
    >
      <UniversalBoundary>
        <StatusBanderole />
      </UniversalBoundary>
      {authenticated && (
        <>
          <UniversalBoundary>
            <FeedbackPoll />
          </UniversalBoundary>

          <UniversalBoundary>
            <AutoNotificationReader />
          </UniversalBoundary>
        </>
      )}
      <UniversalBoundary>
        <PageTitle />
      </UniversalBoundary>
    </AppBootstrap>
  );
};

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
