import { RedirectToLink } from "@mittwald/flow-lib/dist/components/RedirectToLink";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import sessionStore from "../store/session";

export const StartPage: FC = observer(() => {
  if (sessionStore.accessToken) {
    return <RedirectToLink linkId="app" />;
  } else {
    return <RedirectToLink linkId="login" />;
  }
});

export default StartPage;
