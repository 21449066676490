import { Banner } from "@mittwald/flow-components/dist/components/Banner";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import React from "react";
import ExtensionInstance from "../../../../../../model/marketplace/ExtensionInstance";

interface Props {
  extensionInstanceId: string;
}

export const ExtensionInstanceInformationBanner: React.FC<Props> = (props) => {
  const { extensionInstanceId } = props;

  const contributor =
    ExtensionInstance.useLoadById(extensionInstanceId).useContributor();

  return (
    <TranslationProvider
      name="extensionInstanceInformationBanner"
      type="section"
    >
      <Banner
        description={{
          id: "contactContributor",
          values: {
            contactEmail: (
              <TextLink
                _inline
                action={`mailto:${contributor.data.email}`}
                strong
              >
                {contributor.data.email}
              </TextLink>
            ),
          },
        }}
        headline="extensionInstanceInformation"
      />
    </TranslationProvider>
  );
};
