import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { DateTime } from "luxon";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { isInLessThan7Days } from "../../pages/app/profile/lib";

export type SshKeyApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Signup_SshKey;

export interface NewSshKeyInputs {
  publicKey: string;
  comment: string;
  expiresAt?: string;
}

export interface UpdateSshKeyInputs {
  comment: string;
  expiresAt?: string;
}

export class SshKey {
  public readonly id: string;
  public readonly data: SshKeyApiData;
  public readonly comment: string;
  public readonly key: string;
  public readonly expires: boolean;
  public readonly expiresSoon: boolean;
  public readonly expiresAt: DateTime | undefined;

  private constructor(data: SshKeyApiData) {
    this.id = data.sshKeyId;
    this.data = Object.freeze(data);
    this.comment = data.comment;
    this.key = data.key;
    this.expires = !!data.expiresAt;
    this.expiresAt = data.expiresAt
      ? DateTime.fromISO(data.expiresAt)
      : undefined;
    this.expiresSoon = !!this.expiresAt && isInLessThan7Days(this.expiresAt);
  }

  public static fromApiData(data: SshKeyApiData): SshKey {
    return new SshKey(data);
  }

  public static useLoadById(sshKeyId: string): SshKey {
    const data = mittwaldApi.userGetSshKey
      .getResource({
        path: {
          sshKeyId,
        },
      })
      .useWatchData().sshKey;

    return SshKey.fromApiData(data);
  }

  public static useLoadByPathParam(): SshKey {
    const { sshKeyId } = usePathParams("sshKeyId");
    return SshKey.useLoadById(sshKeyId);
  }

  public static async createNew(values: NewSshKeyInputs): Promise<void> {
    const response = await mittwaldApi.userCreateSshKey.request({
      requestBody: {
        publicKey: `${values.publicKey} ${values.comment}`,
        expiresAt: values.expiresAt ?? undefined,
      },
    });

    assertStatus(response, 201);
  }

  public async update(values: UpdateSshKeyInputs): Promise<void> {
    const response = await mittwaldApi.userEditSshKey.request({
      path: {
        sshKeyId: this.id,
      },
      requestBody: {
        comment: values.comment,
        expiresAt: values.expiresAt ?? undefined,
      },
    });

    assertStatus(response, 204);
  }

  public async delete(): Promise<void> {
    const sshDeleteResponse = await mittwaldApi.userDeleteSshKey.request({
      path: {
        sshKeyId: this.id,
      },
    });

    assertStatus(sshDeleteResponse, 204);
  }
}

export default SshKey;
