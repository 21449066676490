import { lazy } from "react";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import FrontsideRenderer from "@mittwald/flow-renderer/dist/components/FrontsideRenderer/FrontsideRenderer";
import React from "react";
import { PageSkeleton } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/components/PageSkeleton";

export const registerPages = PageTree.build(FrontsideRenderer, {
  "/register": {
    sector: "addons",
    views: {
      main: lazy(() => import("./pages/Register")),
    },
    linkId: "register",
  },
  "/register/verify": {
    views: {
      main: lazy(() => import("./pages/RegistrationVerification")),
    },
    linkId: "registration-verify",
  },
  "/register/resend-email": {
    views: {
      main: lazy(() => import("./pages/ResendEmail")),
    },
    linkId: "registration-resend-email",
  },
  "/register/reset": {
    views: {
      main: () => <PageSkeleton redirectTo="register" />,
    },
  },
  "/register/reset/password": {
    views: {
      main: lazy(() => import("./pages/ResetPassword")),
    },
    linkId: "reset-password",
  },
  "/register/reset/password/confirm": {
    views: {
      main: lazy(() => import("./pages/ResetPasswordConfirmation")),
    },
  },
});
