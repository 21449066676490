import { ModalLazyLoadingFallback } from "@mittwald/flow-components/dist/components/Modal/components/ModalLazyLoadingFallback";
import { lazy } from "react";
import React, { createElement, FC } from "react";

const LazyModal = lazy(() => import("./NewIssueModal"));

export const LazyNewIssueModal: FC = (props) => (
  <React.Suspense fallback={<ModalLazyLoadingFallback />}>
    {createElement(LazyModal, props)}
  </React.Suspense>
);

export default LazyNewIssueModal;
