import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import Backup from "./Backup";

export class BackupList extends ListModel<Backup> {
  public static useLoadAllByProjectId(projectId: string): BackupList {
    const backups = mittwaldApi.backupListProjectBackups
      .getResource({ path: { projectId } })
      .useWatchData()
      .map((b) => Backup.fromApiData(b));

    return new BackupList(backups);
  }

  public static useLoadAllByPathParam(): BackupList {
    const { projectId } = usePathParams("projectId");
    return BackupList.useLoadAllByProjectId(projectId);
  }
}
