import { MittwaldApi } from "../../api/Mittwald";

export type RedisVersionApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Database_RedisVersion;

export class RedisVersion {
  public readonly id: string;
  public readonly data: RedisVersionApiData;
  public readonly name: string;
  public readonly number: string;

  private constructor(data: RedisVersionApiData) {
    this.id = data.id;
    this.data = Object.freeze(data);
    this.name = data.name;
    this.number = data.number;
  }

  public static fromApiData(data: RedisVersionApiData): RedisVersion {
    return new RedisVersion(data);
  }
}

export default RedisVersion;
