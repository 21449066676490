import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import ContractItem from "../../../../../../model/contract/ContractItem";
import { Text } from "@mittwald/flow-components/dist/components/Text/Text";
import DomainDependencyInfo from "./DomainDependencyInfo";
import ProjectDependencyInfo from "./ProjectDependencyInfo";

interface Props {
  contractItem: ContractItem;
}

export const DependencyStep: FC<Props> = (props) => {
  const { contractItem } = props;

  const deps = contractItem.useDependencies();

  return (
    <WizardStep id="dependencies" indicatorText="dependencies">
      <SectionItem headline="checkDependencies">
        <Text
          i18n={{
            id: "dependenciesNotice",
          }}
        />
        {deps.domainCount > 0 && <DomainDependencyInfo />}
        <ProjectDependencyInfo />
      </SectionItem>
    </WizardStep>
  );
};

export default DependencyStep;
