import { mittwaldApi } from "../api/Mittwald";
import { useAccessToken } from "./useAccessToken";

/**
 * Checks the access token from the session against the signup API
 */
export const useCheckAuthenticated = (failOnApiError = true): boolean => {
  const accessToken = useAccessToken();
  const checkApiToken = mittwaldApi.userCheckToken
    .getResource(accessToken ? {} : null)
    .useWatchData({ optional: true, throwOnError: failOnApiError });
  return !!checkApiToken;
};
