import { lazy } from "react";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import FrontsideRenderer from "@mittwald/flow-renderer/dist/components/FrontsideRenderer/FrontsideRenderer";

export const invitePages = PageTree.build(FrontsideRenderer, {
  "/invite": {
    views: {
      main: lazy(() => import("./pages/Invite")),
    },
  },
  "/accept-invite": {
    views: {
      main: lazy(() => import("./pages/InviteWithOtherMail")),
    },
    linkId: "inviteWithOtherMail",
  },
});
