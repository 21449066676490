import { Role } from "../acl/Role";
import ProjectMembership from "../../project/ProjectMembership";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";
import { iconSignOut } from "@mittwald/flow-icons/dist/signOut";

ModelActionBuilderRegistry.register({
  model: ProjectMembership,
  method: "leaveProject",
  roles: [Role.anybody],
  isAvailable: (membership) => {
    const lastOwner = membership.useIsLastProjectOwner();
    const isMe = membership.useIsMe();
    const isInherited = membership.data.inherited;
    return !lastOwner && isMe && !isInherited;
  },
  altAction: (action) => ({
    action,
    confirmation: {
      modalTranslationKey: "leaveProject",
      required: true,
      modalProps: { headlineIcon: iconSignOut },
    },
  }),
}).register({
  model: ProjectMembership,
  method: "removeMember",
  roles: [Role.projectAdmin],
  isAvailable: (membership) => {
    const isMe = membership.useIsMe();
    const isInherited = membership.data.inherited;
    return !isMe && !isInherited;
  },
  altAction: (action) => ({
    action,
    confirmation: {
      modalTranslationKey: "removeMember",
      required: true,
      modalProps: { headlineIcon: iconSignOut },
    },
  }),
});
