import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import { ImageMetaProps } from "../../../../../../model/container/Container";
import { PortHeavyList } from "../../../components/PortHeavyList/PortHeavyList";

export const PortStep: FC<ImageMetaProps> = (props) => {
  return (
    <WizardStep id="ports" indicatorText="ports">
      <Section.Item headline="addPorts">
        <Text i18n="description" />
        <PortHeavyList suggestedPorts={props.imageMeta?.exposedPorts} />
      </Section.Item>
    </WizardStep>
  );
};

export default PortStep;
