import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import { DomainPathInputs } from "../../../../../../model/domain/Domain";
import DomainTargetFormFields from "../../../components/domainTargetFormFields";

export const TargetStep: FC = () => {
  const form = useFormContext<DomainPathInputs>();
  const targetType = form.watch("targetType");

  return (
    <WizardStep id="target" indicatorText="target" stepWillSubmitForm>
      <Section.Item headline="target">
        <Text i18n="targetDesc" />
        <DomainTargetFormFields targetType={targetType} />
      </Section.Item>
    </WizardStep>
  );
};

export default TargetStep;
