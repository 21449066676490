import {
  autoNotificationReaderConfiguration,
  AutoNotificationReaderConfigurationItem,
  NotificationReferenceTypeWithId,
} from "../AutoNotificationReaderConfiguration";

const uuidRegexText =
  "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}";
const pathParameterRegex = new RegExp(":([a-zA-Z0-9]+)", "g");

const extractId = (
  url: string,
  mappingObject: AutoNotificationReaderConfigurationItem,
): string | undefined => {
  const partsIndex = mappingObject.path
    .split("/")
    .indexOf(`:${mappingObject.referenceIdKey}`);

  if (partsIndex === -1) {
    return undefined;
  }

  const parts = url.split("/");

  return parts[partsIndex];
};

const replacePathParameterWithRegex = (path: string): string => {
  return path.replace(pathParameterRegex, uuidRegexText);
};

const transformPathToRegex = (path: string): RegExp => {
  return new RegExp("^" + replacePathParameterWithRegex(path) + "(\\/.*)?$");
};

export const getAggregatesFromUrl = (
  path: string,
): NotificationReferenceTypeWithId[] => {
  const mappingItem = autoNotificationReaderConfiguration.find((item) => {
    return transformPathToRegex(item.path).test(path);
  });

  if (!mappingItem) {
    return [];
  }

  const id = extractId(path, mappingItem);

  if (id === undefined) {
    return [];
  }

  return mappingItem.references.map((reference) => ({
    ...reference,
    id,
  }));
};
