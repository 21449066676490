import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";

import React from "react";
import CreateApiToken from "../../../pages/app/profile/apiTokens/modals/CreateApiToken/CreateApiToken";
import { Role } from "../acl/Role";
import ApiToken from "../../signup/ApiToken";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultApiTokenRoles = [Role.anybody];

ModelActionBuilderRegistry.register({
  model: ApiToken,
  roles: defaultApiTokenRoles,
  method: "delete",
  altAction: (action) => ({
    action,
    confirmation: {
      modalTranslationKey: "deleteApiToken",
      required: true,
      modalProps: {
        headlineIcon: iconDelete,
      },
    },
  }),
}).register({
  model: ApiToken,
  roles: defaultApiTokenRoles,
  method: "createNew",
  altAction: () => () => showModal("createApiToken", <CreateApiToken />),
});
