import { DateTime } from "luxon";
import {
  Severity,
  StatusData,
  statusPageResource,
} from "../../resources/statusPageResource";

enum RelevantServices {
  mStudio = 4876,
  cloudSystems = 4887,
  mailSystems = 4863,
}

export interface Service {
  incidentSeverity?: Severity;
  name: string;
}

export interface Maintenance {
  startDate: DateTime;
  endDate: DateTime;
}

export interface Incident {
  startDate?: DateTime;
  endDate?: DateTime;
  severity?: Severity;
}

export class Status {
  public readonly data: StatusData;
  public readonly relevantServices: Service[];
  public readonly maintenances: Maintenance[];
  public readonly incidents: Incident[];

  public constructor(data: StatusData) {
    this.data = Object.freeze(data);

    this.relevantServices = this.data.services
      .filter((s) => Object.values(RelevantServices).includes(s.id))
      .map((s) => ({
        name: s.name,
        incidentSeverity: s.current_incident_type
          ? Severity[s.current_incident_type]
          : undefined,
      }));

    this.maintenances = this.data.maintenances
      .filter((m) =>
        m.services?.find((s) => Object.values(RelevantServices).includes(s.id)),
      )
      .filter((m) => m.starts_at && m.ends_at)
      .map((m) => ({
        startDate: DateTime.fromISO(m.starts_at!, { zone: "utc" }),
        endDate: DateTime.fromISO(m.ends_at!, { zone: "utc" }),
      }));

    this.incidents = this.data.incidents
      .filter((i) =>
        i.services?.find((s) => Object.values(RelevantServices).includes(s.id)),
      )
      .map((i) => ({
        startDate: i.starts_at
          ? DateTime.fromISO(i.starts_at, { zone: "utc" })
          : undefined,
        endDate: i.ends_at
          ? DateTime.fromISO(i.ends_at, { zone: "utc" })
          : undefined,
        severity: i.type ? Severity[i.type] : undefined,
      }));
  }

  public static useFind(): Status | undefined {
    const data = statusPageResource.useWatchData({
      retry: true,
      optional: true,
    });

    return data ? new Status(data) : undefined;
  }

  public findMostSevereActiveIncident(): Incident | undefined {
    const activeIncidents = this.incidents;

    return activeIncidents.length > 0
      ? activeIncidents.reduce((prev, curr) =>
          !curr.severity
            ? prev
            : !prev.severity
              ? curr
              : curr.severity > prev.severity
                ? curr
                : prev,
        )
      : undefined;
  }

  public findImminentMaintenance(): Maintenance | undefined {
    return this.maintenances
      .filter(
        (m) =>
          m.startDate > DateTime.now() &&
          m.startDate <= DateTime.now().plus({ day: 3 }),
      )
      .sort((a, b) => a.startDate.toMillis() - b.startDate.toMillis())[0];
  }
}

export default Status;
