import { FormController } from "@mittwald/flow-components/dist/components/Form";
import { FC } from "react";
import AppVersion from "../../../../model/app/AppVersion";
import { InstallationUserInputDataSourceName } from "../../../../model/misc/userInput/UserInputDataSource";
import UserInputListUI from "../../../../model/ui/misc/UserInputListUI";

interface Props {
  appVersion: AppVersion;
  form: FormController<any>;
  fieldNamePrefix?: string;
}

export const SetUserInputFieldDefaultValues: FC<Props> = (props) => {
  const { form, fieldNamePrefix, appVersion } = props;
  const userInputs = appVersion.userInputs;

  UserInputListUI.of(userInputs).useSetDefaultValues({
    form,
    fieldNamePrefix,
    installationDataSourceFields: {
      description: InstallationUserInputDataSourceName.description,
    },
  });

  return null;
};

export default SetUserInputFieldDefaultValues;
