import AutoCompleteField from "@mittwald/flow-components/dist/components/AutoCompleteField/AutoCompleteField";
import { SuggestionsFactory } from "@mittwald/flow-components/dist/components/AutoCompleteField/types";
import { Banner } from "@mittwald/flow-components/dist/components/Banner";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { useAsyncDebounce } from "@mittwald/flow-components/dist/hooks/useAsyncDebounce";
import { isEmail } from "@mittwald/flow-lib/dist/validation";
import React, { FC } from "react";
import { ModelActionBuilder } from "../../../../../model/actions/ModelActionBuilder";
import Ingress from "../../../../../model/domain/Ingress";
import { IngressList } from "../../../../../model/domain/IngressList";
import EmailAddress from "../../../../../model/mail/EmailAddress";
import Project from "../../../../../model/project/Project";

export interface EmailAddressFormProps {
  catchAll: boolean;
  ingressList: IngressList;
  autoFocus?: boolean;
  name?: string;
  existingEmailAddress?: boolean;
}

export const EmailAddressForm: FC<EmailAddressFormProps> = (props) => {
  const { ingressList, autoFocus, name, existingEmailAddress } = props;
  const ownRole = Project.useLoadByPathParam().useMyRole();
  const ingresses = ingressList.useItems();

  const suggestions = ingresses.map((i) => i.hostname);
  const createAction = ModelActionBuilder.build(Ingress, "createNew");

  const removeDuplicateAt = (mail: string): string =>
    mail.includes("@") ? mail.replace("@", "") : mail;
  const isEmailWithVerifiedDomain = (email: string): boolean =>
    suggestions.includes(EmailAddress.getEmailAddressDomainPart(email));
  const validLocalPart = (email: string): boolean => {
    const regex = /^[A-Za-z0-9.!#$%&'*+-/=?^_`{|}~]*$/;
    return regex.test(email.split("@")[0] ?? "");
  };

  const getSuggestions: SuggestionsFactory<string> = (value) => {
    const local = EmailAddress.getEmailAddressLocalPart(value);
    const mail = removeDuplicateAt(local);

    return suggestions
      .map((domain) => ({
        value: mail.concat("@", domain),
        label: { text: mail.concat("@", domain) },
      }))
      .filter((domain) => {
        return domain.value
          .substring(domain.value.lastIndexOf("@") + 1)
          .startsWith(EmailAddress.getEmailAddressDomainPart(value));
      });
  };

  const getSuggestion = useAsyncDebounce(getSuggestions, 250);

  if (ingresses.length === 0) {
    return (
      <TranslationProvider
        name="noVerifiedDomainsForEmailWarning"
        type="section"
      >
        <Banner
          headline={
            existingEmailAddress
              ? "noVerifiedDomainsInExistingEmailAddress"
              : "noVerifiedDomains"
          }
          textLink={
            ownRole.is("emailadmin")
              ? undefined
              : {
                  title: "createDomainOwnership",
                  action: createAction,
                }
          }
          warning
        />
      </TranslationProvider>
    );
  }
  return (
    <ColumnLayout medium={[1, 1]}>
      <AutoCompleteField
        autoFocus={autoFocus}
        label="email"
        name={name ?? "email"}
        rules={{
          required: true,
          validate: {
            email: (value: string) => isEmail(value),
            validDomain: isEmailWithVerifiedDomain,
            validLocalPart: validLocalPart,
          },
        }}
        suggestions={getSuggestion}
        type="email"
      />
    </ColumnLayout>
  );
};

export default EmailAddressForm;
