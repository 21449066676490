import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox, {
  SelectBoxOptions,
} from "@mittwald/flow-components/dist/components/SelectBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import TextField from "@mittwald/flow-components/dist/components/TextField";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { useAsyncDebounce } from "@mittwald/flow-components/dist/hooks/useAsyncDebounce";
import React, { FC, useState } from "react";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import {
  Container,
  CreateContainerInputs,
  ImageMeta,
  ImageState,
} from "../../../../../../model/container/Container";
import { ContainerPortList } from "../../../../../../model/container/ContainerPortList";
import { ContainerVolumeRelationList } from "../../../../../../model/container/ContainerVolumeRelationList";
import { RootOverwriteBanner } from "../../../components/RootOverwriteBanner/RootOverwriteBanner";
import { iconSpinner } from "@mittwald/flow-icons/dist/spinner";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form";

interface Props {
  setImageMeta: (meta?: ImageMeta) => void;
}

export const ImageStep: FC<Props> = (props) => {
  const { projectId } = usePathParams("projectId");
  const form = useFormContext<CreateContainerInputs>();
  const [imageState, setImageState] = useState<ImageState>({
    isUserRoot: false,
    isValidating: false,
  });

  const presets = ["solr", "mongo", "postgres", "bitnami/keycloak"];
  const selectBoxOptions: SelectBoxOptions = presets.map((p) => ({
    value: p,
    title: p,
  }));

  const imageReference = form.watch("imageReference");

  const validateImageAndSetMeta = async (image: string): Promise<boolean> => {
    setImageState({ ...imageState, isValidating: true });
    const imageMeta = await Container.getImageMeta(image, projectId);
    if (!imageMeta) {
      setImageState({ ...imageState, isUserRoot: false, isValidating: false });
      props.setImageMeta();
      return false;
    }
    setImageState({
      isUserRoot: imageMeta.isUserRoot,
      overwritingUser: imageMeta.overwritingUser,
      isValidating: false,
    });
    props.setImageMeta(imageMeta);
    form.setValue(
      "volumes",
      imageMeta.volumes
        ? ContainerVolumeRelationList.fromImageMeta(
            imageMeta.volumes,
          ).asFormData()
        : [],
    );
    form.setValue(
      "ports",
      imageMeta.exposedPorts
        ? ContainerPortList.fromImageMeta(imageMeta.exposedPorts).asFormData()
        : [],
    );
    form.setValue("environments", []);
    return true;
  };

  return (
    <WizardStep id="image" indicatorText="image">
      <Section.Item headline="selectImage">
        <Text i18n="description" />
        <ColumnLayout medium={[2, 1]}>
          <TextField
            autoFocus
            icon={imageState.isValidating ? iconSpinner : undefined}
            iconProps={{ className: "fa-spin", _alignSelf: "center" }}
            label="image"
            name="imageReference"
            placeholder="imagePlaceholder"
            rules={{
              required: true,
              validate: {
                invalidImageRef: useAsyncDebounce(validateImageAndSetMeta, 500),
              },
            }}
          />
        </ColumnLayout>
        <SelectBox.View
          hideCheckmarks
          options={selectBoxOptions}
          value={imageReference}
          onChange={(v) => {
            form.setValue("imageReference", v);
            void form.trigger();
          }}
        />
        {imageState.isUserRoot && imageState.overwritingUser && (
          <RootOverwriteBanner overwritingUser={imageState.overwritingUser} />
        )}
      </Section.Item>
    </WizardStep>
  );
};

export default ImageStep;
