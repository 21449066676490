import { DateTimeText } from "@mittwald/flow-components/dist/components/DateTimeText";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconPassword } from "@mittwald/flow-icons/dist/password";
import React, { FC } from "react";
import MfaConfirmationModal, {
  useMfaConfirmationController,
} from "../../../../../../components/MfaConfirmationModal";
import {
  ChangePasswordInputs,
  Signup,
} from "../../../../../../model/signup/Signup";
import sessionStore from "../../../../../../store/session";

export const ChangePasswordModal: FC = () => {
  const visibility = useVisibilityController();
  const passwordUpdatedAt = Signup.usePasswordUpdatedAt();
  const mfaModal = useMfaConfirmationController();

  const form = useForm<ChangePasswordInputs>({
    defaultValues: {
      multiFactorCode: "",
      oldPassword: "",
      newPassword: "",
    },
    showSuccessFeedback: true,
    translationKey: "changePassword",
    onSubmit: async (values) => {
      const response = await Signup.changePassword(values);

      if (response === 202) {
        mfaModal.trigger();
        return false;
      }

      visibility.hide();

      setTimeout(() => {
        sessionStore.login(response);
      }, 500);
    },
  });

  return (
    <DefaultModal
      _size="s"
      description={{
        id: passwordUpdatedAt ? "passwordChangedAt" : "passwordChangedNever",
        values: passwordUpdatedAt
          ? {
              date: (
                <Text _inline>
                  <DateTimeText value={passwordUpdatedAt} />
                </Text>
              ),
            }
          : {},
      }}
      headline="headline"
      headlineIcon={iconPassword}
      primary={{
        text: {
          id: "save",
        },
        action: { form },

        ok: true,
      }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Item>
          <TextField
            autoComplete="off"
            autoFocus
            label="currentPassword"
            name="oldPassword"
            rules={{ required: true }}
            type="password"
          />
          <TextField
            autoComplete="off"
            label="newPassword"
            name="newPassword"
            rules={{
              required: true,
              minLength: 12,
            }}
            type="passwordWithGenerator"
          />
        </Section.Item>
        <MfaConfirmationModal
          controller={mfaModal}
          icon={iconPassword}
          triggerSubmit={form.submit}
        />
      </Form>
    </DefaultModal>
  );
};
