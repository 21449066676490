import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { DnsZoneUI, Ttl } from "../ui/domain/DnsZoneUI";
import { DnsZoneApiData } from "./DnsZone";
import { TxtRecordDataForList } from "./TxtRecordsList";

export type TxtRecordSetComponentApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordTxtComponent;

export type TxtRecordSetApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordTxt;

export interface TxtRecord {
  host: string;
  value: string;
}
export type TxtRecords = TxtRecord[];

interface TxtRecordWithHostAndValueApiData {
  entries: TxtRecords;
  settings: MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordSettings;
}

export class TxtRecordSet {
  public readonly data: TxtRecordSetApiData;
  public readonly ttl?: Ttl;
  public readonly id: string;
  public readonly domain: string;
  public readonly records: TxtRecords;
  public readonly isManaged: boolean;

  public constructor(
    id: string,
    domain: string,
    records: TxtRecordSetApiData | TxtRecordWithHostAndValueApiData,
    data: TxtRecordSetApiData,
  ) {
    this.data = Object.freeze(data);
    this.ttl = DnsZoneUI.ttlFromRecord(data);

    this.records = [];
    if ("entries" in records) {
      this.records = records.entries.map((r) => {
        if (typeof r === "string") {
          return {
            host: domain,
            value: r,
          };
        }

        return r;
      });
    }

    this.domain = domain;
    this.id = id;
    this.isManaged = !!this.records.find(
      (record) => record.value === DnsZoneUI.defaultTxtRecord,
    );
  }

  public static fromDnsZoneApiData = (data: DnsZoneApiData): TxtRecordSet => {
    return new TxtRecordSet(
      data.id,
      data.domain,
      data.recordSet.txt,
      data.recordSet.txt,
    );
  };

  public static fromList = (
    id: string,
    domain: string,
    ttl: Ttl,
    data: TxtRecordDataForList[],
  ): TxtRecordSet => {
    const txtData: TxtRecordSetComponentApiData = {
      entries: data.map((d) => d.txt),
      settings: {
        ttl: {
          seconds: ttl !== "auto" ? ttl : undefined,
          auto: ttl === "auto",
        },
      },
    };

    return new TxtRecordSet(id, domain, txtData, txtData);
  };

  public async saveRecords(): Promise<void> {
    const requestBody: Partial<TxtRecordSetComponentApiData> = {
      entries: this.records.map((r) => r.value),
      settings: {
        ttl: {
          seconds: this.ttl !== "auto" ? this.ttl : undefined,
          auto: this.ttl === "auto",
        },
      },
    };

    await mittwaldApi.dnsUpdateRecordSet.request({
      path: {
        dnsZoneId: this.id,
        recordSet: "txt",
      },
      requestBody: this.records.length === 0 ? {} : requestBody,
    });
  }
}

export default TxtRecordSet;
