import resourceFactoryBuilder from "@mittwald/flow-lib/dist/resources/resourceFactoryBuilder";
import { OpenAPIV3 } from "openapi-types";
import HttpResource from "@mittwald/flow-lib/dist/resources/HttpResource";

const urlPrefixes = {
  mwPublic: import.meta.env.VITE_APP_API_URI,
  mwInternal: import.meta.env.VITE_APP_INTERNAL_API_URI,
} as const;

export type ApiSpecType = keyof typeof urlPrefixes;

export const getApiSpecResource = resourceFactoryBuilder(
  (apiType: ApiSpecType) =>
    new HttpResource<OpenAPIV3.Document>("openapi", {
      prefixUrl: urlPrefixes[apiType],
    }),
);

export default getApiSpecResource;
