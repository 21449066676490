import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import React, { FC } from "react";
import { AppInstallationList } from "../../../../../model/app/AppInstallationList";
import { DomainPathInputs } from "../../../../../model/domain/Domain";
import Ingress from "../../../../../model/domain/Ingress";
import { DomainUI, TargetTypes } from "../../../../../model/ui/domain/DomainUI";
import PathStep from "./components/PathStep";
import TargetStep from "./components/TargetStep";

interface Props {
  ingress: Ingress;
}

export const NewDomainPath: FC<Props> = (props) => {
  const ingress = props.ingress;

  const appInstallations = AppInstallationList.useLoadAllByProjectId(
    ingress.data.projectId,
  ).useItems();

  const appPresent = !!appInstallations[0]?.id;

  const form = useForm<DomainPathInputs>({
    translationKey: "addDomainPath",
    showSuccessFeedback: true,
    defaultValues: {
      targetType: appPresent ? TargetTypes.app : TargetTypes.url,
      directory: "/",
      path: "",
      containerName: "",
      containerPort: "",
      installationId: appInstallations[0]?.id,
    },
    onSubmit: async (values) => {
      const { path, targetType } = values;

      await ingress.updatePaths([
        ...ingress.data.paths,
        {
          path,
          target: DomainUI.getTargetValue(targetType, values),
        },
      ]);
    },
  });

  return (
    <WizardModal form={form} steps={["path", "target"]}>
      <PathStep ingress={ingress} />
      <TargetStep />
    </WizardModal>
  );
};

export default NewDomainPath;
