import { Button } from "@mittwald/flow-components/dist/components/Button";
import { color } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import styled from "styled-components";

export const HeaderButton = styled(Button)`
  border-radius: 50%;

  &.info {
    background-color: ${color.base.mittwaldNavy.$300};

    &:hover {
      background-color: ${color.base.mittwaldNavy.$600};
    }

    &:active {
      background-color: ${color.base.mittwaldNavy.$900};
    }
  }
`;
