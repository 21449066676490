import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { Notification } from "./Notification";

export class NotificationList extends ListModel<Notification> {
  public static useAll(): NotificationList {
    const notifications = mittwaldApi.notificationsListNotifications
      .getResource({})
      .useWatchData()
      .map((n) => Notification.fromApiData(n));

    return new NotificationList(notifications);
  }

  public static useUnread(): NotificationList {
    const notifications = mittwaldApi.notificationsListNotifications
      .getResource({
        query: {
          status: "unread",
        },
      })
      .useWatchData()
      .map((n) => Notification.fromApiData(n));

    return new NotificationList(notifications);
  }

  public async markAllAsRead(): Promise<void> {
    const response =
      await mittwaldApi.notificationsReadAllNotifications.request({});

    assertStatus(response, 200);
  }
}

export default NotificationList;
