import { FC } from "react";
import ModelRelation from "../../model/misc/modelRelation/ModelRelation";
import { ModelRelationUI } from "../../model/ui/misc/ModelRelationUI";

interface Props {
  relation: ModelRelation;
}

export const ModelRelationTarget: FC<Props> = (props) => {
  const { relation } = props;
  return ModelRelationUI.of(relation).useTitle();
};

export default ModelRelationTarget;
