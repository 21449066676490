import useMergedLists from "@mittwald/flow-lib/dist/hooks/useMergedLists";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import invariant from "invariant";
import { AnyDatabase } from "./Database";
import { MySqlDatabaseList } from "./MySqlDatabaseList";
import { RedisDatabaseList } from "./RedisDatabaseList";

export class DatabaseList {
  public static useLoadAllDatabasesByProjectId(
    projectId: string,
  ): AnyDatabase[] {
    const mySqlDatabases =
      MySqlDatabaseList.useLoadAllByProjectId(projectId).useItems();
    const redisDatabases =
      RedisDatabaseList.useLoadAllByProjectId(projectId).useItems();

    return useMergedLists<AnyDatabase>(mySqlDatabases, redisDatabases);
  }

  public static useFindDatabaseByPathParams(): AnyDatabase {
    const { databaseId, projectId } = usePathParams("databaseId", "projectId");

    const database = DatabaseList.useLoadAllDatabasesByProjectId(
      projectId,
    ).find((database) => database.id === databaseId);

    invariant(database, "database not found");

    return database;
  }
}
