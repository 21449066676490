import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { arrayRemoveItem } from "@mittwald/flow-app-utils/dist/demo/helpers";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi } from "../../api/Mittwald";
import { Access } from "./Access";
import { PublicKey } from "./PublicKey";
import {
  AddPublicKeyInputs,
  NewSshUserInputs,
  SshUserApiData,
  UpdatePasswordInputs,
  UpdateSshUserInputs,
} from "./types";

export class SshUser extends Access {
  public readonly data: SshUserApiData;

  private constructor(data: SshUserApiData) {
    super(data);
    this.data = Object.freeze(data);
  }

  public static fromApiData(data: SshUserApiData): SshUser {
    return new SshUser(data);
  }

  public static useLoadById(id: string): SshUser {
    const data = mittwaldApi.sshUserGetSshUser
      .getResource({
        path: { sshUserId: id },
      })
      .useWatchData();
    return SshUser.fromApiData(data);
  }

  public static useLoadByPathParam(): SshUser {
    const { sshUserId } = usePathParams("sshUserId");
    return SshUser.useLoadById(sshUserId);
  }

  public static async createNew(
    values: NewSshUserInputs,
    projectId: string,
  ): Promise<SshUser | false> {
    const result = await mittwaldApi.sshUserCreateSshUser.request({
      path: { projectId },
      requestBody: {
        description: values.description,
        authentication:
          values.authType === "publicKey"
            ? { publicKeys: [{ key: values.key, comment: values.comment }] }
            : { password: values.password },
        expiresAt: values.expiresAt,
      },
    });

    if (
      result.status === 400 &&
      result.content.message?.includes("publicKey is not a valid sshKey")
    ) {
      return false;
    }

    assertStatus(result, 201);

    return SshUser.fromApiData(result.content);
  }

  public async update(values: UpdateSshUserInputs): Promise<void | false> {
    const result = await mittwaldApi.sshUserUpdateSshUser.request({
      path: { sshUserId: this.id },
      requestBody: {
        description: values.description ?? this.description,
        active: values.active ?? this.active,
        expiresAt: values.expiresAt ?? this.data.expiresAt,
      },
    });

    assertStatus(result, 204);
  }

  public async updatePassword(values?: UpdatePasswordInputs): Promise<void> {
    const response = await mittwaldApi.sshUserUpdateSshUser.request({
      path: {
        sshUserId: this.id,
      },
      requestBody: {
        password: values?.password ?? "",
      },
    });

    assertStatus(response, 204);
  }

  public async deletePassword(): Promise<void> {
    await this.updatePassword();
  }

  public async addPublicKey(values: AddPublicKeyInputs): Promise<void | false> {
    const response = await mittwaldApi.sshUserUpdateSshUser.request({
      path: {
        sshUserId: this.id,
      },
      requestBody: {
        publicKeys: this.data.publicKeys
          ? [
              ...this.data.publicKeys,
              { key: values.key, comment: values.comment },
            ]
          : [{ key: values.key, comment: values.comment }],
      },
    });

    if (
      response.status === 400 &&
      response.content.message?.includes("publicKey is not a valid sshKey")
    ) {
      return false;
    }

    assertStatus(response, 204);
  }

  public async toggleActive(): Promise<void> {
    const result = await mittwaldApi.sshUserUpdateSshUser.request({
      path: { sshUserId: this.id },

      requestBody: { active: !this.active },
    });
    assertStatus(result, 204);
  }

  public async deletePublicKey(publicKey: PublicKey): Promise<void> {
    arrayRemoveItem(
      this.publicKeyList.items,
      (p) => p.key === publicKey.key && p.comment === publicKey.comment,
    );

    const result = await mittwaldApi.sshUserUpdateSshUser.request({
      path: { sshUserId: this.id },

      requestBody: {
        publicKeys: this.publicKeyList.items,
      },
    });

    assertStatus(result, 204);
  }

  public async deleteSshUser(): Promise<void> {
    const result = await mittwaldApi.sshUserDeleteSshUser.request({
      path: { sshUserId: this.id },
    });

    assertStatus(result, 204);
  }
}
