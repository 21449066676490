import Avatar from "@mittwald/flow-components/dist/components/Avatar/Avatar";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import { useTranslation } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { extractTextFromReactNode } from "@mittwald/flow-components/dist/lib/extractTextFromReactNode";
import { pxNumber } from "@mittwald/flow-components/dist/mixins/formatters";
import { size } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import React from "react";
import { AvatarProps } from "@mittwald/flow-components/dist/components/Avatar";
import { RestAvatar } from "./styled/RestAvatar";
import withSubcomponents from "@mittwald/flow-components/dist/lib/withSubcomponents";
import Tooltip from "@mittwald/flow-components/dist/components/Tooltip";

export type AvatarStackAvatarProps = Omit<AvatarProps, "_size">;
interface AvatarStackProps {
  avatars: AvatarStackAvatarProps[];
  maxAvatarCount?: number;
  size?: AvatarProps["_size"];
}

const maxTooltipAvatarCount = 10;

const AvatarStackComponent: React.FC<AvatarStackProps> = (props) => {
  const { avatars, maxAvatarCount = avatars.length, size = "m" } = props;
  const translate = useTranslation();

  const visibleAvatars = avatars.slice(0, maxAvatarCount);
  const leftOverAvatars = avatars.slice(maxAvatarCount);

  const moreAvatarTooltipText =
    leftOverAvatars.length > maxTooltipAvatarCount
      ? `\n${extractTextFromReactNode(
          translate({
            id: "moreAvatars",
            values: {
              count: leftOverAvatars.length - maxTooltipAvatarCount,
            },
          }),
        )}`
      : "";

  const tooltipText =
    leftOverAvatars
      .slice(0, maxTooltipAvatarCount - 1)
      .map((avatar) => avatar.tooltip)
      .map((tooltip) => extractTextFromReactNode(translate(tooltip)))
      .join("\n") + moreAvatarTooltipText;

  return (
    <Box _display="flex" _flexDirection="row" _itemGap="s">
      {visibleAvatars.map((avatarProps, index) => (
        <Avatar {...avatarProps} _noShrink _size={size} key={index} />
      ))}
      {leftOverAvatars.length !== 0 ? (
        <Tooltip
          content={{
            text: tooltipText,
          }}
        >
          <RestAvatar>
            <span>+</span>
            <span>{leftOverAvatars.length}</span>
          </RestAvatar>
        </Tooltip>
      ) : null}
    </Box>
  );
};

const Skeleton: React.FC = () => {
  return (
    <Box _display="flex" _flexDirection="row" _itemGap="s">
      <Box
        _borderRadius="circle"
        _height={pxNumber(size.avatar.m)}
        _width={pxNumber(size.avatar.m)}
        skeleton
      />
      <Box
        _borderRadius="circle"
        _height={pxNumber(size.avatar.m)}
        _width={pxNumber(size.avatar.m)}
        skeleton
      />
      <Box
        _borderRadius="circle"
        _height={pxNumber(size.avatar.m)}
        _width={pxNumber(size.avatar.m)}
        skeleton
      />
    </Box>
  );
};

export const AvatarStack = withSubcomponents(AvatarStackComponent, {
  Skeleton: Skeleton,
});
