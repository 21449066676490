import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import React from "react";
import CreateProject from "../../../pages/app/project/modals/CreateProject/CreateProject";
import AdjustServerWizard from "../../../pages/app/server/modals/AdjustServerWizard/AdjustServerWizard";
import OrderServerWizard from "../../../pages/app/server/modals/OrderServerWizard/OrderServerWizard";
import UpdateServerAvatar from "../../../pages/app/server/modals/UpdateServerAvatar/UpdateServerAvatar";
import { Role } from "../acl/Role";
import Server from "../../server/Server";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultServerRoles = [Role.customerOwner, Role.customerMember];

const isCustomerBankrupt = (server: Server): boolean => {
  const customer = server.useCustomer();
  return customer.useIsBankrupt();
};

ModelActionBuilderRegistry.register({
  model: Server,
  method: "adjust",
  roles: defaultServerRoles,
  isAvailable: (server) =>
    server.useContract().isTariffChangePossible && !isCustomerBankrupt(server),
  altAction: (action) => () =>
    showModal(
      "adjustServer",
      <AdjustServerWizard serverId={action.model.id} />,
    ),
})
  .register({
    model: Server,
    method: "createProject",
    roles: [Role.anybody],
    isAvailable: (server) => !isCustomerBankrupt(server),
    altAction: (action) => () =>
      showModal("createProject", <CreateProject server={action.model} />),
  })
  .register({
    model: Server,
    method: "createNew",
    roles: [Role.anybody],
    altAction: () => () => showModal("createServer", <OrderServerWizard />),
  })
  .register({
    model: Server,
    method: "requestAvatarUpload",
    roles: defaultServerRoles,
    altAction: (action) => () =>
      showModal(
        "updateServerAvatar",
        <UpdateServerAvatar server={action.model} />,
      ),
  });
