import { Box } from "@mittwald/flow-components/dist/components/Box";
import { Button } from "@mittwald/flow-components/dist/components/Button";
import { H1 } from "@mittwald/flow-components/dist/components/H1";
import { PlaceholderBoxProps } from "@mittwald/flow-components/dist/components/PlaceholderBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";

export const HeavyListPlaceholder: FC<Omit<PlaceholderBoxProps, "icon">> = (
  props,
) => {
  const {
    description,
    headline,
    actionTitle = "NoItemMessage.action.title",
    action,
    error,
    children,
  } = props;

  const headlineElement = (
    <H1 _color={error && "status-error-darker"} center i18n={headline} />
  );

  const descriptionElement = (
    <Box _itemGap="m">
      {description && <Text center i18n={description} />}
      {children}
    </Box>
  );

  const actionElement = action && (
    <Box _flexDirection="row" _justifyContent="center" _mt="l">
      <Button action={action} text={actionTitle} />
    </Box>
  );

  return (
    <TranslationProvider name="placeholderBox" type="component">
      <Box
        _alignItems="center"
        _flexDirection="row"
        _justifyContent="center"
        _mt="s"
      >
        <Box _inset="s" _itemGap="m" _justifyContent="center" _maxW={400}>
          {headlineElement}
          {descriptionElement}
          {actionElement}
        </Box>
      </Box>
    </TranslationProvider>
  );
};

export default HeavyListPlaceholder;
