import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { PageSkeleton } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/components/PageSkeleton";
import FrontsideRenderer from "@mittwald/flow-renderer/dist/components/FrontsideRenderer/FrontsideRenderer";
import React, { lazy } from "react";

export const oAuthPages = PageTree.build(FrontsideRenderer, {
  "/oauth": {
    views: {
      main: () => <PageSkeleton redirectTo="oauthLogin" />,
    },
  },
  "/oauth/login": {
    views: {
      main: lazy(() => import("./pages/OAuthLogin")),
    },
    linkId: "oauthLogin",
  },
  "/oauth/mfa": {
    views: {
      main: lazy(() => import("./pages/OAuthMfa")),
    },
    linkId: "oauthMfa",
  },
  "/oauth/consent": {
    views: {
      main: lazy(() => import("./pages/OAuthConsent")),
    },
    linkId: "oauthConsent",
  },
});
