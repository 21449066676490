import { Banner } from "@mittwald/flow-components/dist/components/Banner";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { iconDatabase } from "@mittwald/flow-icons/dist/database";
import React, { FC } from "react";
import { IconList } from "../../../../../../components/IconList/IconList";
import AppInstallation from "../../../../../../model/app/AppInstallation";
import { FeePeriod } from "../../../../../../model/app/SystemSoftwareVersion";
import {
  AnyProject,
  SpaceServerProject,
} from "../../../../../../model/project";
import SelectProject from "./SelectProject";
import CurrentFeeBanner from "./CurrentFeeBanner";
import Section from "@mittwald/flow-components/dist/components/Section";
import Text from "@mittwald/flow-components/dist/components/Text";

interface Props {
  appInstallation: AppInstallation;
  project: AnyProject;
  currentFee?: FeePeriod;
}

export const CopyStepContent: FC<Props> = (props) => {
  const { appInstallation, project, currentFee } = props;

  const databasesExist =
    appInstallation.data.linkedDatabases &&
    appInstallation.data.linkedDatabases.length > 0;

  const hasAdditionalDatabases =
    appInstallation.data.linkedDatabases &&
    appInstallation.data.linkedDatabases.some((d) => d.purpose !== "primary");

  return (
    <Section.Layout>
      <Section.Item headline="settings">
        <ColumnLayout medium={[2, 1]}>
          <TextField
            label="description"
            name="description"
            rules={{ required: true }}
          />{" "}
          {project instanceof SpaceServerProject && (
            <SelectProject project={project} />
          )}
        </ColumnLayout>

        {currentFee && <CurrentFeeBanner />}
      </Section.Item>

      <Section.Item headline="dependencies">
        <Text i18n="dependenciesDescription" />
        {databasesExist && (
          <ColumnLayout medium={[1, 1]}>
            <IconList.Item i18n="databases" icon={iconDatabase} />
          </ColumnLayout>
        )}
        <Banner headline="cronjobsAndRedis" />
        {hasAdditionalDatabases && (
          <Banner headline="manualDbWarning" warning />
        )}
      </Section.Item>
    </Section.Layout>
  );
};

export default CopyStepContent;
