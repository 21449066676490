import { iconAccess } from "@mittwald/flow-icons/dist/access";
import { iconPublicKey } from "@mittwald/flow-icons/dist/publicKey";
import { iconSftpUser } from "@mittwald/flow-icons/dist/sftpUser";
import { iconSshUser } from "@mittwald/flow-icons/dist/sshUser";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/BacksideRenderer";
import { lazy } from "react";
import { SftpUser } from "../../../model/access/SftpUser";
import { SftpUserList } from "../../../model/access/SftpUserList";
import { SshUser } from "../../../model/access/SshUser";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";

export const accessPages = PageTree.build(BacksideRenderer, {
  "/app/projects/:projectId/access": {
    sector: "basics",
    props: {
      navigationSection: "component",
      tabNav: true,
    },
    icon: iconAccess,
    accessCheck: () =>
      ModelActionBuilder.build(SftpUserList, "useLoadByProjectId").hasAccess,
  },
  "/app/projects/:projectId/access/ssh": {
    props: {
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(SshUser, "createNew"),
          },
          icon: iconSshUser,
          title: "CreateSshUser",
        },
        {
          action: {
            useValue: () => ModelActionBuilder.build(SftpUser, "createNew"),
          },
          icon: iconSftpUser,
          title: "CreateSftpUser",
        },
      ],
    },
    views: {
      main: lazy(() => import("./ssh/overview")),
    },
    icon: iconSshUser,
    linkId: "sshOverview",
  },
  "/app/projects/:projectId/access/ssh/:sshUserId": {
    views: {
      pageTitle: lazy(() => import("./ssh/components/sshUserTitle")),
    },
    props: {
      tabNav: true,
    },
  },
  "/app/projects/:projectId/access/ssh/:sshUserId/details": {
    views: {
      main: lazy(() => import("./ssh/details/general")),
    },
    linkId: "sshDetails",
  },

  "/app/projects/:projectId/access/ssh/:sshUserId/sshKeys": {
    props: {
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                SshUser.useLoadByPathParam(),
                "addPublicKey",
              ),
          },
          title: "createPublicKey",
          icon: iconPublicKey,
        },
      ],
    },
    views: {
      main: lazy(() => import("./ssh/details/sshKeys")),
    },
    linkId: "sshSshKeys",
  },
  "/app/projects/:projectId/access/sftp": {
    props: {
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(SftpUser, "createNew"),
          },
          icon: iconSftpUser,
          title: "CreateSftpUser",
        },
        {
          action: {
            useValue: () => ModelActionBuilder.build(SshUser, "createNew"),
          },
          icon: iconSshUser,
          title: "CreateSshUser",
        },
      ],
    },
    views: {
      main: lazy(() => import("./sftp/overview")),
    },
    icon: iconSftpUser,
    linkId: "sftpOverview",
  },

  "/app/projects/:projectId/access/sftp/:sftpUserId": {
    views: {
      pageTitle: lazy(() => import("./sftp/components/sftpUserTitle")),
    },
    props: {
      tabNav: true,
    },
  },
  "/app/projects/:projectId/access/sftp/:sftpUserId/details": {
    views: {
      main: lazy(() => import("./sftp/details/general")),
    },
    linkId: "sftpDetails",
  },

  "/app/projects/:projectId/access/sftp/:sftpUserId/sshKeys": {
    props: {
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                SftpUser.useLoadByPathParam(),
                "addPublicKey",
              ),
          },
          title: "createPublicKey",
          icon: iconPublicKey,
        },
      ],
    },
    views: {
      main: lazy(() => import("./sftp/details/sshKeys")),
    },
    linkId: "sftpSshKeys",
  },
  "/app/projects/:projectId/access/sftp/:sftpUserId/permissions": {
    views: {
      main: lazy(() => import("./sftp/details/permissions")),
    },
    linkId: "sftpPermissions",
  },
});
