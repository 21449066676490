import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import useWizardState from "@mittwald/flow-components/dist/components/Wizard/hooks/useWizardState";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useAnimationController } from "@mittwald/flow-components/dist/hooks/useAnimationController";
import { iconMfa } from "@mittwald/flow-icons/dist/mfa";

import React, { FC, useState } from "react";
import { useCodeInputAutoSubmit } from "../../../../../../hooks/useCodeInputAutoSubmit";
import { Signup } from "../../../../../../model/signup/Signup";
import DownloadRecoveryCodes from "./components/downloadRecoveryCodes";
import { ScanStep } from "./components/ScanStep";

export const ActivateMfa: FC = () => {
  const [recoveryCodeList, setRecoveryCodeList] = useState<string[]>([""]);
  const rejectionAnimation = useAnimationController();
  const wizardState = useWizardState();

  const form = useForm({
    defaultValues: {
      multiFactorCode: "",
    },
    showSuccessFeedback: false,
    onSubmit: async (values) => {
      const recoveryCodes = await Signup.confirmMfa(
        values.multiFactorCode,
        rejectionAnimation,
      );

      setRecoveryCodeList(recoveryCodes);
      wizardState.gotoNextStep();
    },
  });

  useCodeInputAutoSubmit(form);

  return (
    <WizardModal
      _size="m"
      form={form}
      steps={["qr", "recoveryCodes"]}
      wizardState={wizardState}
    >
      <WizardIntroStep
        description="description"
        headline="scanQR"
        headlineIcon={iconMfa}
        id="qr"
        indicatorText="scanQRCode"
        stepWillSubmitForm
      >
        <ScanStep rejectionAnimation={rejectionAnimation} />
      </WizardIntroStep>
      <WizardStep id="recoveryCodes" indicatorText="recoveryCodes" noAbort>
        <DownloadRecoveryCodes recoveryCodes={recoveryCodeList} />
      </WizardStep>
    </WizardModal>
  );
};

export default ActivateMfa;
