import { cast, types } from "mobx-state-tree";

export const dashboardSettingsStoreModel = types
  .model({
    disabledTiles: types.array(types.string),
  })
  .actions((self) => ({
    update: (disabledTiles: string[]) => {
      self.disabledTiles = cast(disabledTiles);
    },
  }))
  .views((self) => ({
    isDisabled: (tile: string): boolean => {
      return self.disabledTiles.includes(tile);
    },
  }));
