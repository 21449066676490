import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import InlineCode from "@mittwald/flow-components/dist/components/InlineCode";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { StaticInformation } from "@mittwald/flow-components/dist/components/StaticInformation";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC } from "react";
import { EntrypointMeta } from "../../../../model/container/Container";

interface Props {
  relevantMeta?: EntrypointMeta;
}

export const EntrypointInputFields: FC<Props> = (props) => {
  const { relevantMeta } = props;
  const form = useFormContext();
  const entrypointSelection = form.watch("entrypointSelection");
  const commandSelection = form.watch("commandSelection");

  return (
    <Section.Layout>
      <Section.Item headline="entrypoint">
        <Text i18n="entrypointDescription" />
        <SelectBox
          name="entrypointSelection"
          options={[
            { title: "default", value: "default" },
            { title: "custom", value: "custom" },
          ]}
        />
        {entrypointSelection === "custom" && (
          <ColumnLayout medium={[2, 1]}>
            <TextField
              autoFocus
              label="entrypoint"
              name="entrypoint"
              placeholder={{ text: relevantMeta?.entrypoint }}
            />
          </ColumnLayout>
        )}
        {entrypointSelection === "default" && (
          <StaticInformation
            label="entrypoint"
            text={{
              text: <InlineCode>{relevantMeta?.entrypoint}</InlineCode>,
            }}
          />
        )}
      </Section.Item>
      <Section.Item headline="command">
        <Text i18n="commandDescription" />
        <SelectBox
          name="commandSelection"
          options={[
            { title: "default", value: "default" },
            { title: "custom", value: "custom" },
          ]}
        />
        {commandSelection === "custom" && (
          <ColumnLayout medium={[2, 1]}>
            <TextField
              label="command"
              name="command"
              placeholder={{ text: relevantMeta?.command }}
            />
          </ColumnLayout>
        )}
        {commandSelection === "default" && (
          <StaticInformation
            label="command"
            text={{ text: <InlineCode>{relevantMeta?.command}</InlineCode> }}
          />
        )}
      </Section.Item>
    </Section.Layout>
  );
};
