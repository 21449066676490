import HttpResource from "@mittwald/flow-lib/dist/resources/HttpResource";

export enum Severity {
  scheduled = 1,
  minor = 2,
  major = 3,
}

type SeverityKeys = keyof typeof Severity;

interface Service {
  id: number;
  name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  current_incident_type: SeverityKeys | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  parent_id?: string | null;
  children: Service[] | null;
}

interface Maintenance {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  starts_at?: string | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ends_at?: string | null;
  services?: Service[];
}

interface Incident {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  starts_at?: string | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ends_at?: string | null;
  services?: Service[];
  type: SeverityKeys | null;
}

export interface StatusData {
  services: Service[];
  incidents: Incident[];
  maintenances: Maintenance[];
}

export const statusPageResource = new HttpResource<StatusData>(
  import.meta.env.VITE_STATUS_MONITOR_URI,
  {
    cache: "no-cache",
  },
);

export default statusPageResource;

window.addEventListener("focus", () => {
  statusPageResource.expire();
});

window.setInterval(() => {
  if (!document.hasFocus()) {
    return;
  }

  statusPageResource.expire();
}, 30 * 1000);
