import userSettingsStore from "../../store/userSettings";

export interface DashboardSettingsData {
  firstSteps?: boolean;
  todos?: boolean;
  support?: boolean;
  productSlider?: boolean;
  features?: boolean;
  blog?: boolean;
  debit?: boolean;
  status?: boolean;
}

export class Dashboard {
  public static updateSettings(values: DashboardSettingsData): void {
    const disabledTiles = [];
    for (const [key, value] of Object.entries(values)) {
      if (!value) {
        disabledTiles.push(key);
      }
    }
    userSettingsStore.dashboardSettings.update(disabledTiles);
  }

  public static isDisabled(tile: string): boolean {
    return userSettingsStore.dashboardSettings.isDisabled(tile);
  }
}

export default Dashboard;
