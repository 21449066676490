import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import FrontsideRenderer from "@mittwald/flow-renderer/dist/components/FrontsideRenderer/FrontsideRenderer";
import { lazy } from "react";
import { FullscreenRenderer } from "./components/FullscreenRenderer";

export const loginPages = PageTree.build(FrontsideRenderer, {
  "/login": {
    sector: "addons",
    views: {
      main: lazy(() => import("./pages/Login")),
    },
    linkId: "login",
  },
  "/login/mfa": {
    views: {
      main: lazy(() => import("./pages/LoginMfa")),
    },
    linkId: "mfa-login",
  },
}).extend(
  PageTree.build(FullscreenRenderer, {
    "/impersonate": {
      views: {
        main: lazy(() => import("./impersonate/impersonate")),
      },
    },
  }),
);
