import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { SelectBoxOptions } from "@mittwald/flow-components/dist/components/SelectBox";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import is from "@sindresorhus/is";
import AppUserInput from "../../app/AppUserInput";
import DomainHandleUserInput from "../../domain/DomainHandleUserInput";
import UserInput from "../../misc/userInput/UserInput";
import {
  ParsedValue,
  UserInputRecord,
} from "../../misc/userInput/UserInputRecord";

interface GetLabelTextOptions {
  suffix?: string;
}

interface GetFieldNameOptions {
  prefix?: string;
}

const translationRequiredFields: string[] = [
  "installmode",
  "shop_currency",
  "shop_lang",
];

export class UserInputUI {
  private readonly userInput: UserInput;
  private readonly needsTranslatedValue: boolean;

  private constructor(userInput: UserInput) {
    this.userInput = userInput;
    this.needsTranslatedValue = translationRequiredFields.includes(
      userInput.name,
    );
  }

  public static of(userInput: UserInput): UserInputUI {
    return new UserInputUI(userInput);
  }

  public getLabelText(
    opts: GetLabelTextOptions = {},
    label?: string,
  ): I18nDefinition {
    const translations: I18nDefinition[] = [];
    const { suffix } = opts;

    const addTranslationAttempt = (
      ...mainPart: Array<string | number | undefined | false>
    ): void => {
      translations.push(
        joinTranslationKeys(
          "userInput",
          ...mainPart,
          label ?? this.userInput.name,
          suffix,
        ),
      );
    };

    if (this.userInput instanceof AppUserInput) {
      addTranslationAttempt("appInstallation");
      addTranslationAttempt(
        "appInstallation",
        this.userInput.appVersion.app.slug,
      );
    } else if (this.userInput instanceof DomainHandleUserInput) {
      addTranslationAttempt("domain");
    }

    return translations;
  }

  public getDisplayValue(value?: ParsedValue): I18nDefinition {
    if (this.userInput.format === "password") {
      return { text: "••••••" };
    }

    if (value !== undefined) {
      const stringifiedValue = UserInputRecord.stringifyValue(value);

      return this.needsTranslatedValue
        ? this.getLabelText({ suffix: stringifiedValue })
        : { text: stringifiedValue };
    }
  }

  public getFieldName(opts: GetFieldNameOptions = {}): string {
    const { prefix } = opts;
    return [prefix, this.userInput.name].filter(is.string).join(".");
  }

  public getSchemaSelectOptions(): SelectOptions {
    const schema = this.userInput.schema;

    if (schema.enum) {
      return schema.enum.map((value) => ({
        value,
        label: this.getLabelText({
          suffix: value,
        }),
        searchValue: value,
      }));
    }

    return [];
  }

  public getSchemaSelectBoxOptions(): SelectBoxOptions {
    const schema = this.userInput.schema;

    if (schema.enum) {
      return schema.enum.map((value) => ({
        value,
        title: this.getLabelText({
          suffix: value,
        }),
      }));
    }
    return [];
  }
}

export default UserInputUI;
