import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { useAsyncDebounce } from "@mittwald/flow-components/dist/hooks/useAsyncDebounce";
import { iconContainerRegistry } from "@mittwald/flow-icons/dist/containerRegistry";
import React, { FC } from "react";
import { Registry } from "../../../../../../model/container/Registry";
import { RegistryList } from "../../../../../../model/container/RegistryList";

interface Props {
  registries: RegistryList;
}

export const GeneralStep: FC<Props> = (props) => {
  const { registries } = props;

  const validateRegistryUri = (value: string): Promise<boolean> => {
    return Registry.validateUri(value);
  };

  return (
    <WizardIntroStep
      description="description"
      headline="createRegistry"
      headlineIcon={iconContainerRegistry}
      id="general"
      indicatorText="details"
    >
      <Section.Item>
        <ColumnLayout medium={[1, 1]}>
          <TextField
            autoFocus
            label="description"
            name="description"
            rules={{ required: true }}
          />
        </ColumnLayout>
        <ColumnLayout medium={[1, 1]}>
          <TextField
            label="hostname"
            name="url"
            rules={{
              required: true,
              validate: {
                duplicateHostname: (value) =>
                  registries.validateDuplicate(value),
                validHostname: useAsyncDebounce(validateRegistryUri, 500),
              },
            }}
          />
        </ColumnLayout>
      </Section.Item>
    </WizardIntroStep>
  );
};

export default GeneralStep;
