import Select from "@mittwald/flow-components/dist/components/Select/Select";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import React, { FC } from "react";
import AppVersion from "../../../../model/app/AppVersion";

interface AppVersionSelectProps {
  versions: AppVersion[];
  name: string;
  label?: I18nDefinition;
}

export const AppVersionSelect: FC<AppVersionSelectProps> = (props) => {
  const { versions, name, label } = props;

  const versionOptions = versions.map((v) => ({
    label: { text: v.data.externalVersion },
    value: v.data.id,
  }));

  return (
    <Select
      label={label ?? "version"}
      name={name}
      options={versionOptions}
      placeholder="version"
      rules={{ required: true }}
    />
  );
};

export default AppVersionSelect;
