import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import React from "react";
import CreateContractPartner from "../../../pages/app/customer/modals/CreateContractPartner/CreateContractPartner";
import CreateCustomer from "../../../pages/app/customer/modals/CreateCustomer/CreateCustomer";
import DeleteCustomer from "../../../pages/app/customer/modals/DeleteCustomer/DeleteCustomer";
import InviteCustomerMember from "../../../pages/app/customer/modals/InviteCustomerMember/InviteCustomerMember";
import UpdateCustomerAvatar from "../../../pages/app/customer/modals/UpdateCustomerAvatar/UpdateCustomerAvatar";
import CreateProject from "../../../pages/app/project/modals/CreateProject/CreateProject";
import OrderServerWizard from "../../../pages/app/server/modals/OrderServerWizard/OrderServerWizard";
import Customer from "../../customer/Customer";
import { Role } from "../acl/Role";
import { bankruptcyCheckedAction } from "../BankruptGuard";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

ModelActionBuilderRegistry.register({
  model: Customer,
  roles: [Role.customerOwner, Role.customerMember],
  method: "useServers",
})
  .register({
    model: Customer,
    roles: [Role.customerOwner, Role.customerMember],
    method: "useProjects",
  })
  .register({
    model: Customer,
    roles: [Role.customerOwner, Role.customerAccountant],
    method: "hasContact",
  })
  .register({
    model: Customer,
    roles: [Role.customerOwner, Role.customerAccountant],
    method: "createContractPartner",
    altAction: (action) => () =>
      showModal(
        "createContractPartner",
        <CreateContractPartner customer={action.model} />,
      ),
  })
  .register({
    model: Customer,
    roles: [Role.customerOwner, Role.customerMember],
    method: "createServer",
    isAvailable: (customer) =>
      !customer.useIsBankrupt() &&
      !!customer.contact &&
      !customer.data.isBanned &&
      !customer.data.isInDefaultOfPayment,
    altAction: (action) => () =>
      showModal("createServer", <OrderServerWizard customer={action.model} />),
  })
  .register({
    model: Customer,
    roles: [Role.customerOwner, Role.customerMember],
    method: "createProject",
    isAvailable: (customer) =>
      !customer.useIsBankrupt() &&
      !!customer.contact &&
      !customer.data.isBanned &&
      !customer.data.isInDefaultOfPayment,
    altAction: (action) => () =>
      showModal("createProject", <CreateProject customer={action.model} />),
  })
  .register({
    model: Customer,
    roles: [Role.customerOwner],
    method: "delete",
    altAction: (action) =>
      bankruptcyCheckedAction(action.model, () =>
        showModal("deleteCustomer", <DeleteCustomer customer={action.model} />),
      ),
  })
  .register({
    model: Customer,
    roles: [Role.anybody],
    method: "createNew",
    altAction: () => () => showModal("createCustomer", <CreateCustomer />),
  })
  .register({
    model: Customer,
    roles: [Role.customerOwner],
    method: "inviteMember",
    altAction: (action) => () =>
      showModal(
        "inviteCustomerMember",
        <InviteCustomerMember customer={action.model} />,
      ),
  })
  .register({
    model: Customer,
    roles: [Role.customerOwner],
    method: "requestAvatarUpload",
    altAction: (action) => () =>
      showModal(
        "updateCustomerAvatar",
        <UpdateCustomerAvatar customer={action.model} />,
      ),
  });
