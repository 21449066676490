import { Box } from "@mittwald/flow-components/dist/components/Box";
import { useMediaQuery } from "@react-hook/media-query";
import React, { FC } from "react";
import ConfettiExplosion from "react-confetti-explosion";

export const OrderCompleteConfetti: FC = () => {
  const isReducedMotionPreferred = useMediaQuery(
    "(prefers-reduced-motion: reduce)",
  );

  const confetti = !isReducedMotionPreferred && (
    <Box _flexDirection="row" _justifyContent="center">
      <ConfettiExplosion
        colors={[
          "#00C795",
          "#00A674",
          "#BFD4FD",
          "#0054F5",
          "#FF9343",
          "#FFC9A1",
        ]}
        duration={3000}
        force={1}
        particleCount={100}
        particleSize={24}
        width={3000}
        zIndex={10}
      />
    </Box>
  );

  return <>{confetti}</>;
};

export default OrderCompleteConfetti;
