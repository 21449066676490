import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import { ImageMetaProps } from "../../../../../../model/container/Container";
import EnvironmentForm from "../../../components/EnvironmentForm/EnvironmentForm";

export const EnvironmentStep: FC<ImageMetaProps> = (props) => {
  const envSuggestions = props.imageMeta?.env ? props.imageMeta.env : undefined;

  return (
    <WizardStep id="environment" indicatorText="environment">
      <Section.Item headline="addEnvironment">
        <Text i18n="description" />
        <EnvironmentForm suggestedEnvs={envSuggestions} />
      </Section.Item>
    </WizardStep>
  );
};

export default EnvironmentStep;
