import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import CustomerInvite, { CustomerInviteApiData } from "./CustomerInvite";

export class CustomerInviteList extends ListModel<CustomerInvite> {
  public static useLoadAllByCustomerId(customerId: string): CustomerInviteList {
    const customerInvites = mittwaldApi.customerListInvitesForCustomer
      .getResource({
        path: {
          customerId,
        },
        query: {},
      })
      .useWatchData()
      .map((c) => CustomerInvite.fromApiData(c));

    return new CustomerInviteList(customerInvites);
  }

  public static useLoadAllIncoming(): CustomerInviteList {
    const customerInvites = mittwaldApi.customerListCustomerInvites
      .getResource({})
      .useWatchData() as CustomerInviteApiData[];

    return new CustomerInviteList(
      customerInvites.map((c) => CustomerInvite.fromApiData(c)),
    );
  }
}
