import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import path from "path";
import React, { FC } from "react";
import { AppInstallationList } from "../../../../model/app/AppInstallationList";
import Ingress from "../../../../model/domain/Ingress";
import { Project, ProjectDir } from "../../../../model/project";
import DomainUI, { TargetTypes } from "../../../../model/ui/domain/DomainUI";
import AppInstallationSelect from "../../app/components/AppInstallationSelect/AppInstallationSelect";
import { ProjectFileSystemPathField } from "../../components/ProjectFileSystemPathField";
import { ContainerTargetFormFields } from "./containerTargetFormFields";

export interface DomainTargetFormFieldProps {
  ingress?: Ingress;
  targetType: TargetTypes;
}

export const DomainTargetFormFields: FC<DomainTargetFormFieldProps> = (
  props,
) => {
  const { targetType, ingress } = props;
  const project = Project.useLoadByPathParam();
  const form = useFormContext();
  const directory = form.watch("directory");
  const buildLink = useLinkBuilder();

  const isAllowedToUseDirectoryTarget =
    (ingress && DomainUI.isAllowedToUseDirectoryTargets(ingress)) ||
    (targetType === TargetTypes.directory && directory !== "/");
  const isAllowedToUseContainerTarget =
    project.hasContainerAccess() || targetType === TargetTypes.container;

  const appInstallationList = AppInstallationList.useLoadAllByPathParam();
  const currentAppId = form.watch("installationId");
  const currentApp = appInstallationList.findById(currentAppId);
  const currentVersion = currentApp?.useVersion();

  return (
    <TranslationProvider name="domainTarget" type="section">
      <SelectBox
        label="targetType"
        name="targetType"
        options={DomainUI.useTargetTypeOptions(
          project.id,
          isAllowedToUseDirectoryTarget,
          isAllowedToUseContainerTarget,
        )}
        rules={{
          required: true,
        }}
      />
      {targetType === TargetTypes.app && (
        <>
          <ColumnLayout medium={[1, 1]}>
            <AppInstallationSelect name="installationId" />
            {currentApp && currentVersion && (
              <StaticInformation
                label="documentRoot"
                text={{
                  text: path.join(
                    currentApp.installationPath,
                    currentApp.customDocRootPath ??
                      currentVersion.defaultDocRootPath,
                  ),
                }}
              />
            )}
          </ColumnLayout>
          <TextLink
            action={buildLink("appDetails", {
              appInstallationId: currentAppId,
            })}
            i18n="toApp"
          />
        </>
      )}
      {isAllowedToUseDirectoryTarget &&
        targetType === TargetTypes.directory && (
          <ProjectFileSystemPathField
            name="directory"
            onlyDirectoriesAndSymlinks
            projectId={project.id}
            restrictToProjectDirectory={ProjectDir.web}
          />
        )}
      {targetType === TargetTypes.url && (
        <TextField
          label="url"
          name="url"
          placeholder={{ text: "https://" }}
          rules={{
            required: true,
          }}
          type="url"
        />
      )}
      {isAllowedToUseContainerTarget &&
        targetType === TargetTypes.container && (
          <ContainerTargetFormFields project={project} />
        )}
    </TranslationProvider>
  );
};

export default DomainTargetFormFields;
