import { FullscreenErrorComponent } from "@mittwald/flow-components/dist/components/FullscreenErrorComponent";
import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import { RendererComponentType } from "@mittwald/flow-lib/dist/pages/RendererProps";
import { PlainTopPageRenderer } from "@mittwald/flow-renderer/dist/components/PlainTopPageRenderer";
import React from "react";

export const FullscreenRenderer: RendererComponentType = (props) => (
  <PlainTopPageRenderer
    {...props}
    errorView={FullscreenErrorComponent}
    fallback={<LoadingView absolute />}
  />
);

export default FullscreenRenderer;
