import { AvatarSource } from "@mittwald/flow-components/dist/components/Avatar";
import { IconLookup } from "@mittwald/flow-components/dist/components/Icon";
import { iconRoleProjectEmailAdmin } from "@mittwald/flow-icons/dist/roleProjectEmailAdmin";
import { iconRoleProjectExternal } from "@mittwald/flow-icons/dist/roleProjectExternal";
import { iconRoleProjectOwner } from "@mittwald/flow-icons/dist/roleProjectOwner";

import { useImageUrlBuilder } from "../../../hooks/useFileServiceUrlBuilder";
import { ProjectRoleName } from "../../project";
import ProjectBase from "../../project/ProjectBase";

export class ProjectUI {
  public readonly project: ProjectBase;

  private constructor(project: ProjectBase) {
    this.project = project;
  }

  public static of(project: ProjectBase): ProjectUI {
    return new ProjectUI(project);
  }

  public static getRoleIcon(role: ProjectRoleName): IconLookup {
    return role === "owner"
      ? iconRoleProjectOwner
      : role === "external"
        ? iconRoleProjectExternal
        : iconRoleProjectEmailAdmin;
  }

  public useProjectAvatarSource(): AvatarSource {
    const imageUrl = useImageUrlBuilder();

    const initials = !this.project.imageRefId
      ? this.project.description
      : undefined;
    const url = imageUrl(this.project.imageRefId);

    return {
      url,
      initials,
    };
  }

  public static useDryProjectAvatarSource(): AvatarSource {
    useImageUrlBuilder();

    return {
      url: undefined,
      initials: [],
    };
  }
}

export default ProjectUI;
