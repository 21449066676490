import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import FrontsideRenderer from "@mittwald/flow-renderer/dist/components/FrontsideRenderer/FrontsideRenderer";
import { lazy } from "react";

export const profileDeletedPages = PageTree.build(FrontsideRenderer, {
  "/profile-deleted": {
    sector: "addons",
    views: {
      main: lazy(() => import("./profileDeleted")),
    },
    linkId: "profile-deleted",
  },
});
