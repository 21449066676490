import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useTranslation } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import React, { FC } from "react";
import AppInstallation, {
  CopyAppInstallationInput,
} from "../../../../../model/app/AppInstallation";
import Model from "../../../../../model/Model";
import { Project } from "../../../../../model/project";
import OverviewStepContent from "../UpdateInstalledSystemSoftwareVersion/components/OverviewStepContent";
import CopyStepContent from "./components/CopyStepContent";

interface Props {
  appInstallation: AppInstallation;
}

export const CopyAppInstallation: FC<Props> = (props) => {
  const { appInstallation } = props;

  const goto = useGotoLink();
  const translate = useTranslation();
  const project = Project.useLoadById(appInstallation.projectId);

  const form = useForm<CopyAppInstallationInput>({
    defaultValues: {
      description: `${appInstallation.description} - ${translate("copy")}`,
      targetProjectId: project.id,
    },
    onSubmit: async (values) => {
      await appInstallation.copy(values);
      goto("apps", { projectId: values.targetProjectId });
    },
  });

  const php = appInstallation.useInstalledPhp();

  const phpVersion = php?.useVersion() ?? Model.useDryApi();

  const currentFee = phpVersion?.checkCurrentFee();

  const otherTargetProject = project.id !== form.watch("targetProjectId");

  return (
    <WizardModal form={form} steps={["copy", "overview"]}>
      <WizardStep id="copy" indicatorText="copyIndicator">
        <CopyStepContent
          appInstallation={appInstallation}
          currentFee={
            !!currentFee && otherTargetProject ? currentFee : undefined
          }
          project={project}
        />
      </WizardStep>
      {currentFee && phpVersion && (
        <WizardStep id="overview" indicatorText="overviewIndicator">
          <OverviewStepContent
            currentFee={currentFee}
            project={project}
            systemSoftwareVersion={phpVersion}
          />
        </WizardStep>
      )}
    </WizardModal>
  );
};

export default CopyAppInstallation;
