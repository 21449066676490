import useSelector from "@mittwald/flow-lib/dist/hooks/useSelector";
import userSettingsStore from "../../store/userSettings";

export interface ChangelogEntryData {
  content: string;
  createdAt: string | undefined;
  id: number;
}

export class ChangelogEntry {
  public readonly data: ChangelogEntryData;
  public readonly id: number;
  public readonly createdAt: string | undefined;
  public readonly content: string;

  public constructor(data: ChangelogEntryData) {
    this.data = data;
    this.id = data.id;
    this.createdAt = data.createdAt;
    this.content = data.content;
  }

  public useIsNew(): boolean {
    return useSelector(() => !userSettingsStore.changelogs.isRead(this.id));
  }

  public markAsRead(): void {
    return userSettingsStore.changelogs.markAsRead(this.id);
  }

  public valueOf(): number {
    return this.id;
  }
}

export default ChangelogEntry;
