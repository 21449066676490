import { types } from "mobx-state-tree";

export const statsDisplayModel = types
  .model({
    logarithmicDisplay: types.boolean,
  })
  .actions((self) => ({
    setLogarithmic: (isLogarithmic: boolean) => {
      self.logarithmicDisplay = isLogarithmic;
    },
  }))
  .views((self) => ({
    isLogarithmicDisplay: (): boolean => {
      return self.logarithmicDisplay;
    },
  }));
