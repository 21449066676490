import { Box } from "@mittwald/flow-components/dist/components/Box";
import styled from "styled-components";

export const SelectContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;

  & > div {
    flex: 1;
  }
`;
