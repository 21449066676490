import { Box } from "@mittwald/flow-components/dist/components/Box";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import Switch from "@mittwald/flow-components/dist/components/Switch/Switch";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";

interface Props {
  isDisabled: boolean;
}

export const ConfigurationDedicationSection: FC<Props> = (props) => {
  const { isDisabled } = props;

  return (
    <Section.Item headline="dedication">
      <Box>
        <Switch disabled={isDisabled} name="dedicated" textRight />
      </Box>
      <Text i18n="dedicationDescription" small squished />
    </Section.Item>
  );
};

export default ConfigurationDedicationSection;
