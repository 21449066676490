import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import React, { FC, useEffect } from "react";
import { mittwaldApi } from "../../api/Mittwald";
import useImpersonated from "../../hooks/useImpersonated";
import User from "../../model/user/User";
import { FeedbackPollModal } from "./FeedbackPollModal";
import { DateTime, Duration } from "luxon";

export const FeedbackPoll: FC = () => {
  const user = User.useMe();
  const isImpersonated = useImpersonated();
  const isNewUser = user.data.registeredAt
    ? DateTime.now().diff(DateTime.fromISO(user.data.registeredAt)) <
      Duration.fromObject({ days: 60 })
    : true;

  const isOAuthPage = window.location.pathname.includes("/oauth");

  const pollModalVisibility = useVisibilityController();

  const pollSettingsResource = mittwaldApi.userGetPollStatus.getResource({
    path: {
      userId: user.id,
    },
  });
  const pollSettings = pollSettingsResource.useWatchData();

  useEffect(() => {
    if (
      pollSettings.shouldShow &&
      !isImpersonated &&
      !isNewUser &&
      !isOAuthPage
    ) {
      pollModalVisibility.show();
    } else {
      pollModalVisibility.hide();
    }
  }, [pollSettings.shouldShow]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        pollSettingsResource.expire();
      },
      2 * 60 * 1000,
    );
    return () => clearInterval(interval);
  }, [user.id]);

  return (
    <FeedbackPollModal visibility={pollModalVisibility}></FeedbackPollModal>
  );
};

export default FeedbackPoll;
