import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC, useEffect } from "react";
import { NewCronjobInputs } from "../../../../../../model/cronjob/Cronjob";
import { CronjobUI } from "../../../../../../model/ui/cronjob/CronjobUI";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import CronjobFileCommandForm from "../../../components/CronjobFileCommandForm";
import CronjobFileUrlForm from "../../../components/CronjobFileUrlForm";

export const TypeStep: FC = () => {
  const form = useFormContext<NewCronjobInputs>();

  const [watchedDestinationType, watchedDestinationUrl, appId] = form.watch([
    "destination.type",
    "destination.url",
    "appId",
  ]);

  useEffect(() => {
    if (watchedDestinationType === "url") {
      form.setValue("destination.url", "", { shouldDirty: false });
    }
  }, [watchedDestinationType]);

  return (
    <WizardStep id="type" indicatorText="type">
      <Section.Item headline="type">
        <Text i18n="type" />
        <SelectBox
          name="destination.type"
          options={CronjobUI.typeSelectBoxOptions}
          rules={{ required: true }}
        />
        <Text i18n="destination" />
        {watchedDestinationType === "command" ? (
          <CronjobFileCommandForm appId={appId} controller={form as any} />
        ) : (
          <CronjobFileUrlForm url={watchedDestinationUrl} />
        )}
      </Section.Item>
    </WizardStep>
  );
};

export default TypeStep;
