import { FC, PropsWithChildren } from "react";
import { createPortal } from "react-dom";

export const RootPortal: FC<PropsWithChildren> = (props) => {
  const root = document.getElementById("status-root");

  if (root) {
    return createPortal(props.children, root);
  }

  return null;
};
