import { isDomain } from "@mittwald/flow-lib/dist/validation/domain";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import Domain from "./Domain";
import { TldList } from "./TldList";

export type GeneratedDomainsApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Domain_SuggestedDomains;

export interface GenerateDomainsInputs {
  prompt: string;
  tlds: string[];
}

export interface GeneratedDomain {
  domain: string;
  available: boolean;
}

export class GeneratedDomains {
  public readonly apiData: GeneratedDomainsApiData;
  public readonly domains: GeneratedDomain[];

  private constructor(
    data: GeneratedDomainsApiData,
    promptDomain?: GeneratedDomain,
  ) {
    this.apiData = data;
    const apiDataDomain = data.domains.map((domain) => ({
      available: true,
      domain,
    }));
    this.domains = promptDomain
      ? [promptDomain, ...apiDataDomain]
      : apiDataDomain;
  }

  public static fromApiData(
    data: GeneratedDomainsApiData,
    promptDomain?: GeneratedDomain,
  ): GeneratedDomains {
    return new GeneratedDomains(data, promptDomain);
  }

  public static async generateDomains(
    prompt: string,
    domainCount: number,
    tlds: string[],
  ): Promise<GeneratedDomains | undefined> {
    const generatedDomainsResponse = await mittwaldApi.domainSuggest.request({
      query: { domainCount, prompt, tlds },
    });
    if (generatedDomainsResponse.status != 200) {
      return undefined;
    }
    return GeneratedDomains.fromApiData(generatedDomainsResponse.content);
  }

  public static async generateDomainsWithPromptDomain(
    prompt: string,
    domainCount: number,
    tlds: string[],
  ): Promise<GeneratedDomains | undefined> {
    const promptIsDomain = isDomain(prompt);
    if (!promptIsDomain) {
      return GeneratedDomains.generateDomains(prompt, domainCount, tlds);
    }

    const promptDomainIsAvailable =
      (await Domain.isAvailable(prompt)) === "available";
    const tldAvailable = (await TldList.getTldByDomain(prompt)) != undefined;

    const promptDomain: GeneratedDomain = {
      domain: prompt,
      available: promptDomainIsAvailable && tldAvailable,
    };

    const generatedDomainsResponse = await mittwaldApi.domainSuggest.request({
      query: {
        domainCount: domainCount - 1,
        prompt,
        tlds,
      },
    });
    if (generatedDomainsResponse.status != 200) {
      return undefined;
    }
    return GeneratedDomains.fromApiData(
      generatedDomainsResponse.content,
      promptDomain,
    );
  }

  public getFirstAvailableDomain(): string | undefined {
    if (this.domains.length === 0) {
      return undefined;
    }
    const foundDomain = this.domains.find((domain) => domain.available);
    return foundDomain ? foundDomain.domain : undefined;
  }
}
