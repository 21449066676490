import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import React, { FC, useState } from "react";
import { SftpUser } from "../../../../../model/access/SftpUser";
import { NewSftpUserInputs } from "../../../../../model/access/types";
import { Project } from "../../../../../model/project";
import DescriptionStep from "./components/DescriptionStep";
import PermissionStep from "./components/PermissionStep";

export const CreateSftpUser: FC = () => {
  const goto = useGotoLink();
  const [noDirectories, setNoDirectories] = useState<boolean>();
  const project = Project.useLoadByPathParam();

  const form = useForm<NewSftpUserInputs, string>({
    translationKey: "createSftpUser",
    defaultValues: {
      description: "",
      password: "",
      comment: "",
      key: "",
      accessLevel: "full",
      authType: "password",
      directories: [{ path: "/" }],
    },
    onSubmit: async (values) => {
      const result = await SftpUser.createNew(values, project);

      if (result === "noDirectories") {
        setNoDirectories(true);
        return false;
      } else if (result === "invalidKey") {
        form.setError("key", {
          message: "invalidKey",
        });
        return false;
      }

      goto("sftpDetails", { sftpUserId: result.id }, { replace: true });
    },
  });

  return (
    <WizardModal _size="l" form={form} steps={["description", "permissions"]}>
      <DescriptionStep />
      <PermissionStep noDirectories={noDirectories} projectId={project.id} />
    </WizardModal>
  );
};

export default CreateSftpUser;
