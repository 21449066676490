import { faLightbulbOn } from "@fortawesome/pro-regular-svg-icons/faLightbulbOn";
import {
  BadgeSelectionList,
  BadgeSelectionListItem,
} from "@mittwald/flow-components/dist/components/BadgeSelectionList";
import {
  Form,
  FormController,
  useForm,
} from "@mittwald/flow-components/dist/components/Form";
import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import MoneyValue from "@mittwald/flow-components/dist/components/MoneyValue";
import { Placeholder } from "@mittwald/flow-components/dist/components/PlaceholderBox";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { Dispatch, FC, useState } from "react";
import { NewDomainInputs } from "../../../../../../model/domain/Domain";
import {
  GeneratedDomain,
  GeneratedDomains,
  GenerateDomainsInputs,
} from "../../../../../../model/domain/GeneratedDomains";
import DomainPrice from "../../../../../../model/domain/prices/DomainPrice";
import DomainUI from "../../../../../../model/ui/domain/DomainUI";

interface Props {
  setDisablePrimaryAction: Dispatch<React.SetStateAction<boolean>>;
  domainForm: FormController<NewDomainInputs>;
  domainPrices: DomainPrice[];
}

export const SelectGeneratedDomainSection: FC<Props> = (props) => {
  const { setDisablePrimaryAction, domainForm, domainPrices } = props;
  const [generateDomainsError, setGeneratedDomainsError] = useState<
    string | undefined
  >();
  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  const generatorTlds: BadgeSelectionListItem[] =
    DomainUI.useGeneratorTldBadges();

  const generatedDomains = domainForm.getValues()
    .generatedDomains as GeneratedDomain[];

  const generateForm = useForm<GenerateDomainsInputs>({
    translationKey: "generateDomains",
    defaultValues: {
      tlds: [],
      prompt: domainForm.getValues("generatorPrompt") as string,
    },
    onSubmit: async (values) => {
      const { prompt, tlds } = values;
      domainForm.setValue("generatorPrompt", prompt);
      setIsGenerating(true);
      setDisablePrimaryAction(true);
      const response = await GeneratedDomains.generateDomainsWithPromptDomain(
        prompt,
        6,
        tlds,
      );
      setIsGenerating(false);
      setDisablePrimaryAction(false);
      if (response == undefined) {
        setGeneratedDomainsError("noDomainsGeneratedError");
        return;
      }
      domainForm.setValue("generatedDomains", response.domains);
      domainForm.setValue("selectedDomain", response.getFirstAvailableDomain());
      setGeneratedDomainsError(undefined);
    },
  });

  const selectBox = (
    <SelectBox
      _maxH={generatedDomains.length == 0 ? "0px" : undefined}
      label={
        generatedDomains.length > 0 ? "selectSuggestedDomain" : { text: "" }
      }
      minOptionWidth={300}
      name="selectedDomain"
      options={DomainUI.getGeneratedDomainsOptions(
        generatedDomains,
        domainPrices,
        (value) => ({
          id: "generatedDomainPrice",
          values: {
            price: <MoneyValue value={value} />,
          },
        }),
      )}
      rules={{
        validate: {
          noDomainsGenerated: () => generatedDomains.length > 0,
        },
        required: true,
      }}
    />
  );

  const placeholder = (
    <Placeholder
      description={
        generateDomainsError ? "noDomainsGeneratedErrorDescription" : undefined
      }
      headline={generateDomainsError ?? "noDomainsGenerated"}
      icon={faLightbulbOn}
    />
  );

  return (
    <TranslationProvider name="generateDomains" type="section">
      <Section.Item>
        <Text i18n="generateDomainsDescription" />
        <Form controller={generateForm}>
          <TextField
            autoFocus
            buttonRight={{
              text: "generateDomains",
              action: {
                form: generateForm,
              },
            }}
            name="prompt"
            placeholder="generateDomainsPromptPlaceholder"
            rules={{
              maxLength: 500,
              required: true,
            }}
          />
          <BadgeSelectionList
            _mt="s"
            items={generatorTlds}
            label="tlds"
            name="tlds"
          />
        </Form>
        {isGenerating && <LoadingView _mb="l" _mt="l" />}
        {generatedDomains.length == 0 && !isGenerating && placeholder}
        {!isGenerating && selectBox}
      </Section.Item>
    </TranslationProvider>
  );
};
