import { DateTime } from "luxon";
import { AggregateReferenceApiSchema } from "../../../../../model/support/Conversation";
import { getDefaultRelatedToChild } from "../../../../../pages/app/support/misc/getDefaultRelatedToChild";

export const isInNormalSupportTime = (): boolean => {
  const currentDate = DateTime.now();
  const from = DateTime.fromISO("08:30");
  const to = DateTime.fromISO("18:00");
  return currentDate > from && currentDate < to;
};

export const getConversationDetailsFromPathname = (
  pathname: string,
): {
  relatedTo: AggregateReferenceApiSchema;
  relatedToChild?: AggregateReferenceApiSchema;
  categoryName: string;
} => {
  const defaultRelatedToChild = getDefaultRelatedToChild();

  const relationName = pathname.split("/")[2];
  const relationId = pathname.split("/")[3];
  const category = pathname.split("/")[4];
  const relatedToChildId = pathname.split("/")[5];

  if (relationName === "organizations") {
    const relation = {
      id: relationId ?? "",
      domain: "customer",
      aggregate: "customer",
    };
    let categoryName = "general";

    if (category) {
      if (category == "contract-items") {
        categoryName = "articles";
      } else if (category === "contract-partner") {
        categoryName = "contractOwner";
      } else {
        categoryName = category;
      }
    }

    return { relatedTo: relation, categoryName };
  } else if (relationName === "projects") {
    const relation = {
      id: relationId ?? "",
      domain: "project",
      aggregate: "project",
    };
    let categoryName = "general";
    let relatedToChild: AggregateReferenceApiSchema = {
      id: "",
      domain: defaultRelatedToChild.domain,
      aggregate: defaultRelatedToChild.aggregate,
    };

    if (category) {
      if (category === "email") {
        categoryName = "emails";
      } else if (category === "database") {
        categoryName = "databases";
      } else if (category === "access") {
        categoryName = "ssh";
      } else {
        categoryName = category;
      }
    }

    if (category === "apps" && relatedToChildId) {
      relatedToChild = {
        id: relatedToChildId,
        domain: "app",
        aggregate: "appinstallation",
      };
    }

    return { relatedTo: relation, relatedToChild, categoryName };
  } else if (relationName === "servers") {
    return {
      relatedTo: {
        id: relationId ?? "",
        domain: "project",
        aggregate: "placementgroup",
      },
      categoryName: "general",
    };
  } else {
    return {
      relatedTo: {
        id: "",
        domain: defaultRelatedToChild.domain,
        aggregate: defaultRelatedToChild.aggregate,
      },
      categoryName: "",
    };
  }
};
