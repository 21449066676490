import { Section } from "@mittwald/flow-components/dist/components/Section";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import noDuplicate from "@mittwald/flow-lib/dist/validation/noDuplicate";
import React, { FC } from "react";
import { DomainPathInputs } from "../../../../../../model/domain/Domain";
import Ingress from "../../../../../../model/domain/Ingress";
import { IngressPath } from "../../../../../../model/domain/IngressPath";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";

interface Props {
  ingress: Ingress;
}

export const PathStep: FC<Props> = (props) => {
  const { ingress } = props;
  const form = useFormContext<DomainPathInputs>();
  const path = form.watch("path");
  const existingPaths = ingress.getPaths().map((p) => p.path);

  return (
    <WizardIntroStep
      description={{
        id: "pathDesc",
        values: {
          domain: ingress.hostname,
        },
      }}
      headline="path"
      headlineIcon={iconDomain}
      id="path"
      indicatorText="path"
    >
      <Section.Item>
        <TextField
          label="path"
          name="path"
          rules={{
            required: true,
            validate: {
              noDuplicate: noDuplicate(existingPaths, { trimSlashes: true }),
              pattern: (value) =>
                IngressPath.pathRegex.test(IngressPath.trimSlashes(value)),
            },
          }}
        />
        <StaticInformation
          label="domain"
          text={{ text: `${ingress.hostname}/${path ? path : ""}` }}
        />
      </Section.Item>
    </WizardIntroStep>
  );
};

export default PathStep;
