import { FileData } from "@mittwald/flow-components/dist/components/FileInputField";
import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import { iconFileCertificate } from "@mittwald/flow-icons/dist/fileCertificate";
import React, { FC } from "react";
import useContextState, {
  StateReturnContext,
} from "../../../../../../../../hooks/useContextState";
import { getCertificateData } from "../../../../utils/getCertificateData";
import { KeyField } from "../../../components/KeyField/KeyField";

interface State {
  ca: FileData[] | string;
  caData: string;
}

export const useCaStepState = (): StateReturnContext<State> => {
  return useContextState<State>(
    {
      ca: [],
      caData: "",
    },
    "caStepState",
  );
};

export const CaStep: FC = () => {
  const [state, setState] = useCaStepState();
  const form = useForm<State>({
    defaultValues: state,
    onSubmit: (values) => {
      const caData = getCertificateData(values.ca);
      if (caData.toLowerCase().includes("private")) {
        form.setError("ca", {
          message: joinTranslationKeys(
            "modal",
            "uploadCertificate",
            "ca",
            "containsPrivateKey",
          ),
        });
        return false;
      }
      setState({ ca: values.ca, caData: caData });
    },
  });

  return (
    <WizardIntroStep
      description="caStep.description"
      form={form}
      headline="caStep"
      headlineIcon={iconFileCertificate}
      id="certificateAuthority"
      indicatorText="caStep"
    >
      <KeyField
        accept={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "text/plain": [".crt", ".cert", ".txt", ".pem"],
        }}
        multiple
        name="ca"
        placeholder={{
          text: "-----BEGIN CERTIFICATE-----\n...\n-----END CERTIFICATE-----",
        }}
        required={false}
      />
    </WizardIntroStep>
  );
};
