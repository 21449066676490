import { useEffect, useRef } from "react";
import { MockXhrServer, newServer } from "mock-xmlhttprequest";
import sleep from "@mittwald/flow-lib/dist/lib/sleep";
import { types } from "mobx-state-tree";
import useCheckDemoModeEnabled from "./useCheckDemoModeEnabled";

const blobToBase64 = (blob: Blob): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};

const filesMockStoreModel = types
  .model({
    files: types.map(types.string),
  })
  .actions((self) => ({
    setFile: (key: string, content: string) => {
      self.files.set(key, content);
    },
    removeFile: (key: string) => {
      self.files.delete(key);
    },
  }));

export const filesMockStore = filesMockStoreModel.create({ files: {} });

export const mockFileUploadServer = (): MockXhrServer =>
  newServer({
    post: [
      /.*\/v2\/files\/.*/,
      async (xhr) => {
        // The ref id of the uploaded file
        const refId = (xhr as any).url.split("/").slice(-1)[0];

        const form: FormData = (xhr as any).body;
        const file = form.get("file") as File;
        const blob = file.slice();

        filesMockStore.setFile(refId, await blobToBase64(blob));

        xhr.uploadProgress(xhr.getRequestBodySize());
        await sleep(0.7);
        xhr.respond(200);
      },
    ],
  }).install();

/**
 * Use this hook if you want to mock a file upload server (POST .*\/v2\/files\/.*)
 *
 * WARNING: When this hook active, Hot Module Reloading is not working
 */
export const useMockFileUploadServer = (): void => {
  const demoDataEnabled = useCheckDemoModeEnabled();
  const server = useRef<MockXhrServer>();

  // it's important to destroy the server to have HMR working again
  const destroy = (): void => {
    if (server.current) {
      server.current.remove();
      server.current = undefined;
    }
  };

  if (!demoDataEnabled) {
    destroy();
    return;
  }

  useEffect(() => {
    server.current = mockFileUploadServer();
    return destroy;
  });
};
