import { LinkIdAction } from "@mittwald/flow-components/dist/lib/actions/LinkIdAction";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";

import { Role } from "../acl/Role";
import Session from "../../signup/Session";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

ModelActionBuilderRegistry.register({
  model: Session,
  method: "closeSession",
  roles: [Role.anybody],
  isAvailable: (session) => !session.useIsActive(),
  altAction: (action) => ({
    action,
    confirmation: {
      required: true,
      modalTranslationKey: "closeSession",
      modalProps: { headlineIcon: iconDelete },
    },
  }),
}).register({
  model: Session,
  method: "closeSessionAndChangePassword",
  roles: [Role.anybody],
  isAvailable: (session) => session.useIsActive(),
  altAction: (action) => [
    {
      action,
      confirmation: {
        modalTranslationKey: "notOwnDevice",
        required: true,
        modalProps: { headlineIcon: iconDelete },
      },
    },
    new LinkIdAction("security", {
      replace: true,
    }),
  ],
});
