import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { Link } from "@mittwald/flow-components/dist/components/Link";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { iconInvite } from "@mittwald/flow-icons/dist/invite";

import React, { FC } from "react";
import Customer from "../../../../../model/customer/Customer";
import CustomerInvite, {
  CustomerInviteCreateInputs,
} from "../../../../../model/customer/CustomerInvite";
import AddInviteForm from "../../members/components/AddInviteForm";

interface Props {
  customer: Customer;
}

export const InviteCustomerMember: FC<Props> = (props) => {
  const { customer } = props;
  const visibility = useVisibilityController();

  const addInviteForm = useForm<CustomerInviteCreateInputs>({
    translationKey: "addInvite",
    defaultValues: {
      role: "member",
      mailAddress: undefined,
      membershipExpiresAt: undefined,
      message: undefined,
    },
    onSubmit: async (values) => {
      const response = await CustomerInvite.createNew(
        {
          ...values,
        },
        customer.id,
      );

      if (response === "inviteExists" || response === "memberExists") {
        addInviteForm.setError("mailAddress", {
          message: response,
        });
        return false;
      }
    },
  });

  const primaryAction: AnyAction = [
    { form: addInviteForm },
    visibility.hide,
    {
      link: {
        id: "customerInvitesOverview",
        options: {
          pathParams: {
            customerId: customer.id,
          },
        },
      },
    },
  ];

  return (
    <DefaultModal
      description={{
        id: "description",
        values: {
          onboardingLink: (
            <Link
              _color="link"
              _inline
              action={{
                actions: [
                  {
                    fn: () => {
                      visibility.hide();
                    },
                  },
                  {
                    link: {
                      id: "onboarding",
                    },
                  },
                ],
              }}
              i18n="onboarding"
            />
          ),
        },
      }}
      headline="inviteCustomerMember"
      headlineIcon={iconInvite}
      primary={{ text: "invite", action: primaryAction, ok: true }}
      visibility={visibility}
    >
      <AddInviteForm form={addInviteForm} type="customer" />
    </DefaultModal>
  );
};

export default InviteCustomerMember;
