import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { useTranslation } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { extractTextFromReactNode } from "@mittwald/flow-components/dist/lib/extractTextFromReactNode";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import { MittwaldApi } from "../../../api/Mittwald";
import ModelRelationType from "../../misc/modelRelation/ModelRelationType";
import ConversationCategory from "../../support/ConversationCategory";
import { ConversationCategoryList } from "../../support/ConversationCategoryList";

type SingleCategoryReferenceType =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Conversation_CategoryReferenceType[number];

export class ConversationCategoryListUI {
  public readonly conversationCategoryList: ConversationCategoryList;

  private constructor(conversationCategoryList: ConversationCategoryList) {
    this.conversationCategoryList = conversationCategoryList;
  }

  public static of(
    conversationCategoryList: ConversationCategoryList,
  ): ConversationCategoryListUI {
    return new ConversationCategoryListUI(conversationCategoryList);
  }

  public filterByRelationType(
    relationType: ModelRelationType,
    item: ConversationCategory,
  ): boolean {
    let mappedName: SingleCategoryReferenceType = "unspecified";

    switch (relationType.aggregate) {
      case "customer":
        mappedName = "organisation";
        break;
      case "placementgroup":
        mappedName = "server";
        break;
      default:
        mappedName = relationType.aggregate as SingleCategoryReferenceType;
        break;
    }

    return item.data.referenceType.includes(mappedName);
  }

  public getCategorySelectOptions(
    relationType: ModelRelationType,
    translate: ReturnType<typeof useTranslation>,
  ): SelectOptions {
    return this.conversationCategoryList.items
      .filter((item) => this.filterByRelationType(relationType, item))
      .sort((a, b) => {
        const aName = extractTextFromReactNode(
          translate(joinTranslationKeys("conversationCategory", a.name)),
        );
        const bName = extractTextFromReactNode(
          translate(joinTranslationKeys("conversationCategory", b.name)),
        );

        return aName.localeCompare(bName);
      })
      .map((s) => ({
        value: s.id,
        label: joinTranslationKeys("conversationCategory", s.name),
      }));
  }

  public getCategoryIdByCategoryName(name: string): string | undefined {
    return this.conversationCategoryList.items.find(
      (category) => category.name === name,
    )?.id;
  }
}

export default ConversationCategoryListUI;
