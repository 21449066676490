import { AvatarSource } from "@mittwald/flow-components/dist/components/Avatar";
import { IconLookup } from "@mittwald/flow-components/dist/components/Icon";
import { iconRoleCustomerAccountant } from "@mittwald/flow-icons/dist/roleCustomerAccountant";
import { iconRoleCustomerMember } from "@mittwald/flow-icons/dist/roleCustomerMember";
import { iconRoleCustomerOwner } from "@mittwald/flow-icons/dist/roleCustomerOwner";

import { useImageUrlBuilder } from "../../../hooks/useFileServiceUrlBuilder";
import Customer from "../../customer/Customer";
import { CustomerRoleName } from "../../customer/CustomerRole";

export class CustomerUI {
  public readonly customer: Customer;

  private constructor(customer: Customer) {
    this.customer = customer;
  }

  public static of(customer: Customer): CustomerUI {
    return new CustomerUI(customer);
  }

  public static getRoleIcon(role: CustomerRoleName): IconLookup {
    return role === "owner"
      ? iconRoleCustomerOwner
      : role === "accountant"
        ? iconRoleCustomerAccountant
        : iconRoleCustomerMember;
  }

  public useCustomerAvatarSource(): AvatarSource {
    const imageUrl = useImageUrlBuilder();

    const initials = this.customer.name;
    const url = this.customer.data.avatarRefId
      ? imageUrl(this.customer.data.avatarRefId)
      : undefined;

    return {
      url,
      initials,
    };
  }
}

export default CustomerUI;
