import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";

export interface UnorderedListProps {
  length: number;
  translationKey: string;
}

export const UnorderedList: FC<UnorderedListProps> = (props) => {
  return (
    <ul>
      {Array(props.length)
        .fill("")
        .map((value, index) => (
          <li key={index}>
            <Text i18n={`${props.translationKey}${index + 1}`} />
          </li>
        ))}
    </ul>
  );
};
