import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { SshUser } from "./SshUser";

export class SshUserList extends ListModel<SshUser> {
  public static useLoadByProjectId(projectId: string): SshUserList {
    return new SshUserList(
      mittwaldApi.sshUserListSshUsers
        .getResource({ path: { projectId }, query: {} })
        .useWatchData()
        .map((sshUserData) => SshUser.fromApiData(sshUserData)),
    );
  }

  public static useLoadByPathParam(): SshUserList {
    const { projectId } = usePathParams("projectId");
    return SshUserList.useLoadByProjectId(projectId);
  }
}
