import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { useAnimationController } from "@mittwald/flow-components/dist/hooks/useAnimationController";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconMfa } from "@mittwald/flow-icons/dist/mfa";

import React, { FC, useState } from "react";
import { CodeInput } from "../../../../../../components/CodeInput";
import { useCodeInputAutoSubmit } from "../../../../../../hooks/useCodeInputAutoSubmit";
import { Signup } from "../../../../../../model/signup/Signup";
import { DeactivateMfaFormFields } from "../../../types";

export const DeactivateMfa: FC = () => {
  const [recovery, setRecovery] = useState(false);
  const animation = useAnimationController();
  const visibility = useVisibilityController();

  const form = useForm<DeactivateMfaFormFields>({
    translationKey: "change",
    defaultValues: {
      multiFactorCode: "",
    },
    onSubmit: async (values) => {
      await Signup.removeMfa(values.multiFactorCode, animation);
      visibility.hide();
    },
  });

  useCodeInputAutoSubmit(form);

  const toggleInputMode = (): void => {
    setRecovery((isRecovery) => !isRecovery);
    form.reset();
  };

  return (
    <DefaultModal
      _size="s"
      description="description"
      headline="deactivateMfa"
      headlineIcon={iconMfa}
      linkAction={{
        text: recovery ? "toggle2fa" : "toggleRecovery",
        action: toggleInputMode,
      }}
      primary={{ action: [{ form }, visibility.hide], destructive: true }}
      visibility={visibility}
    >
      <Form controller={form}>
        {recovery && (
          <CodeInput
            label="recoveryCode"
            maxLength={16}
            preventEnterPress
            rejectionAnimation={animation}
          />
        )}
        {!recovery && (
          <CodeInput
            label="2fa"
            preventEnterPress
            rejectionAnimation={animation}
          />
        )}
      </Form>
    </DefaultModal>
  );
};

export default DeactivateMfa;
