import { SelectBoxOptions } from "@mittwald/flow-components/dist/components/SelectBox";
import { TerminationType } from "../../contract/Contract";

export class TerminationUI {
  public static getTerminationTypeOptions(): SelectBoxOptions {
    const terminationDateTypes: TerminationType[] = ["nextDate", "customDate"];
    return terminationDateTypes.map((t) => ({ value: t, title: t }));
  }
}

export default TerminationUI;
