import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { iconFileCertificate } from "@mittwald/flow-icons/dist/fileCertificate";
import React from "react";
import { FC } from "react";
import useContextState, {
  StateReturnContext,
} from "../../../../../../../../hooks/useContextState";
import { getCertificateData } from "../../../../utils/getCertificateData";
import { SSLCertificateParseError } from "../../../../utils/sslCertificateApiErrors";
import { KeyField } from "../../../components/KeyField/KeyField";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import { EditCertificateProps } from "../../EditCertificate";
import { FileData } from "@mittwald/flow-components/dist/components/FileInputField";

interface State {
  certificate: FileData[] | string;
  certificateData: string;
}
export const useCertificateStepState = (): StateReturnContext<State> => {
  return useContextState<State>(
    {
      certificate: [],
      certificateData: "",
    },
    "certificateStepState",
  );
};

export const CertificateStep: FC<EditCertificateProps> = (props) => {
  const { certificate } = props;
  const [state, setState] = useCertificateStepState();

  const form = useForm<Pick<State, "certificate">>({
    defaultValues: {
      certificate: state.certificate,
    },
    onSubmit: async (values) => {
      const certificateData = getCertificateData(values.certificate);

      try {
        if (certificateData.toLowerCase().includes("private")) {
          throw new Error("containsPrivateKey");
        }

        const statusResult =
          await certificate.checkEditCertificate(certificateData);

        if (!statusResult.isReplaceable) {
          if (statusResult.reason !== "private_key_mismatch") {
            throw new SSLCertificateParseError(statusResult.reason);
          }
        }

        setState({
          certificate: values.certificate,
          certificateData: certificateData,
        });
      } catch (e) {
        if (e instanceof SSLCertificateParseError) {
          form.setError("certificate", {
            message: e.message,
          });

          return false;
        }

        form.setError("certificate", {
          message: joinTranslationKeys(
            "modal",
            "editCertificate",
            "certificate",
            e instanceof Error ? e.message : "invalidCertificate",
          ),
        });

        return false;
      }
    },
  });

  return (
    <WizardIntroStep
      description="certificate.description"
      form={form}
      headline="certificate"
      headlineIcon={iconFileCertificate}
      id="certificate"
      indicatorText="certificateStep"
    >
      <KeyField
        accept={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "text/plain": [".crt", ".cert", ".txt", ".pem"],
        }}
        multiple
        name="certificate"
        placeholder={{
          text: "-----BEGIN CERTIFICATE-----\n...\n-----END CERTIFICATE-----",
        }}
      />
    </WizardIntroStep>
  );
};
