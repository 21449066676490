import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC } from "react";
import { SharedUserInputFieldProps } from "../../../components/UserInputField/types";

export const StreetAndHouseNumberInput: FC<SharedUserInputFieldProps> = (
  props,
) => {
  return (
    <TextField
      autoComplete="street-address"
      disabled={props.disabled}
      label="streetAndHouseNumber"
      name={props.name}
      rules={props.rules}
    />
  );
};

export default StreetAndHouseNumberInput;
