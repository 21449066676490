import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { DateTime } from "luxon";
import mittwaldApiClientWithoutLastEventHeader from "../../api/clients/mittwaldWithoutLastEventHeader";
import { MittwaldApi } from "../../api/Mittwald";

export type SupportCodeApiSchema =
  MittwaldApi.Paths.V2_Users_Self_Credentials_Support_Code.Get.Responses.$200.Content.Application_Json;

export class SupportCode {
  public readonly data: SupportCodeApiSchema;
  public readonly supportCode: string | undefined;
  public readonly expiresAt: DateTime | undefined;

  private constructor(data: SupportCodeApiSchema) {
    this.data = Object.freeze(data);
    this.supportCode = data.supportCode;
    this.expiresAt = data.expiresAt
      ? DateTime.fromISO(data.expiresAt)
      : undefined;
  }

  public static fromApiData(data: SupportCodeApiSchema): SupportCode {
    return new SupportCode(data);
  }

  public static async load(): Promise<SupportCode> {
    const response =
      await mittwaldApiClientWithoutLastEventHeader.userSupportCodeRequest({});

    assertStatus(response, 200);

    return SupportCode.fromApiData(response.content);
  }
}

export default SupportCode;
