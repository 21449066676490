import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import ContractItem from "../../../../../../model/contract/ContractItem";
import { TerminationFormFields } from "../ContractItemTerminationModal";
import ConfirmationStepContent from "./ConfirmationStepContent";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";

interface Props {
  contractItem: ContractItem;
}

export const ConfirmationStep: FC<Props> = (props) => {
  const form = useFormContext<TerminationFormFields>();
  const watchedTerminationTargetDate = form.watch("terminationTargetDate");

  return (
    <WizardStep
      destructivePrimaryAction
      id="confirm"
      indicatorText="confirmTermination"
      stepWillSubmitForm
    >
      <ConfirmationStepContent
        contractItem={props.contractItem}
        terminationTargetDate={watchedTerminationTargetDate}
      />
    </WizardStep>
  );
};

export default ConfirmationStep;
