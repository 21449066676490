import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { ColumnLayoutContainer } from "@mittwald/flow-components/dist/components/ColumnLayoutContainer";
import { DatePicker } from "@mittwald/flow-components/dist/components/DatePicker";
import {
  Form,
  FormController,
} from "@mittwald/flow-components/dist/components/Form";
import { TextArea } from "@mittwald/flow-components/dist/components/TextArea";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { DateTime } from "luxon";
import React, { FC } from "react";
import { CustomerInviteCreateInputs } from "../../../../../model/customer/CustomerInvite";
import { ProjectInviteCreateInputs } from "../../../../../model/project/ProjectInvite";
import CustomerMembershipUI from "../../../../../model/ui/customer/CustomerMembershipUI";
import ProjectMembershipUI from "../../../../../model/ui/project/ProjectMembershipUI";

export interface AddInviteFormProps {
  form:
    | FormController<ProjectInviteCreateInputs>
    | FormController<CustomerInviteCreateInputs>;
  type: "project" | "customer";
}

export const AddInviteForm: FC<AddInviteFormProps> = ({ form, type }) => {
  const isProjectInvite = type === "project";

  return (
    <TranslationProvider name="AddInviteForm" type="component">
      <Form controller={form}>
        <ColumnLayoutContainer>
          <ColumnLayout medium={[1, 1]}>
            <TextField
              autoFocus
              label="email"
              name="mailAddress"
              rules={{
                required: true,
              }}
              type="email"
            />
            <DatePicker
              clearable
              label="expiration"
              name="membershipExpiresAt"
              whitelist={{
                fromDate: DateTime.local().plus({ days: 1 }),
                toDate: DateTime.local().plus({ years: 5 }),
              }}
            />
          </ColumnLayout>
          <SelectBox
            label="role"
            name="role"
            options={
              isProjectInvite
                ? ProjectMembershipUI.roleSelectBoxOptions
                : CustomerMembershipUI.roleSelectBoxOptions
            }
            rules={{ required: true }}
          />

          <TextArea
            _height={100}
            infoMessage={{ message: "markdownHint" }}
            label="message"
            name="message"
          />
        </ColumnLayoutContainer>
      </Form>
    </TranslationProvider>
  );
};

export default AddInviteForm;
