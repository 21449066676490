import React, { useEffect, useState } from "react";
import {
  AvatarStack,
  AvatarStackAvatarProps,
} from "../../../../../../components/AvatarStack/AvatarStack";
import { useImageUrlBuilder } from "../../../../../../hooks/useFileServiceUrlBuilder";
import Customer from "../../../../../../model/customer/Customer";
import CustomerMembership from "../../../../../../model/customer/CustomerMembership";
import CustomerMembershipList from "../../../../../../model/customer/CustomerMembershipList";
import {
  ProjectMembership,
  ProjectMembershipList,
} from "../../../../../../model/project";
import Server from "../../../../../../model/server/Server";
import User from "../../../../../../model/user/User";

interface CreateConversationAvatarStackProps {
  relationTypeName: string;
  relatedToId: string;
}

export const CreateConversationAvatarStack: React.FC<
  CreateConversationAvatarStackProps
> = (props) => {
  const { relationTypeName, relatedToId } = props;
  const getImageUrl = useImageUrlBuilder();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [users, setUsers] = useState<User[] | undefined>(undefined);

  const avatars: AvatarStackAvatarProps[] | undefined = users?.map((user) => ({
    url: getImageUrl(user.data.avatarRef),
    tooltip: {
      text: user.fullName,
    },
    initials: [user.data.person.firstName, user.data.person.lastName],
  }));

  useEffect(() => {
    void (async () => {
      if (relatedToId === "") {
        setUsers(undefined);
        return;
      }

      setIsLoading(true);

      let members: Array<ProjectMembership | CustomerMembership> = [];

      if (relationTypeName === "project") {
        members = (await ProjectMembershipList.loadByProjectId(relatedToId))
          .items;
      } else if (relationTypeName === "customer") {
        const customer = await Customer.loadById(relatedToId);
        members = (
          await CustomerMembershipList.loadByCustomer(customer)
        ).items.filter((member) => member.data.role !== "member");
      } else if (relationTypeName === "placementgroup") {
        const server = await Server.loadById(relatedToId);
        const customer = await Customer.loadById(server.data.customerId);
        members = (
          await CustomerMembershipList.loadByCustomer(customer)
        ).items.filter((member) => member.data.role !== "accountant");
      }

      const users = await Promise.all(
        members.map((member) => User.getById(member.userId)),
      );
      setUsers(users);
      setIsLoading(false);
    })();
  }, [relatedToId]);

  return (
    <>
      {users !== undefined && (
        <>
          {!isLoading && avatars ? (
            <AvatarStack avatars={avatars} maxAvatarCount={4} size="m" />
          ) : (
            <AvatarStack.Skeleton />
          )}
        </>
      )}
    </>
  );
};
