import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { VisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import React, { FC } from "react";
import Customer from "../../../../../../model/customer/Customer";
import { Text } from "@mittwald/flow-components/dist/components/Text";

interface Props {
  visibilityController?: VisibilityController;
  center?: boolean;
  customer: Customer;
}

export const AdditionalExtensionsInProjectInfo: FC<Props> = (props) => {
  const { visibilityController, center, customer } = props;

  const buildLink = useLinkBuilder();
  const goto = useGotoLink();

  const hasAccess = customer.checkMyRoleIsIn("owner", "member");

  if (!hasAccess) {
    return null;
  }

  return (
    <TranslationProvider name="additionalExtensionsInProject" type="component">
      <Text
        _alignSelf={center ? "center" : "flex-end"}
        center={center}
        i18n={{
          id: "text",
          values: {
            link: (
              <TextLink
                action={
                  visibilityController
                    ? () => {
                        visibilityController.hide();
                        goto("customerProjectsOverview");
                      }
                    : buildLink("customerProjectsOverview")
                }
                i18n="link"
              />
            ),
          },
        }}
      />
    </TranslationProvider>
  );
};

export default AdditionalExtensionsInProjectInfo;
