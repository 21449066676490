import { Box } from "@mittwald/flow-components/dist/components/Box";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { LinkIdAction } from "@mittwald/flow-components/dist/lib/actions/LinkIdAction";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { iconContainer } from "@mittwald/flow-icons/dist/container";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import React, { FC } from "react";
import { Container } from "../../../../model/container/Container";
import { ContainerList } from "../../../../model/container/ContainerList";
import { Volume } from "../../../../model/container/Volume";

interface Props {
  volume: Volume;
}

export const DeleteVolume: FC<Props> = (props) => {
  const volume = props.volume;
  const containers = ContainerList.useLoadByVolume(volume);
  const isUsedInContainers = !containers.isEmpty;
  const visibility = useVisibilityController();

  const [includingContainers, recreateRequiredContainers] =
    containers.items.reduce(
      (prev: [Container[], Container[]], curr) => {
        const foundInPendingState =
          !!curr.pendingState.volumes.findRelationByVolumeName(volume.name);
        const foundInDeployedState =
          !!curr.deployedState.volumes.findRelationByVolumeName(volume.name);
        if (
          (foundInDeployedState && foundInPendingState) ||
          (!foundInDeployedState && foundInPendingState)
        ) {
          prev[0].push(curr);
        }
        if (foundInDeployedState && !foundInPendingState) {
          prev[1].push(curr);
        }
        return prev;
      },
      [[], []],
    );

  const primaryAction: AnyAction = [
    () => volume.delete(),
    visibility.hide,
    new LinkIdAction("containerVolume", { replace: true }),
  ];

  return (
    <DefaultModal
      _size="s"
      description={{
        id: "description",
        values: {
          volumeName: volume.name,
        },
      }}
      headline="deleteVolume"
      headlineIcon={iconDelete}
      primary={{
        action: primaryAction,
        destructive: true,
        disabled: isUsedInContainers,
        text: "deleteVolume",
      }}
      visibility={visibility}
    >
      {isUsedInContainers && (
        <Box _itemGap="m" _pl={2}>
          {includingContainers.length > 0 && (
            <Text i18n="noDeleteDescription" />
          )}
          {includingContainers.map((c, i) => (
            <TextWithIconLayout icon={iconContainer} key={`container-${i}`}>
              <Text
                i18n={{
                  text: c.description,
                }}
              />
            </TextWithIconLayout>
          ))}
          {recreateRequiredContainers.length > 0 && (
            <Text i18n="noDeleteRecreateDescription" />
          )}
          {recreateRequiredContainers.map((c, i) => (
            <TextWithIconLayout
              icon={iconContainer}
              key={`container-deployed-${i}`}
            >
              <Text
                i18n={{
                  text: c.description,
                }}
              />
            </TextWithIconLayout>
          ))}
        </Box>
      )}
    </DefaultModal>
  );
};

export default DeleteVolume;
