import { faNewspaper } from "@fortawesome/pro-regular-svg-icons/faNewspaper";
import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import React, { FC } from "react";
import Newsletter from "../../../../../../model/newsletter/Newsletter";
import User from "../../../../../../model/user/User";

interface FormFields {
  confirmUserData: boolean;
  confirmSubscription: boolean;
}

const SubscribeToNewsletter: FC = () => {
  const visibility = useVisibilityController();
  const user = User.useMe();

  const form = useForm<FormFields>({
    onSubmit: async (values) => {
      await Newsletter.subscribe({
        firstName: values.confirmUserData
          ? user.data.person.firstName
          : undefined,
        lastName: values.confirmUserData
          ? user.data.person.lastName
          : undefined,
      });
    },
  });

  return (
    <DefaultModal
      description="description"
      headline="subscribeToNewsletter"
      headlineIcon={faNewspaper}
      primary={{ action: [{ form }, visibility.hide], ok: true }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Item>
          <CheckBox name="confirmUserData" title="confirmUserData" />
          <CheckBox
            name="confirmSubscription"
            rules={{ required: true }}
            title={{
              id: "confirmSubscription",
              values: {
                privacy: (
                  <TextLink
                    action={import.meta.env.VITE_PRIVACY_LINK}
                    i18n="privacy"
                  />
                ),
              },
            }}
          />
          <Text
            i18n={{ id: "confirmationLink", values: { email: user.email } }}
          />
        </Section.Item>
      </Form>
    </DefaultModal>
  );
};

export default SubscribeToNewsletter;
