import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import { iconPlay } from "@mittwald/flow-icons/dist/play";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import { Cronjob } from "../../../model/cronjob/Cronjob";
import { CronjobExecutionStatus } from "../../../model/cronjob/CronjobExecution";
import Project from "../../../model/project/Project";
import { CronjobUI } from "../../../model/ui/cronjob/CronjobUI";

export const cronjobPages = PageTree.build(BacksideRenderer, {
  "/app/projects/:projectId/cronjob": {
    sector: "basics",
    props: {
      navigationSection: "component",
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(Cronjob, "createNew"),
          },
          icon: CronjobUI.icon,
          title: "createCronjob",
        },
      ],
    },
    views: {
      main: lazy(() => import("./overview")),
    },
    icon: CronjobUI.icon,
    linkId: "cronjob-list",
    accessCheck: () =>
      Project.useLoadByPathParam().useCheckMyRoleIsIn(["owner", "external"]),
  },

  "/app/projects/:projectId/cronjob/:cronjobId": {
    views: {
      pageTitle: lazy(() => import("./components/CronjobTitle")),
    },
    icon: CronjobUI.icon,
    linkId: "cronjob",
    props: {
      tabNav: true,
    },
  },
  "/app/projects/:projectId/cronjob/:cronjobId/general": {
    views: {
      main: lazy(() => import("./details/general")),
    },
    icon: CronjobUI.icon,
    linkId: "cronjob-general",
  },
  "/app/projects/:projectId/cronjob/:cronjobId/file": {
    views: {
      main: lazy(() => import("./details/destination")),
    },
    icon: CronjobUI.icon,
    linkId: "cronjob-file",
  },
  "/app/projects/:projectId/cronjob/:cronjobId/interval": {
    views: {
      main: lazy(() => import("./details/interval")),
    },
    icon: CronjobUI.icon,
    linkId: "cronjob-interval",
  },
  "/app/projects/:projectId/cronjob/:cronjobId/settings": {
    views: {
      main: lazy(() => import("./details/settings")),
    },
    icon: CronjobUI.icon,
    linkId: "cronjob-settings",
  },
  "/app/projects/:projectId/cronjob/:cronjobId/history": {
    views: {
      main: lazy(() => import("./details/history")),
    },
    icon: CronjobUI.icon,
    linkId: "cronjob-history",
    props: {
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                Cronjob.useLoadByPathParam(),
                "triggerCronjob",
              ),
          },
          title: "triggerCronjob",
          icon: iconPlay,
        },
      ],
    },
    hooks: {
      useCheckState: () =>
        Cronjob.useLoadByPathParam().latestExecution?.data.status ===
        CronjobExecutionStatus.Failed
          ? "error"
          : null,
    },
  },
  "/app/projects/:projectId/cronjob/:cronjobId/history/:executionId": {
    views: {
      main: lazy(() => import("./details/execution")),
      pageTitle: lazy(() => import("./components/CronjobTitle")),
    },
    icon: CronjobUI.icon,
    linkId: "cronjobExecution",
  },
});
