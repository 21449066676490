import { iconDeliveryBox } from "@mittwald/flow-icons/dist/deliveryBox";
import { iconEmail } from "@mittwald/flow-icons/dist/email";
import { iconForwardAddress } from "@mittwald/flow-icons/dist/forwardAddress";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { PageSkeleton } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/components/PageSkeleton";
import React, { lazy } from "react";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import DeliveryBox from "../../../model/mail/DeliveryBox";
import EmailAddress from "../../../model/mail/EmailAddress";

export const emailPages = PageTree.build(BacksideRenderer, {
  "/app/projects/:projectId/email": {
    sector: "basics",
    props: {
      navigationSection: "component",
      tabNav: true,
    },
    icon: iconEmail,
    linkId: "email",
  },
  "/app/projects/:projectId/email/addresses": {
    props: {
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(EmailAddress, "createNewEmailAddress"),
          },
          title: "createEmailAddress",
          icon: iconEmail,
        },
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(EmailAddress, "createNewForwardAddress"),
          },
          title: "createForwardAddress",
          icon: iconForwardAddress,
        },
      ],
    },
    linkId: "mailAddresses",
    views: {
      main: lazy(() => import("./addresses/overview")),
    },
  },
  "/app/projects/:projectId/email/addresses/:emailAddressId": {
    linkId: "mailAddress",
    views: {
      pageTitle: lazy(() => import("./addresses/components/MailAddressTitle")),
    },
    props: {
      tabNav: true,
    },
  },
  "/app/projects/:projectId/email/addresses/:emailAddressId/general": {
    views: {
      main: lazy(() => import("./addresses/details/general")),
    },
    icon: iconEmail,
  },

  "/app/projects/:projectId/email/addresses/:emailAddressId/storage": {
    views: {
      main: lazy(() => import("./addresses/details/storage")),
    },
    hooks: {
      useCheckState: () =>
        EmailAddress.useLoadByPathParam().mailboxStoragePageStatus(),
    },
  },
  "/app/projects/:projectId/email/addresses/:emailAddressId/spam-protection": {
    views: {
      main: lazy(() => import("./addresses/details/spamprotection")),
    },
  },
  "/app/projects/:projectId/email/addresses/:emailAddressId/forwards": {
    views: {
      main: lazy(() => import("./addresses/details/forwards")),
    },
  },
  "/app/projects/:projectId/email/addresses/:emailAddressId/autoresponder": {
    views: {
      main: lazy(() => import("./addresses/details/autoresponder")),
    },
  },

  "/app/projects/:projectId/email/forwards": {
    views: {
      main: () => <PageSkeleton redirectTo="mailAddresses" />,
    },
    props: {
      hideInMenu: true,
    },
  },
  "/app/projects/:projectId/email/forwards/:emailAddressId": {
    views: {
      pageTitle: lazy(() => import("./addresses/components/MailAddressTitle")),
    },
    linkId: "forward",
    props: {
      tabNav: true,
    },
  },
  "/app/projects/:projectId/email/forwards/:emailAddressId/general": {
    views: {
      main: lazy(() => import("./forwards/details/general")),
    },
  },
  "/app/projects/:projectId/email/forwards/:emailAddressId/forwards": {
    views: {
      main: lazy(() => import("./forwards/details/forwards")),
    },
  },

  "/app/projects/:projectId/email/deliveryboxes": {
    props: {
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(DeliveryBox, "createNew"),
          },
          title: "createDeliveryBox",
          icon: iconDeliveryBox,
        },
      ],
    },
    linkId: "deliveryboxes",
    views: {
      main: lazy(() => import("./deliveryboxes/overview")),
    },
  },

  "/app/projects/:projectId/email/deliveryboxes/:deliveryboxId": {
    views: {
      main: lazy(() => import("./deliveryboxes/details/general")),
      pageTitle: lazy(
        () => import("./deliveryboxes/components/DeliveryboxTitle"),
      ),
    },
    linkId: "deliverybox",
  },

  "/app/projects/:projectId/email/settings": {
    views: {
      main: lazy(() => import("./settings/settings")),
    },
    linkId: "mailSettings",
  },
});
