import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { iconProject } from "@mittwald/flow-icons/dist/project";
import React, { FC } from "react";
import Customer from "../../../../../../model/customer/Customer";
import Server from "../../../../../../model/server/Server";
import ProSpaceArticleUI from "../../../../../../model/ui/article/ProSpaceArticleUI";
import ArticleTypeProjectDetailsSection from "./ArticleTypeProjectDetailsSection";

interface Props {
  customer?: Customer;
  server?: Server;
  spaceServerSelected: boolean;
}

export const ArticleTypeStep: FC<Props> = (props) => {
  return (
    <TranslationProvider name="projectArticleTypeStepContent" type="component">
      <WizardIntroStep
        description="articleTypeDescription"
        headline="articleType"
        headlineIcon={iconProject}
        id="articleType"
        indicatorText="articleTypeStep"
      >
        <Section.Layout>
          <Section.Item>
            <SelectBox
              name="proSpaceOrSpaceServer"
              options={ProSpaceArticleUI.useArticleTypeOptions(
                props.customer?.id,
              )}
              rules={{ required: true }}
            />
          </Section.Item>
          <ArticleTypeProjectDetailsSection
            customer={props.customer}
            server={props.server}
            spaceServerSelected={props.spaceServerSelected}
          />
        </Section.Layout>
      </WizardIntroStep>
    </TranslationProvider>
  );
};

export default ArticleTypeStep;
