import {
  FileInputField,
  FileInputFieldProps,
} from "@mittwald/flow-components/dist/components/FileInputField";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { TextArea } from "@mittwald/flow-components/dist/components/TextArea";
import { I18nTextDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import React, { FC, useState } from "react";

export type KeyFieldProps = {
  name: string;
  placeholder: I18nTextDefinition;
  required?: boolean;
} & Pick<FileInputFieldProps, "multiple" | "maxFiles" | "accept">;

export const KeyField: FC<KeyFieldProps> = (props) => {
  const { name, placeholder, required = true, ...rest } = props;
  const formContext = useFormContext();
  const file = formContext.watch(name);
  const [typeSelect, setTypeSelect] = useState<"input" | "file">(
    typeof file === "string" ? "input" : "file",
  );

  return (
    <>
      <SelectBox.View
        _mb="m"
        options={[
          {
            title: "file",
            value: "file",
          },
          {
            title: "input",
            value: "input",
          },
        ]}
        value={typeSelect}
        onChange={(value) => {
          const selected = value as "input" | "file";
          formContext.reset({
            [name]: selected === "file" ? [] : "",
          });
          setTypeSelect(selected);
        }}
      />
      {typeSelect === "file" && (
        <FileInputField
          label="file.label"
          {...rest}
          name={name}
          rules={{ required }}
        />
      )}
      {typeSelect === "input" && (
        <TextArea
          label="pem.label"
          name={name}
          placeholder={placeholder}
          rules={{ required }}
        ></TextArea>
      )}
    </>
  );
};
