import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import iconAccessDenied from "@mittwald/flow-icons/dist/accessDenied";
import React, { FC } from "react";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import Section from "@mittwald/flow-components/dist/components/Section";

export const CustomerActionDeniedModal: FC = () => {
  const visibility = useVisibilityController();
  return (
    <DefaultModal
      _size="s"
      abort={{ action: visibility.hide }}
      headline="canNotPerformAction"
      headlineIcon={iconAccessDenied}
      visibility={visibility}
    >
      <Section.Item>
        <Text i18n="actionDeniedDescription" />
      </Section.Item>
    </DefaultModal>
  );
};
