import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import parseCronjob, {
  ParsedCronjob,
} from "@mittwald/flow-components/dist/components/CrontabInterval/model/parseCronjob";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { BackupTtl } from "../ui/backup/BackupUI";
import { ScheduleTimeInterval } from "../ui/misc/ScheduleUI";

export type BackupScheduleApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Backup_ProjectBackupSchedule;

export interface BackupScheduleInputs {
  schedule: string;
  description: string;
  ttl: BackupTtl | string;
  interval: ScheduleTimeInterval;
  time: string;
}

export class BackupSchedule {
  public readonly id: string;
  public readonly data: BackupScheduleApiData;
  public readonly nextExecutionDate?: Date;
  public readonly isSystemBackup: boolean;
  public readonly parsedSchedule: ParsedCronjob;

  private constructor(data: BackupScheduleApiData) {
    this.id = data.id;
    this.data = Object.freeze(data);
    this.parsedSchedule = parseCronjob(data.schedule);
    this.nextExecutionDate = this.parsedSchedule.isValid
      ? this.parsedSchedule.value.executions.get(1)[0]?.getData()
      : undefined;
    this.isSystemBackup = data.isSystemBackup;
  }

  public static fromApiData(data: BackupScheduleApiData): BackupSchedule {
    return new BackupSchedule(data);
  }

  public static useLoadById(scheduleId: string): BackupSchedule {
    const data = mittwaldApi.backupGetProjectBackupSchedule
      .getResource({
        path: { projectBackupScheduleId: scheduleId },
      })
      .useWatchData();

    return BackupSchedule.fromApiData(data);
  }

  public static useLoadByPathParam(): BackupSchedule {
    const { scheduleId } = usePathParams("scheduleId");
    return BackupSchedule.useLoadById(scheduleId);
  }

  public static async createNew(
    values: BackupScheduleInputs,
    projectId: string,
  ): Promise<void> {
    const response =
      await mittwaldApi.backupCreateProjectBackupSchedule.request({
        path: { projectId },
        requestBody: {
          schedule: values.schedule,
          ttl: values.ttl,
          description: values.description,
        },
      });

    assertStatus(response, 201);
  }

  public async update(values: BackupScheduleInputs): Promise<void> {
    const response =
      await mittwaldApi.backupUpdateProjectBackupSchedule.request({
        path: { projectBackupScheduleId: this.id },
        requestBody: {
          schedule: values.schedule,
          ttl: values.ttl,
          description: values.description,
        },
      });

    assertStatus(response, 204);
  }

  public async delete(): Promise<void> {
    const response =
      await mittwaldApi.backupDeleteProjectBackupSchedule.request({
        path: { projectBackupScheduleId: this.id },
      });

    assertStatus(response, 204);
  }

  public async updateSystemBackupSchedule(ttl: string): Promise<void> {
    const response =
      await mittwaldApi.backupUpdateProjectBackupSchedule.request({
        path: { projectBackupScheduleId: this.id },
        requestBody: { ttl },
      });

    assertStatus(response, 204);
  }
}

export default BackupSchedule;
