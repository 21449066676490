import { SubmitTrigger } from "@mittwald/flow-components/dist/components/Form/hooks/useForm";
import { IconLookup } from "@mittwald/flow-components/dist/components/Icon";
import { ModalFooter } from "@mittwald/flow-components/dist/components/Modal/components/ModalFooter";
import { Modal } from "@mittwald/flow-components/dist/components/Modal/Modal";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider/TranslationProvider";
import {
  AnimationController,
  useAnimationController,
} from "@mittwald/flow-components/dist/hooks/useAnimationController";
import {
  useVisibilityController,
  VisibilityController,
} from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { ActionResult } from "@mittwald/flow-components/dist/lib/actions/BaseAction";
import React, { FC } from "react";
import { MfaConfirmationModalContent } from "./MfaConfirmationModalContent";

export interface MfaConfirmationModalProps {
  icon?: IconLookup;
  triggerSubmit: SubmitTrigger;
  controller: MfaConfirmationController;
}

export interface MfaConfirmationController {
  trigger: () => void;
  hide: () => void;
  visibility: VisibilityController;
  rejectionAnimation: AnimationController;
}

export const useMfaConfirmationController = (): MfaConfirmationController => {
  const visibility = useVisibilityController();
  const rejectionAnimation = useAnimationController();

  const trigger = (): void => {
    visibility.show();
  };

  return {
    trigger,
    hide: visibility.hide,
    visibility,
    rejectionAnimation,
  };
};

export const MfaConfirmationModal: FC<MfaConfirmationModalProps> = (props) => {
  const { icon, triggerSubmit, controller } = props;
  const { visibility, hide } = controller;

  return (
    <Modal _size="s" visibility={visibility}>
      <MfaConfirmationModalContent
        icon={icon}
        rejectionAnimation={controller.rejectionAnimation}
      />
      <TranslationProvider name="mfaConfirmation" type="modal">
        <ModalFooter
          primary={{
            action: [
              async () => {
                const result = await triggerSubmit();
                return result ? ActionResult.success : ActionResult.aborted;
              },
              hide,
            ],
          }}
          visibility={visibility}
        />
      </TranslationProvider>
    </Modal>
  );
};

export default MfaConfirmationModal;
