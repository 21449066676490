import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";

import React from "react";
import AddressDeletionConfirmation from "../../../pages/app/mail/addresses/components/AddressDeletionConfirmation";
import ConfirmDeliveryboxDeletion from "../../../pages/app/mail/deliveryboxes/components/DeliveryboxDeletionConfirmation";
import CreateDeliveryBox from "../../../pages/app/mail/modals/CreateDeliveryBox/CreateDeliveryBox";
import CreateEmailAddress from "../../../pages/app/mail/modals/CreateEmailAddress/CreateEmailAddress";
import CreateForwardAddress from "../../../pages/app/mail/modals/CreateForwardAddress/CreateForwardAddress";
import UpdateDeliveryBoxPassword from "../../../pages/app/mail/modals/UpdateDeliveryBoxPassword/UpdateDeliveryBoxPassword";
import UpdateEmailAddressPassword from "../../../pages/app/mail/modals/UpdateEmailAddressPassword/UpdateEmailAddressPassword";
import { Role } from "../acl/Role";
import DeliveryBox from "../../mail/DeliveryBox";
import EmailAddress from "../../mail/EmailAddress";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultEmailRoles = [
  Role.projectAdmin,
  Role.emailAdmin,
  Role.projectDeveloper,
];

ModelActionBuilderRegistry.register({
  model: EmailAddress,
  roles: defaultEmailRoles,
  method: "delete",
  altAction: (action) => ({
    action,
    confirmation: {
      modalTranslationKey: action.model.isEmailAddress
        ? "deleteMailAddress"
        : "deleteForward",
      required: true,
      modalProps: { headlineIcon: iconDelete },
      modalContent: AddressDeletionConfirmation,
      modalTranslationValues: {
        address: action.model.address,
      },
    },
  }),
})
  .register({
    model: DeliveryBox,
    roles: defaultEmailRoles,
    method: "delete",
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deleteDeliveryBox",
        required: true,
        modalContent: ConfirmDeliveryboxDeletion,
        modalTranslationValues: {
          deliveryboxName: action.model.data.name,
        },
        modalProps: { headlineIcon: iconDelete },
      },
    }),
  })
  .register({
    model: EmailAddress,
    roles: defaultEmailRoles,
    method: "createNewEmailAddress",
    altAction: () => () =>
      showModal("createEmailAddress", <CreateEmailAddress />),
  })
  .register({
    model: EmailAddress,
    roles: defaultEmailRoles,
    method: "createNewForwardAddress",
    altAction: () => () =>
      showModal("createForwardAddress", <CreateForwardAddress />),
  })
  .register({
    model: EmailAddress,
    roles: defaultEmailRoles,
    method: "updatePassword",
    isAvailable: (emailAddress) => emailAddress.isEmailAddress,
    altAction: (action) => () =>
      showModal(
        "updateEmailAddressPassword",
        <UpdateEmailAddressPassword emailAddress={action.model} />,
      ),
  })
  .register({
    model: DeliveryBox,
    roles: defaultEmailRoles,
    method: "createNew",
    altAction: () => () =>
      showModal("createDeliveryBox", <CreateDeliveryBox />),
  })
  .register({
    model: DeliveryBox,
    roles: defaultEmailRoles,
    method: "updatePassword",
    altAction: (action) => () =>
      showModal(
        "updateDeliveryBoxPassword",
        <UpdateDeliveryBoxPassword deliveryBox={action.model} />,
      ),
  });
