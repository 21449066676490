import { OpenAPIV3 } from "openapi-types";
import getApiSpecResource, {
  ApiSpecType,
} from "../resources/getApiSpecResource";
import useCheckDemoModeEnabled from "./useCheckDemoModeEnabled";

export interface UseCheckApiSpecPathOptions {
  method?: OpenAPIV3.HttpMethods;
  api?: ApiSpecType;
}

export const useCheckApiSpecPath = (
  path: string,
  options: UseCheckApiSpecPathOptions = {},
): boolean => {
  const { method, api = "mwPublic" } = options;

  const demoModeEnabled = useCheckDemoModeEnabled();

  const spec = getApiSpecResource(api).useWatchData({
    throwOnError: false,
    load: !demoModeEnabled,
  });

  if (demoModeEnabled) {
    return true;
  }

  const pathSpec = spec?.paths[path];
  return !!pathSpec && (!method || method in pathSpec);
};

export default useCheckApiSpecPath;
