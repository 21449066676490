import { iconTicket } from "@mittwald/flow-icons/dist/ticket";
import React from "react";
import { NavigationItem } from "@mittwald/flow-components/dist/components/Navigation/components";

interface CreateSupportRequestNavigationItemProps {
  onCreateSupportRequestButtonClick: () => void;
}

export const CreateSupportRequestNavigationItem: React.FC<
  CreateSupportRequestNavigationItemProps
> = (props) => {
  const { onCreateSupportRequestButtonClick } = props;
  return (
    <>
      <NavigationItem
        action={onCreateSupportRequestButtonClick}
        icon={iconTicket}
        title="create-supportrequest"
      />
    </>
  );
};
