import { InlineCode } from "@mittwald/flow-components/dist/components/InlineCode";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";

interface Props {
  apiToken: string;
}

export const CopyStep: FC<Props> = (props) => {
  const { apiToken } = props;

  return (
    <WizardStep id="copy" indicatorText="copy" noAbort noReturn>
      <Section.Item headline="getToken">
        <Text i18n="description" />
        <StaticInformation
          copyContent={apiToken}
          label="copyApiToken"
          text={<InlineCode>{apiToken}</InlineCode>}
        />
      </Section.Item>
    </WizardStep>
  );
};

export default CopyStep;
