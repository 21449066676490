import useSelector from "@mittwald/flow-lib/dist/hooks/useSelector";
import useCheckDemoModeEnabled from "./useCheckDemoModeEnabled";
import { filesMockStore } from "./useMockFileUploadServer";

type DemoDataFileUrlBuilder = (
  fileId: string,
  mockedFiles: typeof filesMockStore.files,
) => string;
export type UseFileUrlBuilder = () => (fileId?: string) => string;

const makeUseFileServiceUrlBuilder =
  (demoBuilder: DemoDataFileUrlBuilder): UseFileUrlBuilder =>
  () => {
    // Hooks must be called once during builder creation
    const demoDataEnabled = useCheckDemoModeEnabled();
    const mockedFiles = useSelector(() => filesMockStore.files);

    return (fileId?: string): string => {
      if (!fileId) {
        return "";
      }
      if (fileId.startsWith("/") || fileId.startsWith("http")) {
        return fileId;
      } else if (demoDataEnabled) {
        return demoBuilder(fileId, mockedFiles);
      } else {
        return `${import.meta.env.VITE_APP_API_URI}/v2/files/${fileId}`;
      }
    };
  };

/**
 * Get the URL of a pdf file, that has been uploaded to the FileService.
 */
export const useFileUrlBuilder: UseFileUrlBuilder =
  makeUseFileServiceUrlBuilder(
    (fileId, mockedFiles) => mockedFiles.get(fileId) ?? fileId,
  );

/**
 * Get the URL of a pdf file, that has been uploaded to the FileService.
 */
export const usePdfFileUrlBuilder: UseFileUrlBuilder =
  makeUseFileServiceUrlBuilder(
    (fileId, mockedFiles) => mockedFiles.get(fileId) ?? "/demo/invoice.pdf",
  );

/**
 * Get the URL of an image file, that has been uploaded to the FileService.
 */
export const useImageUrlBuilder: UseFileUrlBuilder =
  makeUseFileServiceUrlBuilder(
    (fileId, mockedFiles) =>
      mockedFiles.get(fileId) ?? `https://picsum.photos/seed/${fileId}/500/500`,
  );
