import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Placeholder } from "@mittwald/flow-components/dist/components/PlaceholderBox";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import React, { FC } from "react";
import { iconBell } from "@mittwald/flow-icons/dist/bell";
import { NotificationList } from "../../../model/notification/NotificationList";
import { NotificationResourceList } from "./components/NotificationRessourceList/NotificationResourceList";

export const NotificationCenter: FC = () => {
  const notificationList = NotificationList.useUnread();
  const visibility = useVisibilityController();

  const primaryAction: AnyAction = {
    actions: [
      async () => {
        if (!notificationList.isEmpty) {
          await notificationList.markAllAsRead();
        }
      },
      visibility.hide,
    ],
    confirmation: {
      modalTranslationKey: "markAllAsRead",
      required: true,
    },
    accessCheck: () => !notificationList.isEmpty,
  };

  return (
    <DefaultModal
      description="description"
      headline="notificationCenter"
      headlineIcon={iconBell}
      primary={{
        action: primaryAction,
        destructive: true,
      }}
      visibility={visibility}
    >
      <Section.Item>
        {notificationList.isEmpty ? (
          <Placeholder
            description="noNotificationsDescription"
            headline="noNotifications"
            icon={iconBell}
          />
        ) : (
          <NotificationResourceList
            notificationList={notificationList}
            visibility={visibility}
          />
        )}
      </Section.Item>
    </DefaultModal>
  );
};

export default NotificationCenter;
