import { mittwaldApi } from "../api/Mittwald";

export class Model {
  public static useDryApi(times = 1): undefined {
    for (let i = 0; i < times; i++) {
      mittwaldApi.userGetUser.getResource(null).useWatchData();
    }

    return;
  }
}

export default Model;
