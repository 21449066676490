import { Box } from "@mittwald/flow-components/dist/components/Box";
import { Skeleton } from "@mittwald/flow-components/dist/components/Skeleton";
import { Wrap } from "@mittwald/flow-lib/dist/components/Wrap";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { SidebarSectionHeader } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/components/SidebarSectionHeader";
import React, { FC } from "react";
import { useImageUrlBuilder } from "../../../../hooks/useFileServiceUrlBuilder";
import useUpdateRecentVisits from "../../../../hooks/useUpdateRecentVisits";
import { ModelActionBuilder } from "../../../../model/actions/ModelActionBuilder";
import Project from "../../../../model/project/Project";

const outerBox = <Box _itemGap="l" />;

export const ProjectSidebarHeaderLoading: FC = () => (
  <Wrap with={outerBox}>
    <SidebarSectionHeader
      subTitle={<Skeleton _width={100} />}
      title={<Skeleton _width={200} />}
    />
  </Wrap>
);

export const ProjectSideBarHeader: FC = () => {
  const { projectId } = usePathParams("projectId");
  useUpdateRecentVisits(projectId);
  const project = Project.useLoadById(projectId);

  const imageUrl = useImageUrlBuilder();

  return (
    <Wrap with={outerBox}>
      <SidebarSectionHeader
        avatar={
          project.imageRefId
            ? { url: imageUrl(project.imageRefId) }
            : { initials: project.description }
        }
        imageAction={ModelActionBuilder.build(project, "requestAvatarUpload")}
        subTitle="project"
        title={{ text: project.description }}
      />
    </Wrap>
  );
};

export default ProjectSideBarHeader;
