import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { iconInfoCircle } from "@mittwald/flow-icons/dist/infoCircle";
import React, { FC } from "react";
import Onboarding from "../../../../../../model/onboarding/Onboarding";
import { OnboardingSection } from "../../components/OnboardingSection";
import { UnorderedList } from "../../components/UnorderedList";
import structureImage1 from "../../img/structure-1.png";
import structureImage2 from "../../img/structure-2.png";
import structureImage3 from "../../img/structure-3.png";
import structureImage4 from "../../img/structure-4.png";
import structureImage from "../../img/structure.png";

export const StructureOnboarding: FC = () => {
  const form = useForm({
    onSubmit: () => {
      Onboarding.completeStructure();
    },
  });

  return (
    <WizardModal
      form={form}
      steps={["intro", "customer", "server", "project", "members"]}
    >
      <WizardIntroStep
        description="structureDescription"
        headline="structure"
        headlineIcon={iconInfoCircle}
        id="intro"
        indicatorText="structureIndicator"
      >
        <OnboardingSection image={structureImage}>
          <Text i18n="structureText" />
        </OnboardingSection>
      </WizardIntroStep>
      <WizardStep id="customer" indicatorText="customerIndicator">
        <OnboardingSection headline="customer" image={structureImage1}>
          <Text i18n="customerDescription" />
        </OnboardingSection>
      </WizardStep>
      <WizardStep id="server" indicatorText="serverIndicator">
        <OnboardingSection headline="server" image={structureImage2}>
          <Text i18n="serverDescription" />
        </OnboardingSection>
      </WizardStep>
      <WizardStep id="project" indicatorText="projectIndicator">
        <OnboardingSection headline="project" image={structureImage3}>
          <Text i18n="projectDescription" />
          <UnorderedList length={3} translationKey="project" />
        </OnboardingSection>
      </WizardStep>
      <WizardStep id="members" indicatorText="membersIndicator">
        <OnboardingSection headline="members" image={structureImage4}>
          <Text i18n="membersDescription" />
        </OnboardingSection>
      </WizardStep>
    </WizardModal>
  );
};

export default StructureOnboarding;
