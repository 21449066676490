import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import ContractItemArticle from "../../contract/ContractItemArticle";
import ArticleUI from "../article/ArticleUI";

export class ContractItemArticleUi {
  public readonly contractItemArticle: ContractItemArticle;

  private constructor(contractItemArticle: ContractItemArticle) {
    this.contractItemArticle = contractItemArticle;
  }

  public static of(
    contractItemArticle: ContractItemArticle,
  ): ContractItemArticleUi {
    return new ContractItemArticleUi(contractItemArticle);
  }

  public useIcon(): IconDefinition {
    return ArticleUI.of(this.contractItemArticle.useArticle()).getIcon();
  }
}

export default ContractItemArticleUi;
