import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { iconTerminate } from "@mittwald/flow-icons/dist/terminate";
import React, { FC, useEffect } from "react";
import { strictToISO } from "../../../../../../lib/strictToISO";
import ContractItem from "../../../../../../model/contract/ContractItem";
import { TerminationFormFields } from "../ContractItemTerminationModal";
import { TerminationDateSection } from "./TerminationDateSection";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";

interface Props {
  contractItem: ContractItem;
}

export const SelectDateStep: FC<Props> = (props) => {
  const { contractItem } = props;
  const nextTerminationDate = strictToISO(
    contractItem.nextPossibleTerminationDate,
  );
  const form = useFormContext<TerminationFormFields>();
  const terminationType = form.watch("terminationType");

  useEffect(() => {
    if (terminationType === "nextDate" && nextTerminationDate) {
      form.setValue("terminationTargetDate", nextTerminationDate);
    }
  }, [terminationType]);

  return (
    <WizardIntroStep
      description={{
        id: "selectTerminationDate.description",
        values: {
          name: contractItem.description,
        },
      }}
      headline={{
        id: "selectTerminationDate.headline",
        values: {
          name: contractItem.baseArticle?.name,
        },
      }}
      headlineIcon={iconTerminate}
      id="terminationDate"
      indicatorText="selectTerminationDate"
    >
      <TerminationDateSection
        nextTerminationDate={nextTerminationDate}
        terminationType={terminationType}
      />
    </WizardIntroStep>
  );
};

export default SelectDateStep;
