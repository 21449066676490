import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import React, { FC, useState } from "react";
import ApiToken, {
  NewApiTokenInputs,
} from "../../../../../../model/signup/ApiToken";
import CopyStep from "./components/CopyStep";
import GeneralStep from "./components/GeneralStep";
import { DateTime } from "luxon";

export const CreateApiToken: FC = () => {
  const [apiToken, setApiToken] = useState("");

  const form = useForm<NewApiTokenInputs>({
    defaultValues: {
      description: "",
      expiresAt: DateTime.local().plus({ years: 1 }).toISO(),
      writeAccess: false,
    },
    showSuccessFeedback: false,
    onSubmit: async (values) => {
      setApiToken(await ApiToken.createNew(values));
    },
  });

  return (
    <WizardModal _size="m" form={form} steps={["intro", "copy"]}>
      <GeneralStep />
      <CopyStep apiToken={apiToken} />
    </WizardModal>
  );
};

export default CreateApiToken;
