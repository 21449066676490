import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import React, { FC } from "react";
import Domain from "../../../../../model/domain/Domain";
import DependencyStep from "./components/DependencyStep";
import OverviewStep from "./components/OverviewStep";

export interface DeleteDomainProps {
  domain: Domain;
}

export interface DeleteDomainInputs {
  confirmationDomainDependencies: boolean;
  confirmationDomainDeletion: boolean;
  terminationType: "termination" | "transit";
}

export const DeleteDomain: FC<DeleteDomainProps> = (props) => {
  const { domain } = props;

  const visibility = useVisibilityController();
  const goto = useGotoLink();

  const owner = domain.contacts.owner?.findByName("name")?.value;
  const ingresses = domain.useLoadIngressesOfDomain();

  const form = useForm<DeleteDomainInputs>({
    defaultValues: {
      confirmationDomainDependencies: false,
      confirmationDomainDeletion: false,
      terminationType: "termination",
    },
    onSubmit: async () => {
      for (const ingress of ingresses) {
        await ingress.delete();
      }
      await domain.delete();
      visibility.hide();
      goto("domainOverview", undefined, { replace: true });
    },
  });

  const wizard = (
    <WizardModal form={form} steps={["dependencies", "overview"]}>
      <DependencyStep domain={domain} />
      <OverviewStep domain={domain} owner={owner} />
    </WizardModal>
  );

  return (
    <TranslationProvider name="domainDeletionWizard" type="modal">
      {wizard}
    </TranslationProvider>
  );
};
