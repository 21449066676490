import React, { FC } from "react";
import User from "../model/user/User";

export interface UserNameProps {
  userId: string;
  firstNameOnly?: boolean;
}

export const UserName: FC<UserNameProps> = (props) => {
  const user = User.useLoadById(props.userId);

  return (
    <>{props.firstNameOnly ? user.data.person.firstName : user.fullName}</>
  );
};

export default UserName;
