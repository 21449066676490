import { iconApp } from "@mittwald/flow-icons/dist/app";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";

export const globalAppPages = PageTree.build(BacksideRenderer, {
  "/app/apps": {
    views: {
      main: lazy(() => import("./pages/GlobalOverview")),
    },
    icon: iconApp,
    linkId: "globalApps",
  },
});
