import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import { ImageMetaProps } from "../../../../../../model/container/Container";
import { VolumeHeavyList } from "../../../components/VolumeHeavyList/VolumeHeavyList";

export const VolumeStep: FC<ImageMetaProps> = (props) => {
  return (
    <WizardStep id="volume" indicatorText="volume">
      <Section.Item headline="addVolume">
        <Text i18n="description" />
        <VolumeHeavyList suggestedPaths={props.imageMeta?.volumes} />
      </Section.Item>
    </WizardStep>
  );
};

export default VolumeStep;
