import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import TextArea from "@mittwald/flow-components/dist/components/TextArea/TextArea";
import TextField from "@mittwald/flow-components/dist/components/TextField/TextField";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";
import { sshPublicKeyRegex } from "../../../../lib/splitSshKey";

const PublicKeyFields: FC = () => {
  return (
    <TranslationProvider name="publicKeyFields" type="section">
      <TextArea
        _height={150}
        autoFocus
        infoMessage={{ message: "keyHint" }}
        label="key"
        name="key"
        placeholder="placeholder"
        rules={{
          validate: {
            invalidPublicKey: (val: string) => sshPublicKeyRegex.test(val),
          },
          required: true,
        }}
      />
      <ColumnLayout medium={[1, 1]}>
        <TextField
          label="comment"
          name="comment"
          rules={{
            required: true,
          }}
        />
      </ColumnLayout>
    </TranslationProvider>
  );
};

export default PublicKeyFields;
