import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { FC, useEffect } from "react";
import { CustomerActionDeniedModal } from "../../pages/app/customer/components/CustomerActionDeniedModal";
import Customer from "../customer/Customer";
import Domain from "../domain/Domain";
import React from "react";
import ProjectBase from "../project/ProjectBase";

interface BankruptGuardProps {
  entity: Customer | ProjectBase | Domain;
  onAction: (isBankrupt: boolean) => void;
}

const BankruptGuardCheck: FC<BankruptGuardProps> = ({ entity, onAction }) => {
  const customer =
    entity instanceof Customer
      ? entity
      : entity instanceof Domain
        ? entity.useProject().useCustomer()
        : entity.useCustomer();
  const isBankrupt = customer?.useIsBankrupt() ?? false;

  useEffect(() => {
    if (isBankrupt && customer) {
      showModal("customerBankrupt", <CustomerActionDeniedModal />);
    } else {
      onAction(isBankrupt);
    }
  }, [customer, onAction, isBankrupt]);

  return null;
};

export const bankruptcyCheckedAction =
  (entity: Customer | ProjectBase | Domain, action: () => void) => () =>
    showModal(
      "customerActionCheck",
      <BankruptGuardCheck
        entity={entity}
        onAction={(isBankrupt) => {
          if (!isBankrupt) {
            action();
          }
        }}
      />,
    );
