import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import { iconFileCertificate } from "@mittwald/flow-icons/dist/fileCertificate";
import React from "react";
import { FC } from "react";
import useContextState, {
  StateReturnContext,
} from "../../../../../../../../hooks/useContextState";
import { SSLCertificateCheckReplaceChanges } from "../../../../../../../../model/domain/ssl/SSLCertificate";
import { getCertificateData } from "../../../../utils/getCertificateData";
import { SSLCertificateParseError } from "../../../../utils/sslCertificateApiErrors";
import { KeyField } from "../../../components/KeyField/KeyField";
import { EditCertificateProps } from "../../EditCertificate";
import { useCertificateStepState } from "../CertificateStep/CertificateStep";
import { FileData } from "@mittwald/flow-components/dist/components/FileInputField";

interface State {
  privateKey: FileData[] | string;
  privateKeyData: string;
  diff: SSLCertificateCheckReplaceChanges;
}
export const usePrivateKeyState = (): StateReturnContext<State> => {
  return useContextState<State>(
    {
      privateKey: [],
      privateKeyData: "",
      diff: {},
    },
    "privateKeyState",
  );
};

export const PrivateKeyStep: FC<EditCertificateProps> = (props) => {
  const { certificate } = props;

  const [state, setState] = usePrivateKeyState();
  const [certificateStepState] = useCertificateStepState();

  const form = useForm<State>({
    defaultValues: {
      privateKey: state.privateKey,
    },
    onSubmit: async (values) => {
      const privateKeyData = getCertificateData(values.privateKey);

      try {
        if (!privateKeyData.toLowerCase().includes("private")) {
          throw new Error("containsNoPrivateKey");
        }

        const statusResult = await certificate.checkEditCertificate(
          certificateStepState.certificateData,
          privateKeyData,
        );

        if (!statusResult.isReplaceable) {
          throw new SSLCertificateParseError(statusResult.reason);
        }

        setState({
          privateKey: values.privateKey,
          privateKeyData: privateKeyData,
          diff: statusResult.diff,
        });
      } catch (e) {
        if (e instanceof SSLCertificateParseError) {
          form.setError("privateKey", {
            message: e.message,
          });

          return false;
        }

        form.setError("privateKey", {
          message: joinTranslationKeys(
            "modal",
            "editCertificate",
            "privateKey",
            e instanceof Error ? e.message : "invalidPrivateKey",
          ),
        });

        return false;
      }
    },
  });

  return (
    <WizardIntroStep
      description="privateKey.description"
      form={form}
      headline="privateKey"
      headlineIcon={iconFileCertificate}
      id="privateKey"
      indicatorText="privateKeyStep"
    >
      <KeyField
        accept={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "text/plain": [".key", ".txt", ".pem"],
        }}
        name="privateKey"
        placeholder={{
          text: "-----BEGIN PRIVATE KEY-----\n...\n-----END PRIVATE KEY-----",
        }}
      />
    </WizardIntroStep>
  );
};
