import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import { SSLCertificateErrors } from "../../../../../model/domain/ssl/SSLCertificate";

const knownErrorKeys: Array<SSLCertificateErrors["message"]> = [
  "ca_not_authorized_for_this_name",
  "certificate_decode_failed",
  "certificate_expired",
  "certificate_not_authorized_to_sign",
  "certificate_parsing_failed",
  "certificate_read_failed",
  "certificate_self_signed",
  "incompatible_usage",
  "private_key_decode_failed",
  "private_key_encrypted",
  "private_key_mismatch",
  "private_key_not_rsa",
  "private_key_parse_failed",
  "private_key_read_failed",
  "too_many_intermediates",
  "unknown",
  "unknown_authority",
  "unknown_cloudflare_error",
];

export const getSSLCertificateErrorTranslationKey = (
  error?: string,
): string => {
  return joinTranslationKeys("sslCertificate", "parse", "error", error);
};

export class SSLCertificateParseError extends Error {
  public constructor(message: SSLCertificateErrors["message"]) {
    super(getSSLCertificateErrorTranslationKey(message));
  }
}

export const isSSLCertificateKeyError = (key?: string): boolean => {
  return !!(key && knownErrorKeys.includes(key as any));
};
