import { SectionLayout } from "@mittwald/flow-components/dist/components/Section/components";
import React, { FC } from "react";
import {
  ProSpaceArticle,
  ProSpaceArticleTag,
} from "../../../../../../model/article";
import ConfigurationArticleSection from "./ConfigurationArticleSection";
import ConfigurationDedicationSection from "./ConfigurationDedicationSection";
import ConfigurationResourceSection from "./ConfigurationResourceSection";
import ConfigurationStorageSection from "./ConfigurationStorageSection";

interface Props {
  selectedProSpaceArticles: ProSpaceArticle[];
  selectedArticle: ProSpaceArticle;
}

export const ConfigurationSections: FC<Props> = (props) => {
  const { selectedArticle, selectedProSpaceArticles } = props;

  const liteSelected =
    selectedArticle.articleTag === ProSpaceArticleTag.proSpaceLite;

  return (
    <SectionLayout>
      <ConfigurationArticleSection />
      <ConfigurationResourceSection
        liteSelected={liteSelected}
        selectedProSpaceArticles={selectedProSpaceArticles}
      />
      <ConfigurationStorageSection selectedArticle={selectedArticle} />
      {!liteSelected && <ConfigurationDedicationSection isDisabled={false} />}
    </SectionLayout>
  );
};

export default ConfigurationSections;
