import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { ComponentType } from "react";
import { ActionConfirmationModalProps } from "@mittwald/flow-components/dist/lib/actions/ConfirmAction";
import DefaultConfirmationModal from "@mittwald/flow-components/dist/lib/actions/DefaultConfirmationModal";

export const AddressDeletionConfirmation: ComponentType<
  ActionConfirmationModalProps
> = (props) => (
  <DefaultConfirmationModal {...props}>
    <Text i18n={{ id: "confirmDeletionConsequences" }} strong />
  </DefaultConfirmationModal>
);

export default AddressDeletionConfirmation;
