import React from "react";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { ChangePasswordModal } from "../../../pages/app/profile/security/modals/ChangePassword/ChangePasswordModal";
import { Signup } from "../../signup/Signup";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

ModelActionBuilderRegistry.register({
  model: Signup,
  method: "changePassword",
  roles: [Role.anybody],
  altAction: () => () => showModal("changePassword", <ChangePasswordModal />),
});
