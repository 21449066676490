import { iconDashboard } from "@mittwald/flow-icons/dist/dashboard";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import Customer from "../../../../model/customer/Customer";

export const customerDashboardPages = PageTree.build(BacksideRenderer, {
  "/app/organizations/:customerId/dashboard": {
    views: {
      main: lazy(() => import("./dashboard")),
    },
    props: {
      navigationSection: "management",
    },
    icon: iconDashboard,
    linkId: "customerDashboard",
    accessCheck: () => Customer.useLoadByPathParam().checkMyRoleIsIn("owner"),
  },
});
