import { FormController } from "@mittwald/flow-components/dist/components/Form";
import { useEffect } from "react";
import { InstallationUserInputDataSourceName } from "../../misc/userInput/UserInputDataSource";
import UserInputList from "../../misc/userInput/UserInputList";
import UserInputDataSourceUI from "./UserInputDataSourceUI";
import UserInputUI from "./UserInputUI";

interface UseSetDefaultValuesOptions {
  fieldNamePrefix?: string;
  form: FormController<any>;
  installationDataSourceFields?: Record<
    string,
    InstallationUserInputDataSourceName
  >;
}

export class UserInputListUI {
  private readonly userInputList: UserInputList;

  private constructor(userInputList: UserInputList) {
    this.userInputList = userInputList;
  }

  public static of(userInputList: UserInputList): UserInputListUI {
    return new UserInputListUI(userInputList);
  }

  private useSetInstallationDefaultValues(
    sourceField: string,
    dataSource: InstallationUserInputDataSourceName,
    opts: UseSetDefaultValuesOptions,
  ): void {
    const { form, fieldNamePrefix } = opts;

    const input = this.userInputList.findByDataSource(dataSource);

    const watchedFieldValue = form.watch(sourceField);

    useEffect(() => {
      if (!input) {
        return;
      }

      const fieldName = UserInputUI.of(input).getFieldName({
        prefix: fieldNamePrefix,
      });

      form.setValue(fieldName, watchedFieldValue);
    }, [watchedFieldValue]);
  }

  public useSetDefaultValues(opts: UseSetDefaultValuesOptions): void {
    const { form, fieldNamePrefix, installationDataSourceFields = {} } = opts;

    for (const [formField, dataSource] of Object.entries(
      installationDataSourceFields,
    )) {
      this.useSetInstallationDefaultValues(formField, dataSource, opts);
    }

    const inputsWithDefault = this.userInputList.items.map((input) => {
      const inputUi = UserInputUI.of(input);
      const dataSourceUi = input.dataSource
        ? UserInputDataSourceUI.of(input.dataSource)
        : undefined;

      const defaultValueFromApi =
        "defaultValue" in input.data ? input.data.defaultValue : undefined;
      const defaultValueFromDataSource = dataSourceUi?.useDefaultValue();

      const dataSourceOptions = dataSourceUi?.useSelectOptions();

      const firstDataSourceSelectOptionValue =
        dataSourceOptions?.length === 1
          ? dataSourceOptions[0]?.value
          : undefined;

      const firstSelectOptionValue = inputUi.getSchemaSelectOptions()[0]?.value;
      const value =
        defaultValueFromDataSource ??
        defaultValueFromApi ??
        firstSelectOptionValue ??
        firstDataSourceSelectOptionValue;
      const name = inputUi.getFieldName({
        prefix: fieldNamePrefix,
      });

      return {
        value,
        name,
      };
    });

    useEffect(() => {
      inputsWithDefault.forEach(({ value, name }) => {
        form.setValue(name, value);
      });
    }, []);
  }
}

export default UserInputListUI;
