import { types } from "mobx-state-tree";

export const hiddenBannersStoreModel = types
  .model({
    hiddenBanners: types.array(types.string),
  })
  .actions((self) => ({
    add: (banner: string) => {
      self.hiddenBanners.push(banner);
    },
  }))
  .views((self) => ({
    isHidden: (tile: string): boolean => {
      return self.hiddenBanners.includes(tile);
    },
  }));
