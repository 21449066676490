import { ErrorBoundaryComponentProps } from "@mittwald/flow-components/dist/components/ErrorBoundary";
import { DefaultErrorView } from "@mittwald/flow-components/dist/components/ErrorBoundary/components";
import NoAccessError from "@mittwald/flow-lib/dist/access/NoAccessError";
import React, { FC } from "react";
import { NoAccessResourceLoadingError } from "@mittwald/flow-lib/dist/resources/ResourceLoadingError";
import { NoAccess } from "../NoAccess";

export const ErrorView: FC<ErrorBoundaryComponentProps> = (props) => {
  const { error } = props;

  if (
    error instanceof NoAccessError ||
    error instanceof NoAccessResourceLoadingError
  ) {
    return <NoAccess />;
  }

  return <DefaultErrorView error={error} />;
};

export default ErrorView;
