import TextLink from "@mittwald/flow-components/dist/components/TextLink";
import React, { FC } from "react";
import StyledReactMarkdown from "./styled/StyledReactMarkdown";

interface Props {
  markdown: string;
}

export const Markdown: FC<Props> = (props) => {
  const { markdown } = props;

  return (
    <StyledReactMarkdown
      components={{
        a: (props) => (
          <TextLink _inline action={props.href}>
            {props.children}
          </TextLink>
        ),
      }}
    >
      {markdown}
    </StyledReactMarkdown>
  );
};

export default Markdown;
