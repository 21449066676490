import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { iconDesktop } from "@mittwald/flow-icons/dist/desktop";
import React, { FC } from "react";
import InstalledSystemSoftware, {
  UpdateSystemSoftwareVersionInputs,
} from "../../../../../model/app/InstalledSystemSoftware";
import { Project } from "../../../../../model/project";
import SystemSoftwareTitle from "../../components/SystemSoftwareTitle";
import SystemSoftwareVersionSelect from "../../components/SystemSoftwareVersionSelect";
import OverviewStepContent from "./components/OverviewStepContent";
import { CurrentFeeBanner } from "./components/CurrentFeeBanner";

interface Props {
  installedSystemSoftware: InstalledSystemSoftware;
}

export const UpdateInstalledSystemSoftwareVersion: FC<Props> = (props) => {
  const { installedSystemSoftware } = props;
  const project = Project.useLoadByPathParam();

  const systemSoftware = installedSystemSoftware.useSoftware();
  const systemSoftwareVersionsInRange =
    installedSystemSoftware.useVersionsInRange();
  const latestVersion = systemSoftwareVersionsInRange.latest();

  const form = useForm<UpdateSystemSoftwareVersionInputs>({
    defaultValues: { systemSoftwareVersionId: latestVersion.data.id },
    onSubmit: async (values) => {
      await installedSystemSoftware.updateVersion(values);
    },
  });

  const selectedVersionUid = form.watch("systemSoftwareVersionId");
  const selectedSoftwareVersion = systemSoftwareVersionsInRange.items.find(
    (v) => selectedVersionUid === v.data.id,
  );
  const currentFee = selectedSoftwareVersion?.checkCurrentFee();

  return (
    <WizardModal
      _size={currentFee ? "m" : "s"}
      form={form}
      steps={["version", "overview"]}
    >
      <WizardIntroStep
        description="description"
        headline="updateInstalledSystemSoftwareVersion"
        headlineIcon={iconDesktop}
        id="version"
        indicatorText="versionIndicator"
      >
        <Section.Item
          headline={{
            id: "version",
            values: {
              app: <SystemSoftwareTitle systemSoftware={systemSoftware} />,
            },
          }}
        >
          <ColumnLayout medium={[1, 1]}>
            <SystemSoftwareVersionSelect
              name="systemSoftwareVersionId"
              versions={systemSoftwareVersionsInRange.useItems()}
            />
          </ColumnLayout>
          {selectedSoftwareVersion && currentFee && (
            <CurrentFeeBanner
              currentFee={currentFee}
              selectedSoftwareVersion={selectedSoftwareVersion}
            />
          )}
        </Section.Item>
      </WizardIntroStep>
      {selectedSoftwareVersion && currentFee && (
        <WizardStep id="overview" indicatorText="overviewIndicator">
          <OverviewStepContent
            currentFee={currentFee}
            project={project}
            systemSoftwareVersion={selectedSoftwareVersion}
          />
        </WizardStep>
      )}
    </WizardModal>
  );
};
export default UpdateInstalledSystemSoftwareVersion;
