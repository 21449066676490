import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Wrap } from "@mittwald/flow-lib/dist/components/Wrap";
import React, { FC } from "react";
import { UserInputField } from "../../../../components/UserInputField";
import UserInputList from "../../../../model/misc/userInput/UserInputList";
import UserInputLayout from "../../domain/owner/components/ContactFields/styled/UserInputLayout";

interface Props {
  userInputs: UserInputList;
  step?: string;
  section?: string;
  fieldNamePrefix?: string;
}

export const UserInputFields: FC<Props> = (props) => {
  const { userInputs, fieldNamePrefix, step, section } = props;

  if (userInputs.isEmpty) {
    return null;
  }

  const userInputsForStepAndSection = userInputs.filter({ section, step });

  return (
    <UserInputLayout medium={[1, 1]}>
      {userInputsForStepAndSection.items.map((userInput) => {
        return (
          <Wrap
            key={userInput.name}
            with={
              (userInput.name === "host" ||
                ((userInput.schema.enum || userInput.format === "password") &&
                  userInputsForStepAndSection.items.length > 2)) && (
                <div data-user-input-layout="full-width" />
              )
            }
          >
            <Wrap
              with={
                (userInput.format === "password" ||
                  (userInput.schema.enum &&
                    userInput.schema.enum.length > 2)) &&
                userInputsForStepAndSection.items.length > 2 && (
                  <ColumnLayout medium={[1, 1]} small={[1]} />
                )
              }
            >
              <UserInputField
                fieldNamePrefix={fieldNamePrefix}
                userInput={userInput}
              />
            </Wrap>
          </Wrap>
        );
      })}
    </UserInputLayout>
  );
};

export default UserInputFields;
