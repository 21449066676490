import { useTranslation } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { extractTextFromReactNode } from "@mittwald/flow-components/dist/lib/extractTextFromReactNode";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import { useBreadcrumb } from "@mittwald/flow-lib/dist/hooks/useBreadcrumb";
import useOptionalPathParams from "@mittwald/flow-lib/dist/hooks/useOptionalPathParams";
import { FC } from "react";
import Customer from "../../model/customer/Customer";
import Model from "../../model/Model";
import { Project } from "../../model/project";
import Server from "../../model/server/Server";

const PageTitle: FC = () => {
  const { projectId, serverId, customerId } = useOptionalPathParams();
  const translate = useTranslation();
  const breadcrumbId = useBreadcrumb().getId();

  const value: string | undefined = customerId
    ? Customer.useLoadById(customerId).data.name
    : serverId
      ? Server.useLoadById(serverId).data.description
      : projectId
        ? Project.useLoadById(projectId).description
        : Model.useDryApi();

  const breadcrumbString = breadcrumbId
    .split(".")
    .slice(0, value ? 4 : breadcrumbId.includes("app") ? 3 : 1)
    .join(".");

  const translatedPageTitle = extractTextFromReactNode(
    translate(joinTranslationKeys("pages", breadcrumbString, "title")),
  );

  document.title = `${value ?? "mStudio"} - ${translatedPageTitle}`;

  return null;
};

export default PageTitle;
