import { DateTime } from "@mittwald/flow-components/dist/components/DateTimeText";
import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column";
import { iconExpiration } from "@mittwald/flow-icons/dist/expiration";
import React from "react";

import ProjectMembership from "../../../../../../../model/project/ProjectMembership";

export const ExpiresAtColumn: ResourceListColumnRenderer<ProjectMembership> = ({
  resource,
}) => {
  if (!resource.data.expiresAt) {
    return <Column disabled text="noExpireDate" />;
  }
  return (
    <Column
      icon={iconExpiration}
      text={
        <DateTime
          format="date"
          translationKey="memberUntil"
          value={resource.data.expiresAt}
        />
      }
      tooltip={
        <DateTime
          format="date"
          translationKey="memberUntil"
          value={resource.data.expiresAt}
        />
      }
    />
  );
};
