import Select from "@mittwald/flow-components/dist/components/Select";
import React, { FC } from "react";
import { MySqlVersionList } from "../../../../../../model/database/MySqlVersionList";
import { DatabaseVersionListUI } from "../../../../../../model/ui/database/DatabaseVersionListUI";

interface Props {
  projectId: string;
}

export const MySqlVersionSelect: FC<Props> = (props) => {
  const versionList = MySqlVersionList.useLoadAllByProjectId(props.projectId);

  return (
    <Select
      label="version"
      name="database.version"
      options={DatabaseVersionListUI.of(versionList).getVersionSelectOptions()}
      placeholder="version"
      rules={{ required: true }}
    />
  );
};

export default MySqlVersionSelect;
