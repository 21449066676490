import { faPersonDigging } from "@fortawesome/pro-regular-svg-icons/faPersonDigging";
import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { DefaultModal } from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import React, { FC } from "react";
import Onboarding from "../../../../../../model/onboarding/Onboarding";

export const StatusOnboarding: FC = () => {
  const visibility = useVisibilityController();

  const form = useForm({
    onSubmit: () => {
      Onboarding.completeStatus();
    },
  });

  return (
    <DefaultModal
      description="description"
      headline="status"
      headlineIcon={faPersonDigging}
      primary={{ action: [{ form }, visibility.hide], ok: true }}
      visibility={visibility}
    >
      <Section.Item headline="statusPage">
        <Text
          i18n={{
            id: "description",
            values: {
              statusPage: (
                <TextLink
                  action={import.meta.env.VITE_STATUS_PAGE_URI}
                  i18n="statusPage"
                />
              ),
              history: (
                <TextLink
                  action={`${import.meta.env.VITE_STATUS_PAGE_URI}/incidents`}
                  i18n="history"
                />
              ),
            },
          }}
        />
      </Section.Item>

      <Section.Item headline="subscribe">
        <Text i18n="description" />
        <TextLink
          action={`${import.meta.env.VITE_STATUS_PAGE_URI}/#subscribe`}
          i18n="subscribe"
        />
      </Section.Item>
    </DefaultModal>
  );
};

export default StatusOnboarding;
