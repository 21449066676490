import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import React from "react";
import EditCertificate from "../../../pages/app/domain/ssl/modals/EditCertificate/EditCertificate";
import UploadCertificate from "../../../pages/app/domain/ssl/modals/UploadCertificate/UploadCertificate";
import SSLCertificate from "../../domain/ssl/SSLCertificate";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultSSLCertificateRoles = [Role.projectAdmin, Role.projectDeveloper];

ModelActionBuilderRegistry.register({
  model: SSLCertificate,
  method: "createCertificate",
  roles: defaultSSLCertificateRoles,
  altAction: () => () => showModal("uploadCertificate", <UploadCertificate />),
})
  .register({
    model: SSLCertificate,
    method: "editCertificate",
    roles: defaultSSLCertificateRoles,
    altAction: (action) => () =>
      showModal(
        "editCertificate",
        <EditCertificate certificate={action.model} />,
      ),
  })
  .register({
    model: SSLCertificate,
    method: "deleteCertificate",
    roles: defaultSSLCertificateRoles,
    altAction: (action) => ({
      actions: [action],
      confirmation: {
        required: true,
        modalTranslationKey: "deleteCertificateAction",
        modalProps: {
          headlineIcon: iconDelete,
        },
      },
    }),
  });
