import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import Extension, { MarketplaceContext } from "./Extension";
import ExtensionInstanceList from "./ExtensionInstanceList";
import invariant from "invariant";

class ExtensionList extends ListModel<Extension> {
  public static useLoadAll(context: MarketplaceContext): ExtensionList {
    const extensions = mittwaldApi.extensionListExtensions
      .getResource({ query: { context } })
      .useWatchData()
      .map((extension) => new Extension(extension));

    return new ExtensionList(extensions);
  }

  public static useLoadAllAvailable(
    context: MarketplaceContext,
    contextId: string,
  ): ExtensionList {
    const extensions = ExtensionList.useLoadAll(context).items;

    const extensionInstanceList = ExtensionInstanceList.useLoadAll(
      context,
      contextId,
    );

    const filteredExtensions = extensions.filter(
      (extension) =>
        !extension.isBlocked &&
        !extension.isDisabled &&
        !extensionInstanceList.items.find(
          (extensionInstance) => extensionInstance.extensionId === extension.id,
        ),
    );

    return new ExtensionList(filteredExtensions);
  }

  public tryFindExtension(extensionId: string): Extension | undefined {
    return this.items.find((extension) => extension.id === extensionId);
  }

  public findExtension(extensionId: string): Extension {
    const extension = this.tryFindExtension(extensionId);

    invariant(extension, "extension not found");

    return extension;
  }
}

export default ExtensionList;
