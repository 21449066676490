import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import React, { FC } from "react";
import UserInput from "../../model/misc/userInput/UserInput";
import { ParsedValue } from "../../model/misc/userInput/UserInputRecord";
import UserInputUI from "../../model/ui/misc/UserInputUI";

interface Props {
  userInput: UserInput;
  value?: ParsedValue;
}

export const UserInputInformation: FC<Props> = (props) => {
  const { userInput, value } = props;
  const userInputUI = UserInputUI.of(userInput);

  const copyPasswordContent =
    userInput.format === "password" && typeof value === "string"
      ? value
      : undefined;

  return (
    <StaticInformation
      copyContent={copyPasswordContent}
      label={userInputUI.getLabelText()}
      text={userInputUI.getDisplayValue(value)}
    />
  );
};

export default UserInputInformation;
