import { SidebarSectionHeader } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/components/SidebarSectionHeader";
import React, { FC } from "react";
import { Skeleton } from "@mittwald/flow-components/dist/components/Skeleton";

export const CustomerSideBarHeaderLoadingView: FC = () => (
  <SidebarSectionHeader
    subTitle={<Skeleton _width={100} />}
    title={<Skeleton _width={200} />}
  />
);

export default CustomerSideBarHeaderLoadingView;
