import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Select } from "@mittwald/flow-components/dist/components/Select";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import React, { FC } from "react";
import { iconDatabase } from "@mittwald/flow-icons/dist/database";
import {
  AppInstallation,
  ConnectDatabaseInputs,
} from "../../../../../model/app/AppInstallation";
import { AppInstallationUI } from "../../../../../model/ui/app/AppInstallationUI";

interface Props {
  appInstallation: AppInstallation;
}

export const ConnectDatabase: FC<Props> = (props) => {
  const visibility = useVisibilityController();

  const databaseOptions = AppInstallationUI.of(
    props.appInstallation,
  ).useDatabaseSelectOptions();

  const form = useForm<ConnectDatabaseInputs>({
    translationKey: "connectDatabase",
    defaultValues: { databaseId: databaseOptions[0]?.value },
    onSubmit: async (values) => {
      const databaseInfo = values.databaseId.split("_");

      const databaseId = databaseInfo[0];
      const databaseType = databaseInfo[1];

      if (!databaseId) {
        throw new Error("invalid database input");
      }

      await props.appInstallation.connectDatabase({ databaseType, databaseId });
      visibility.hide();
    },
  });

  form.watch("databaseId");

  return (
    <DefaultModal
      _size="s"
      description="description"
      headline="connectDatabase"
      headlineIcon={iconDatabase}
      primary={{ action: [{ form }, visibility.hide], ok: true }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Select
          label="selectDatabase"
          name="databaseId"
          options={databaseOptions}
          rules={{ required: true }}
        />
      </Form>
    </DefaultModal>
  );
};

export default ConnectDatabase;
