import { faUserShield } from "@fortawesome/pro-regular-svg-icons/faUserShield";
import {
  BodyColumnDisplay,
  ResourceList,
  useResourceList,
} from "@mittwald/flow-components/dist/components/ResourceList";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import React, { FC } from "react";
import { iconSignOut } from "@mittwald/flow-icons/dist/signOut";
import UserName from "../../../../../../components/UserName";
import useUserAvatarSource from "../../../../../../hooks/useUserAvatarSource";
import { includeFilter } from "../../../../../../lib/filter";
import { showDetails } from "../../../../../../lib/showDetails";
import { ModelActionBuilder } from "../../../../../../model/actions/ModelActionBuilder";
import CustomerMembership from "../../../../../../model/customer/CustomerMembership";
import { ExpiresAtColumn } from "./components/ExpiresAtColumn";
import { filter } from "./components/filter";
import { MemberRoleColumn } from "./components/MemberRoleColumn";

export interface CustomerMemberListProps {
  members: CustomerMembership[];
  columns: BodyColumnDisplay[];
  hideFilter?: boolean;
  hideSecondaryActions?: boolean;
  hideSecondaryStatusIcons?: boolean;
}

export const CustomerMemberList: FC<CustomerMemberListProps> = (props) => {
  const {
    columns,
    members,
    hideFilter,
    hideSecondaryActions,
    hideSecondaryStatusIcons,
  } = props;
  const gotoLink = useGotoLink();
  const buildLink = useLinkBuilder();

  const buildDetailsLink = (id: string): string =>
    buildLink("customerMemberDetails", { membershipId: id });

  const users = members.map((m) => m.useUser());

  const completeFilter = filter
    .fulltextFilter((membership, searchText) =>
      includeFilter(
        users
          .filter((u) => u.id === membership.userId)
          .map((u) => u.fullName)
          .join(" "),
        searchText,
      ),
    )
    .get();

  const memberList = useResourceList<CustomerMembership>({
    mainActionMapper: (member) => buildDetailsLink(member.data.id),
    resources: members,
    filter: hideFilter ? undefined : completeFilter,
    booleanFilters: hideFilter ? undefined : ["expiration"],
    registerPropertyValues: hideFilter
      ? undefined
      : (resource, register) => {
          register("role", resource.role.name);
        },
    imageUrl: (member) => ({
      useValue: () => useUserAvatarSource(member.userId),
    }),
    nameColumn: (props) => (
      <UserName key={props.resource.userId} userId={props.resource.userId} />
    ),
    tooltip: "member",
    bodyColumns: [
      {
        id: "role",
        renderer: MemberRoleColumn,
      },
      {
        id: "expiresAt",
        renderer: ExpiresAtColumn,
      },
    ],
    secondaryStatusIcons: hideSecondaryStatusIcons
      ? undefined
      : [
          {
            icon: faUserShield,
            mapper: (member) =>
              member.data.mfa
                ? { status: "enabled", tooltip: "mfaStatusActive" }
                : { status: "disabled", tooltip: "mfaStatusActive" },
          },
        ],
    secondaryActions: (member) =>
      hideSecondaryActions
        ? []
        : [
            showDetails(buildDetailsLink(member.data.id)),
            {
              id: "remove",
              title: "removeMember",
              icon: iconSignOut,
              action: ModelActionBuilder.build(member, "removeMember"),
            },
            {
              id: "leave",
              title: "leaveCustomer",
              icon: iconSignOut,
              action: [
                ModelActionBuilder.build(member, "leaveCustomer"),
                () => gotoLink("customersOverview"),
              ],
            },
          ],
  });

  return (
    <TranslationProvider name="customerMemberList" type="component">
      <ResourceList
        {...memberList}
        columnLayout={{
          name: { minWidth: 300 },
          body: columns,
        }}
        hideSorting={hideFilter}
        hideTilesView
      />
    </TranslationProvider>
  );
};
