import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import AppInstallationTitleWithVersion from "../../../pages/app/app/components/AppInstallationTitleWithVersion";
import AppInstallation from "../../app/AppInstallation";
import { AppInstallationList } from "../../app/AppInstallationList";
import React from "react";
import { MySqlDatabase } from "../../database/MySqlDatabase";
import { MySqlDatabaseList } from "../../database/MySqlDatabaseList";

export class AppInstallationUI {
  public appInstallation: AppInstallation;

  private constructor(appInstallation: AppInstallation) {
    this.appInstallation = appInstallation;
  }

  public static of(appInstallation: AppInstallation): AppInstallationUI {
    return new AppInstallationUI(appInstallation);
  }

  public useDatabaseSelectOptions(): SelectOptions {
    return this.appInstallation.useAvailableDatabases().map((d) => ({
      value: `${d.id}_${d.type}`,
      label: { text: d.data.description },
    }));
  }

  public useAppCompatibleDatabaseSelectOptions(): SelectOptions {
    const mySqlDatabases = MySqlDatabaseList.useLoadAllByProjectId(
      this.appInstallation.projectId,
    ).useItems();

    const allowedDbVersions =
      this.appInstallation
        .useVersion()
        .data.databases?.map((s) =>
          MySqlDatabase.mapDatabaseVersionIdToVersionString(s.version),
        ) ?? [];

    const databases = mySqlDatabases.filter(
      (d) =>
        !d.linkedAppInstallationId &&
        allowedDbVersions.includes(d.data.version),
    );

    return databases.map((d) => ({
      value: `${d.id}_${d.type}`,
      label: { text: d.data.description },
    }));
  }

  public static getAppInstallationSelectOptions(
    appInstallationList: AppInstallationList,
    shortId?: boolean,
  ): SelectOptions {
    return appInstallationList.items.map((appInstallation) => {
      return {
        value: shortId ? appInstallation.data.shortId : appInstallation.id,
        label: (
          <AppInstallationTitleWithVersion appInstallation={appInstallation} />
        ),
      };
    });
  }
}
