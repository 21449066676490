import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import React from "react";
import CreateConversation from "../../../pages/app/support/modals/CreateConversation/CreateConversation";
import { Role } from "../acl/Role";
import Conversation from "../../support/Conversation";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

ModelActionBuilderRegistry.register({
  model: Conversation,
  roles: [Role.anybody],
  method: "createNew",
  altAction: () => () =>
    showModal("createConversation", <CreateConversation />),
});
