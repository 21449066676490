import Project, { AnyProject } from "../project/Project";
import { PublicKeyList } from "./PublicKeyList";
import { AccessApiData } from "./types";

export class Access {
  public readonly id: string;
  public readonly description: string;
  public readonly active: boolean;
  public readonly hasPassword: boolean;
  public readonly publicKeyList: PublicKeyList;
  public readonly userName: string;
  public readonly projectId: string;
  public readonly lastPublicKey: boolean;

  public constructor(data: AccessApiData) {
    this.id = data.id;
    this.description = data.description;
    this.active = !!data.active;
    this.hasPassword = data.hasPassword;
    this.publicKeyList = PublicKeyList.fromApiData(data.publicKeys ?? []);
    this.userName = data.userName;
    this.projectId = data.projectId;
    this.lastPublicKey =
      this.publicKeyList.items.length === 1 && !this.hasPassword;
  }

  public useProject(): AnyProject {
    return Project.useLoadById(this.projectId);
  }

  public useFullUserName(): string {
    const project = this.useProject();
    return `${this.userName}@${project.shortId}`;
  }
}
