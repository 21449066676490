import { iconContainer } from "@mittwald/flow-icons/dist/container";
import { iconContainerRegistry } from "@mittwald/flow-icons/dist/containerRegistry";
import { iconContainerStorage } from "@mittwald/flow-icons/dist/containerStorage";
import { combine } from "@mittwald/flow-lib/dist/access/combine";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import { Container } from "../../../model/container/Container";
import { Registry } from "../../../model/container/Registry";
import { Volume } from "../../../model/container/Volume";
import Project from "../../../model/project/Project";

export const containerPages = PageTree.build(BacksideRenderer, {
  "/app/projects/:projectId/container": {
    sector: "basics",
    props: {
      navigationSection: "component",
      tabNav: true,
      new: true,
    },
    icon: iconContainer,
    accessCheck: combine.and(() => {
      const project = Project.useLoadByPathParam();
      return (
        project.useCheckMyRoleIsIn(["owner", "external"]) &&
        project.hasContainerAccess()
      );
    }),
  },
  "/app/projects/:projectId/container/containers": {
    views: {
      main: lazy(() => import("./containers/overview")),
    },
    props: {
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(Container, "createNew"),
          },
          icon: iconContainer,
          title: "createContainer",
        },
      ],
    },
    linkId: "containerOverview",
  },
  "/app/projects/:projectId/container/volume": {
    views: {
      main: lazy(() => import("./volume/overview")),
    },
    props: {
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(Volume, "createNew"),
          },
          icon: iconContainerStorage,
          title: "createVolume",
        },
      ],
    },
    linkId: "containerVolume",
  },
  "/app/projects/:projectId/container/volume/:volumeId": {
    views: { pageTitle: lazy(() => import("./components/VolumeTitle")) },
    props: {
      tabNav: true,
    },
    linkId: "volumeOverview",
  },
  "/app/projects/:projectId/container/volume/:volumeId/details": {
    views: {
      main: lazy(() => import("./volume/details")),
    },
    linkId: "volumeDetails",
  },
  "/app/projects/:projectId/container/volume/:volumeId/containers": {
    views: {
      main: lazy(() => import("./volume/containers")),
    },
    linkId: "volumeContainers",
  },
  "/app/projects/:projectId/container/registry": {
    views: {
      main: lazy(() => import("./registries/overview")),
    },
    props: {
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(Registry, "createNew"),
          },
          icon: iconContainerRegistry,
          title: "createRegistry",
        },
      ],
    },
    linkId: "containerRegistry",
  },
  "/app/projects/:projectId/container/registry/:registryId": {
    views: { pageTitle: lazy(() => import("./components/RegistryTitle")) },
    props: {
      tabNav: true,
    },
    linkId: "registryOverview",
  },
  "/app/projects/:projectId/container/registry/:registryId/details": {
    views: {
      main: lazy(() => import("./registries/details")),
    },
    linkId: "registryDetails",
  },
  "/app/projects/:projectId/container/containers/:containerId": {
    views: { pageTitle: lazy(() => import("./components/ContainerTitle")) },
    props: {
      tabNav: true,
    },
  },
  "/app/projects/:projectId/container/containers/:containerId/details": {
    views: {
      main: lazy(() => import("./containers/details")),
    },
    linkId: "containerDetails",
    hooks: {
      useCheckState: () => {
        const { projectId } = usePathParams("projectId");
        return Container.useLoadByPathParam(
          projectId,
        ).containerRecreatePageStatus();
      },
    },
  },
  "/app/projects/:projectId/container/containers/:containerId/entrypoint": {
    views: {
      main: lazy(() => import("./containers/entrypoint")),
    },
    linkId: "containerEntrypoint",
  },
  "/app/projects/:projectId/container/containers/:containerId/volume": {
    views: {
      main: lazy(() => import("./containers/volume")),
    },
    linkId: "containerVolume",
  },
  "/app/projects/:projectId/container/containers/:containerId/environment": {
    views: {
      main: lazy(() => import("./containers/environment")),
    },
    linkId: "containerEnvironment",
  },
  "/app/projects/:projectId/container/containers/:containerId/ports": {
    views: {
      main: lazy(() => import("./containers/ports")),
    },
    linkId: "containerPorts",
  },
  "/app/projects/:projectId/container/containers/:containerId/logs": {
    views: {
      main: lazy(() => import("./containers/logs")),
    },
    linkId: "containerLogs",
  },
});
