import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { Volume, VolumeApiData } from "./Volume";

export class VolumeList extends ListModel<Volume> {
  public static fromApiData(data: VolumeApiData[]): VolumeList {
    return new VolumeList(data.map((d) => Volume.fromApiData(d)));
  }

  public static useLoadAllByProjectId(projectId: string): VolumeList {
    const volumes = mittwaldApi.containerListVolumes
      .getResource({ path: { projectId }, query: { stackId: projectId } })
      .useWatchData()
      .map((s) => Volume.fromApiData(s));

    return new VolumeList(volumes);
  }

  public getSelectOptions(): SelectOptions {
    const options: SelectOptions = this.items.map((r) => {
      return { value: r.name, label: { text: r.name } };
    });

    options.unshift({
      label: "newVolume",
      value: "createNewVolume",
    });

    return options;
  }
}
