import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import { Select } from "@mittwald/flow-components/dist/components/Select";
import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { useEffect, useState } from "react";
import { MarketplaceContext } from "../../../../../../model/marketplace/Extension";
import ExtensionInstanceList from "../../../../../../model/marketplace/ExtensionInstanceList";
import { ExtensionInstanceListUI } from "../../../../../../model/marketplace/ExtensionInstanceListUI";
import ModelRelationType from "../../../../../../model/misc/modelRelation/ModelRelationType";
import {
  CreateConversationFormType,
  extensionCategoryId,
} from "../CreateConversation";

interface ExtensionInstanceSelectProps {
  relationType: ModelRelationType;
}

export const ExtensionInstanceSelect: React.FC<ExtensionInstanceSelectProps> = (
  props,
) => {
  const { relationType } = props;
  const form = useFormContext<CreateConversationFormType>();
  const contextAggregate = form.getValues(
    "relatedTo.aggregate",
  ) as MarketplaceContext;
  const contextId = form.getValues("relatedTo.id");
  const selectedCategoryId = form.watch("categoryId");
  const isExtensionsCategorySelected =
    selectedCategoryId === extensionCategoryId;

  const [options, setOptions] = useState<SelectOptions>([]);

  useEffect(() => {
    void (async () => {
      const extensionInstances = await ExtensionInstanceList.loadAll(
        contextAggregate,
        contextId,
      );
      const extensionInstancesUI =
        ExtensionInstanceListUI.of(extensionInstances);

      setOptions(await extensionInstancesUI.getSelectOptions(false));
    })();
  }, [contextAggregate, contextId]);

  useEffect(() => {
    if (
      relationType.aggregate === "customer" ||
      relationType.aggregate === "project"
    ) {
      if (isExtensionsCategorySelected) {
        form.setValue("relatedToChild", {
          id: form.getValues("relatedToChild.id"),
          domain: "extension",
          aggregate: "extensionInstance",
        });
      }
    } else {
      form.setValue("relatedToChild", {
        id: "",
        domain: "extension",
        aggregate: "extensionInstance",
      });
    }
  }, [relationType, selectedCategoryId]);

  if (!isExtensionsCategorySelected) {
    return null;
  }

  return (
    <TranslationProvider name="extensionInstanceSelect" type="component">
      <Select
        isSearchable
        label="extensionInstances"
        name="relatedToChild.id"
        options={options}
        placeholder="extensionInstances"
        rules={{ required: true }}
      />
    </TranslationProvider>
  );
};
