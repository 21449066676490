import { Banner } from "@mittwald/flow-components/dist/components/Banner";
import { H2 } from "@mittwald/flow-components/dist/components/H2";
import { InfoBox } from "@mittwald/flow-components/dist/components/InfoBox";
import { Skeleton } from "@mittwald/flow-components/dist/components/Skeleton";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC, Suspense } from "react";
import DisableForm from "../../../../../../components/DisableForm";
import {
  ProSpaceArticle,
  SpaceServerArticle,
} from "../../../../../../model/article";
import ContractItem from "../../../../../../model/contract/ContractItem";
import Bytes from "../../../../../../model/misc/Bytes";
import MoneyValue from "../../../../../../model/misc/MoneyValue";
import PerMonthWithFreeTrialText from "../../OrderServerWizard/components/PerMonthWithFreeTrialText";
import TariffChangeExecutionDateInfo from "./TariffChangeExecutionDateInfo";

interface Props {
  contractItem: ContractItem;
  storageSize: Bytes;
  article: SpaceServerArticle | ProSpaceArticle;
  showNewPrice: boolean;
}

export const TariffChangePricePreviewBox: FC<Props> = (props) => {
  const { showNewPrice, contractItem, storageSize, article } = props;

  const freeTrialUntil = contractItem.freeTrialUntil;

  const currentPriceSubline = freeTrialUntil ? (
    <PerMonthWithFreeTrialText freeTrialUntil={freeTrialUntil} price={0} />
  ) : (
    "perMonth"
  );

  const currentConfigElement = (
    <>
      <H2 _mt="l" center i18n="currentConfigHeadline" />
      <InfoBox.Price
        _alignItems="center"
        price={contractItem.totalPrice.toMoneyValueInput()}
        priceSubline={currentPriceSubline}
      />
    </>
  );

  const usePreviewPrice = (): MoneyValue =>
    article.useOrderPreview({
      storageSize,
    }).total;

  const newConfigPriceElem = (
    <>
      <H2 _mt="l" center i18n="newConfigHeadline" />
      <InfoBox.Price
        price={{
          useValue: () => usePreviewPrice().toMoneyValueInput(),
          loadingView: (
            <DisableForm>
              <Skeleton _width={140} />
            </DisableForm>
          ),
        }}
        priceSubline="perMonth"
      />
      <Suspense fallback={<Skeleton />}>
        <Text center>
          <TariffChangeExecutionDateInfo
            article={article}
            contractItem={contractItem}
            storageSize={storageSize}
          />
        </Text>
      </Suspense>
    </>
  );

  return (
    <TranslationProvider name="AdjustServerPricePreviewBox" type="component">
      <InfoBox _alignItems="center" _grow>
        <H2 center i18n="mainHeadline" />
        <Text center i18n="mainDescription" />
        {currentConfigElement}
        {showNewPrice && newConfigPriceElem}
        {freeTrialUntil && (
          <Banner
            description="endFreeTrialDescription"
            headline="endFreeTrial"
          />
        )}
      </InfoBox>
    </TranslationProvider>
  );
};

export default TariffChangePricePreviewBox;
