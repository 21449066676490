import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import ColumnLayout from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import { iconApp } from "@mittwald/flow-icons/dist/app";
import { iconDatabase } from "@mittwald/flow-icons/dist/database";
import { iconEmail } from "@mittwald/flow-icons/dist/email";
import { iconSubdomain } from "@mittwald/flow-icons/dist/subdomain";

import React, { FC } from "react";

export const ProjectDependencyInfo: FC = () => (
  <>
    <ColumnLayout _mt="m" medium={[1, 1]}>
      <TextWithIconLayout icon={iconApp}>
        <Text i18n={{ id: "apps" }} />
      </TextWithIconLayout>
      <TextWithIconLayout icon={iconDatabase}>
        <Text i18n={{ id: "database" }} />
      </TextWithIconLayout>
      <TextWithIconLayout icon={iconEmail}>
        <Text i18n={{ id: "email" }} />
      </TextWithIconLayout>
      <TextWithIconLayout icon={iconSubdomain}>
        <Text i18n={{ id: "subdomains" }} />
      </TextWithIconLayout>
    </ColumnLayout>
    <CheckBox
      name="confirmation"
      rules={{ required: true }}
      selectableBoxProps={{ style: { alignSelf: "stretch" } }}
      title={{
        id: "confirmComponents",
      }}
    />
  </>
);

export default ProjectDependencyInfo;
