import { Skeleton } from "@mittwald/flow-components/dist/components/Skeleton";
import withSubcomponents from "@mittwald/flow-components/dist/lib/withSubcomponents";
import useOptionalPathParams from "@mittwald/flow-lib/dist/hooks/useOptionalPathParams";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";
import React, { FC } from "react";
import AppInstallation from "../../../../model/app/AppInstallation";

interface InstallationTitleProps {
  appInstallationId?: string;
}

const Component: FC<InstallationTitleProps> = (props) => {
  const pathParams = useOptionalPathParams();

  const appInstallationId =
    props.appInstallationId ?? pathParams.appInstallationId;

  const appInstallation = AppInstallation.useTryLoadById(appInstallationId);

  return <>{appInstallation?.data.description}</>;
};

export const InstallationTitle = withSubcomponents(Component, {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WithSkeletonLoader: (props: InstallationTitleProps) => (
    <Suspense loadingView={<Skeleton _width={100} />}>
      <Component {...props} />
    </Suspense>
  ),
});

export default InstallationTitle;
