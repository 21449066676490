import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import TextField from "@mittwald/flow-components/dist/components/TextField";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";
import React, { FC } from "react";
import {
  DatabaseTypes,
  NewDatabaseInputs,
} from "../../../../../../model/database/Database";
import MySqlVersionSelect from "./MySqlVersionSelect";
import RedisVersionSelect from "./RedisVersionSelect";
import VersionSelectLoadingView from "./VersionSelectLoadingView";

interface Props {
  projectId: string;
}

export const GeneralStep: FC<Props> = (props) => {
  const form = useFormContext<NewDatabaseInputs>();
  const mySqlSelected = form.watch("type") === DatabaseTypes.mySql;
  const redisSelected = form.watch("type") === DatabaseTypes.redis;

  return (
    <WizardStep id="general" indicatorText="generalStep">
      <Section.Layout>
        <Section.Item headline="general">
          <Text i18n="generalDescription" />
        </Section.Item>
        <Section.Item>
          <ColumnLayout medium={[1, 1]}>
            <TextField
              autoFocus
              label="databaseDescription"
              name={mySqlSelected ? "database.description" : "description"}
              rules={{ required: true }}
            />
            {mySqlSelected && (
              <Suspense loadingView={<VersionSelectLoadingView />}>
                <MySqlVersionSelect projectId={props.projectId} />
              </Suspense>
            )}
            {redisSelected && (
              <Suspense loadingView={<VersionSelectLoadingView />}>
                <RedisVersionSelect projectId={props.projectId} />
              </Suspense>
            )}
          </ColumnLayout>
        </Section.Item>
      </Section.Layout>
    </WizardStep>
  );
};

export default GeneralStep;
