import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import { Wrap } from "@mittwald/flow-lib/dist/components/Wrap";
import React, { FC } from "react";
import { UserInputField } from "../../../../../../components/UserInputField";
import UserInput from "../../../../../../model/misc/userInput/UserInput";
import DomainUI from "../../../../../../model/ui/domain/DomainUI";
import UserInputLayout from "./styled/UserInputLayout";

interface ContactFieldsProps {
  fieldNamePrefix: string;
  userInputs?: UserInput[];
  hostname: string;
  disabled?: boolean;
}

export const ContactFields: FC<ContactFieldsProps> = (props) => {
  const { fieldNamePrefix, hostname, userInputs, disabled } = props;

  return (
    <TranslationProvider name="contactFormInputs" type="section">
      <UserInputLayout medium={[1, 1]}>
        {userInputs &&
          userInputs.map((userInput) => {
            return (
              <Wrap
                key={userInput.name}
                with={
                  userInput.name === "type" && (
                    <div data-user-input-layout="full-width" />
                  )
                }
              >
                <UserInputField
                  disabled={disabled}
                  fieldNamePrefix={fieldNamePrefix}
                  rules={{
                    validate: (value: unknown) =>
                      DomainUI.validateDomainHandle(userInput, value, hostname),
                  }}
                  userInput={userInput}
                />
              </Wrap>
            );
          })}
      </UserInputLayout>
    </TranslationProvider>
  );
};
