import { iconDashboard } from "@mittwald/flow-icons/dist/dashboard";
import { iconProject } from "@mittwald/flow-icons/dist/project";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import { ModelActionBuilder } from "../../../../model/actions/ModelActionBuilder";
import Project from "../../../../model/project/Project";
import ProSpaceProject from "../../../../model/project/ProSpaceProject";

export const projectDashboardPages = PageTree.build(BacksideRenderer, {
  "/app/projects/:projectId/dashboard": {
    views: {
      main: lazy(() => import("./dashboard")),
    },
    props: {
      navigationSection: "system",
      ctaPages: [
        {
          action: {
            useValue: () => {
              const project = Project.useLoadByPathParam();
              return ModelActionBuilder.build(
                project instanceof ProSpaceProject ? project : undefined,
                "adjust",
              );
            },
          },
          icon: iconProject,
          title: "adjustProject",
        },
      ],
    },
    icon: iconDashboard,
    linkId: "projectDashboard",
    accessCheck: () =>
      Project.useLoadByPathParam().useCheckMyRoleIsIn(["owner", "external"]),
  },
});
