import { IconLookup } from "@mittwald/flow-components/dist/components/Icon";
import { ModalContent } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { AnimationController } from "@mittwald/flow-components/dist/hooks/useAnimationController";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import React, { FC, useState } from "react";
import { CodeInput } from "./CodeInput";

export interface MfaConfirmationModalContentProps {
  icon?: IconLookup;
  rejectionAnimation: AnimationController;
}

export const MfaConfirmationModalContent: FC<
  MfaConfirmationModalContentProps
> = (props) => {
  const { icon, rejectionAnimation } = props;
  const [recovery, setRecovery] = useState(false);

  const linkAction: AnyAction = () => setRecovery((old) => !old);

  const mfaInput = (
    <CodeInput
      label="2fa"
      maxLength={recovery ? 16 : 6}
      preventEnterPress
      rejectionAnimation={rejectionAnimation}
    />
  );

  const recoveryInput = (
    <TextField
      _width="100%"
      autoFocus
      label="recoveryCode"
      name="multiFactorCode"
      rules={{ required: true, minLength: 16, maxLength: 16 }}
    />
  );

  return (
    <ModalContent
      description="description"
      headline="mfaConfirmation"
      headlineIcon={icon}
      linkAction={{
        text: recovery ? "link.totp" : "link.recovery",
        action: linkAction,
      }}
    >
      {!recovery && mfaInput}
      {recovery && recoveryInput}
    </ModalContent>
  );
};
