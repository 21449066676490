import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faMemory } from "@fortawesome/pro-regular-svg-icons/faMemory";
import { faMicrochip } from "@fortawesome/pro-regular-svg-icons/faMicrochip";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column";
import React, { FC, ReactNode } from "react";
import { iconInfoCircle } from "@mittwald/flow-icons/dist/infoCircle";
import { iconStorage } from "@mittwald/flow-icons/dist/storage";
import {
  ArticleAttribute,
  RAMArticleAttribute,
  StorageArticleAttribute,
} from "../../../model/article";

interface Props {
  articleAttribute: ArticleAttribute;
  articleAmount: number;
}

export const ArticleAttributeInfo: FC<Props> = (props) => {
  const { articleAttribute, articleAmount } = props;

  let icon: IconDefinition = iconInfoCircle;
  let content: ReactNode = null;

  if (articleAttribute instanceof StorageArticleAttribute) {
    icon = iconStorage;
    content = articleAttribute.bytes.multiply(articleAmount).pretty();
  } else if (articleAttribute.key === "vcpu") {
    content = `${articleAttribute.value} vCPU`;
    icon = faMicrochip;
  } else if (articleAttribute instanceof RAMArticleAttribute) {
    content = articleAttribute.bytes.pretty();
    icon = faMemory;
  }

  if (content) {
    return <Column icon={icon} text={{ text: content }} />;
  }

  return null;
};

export default ArticleAttributeInfo;
