import { RequireAccess } from "@mittwald/flow-components/dist/components/RequireAccess";
import { FCC } from "@mittwald/flow-lib/dist/lib/functions";
import React from "react";
import { useCheckAuthenticated } from "../../hooks/useCheckAuthenticated";

export const IfLoggedIn: FCC = (props) => {
  return (
    <RequireAccess check={useCheckAuthenticated}>
      {props.children}
    </RequireAccess>
  );
};

export default IfLoggedIn;
