import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { iconActivate } from "@mittwald/flow-icons/dist/activate";
import { iconDeactivate } from "@mittwald/flow-icons/dist/deactivate";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import React from "react";
import CreatePublicKey from "../../../pages/app/accesses/modals/CreatePublicKey/CreatePublicKey";
import CreateSftpUser from "../../../pages/app/accesses/modals/CreateSftpUser/CreateSftpUser";
import CreateSshUser from "../../../pages/app/accesses/modals/CreateSshUser/CreateSshUser";
import UpdatePassword from "../../../pages/app/accesses/modals/UpdatePassword/UpdatePassword";
import { SftpUser } from "../../access/SftpUser";
import { SftpUserList } from "../../access/SftpUserList";
import { SshUser } from "../../access/SshUser";
import { SshUserList } from "../../access/SshUserList";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultAccessRoles = [Role.projectAdmin, Role.projectDeveloper];

ModelActionBuilderRegistry.register({
  model: SftpUser,
  roles: defaultAccessRoles,
  method: "toggleActive",
  altAction: (action) => ({
    action,
    confirmation: {
      required: true,
      modalTranslationKey: action.model.active
        ? "deactivateSftpUser"
        : "activateSftpUser",
      modalProps: {
        headlineIcon: action.model.active ? iconDeactivate : iconActivate,
        primary: { ok: !action.model.active, destructive: action.model.active },
      },

      modalTranslationValues: {
        sftpUser: action.model.description,
      },
    },
  }),
})
  .register({
    model: SftpUser,
    roles: defaultAccessRoles,
    method: "deletePublicKey",
    isAvailable: (sftpUser) => !sftpUser.lastPublicKey,
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deletePublicKey",
        required: true,
        modalProps: {
          headlineIcon: iconDelete,
        },
      },
    }),
  })
  .register({
    model: SftpUser,
    roles: defaultAccessRoles,
    method: "deleteSftpUser",
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deleteSftpUser",
        required: true,
        modalProps: { headlineIcon: iconDelete },
        modalTranslationValues: {
          sftpUser: action.model.description,
        },
      },
    }),
  })
  .register({
    model: SshUser,
    roles: defaultAccessRoles,
    method: "toggleActive",
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: action.model.active
          ? "deactivateSshUser"
          : "activateSshUser",
        required: true,
        modalProps: {
          headlineIcon: action.model.active ? iconDeactivate : iconActivate,
          primary: {
            ok: !action.model.active,
            destructive: action.model.active,
          },
        },
        modalTranslationValues: {
          sshUser: action.model.description,
        },
      },
    }),
  })
  .register({
    model: SshUser,
    roles: defaultAccessRoles,
    method: "deletePublicKey",
    isAvailable: (sshUser) => !sshUser.lastPublicKey,
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deletePublicKey",
        required: true,
        modalProps: { headlineIcon: iconDelete },
      },
    }),
  })
  .register({
    model: SshUser,
    roles: defaultAccessRoles,
    method: "deleteSshUser",
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deleteSshUser",
        required: true,
        modalProps: { headlineIcon: iconDelete },
        modalTranslationValues: {
          sshUser: action.model.description,
        },
      },
    }),
  })
  .register({
    model: SftpUserList,
    roles: defaultAccessRoles,
    method: "useLoadByProjectId",
  })
  .register({
    model: SshUserList,
    roles: defaultAccessRoles,
    method: "useLoadByProjectId",
  })
  .register({
    model: SshUser,
    roles: defaultAccessRoles,
    method: "addPublicKey",
    altAction: (action) => () =>
      showModal(
        "createPublicKey",
        <CreatePublicKey sftpOrSshUser={action.model} />,
      ),
  })
  .register({
    model: SftpUser,
    roles: defaultAccessRoles,
    method: "addPublicKey",
    altAction: (action) => () =>
      showModal(
        "createPublicKey",
        <CreatePublicKey sftpOrSshUser={action.model} />,
      ),
  })
  .register({
    model: SshUser,
    roles: defaultAccessRoles,
    method: "updatePassword",
    altAction: (action) => () =>
      showModal(
        "updatePassword",
        <UpdatePassword sftpOrSshUser={action.model} />,
      ),
  })
  .register({
    model: SftpUser,
    roles: defaultAccessRoles,
    method: "updatePassword",
    altAction: (action) => () =>
      showModal(
        "updatePassword",
        <UpdatePassword sftpOrSshUser={action.model} />,
      ),
  })
  .register({
    model: SshUser,
    roles: defaultAccessRoles,
    method: "createNew",
    altAction: () => () => showModal("createSshUser", <CreateSshUser />),
  })
  .register({
    model: SftpUser,
    roles: defaultAccessRoles,
    method: "createNew",
    altAction: () => () => showModal("createSftpUser", <CreateSftpUser />),
  })
  .register({
    model: SftpUser,
    roles: defaultAccessRoles,
    method: "deletePassword",
    isAvailable: (sftpUser) =>
      sftpUser.hasPassword && !sftpUser.publicKeyList.isEmpty,
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deleteAccessPassword",
        required: true,
        modalProps: {
          headlineIcon: iconDelete,
        },
      },
    }),
  })
  .register({
    model: SshUser,
    roles: defaultAccessRoles,
    method: "deletePassword",
    isAvailable: (sshUser) =>
      sshUser.hasPassword && !sshUser.publicKeyList.isEmpty,
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deleteAccessPassword",
        required: true,
        modalProps: {
          headlineIcon: iconDelete,
        },
      },
    }),
  });
