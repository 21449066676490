import { faMessageSmile } from "@fortawesome/pro-regular-svg-icons/faMessageSmile";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { VisibilityControllable } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import React from "react";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import User from "../../model/user/User";

interface PollModalProps extends VisibilityControllable {}

type Status =
  MittwaldApi.Paths.V2_Poll_Settings_UserId.Post.Parameters.RequestBody["status"];

export const FeedbackPollModal: React.FC<PollModalProps> = (props) => {
  const { visibility } = props;

  const user = User.useMe();

  const updatePollSettings = (status: Status): void => {
    void mittwaldApi.userPostPollStatus
      .request({
        path: { userId: user.id },
        requestBody: { userId: user.id, status },
      })
      .then(() => {
        mittwaldApi.userGetPollStatus
          .getResource({ path: { userId: user.id } })
          .expire();
      });
  };

  return (
    <TranslationProvider name="pollModal" type="modal">
      <DefaultModal
        abort={{
          text: "close",
          action: () => updatePollSettings("ignored"),
        }}
        description="pollDescription"
        headline="headline"
        headlineIcon={faMessageSmile}
        primary={{
          text: "openPoll",
          action: [
            () => updatePollSettings("completed"),
            import.meta.env.VITE_FEEDBACK_POLL_URI,
          ],
        }}
        visibility={visibility}
      />
    </TranslationProvider>
  );
};
