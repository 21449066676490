import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import React, { FC } from "react";
import { CreateContextStateProvider } from "../../../../../../hooks/useContextState";
import { CaStep } from "./components/CaStep/CaStep";
import { CertificateStep } from "./components/CertificateStep/CertificateStep";
import { LinkedConnectionsStep } from "./components/LinkedConnectionsStep/LinkedConnectionsStep";
import { OverviewStep } from "./components/OverviewStep/OverviewStep";
import { PrivateKeyStep } from "./components/PrivateKeyStep/PrivateKeyStep";

const UploadCertificateWrapper: FC = () => {
  return (
    <WizardModal
      steps={[
        "certificate",
        "certificateAuthority",
        "privateKey",
        "linkedConnections",
        "overview",
      ]}
    >
      <CertificateStep />
      <CaStep />
      <PrivateKeyStep />
      <LinkedConnectionsStep />
      <OverviewStep />
    </WizardModal>
  );
};

const UploadCertificate: FC = (props) => {
  return (
    <CreateContextStateProvider>
      <UploadCertificateWrapper {...props} />
    </CreateContextStateProvider>
  );
};

export default UploadCertificate;
