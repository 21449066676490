import { Text } from "@mittwald/flow-components/dist/components/Text";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import React, { ComponentType } from "react";

import DefaultConfirmationModal from "@mittwald/flow-components/dist/lib/actions/DefaultConfirmationModal";
import { ActionConfirmationModalProps } from "@mittwald/flow-components/dist/lib/actions/ConfirmAction";

export const ConfirmDeliveryboxDeletion: ComponentType<
  ActionConfirmationModalProps
> = (props) => (
  <DefaultConfirmationModal {...props} headlineIcon={iconDelete}>
    <Text i18n={{ id: "confirmDeletionConsequences" }} strong />
  </DefaultConfirmationModal>
);

export default ConfirmDeliveryboxDeletion;
