import { AvatarSource } from "@mittwald/flow-components/dist/components/Avatar";
import { SidebarSectionHeader } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/components/SidebarSectionHeader";
import React, { FC } from "react";
import { useImageUrlBuilder } from "../../../../hooks/useFileServiceUrlBuilder";
import { ModelActionBuilder } from "../../../../model/actions/ModelActionBuilder";
import User from "../../../../model/user/User";

export const ProfileSectionHeader: FC = () => {
  const user = User.useMe();

  const getImageUrl = useImageUrlBuilder();

  const avatarSource: AvatarSource = {
    url: user.data.avatarRef ? getImageUrl(user.data.avatarRef) : undefined,
    initials: user.fullName,
  };

  return (
    <>
      <SidebarSectionHeader
        avatar={avatarSource}
        circle
        imageAction={ModelActionBuilder.build(user, "requestAvatarUpload")}
        subTitle="profile"
        title={{
          id: "userProfileName",
          values: {
            firstName: user.data.person.firstName,
            lastName: user.data.person.lastName,
          },
        }}
      />
    </>
  );
};

export default ProfileSectionHeader;
