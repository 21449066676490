import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC, useEffect } from "react";
import { App } from "../../../../../../model/app/App";
import AppUI from "../../../../../../model/ui/app/AppUI";
import AppVersionSelect from "../../../components/AppVersionSelect";
import { AppInstallationCreationInputWithAppId } from "../InstallApp";

export const ConfigurationStepContent: FC = () => {
  const form = useFormContext<AppInstallationCreationInputWithAppId>();
  const appId = form.watch("appId");
  const appVersionId = form.watch("appVersionId");

  const app = App.useLoadById(appId);
  const appUI = AppUI.of(app);
  const appVersionList = app.useVersions(true);
  const appVersions = appVersionList.useItems();

  useEffect(() => {
    if (appVersionId) {
      return;
    }
    const latestVersion = appVersionList.latest();
    form.setValue("appVersionId", latestVersion.data.id);
  }, [app.id, appVersions, appVersionId]);

  return (
    <Section.Item
      headline={{ id: "configuration", values: { app: app.name } }}
      headlineIcon={appUI.appIcon}
    >
      <Text
        i18n={
          app.isCustomApp
            ? "customAppConfigurationDescription"
            : "configurationDescription"
        }
      />
      <ColumnLayout large={[1, 1]} medium={[2, 1]}>
        <TextField
          autoFocus
          label="description"
          name="description"
          rules={{ required: true }}
        />
      </ColumnLayout>
      <ColumnLayout large={[1, 1]} medium={[2, 1]}>
        {!app.isCustomApp && (
          <AppVersionSelect name="appVersionId" versions={appVersions} />
        )}
      </ColumnLayout>
    </Section.Item>
  );
};

export default ConfigurationStepContent;
