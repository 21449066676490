import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { iconActivate } from "@mittwald/flow-icons/dist/activate";
import { iconDeactivate } from "@mittwald/flow-icons/dist/deactivate";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import { iconPlay } from "@mittwald/flow-icons/dist/play";
import React from "react";
import CreateCronjob from "../../../pages/app/cronjob/modals/CreateCronjob/CreateCronjob";
import { AppInstallationList } from "../../app/AppInstallationList";
import { Cronjob } from "../../cronjob/Cronjob";
import { CronjobList } from "../../cronjob/CronjobList";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultCronjobRoles = [Role.projectAdmin, Role.projectDeveloper];

ModelActionBuilderRegistry.register({
  model: Cronjob,
  roles: defaultCronjobRoles,
  method: "triggerCronjob",
  altAction: (action) => ({
    action,
    confirmation: {
      modalTranslationKey: "triggerCronjob",
      required: true,
      modalProps: {
        headlineIcon: iconPlay,
        primary: { ok: true, destructive: false },
      },
      modalTranslationValues: {
        cronjobName: action.model.description,
      },
    },
  }),
})
  .register({
    model: Cronjob,
    roles: defaultCronjobRoles,
    method: "delete",
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "removeCronjob",
        required: true,
        modalProps: { headlineIcon: iconDelete },
        modalTranslationValues: {
          cronjobName: action.model.description,
        },
      },
    }),
  })
  .register({
    model: Cronjob,
    roles: defaultCronjobRoles,
    method: "toggleActive",
    isAvailable: (cronjob) => !!cronjob.data.appId,
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: action.model.isActive
          ? "deactivateCronjob"
          : "activateCronjob",
        required: true,
        modalProps: {
          headlineIcon: action.model.isActive ? iconDeactivate : iconActivate,
          primary: {
            destructive: action.model.isActive,
            ok: !action.model.isActive,
          },
        },
        modalTranslationValues: {
          cronjobName: action.model.description,
        },
      },
    }),
  })
  .register({
    model: CronjobList,
    roles: defaultCronjobRoles,
    method: "useLoadAllByProjectId",
  })
  .register({
    model: Cronjob,
    roles: defaultCronjobRoles,
    isAvailable: () => !AppInstallationList.useLoadAllByPathParam().isEmpty,
    method: "createNew",
    altAction: () => () => showModal("createCronjob", <CreateCronjob />),
  });
