import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import { ScheduleInputs } from "../../../../../../../components/ScheduleInputs/ScheduleInputs";
import { BackupScheduleInputs } from "../../../../../../../model/backup/BackupSchedule";
import BackupUI from "../../../../../../../model/ui/backup/BackupUI";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";

export const IntervalStep: FC = () => {
  const form = useFormContext<BackupScheduleInputs>();

  return (
    <WizardStep id="interval" indicatorText="intervalStep">
      <Section.Item headline="interval">
        <Text i18n="intervalDescription" />
        <ScheduleInputs form={form} intervals={BackupUI.backupIntervals} />
      </Section.Item>
    </WizardStep>
  );
};

export default IntervalStep;
