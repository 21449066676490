export interface DNSZone {
  $origin?: string;
  $ttl?: number;
  soa?: {
    name: string;
    minimum: number;
    expire: number;
    retry: number;
    refresh: number;
    serial: number;
    rname: string;
    mname: string;
    ttl?: number;
  };
  ns?: Array<{
    name: string;
    host: string;
    ttl?: number;
  }>;
  a?: Array<{
    name: string;
    ip: string;
    ttl?: number;
  }>;
  aaaa?: Array<{
    name: string;
    ip: string;
    ttl?: number;
  }>;
  cname?: Array<{
    name: string;
    alias: string;
    ttl?: number;
  }>;
  mx?: Array<{
    name: string;
    preference: number;
    host: string;
    ttl?: number;
  }>;
  txt?: Array<{
    name: string;
    txt: string;
    ttl?: number;
  }>;
  ptr?: Array<{
    name: string;
    fullname: string;
    host: string;
    ttl?: number;
  }>;
  srv?: Array<{
    name: string;
    target: string;
    priority: number;
    weight: number;
    port: number;
    ttl?: number;
  }>;
  spf?: Array<{
    name: string;
    data: string;
    ttl?: number;
  }>;
  caa?: Array<{
    name: string;
    flags: number;
    tag: string;
    value: string;
    ttl?: number;
  }>;
  ds?: Array<{
    name: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    key_tag: string;
    algorithm: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    digest_type: string;
    digest: string;
    ttl?: number;
  }>;
}

const defaultTemplate = `; Zone: {zone}
; Exported  (yyyy-mm-ddThh:mm:ss.sssZ): {datetime}

{$origin}
{$ttl}

; SOA Record
{$soa}

; NS Records
{ns}

; MX Records
{mx}

; A Records
{a}

; AAAA Records
{aaaa}

; CNAME Records
{cname}

; PTR Records
{ptr}

; TXT Records
{txt}

; SRV Records
{srv}

; SPF Records
{spf}

; CAA Records
{caa}

; DS Records
{ds}

`;

const process$ORIGIN = function (
  data: DNSZone["$origin"],
  template: string,
): string {
  let ret = "";
  if (typeof data !== "undefined") {
    ret += "$ORIGIN " + data;
  }
  return template.replace("{$origin}", ret);
};

const process$TTL = function (data: DNSZone["$ttl"], template: string): string {
  let ret = "";
  if (typeof data !== "undefined") {
    ret += `$TTL ${data}`;
  }
  return template.replace("{$ttl}", ret);
};

const processSOA = function (data: DNSZone["soa"], template: string): string {
  let ret = template;
  if (data) {
    ret = ret.replace(
      "{$soa}",
      `{name} {ttl}	IN	SOA	{mname}{rname}(
{serial} ;serial
{refresh} ;refresh
{retry} ;retry
{expire} ;expire
{minimum} ;minimum ttl
)`,
    );
    data.name = data.name || "@";
    data.ttl = data.ttl || undefined;
    Object.keys(data).map((key) => {
      ret = ret.replace(
        "{" + key + "}",
        `${data[key as keyof DNSZone["soa"]]}\t`,
      );
    });
  } else {
    ret = ret.replace("{$soa}", "");
  }
  return ret;
};

const processNS = function (data: DNSZone["ns"], template: string): string {
  let ret = "";
  if (data) {
    for (const value of data) {
      ret += (value.name || "@") + "\t";
      if (value.ttl) {
        ret += `${value.ttl}\t`;
      }
      ret += "IN\tNS\t" + value.host + "\n";
    }
  }
  return template.replace("{ns}", ret);
};

const processA = function (data: DNSZone["a"], template: string): string {
  let ret = "";
  if (data) {
    for (const value of data) {
      ret += (value.name || "@") + "\t";
      if (value.ttl) ret += `${value.ttl}\t`;
      ret += "IN\tA\t" + value.ip + "\n";
    }
  }
  return template.replace("{a}", ret);
};

const processAAAA = function (data: DNSZone["aaaa"], template: string): string {
  let ret = "";
  if (data) {
    for (const value of data) {
      ret += (value.name || "@") + "\t";
      if (value.ttl) {
        ret += `${value.ttl}\t`;
      }
      ret += "IN\tAAAA\t" + value.ip + "\n";
    }
  }
  return template.replace("{aaaa}", ret);
};

const processCNAME = function (
  data: DNSZone["cname"],
  template: string,
): string {
  let ret = "";
  if (data) {
    for (const value of data) {
      ret += (value.name || "@") + "\t";
      if (value.ttl) {
        ret += `${value.ttl}\t`;
      }
      ret += "IN\tCNAME\t" + value.alias + "\n";
    }
  }
  return template.replace("{cname}", ret);
};

const processMX = function (data: DNSZone["mx"], template: string): string {
  let ret = "";
  if (data) {
    for (const value of data) {
      ret += (value.name || "@") + "\t";
      if (value.ttl) {
        ret += `${value.ttl}\t`;
      }
      ret += `IN\tMX\t${value.preference}\t${value.host}\n`;
    }
  }
  return template.replace("{mx}", ret);
};

const processPTR = function (data: DNSZone["ptr"], template: string): string {
  let ret = "";
  if (data) {
    for (const value of data) {
      ret += (value.name || "@") + "\t";
      if (value.ttl) {
        ret += `${value.ttl}\t`;
      }
      ret += "IN\tPTR\t" + value.host + "\n";
    }
  }
  return template.replace("{ptr}", ret);
};

const processTXT = function (data: DNSZone["txt"], template: string): string {
  let ret = "";
  if (data) {
    for (const value of data) {
      ret += (value.name || "@") + "\t";
      if (value.ttl) {
        ret += `${value.ttl}\t`;
      }
      ret += 'IN\tTXT\t"' + value.txt + '"\n';
    }
  }
  return template.replace("{txt}", ret);
};

const processSRV = function (data: DNSZone["srv"], template: string): string {
  let ret = "";
  if (data) {
    for (const value of data) {
      ret += (value.name || "@") + "\t";
      if (value.ttl) {
        ret += `${value.ttl}\t`;
      }
      ret += `IN\tSRV\t${value.priority}\t`;
      ret += `${value.weight}\t`;
      ret += `${value.port}\t`;
      ret += `${value.target}\n`;
    }
  }
  return template.replace("{srv}", ret);
};

const processSPF = function (data: DNSZone["spf"], template: string): string {
  let ret = "";
  if (data) {
    for (const value of data) {
      ret += (value.name || "@") + "\t";
      if (value.ttl) {
        ret += `${value.ttl}\t`;
      }
      ret += "IN\tSPF\t" + value.data + "\n";
    }
  }
  return template.replace("{spf}", ret);
};

const processCAA = function (data: DNSZone["caa"], template: string): string {
  let ret = "";
  if (data) {
    for (const value of data) {
      ret += (value.name || "@") + "\t";
      if (value.ttl) {
        ret += `${value.ttl}\t`;
      }
      ret += `IN\tCAA\t${value.flags}\t${value.tag}\t${value.value}\n`;
    }
  }
  return template.replace("{caa}", ret);
};

const processDS = function (data: DNSZone["ds"], template: string): string {
  let ret = "";
  if (data) {
    for (const value of data) {
      ret += (value.name || "@") + "\t";
      if (value.ttl) {
        ret += `${value.ttl}\t`;
      }
      ret += `IN\tDS\t${value.key_tag}\t${value.algorithm}\t${value.digest_type}\t${value.digest}\n`;
    }
  }
  return template.replace("{ds}", ret);
};

const processValues = function (options: DNSZone, template: string): string {
  if (options["$origin"]) {
    template = template.replace("{zone}", options["$origin"]);
  } else if (options["soa"]) {
    template = template.replace("{zone}", options["soa"]["name"]);
  } else {
    template = template.replace("{zone}", "");
  }

  template = template.replace("{datetime}", new Date().toISOString());
  template = template.replace(
    "{time}",
    Math.round(Date.now() / 1000).toString(),
  );
  return template;
};

const generate = (options: DNSZone, overrideTemplate?: string): string => {
  let template = overrideTemplate || defaultTemplate;
  template = process$ORIGIN(options["$origin"], template);
  template = process$TTL(options["$ttl"], template);
  template = processSOA(options["soa"], template);
  template = processNS(options["ns"] || [], template);
  template = processA(options["a"] || [], template);
  template = processAAAA(options["aaaa"] || [], template);
  template = processCNAME(options["cname"] || [], template);
  template = processMX(options["mx"] || [], template);
  template = processPTR(options["ptr"] || [], template);
  template = processTXT(options["txt"] || [], template);
  template = processSRV(options["srv"] || [], template);
  template = processSPF(options["spf"] || [], template);
  template = processCAA(options["caa"] || [], template);
  template = processDS(options["ds"] || [], template);
  template = processValues(options, template);
  return template.replace(/\n{2,}/gim, "\n\n");
};

// copied from https://github.com/elgs/dns-zonefile/blob/master/lib/zonefile.js
// because the lib author is basically stupid in writing code - so this is the patched version
export default {
  generate,
  parse: () => console.log("not converted to ts - steal it from the lib"),
};
