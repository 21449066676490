import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import NewFlag from "@mittwald/flow-components/dist/components/NewFlag";
import Section from "@mittwald/flow-components/dist/components/Section";
import Text from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";
import Extension from "../../../../../model/marketplace/Extension";
interface Props {
  extension: Extension;
}
export const ConfirmationStepIntro: FC<Props> = () => {
  return (
    <Section.Item
      headline={{
        id: "confirmation",
        values: {
          beta: <NewFlag _inline />,
        },
      }}
    >
      <ColumnLayout medium={[2, 1]}>
        <Text i18n="confirmationDescription" />
      </ColumnLayout>
    </Section.Item>
  );
};

export default ConfirmationStepIntro;
