import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import { TargetTypes } from "../../../../../../model/ui/domain/DomainUI";
import DomainTargetFormFields from "../../../components/domainTargetFormFields";

interface DomainTargetStepContentProps {
  targetType: TargetTypes;
}

export const TargetStep: FC<DomainTargetStepContentProps> = (props) => {
  const { targetType } = props;

  return (
    <WizardStep id="target" indicatorText="targetStep">
      <Section.Item headline="domainTarget">
        <Text i18n="domainTargetDescription" />
        <DomainTargetFormFields targetType={targetType} />
      </Section.Item>
    </WizardStep>
  );
};

export default TargetStep;
