import { IconLookup } from "@mittwald/flow-components/dist/components/Icon";
import { SelectBoxOptions } from "@mittwald/flow-components/dist/components/SelectBox";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import React from "react";
import { AnyDatabase, DatabaseTypes } from "../../database/Database";
import Project from "../../project/Project";
import ProSpaceProject from "../../project/ProSpaceProject";
import { ReactComponent as MySqlIcon } from "./img/mysql.svg";
import { ReactComponent as RedisIcon } from "./img/redis.svg";

export class DatabaseUI {
  public readonly database: AnyDatabase;
  public readonly databaseVersionText: string;

  private constructor(database: AnyDatabase) {
    this.database = database;
    this.databaseVersionText = `${database.type} ${database.data.version}`;
  }

  public static of(database: AnyDatabase): DatabaseUI {
    return new DatabaseUI(database);
  }

  public useDatabaseDetailsLink(): string {
    const buildLink = useLinkBuilder();

    return buildLink("databaseDetails", {
      databaseId: this.database.id,
    });
  }

  public getIcon(): IconLookup {
    return this.database.type === "Redis" ? <RedisIcon /> : <MySqlIcon />;
  }

  public static useDatabaseTypeOptions(): SelectBoxOptions {
    const project = Project.useLoadByPathParam();
    const isProSpaceLite =
      project instanceof ProSpaceProject ? project.useIsProSpaceLite() : false;
    return [
      {
        value: DatabaseTypes.mySql,
        title: { text: DatabaseTypes.mySql },
        logo: <MySqlIcon />,
      },
      {
        value: DatabaseTypes.redis,
        title: { text: DatabaseTypes.redis },
        logo: <RedisIcon />,
        disabled: isProSpaceLite,
        tooltip: isProSpaceLite ? "notAvailableInProSpaceLite" : undefined,
      },
    ];
  }
}

export default DatabaseUI;
