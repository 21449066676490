import { faGit } from "@fortawesome/free-brands-svg-icons";
import { faNodeJs } from "@fortawesome/free-brands-svg-icons/faNodeJs";
import { faPhp } from "@fortawesome/free-brands-svg-icons/faPhp";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { faFileZipper } from "@fortawesome/pro-regular-svg-icons/faFileZipper";
import { IconLookup } from "@mittwald/flow-components/dist/components/Icon";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { iconApp } from "@mittwald/flow-icons/dist/app";
import React from "react";
import SystemSoftware, { SystemSoftwareNames } from "../../app/SystemSoftware";
import SystemSoftwareVersion from "../../app/SystemSoftwareVersion";
import { ReactComponent as MySqlIconSmall } from "../database/img/mysql-small.svg";
import { ReactComponent as MySqlIcon } from "../database/img/mysql.svg";
import { ReactComponent as RedisIcon } from "../database/img/redis.svg";
import { ReactComponent as ComposerIcon } from "./img/composer.svg";
import { ReactComponent as CurlIcon } from "./img/curl-small.svg";
import { ReactComponent as CurlIconSmall } from "./img/curl.svg";
import { ReactComponent as GhostScriptIcon } from "./img/ghostScript.svg";
import { ReactComponent as GraphicsMagickIconSmall } from "./img/graphicsMagick-small.svg";
import { ReactComponent as GraphicsMagickIcon } from "./img/graphicsMagick.svg";
import { ReactComponent as ImageMagickIcon } from "./img/imageMagick.svg";
import { ReactComponent as NanoIcon } from "./img/nano.svg";
import { ReactComponent as WebPIcon } from "./img/webP.svg";
import { ReactComponent as WpCliIcon } from "./img/wpcli.svg";
import { ReactComponent as PythonIcon } from "./img/python.svg";
import { ReactComponent as MidnightCommanderIcon } from "./img/midnightCommander.svg";
import { ReactComponent as PerlIcon } from "./img/perl.svg";

export class SystemSoftwareUI {
  public readonly systemSoftware: SystemSoftware;

  private constructor(systemSoftware: SystemSoftware) {
    this.systemSoftware = systemSoftware;
  }

  public static of(systemSoftware: SystemSoftware): SystemSoftwareUI {
    return new SystemSoftwareUI(systemSoftware);
  }

  public getTitle(): I18nDefinition {
    return {
      id: `systemSoftware.title.${this.systemSoftware.slug}`,
      fallbackElement: this.systemSoftware.name,
    };
  }

  public getTitleWithVersion(version: SystemSoftwareVersion): I18nDefinition {
    return {
      id: `systemSoftware.titleWithVersion.${this.systemSoftware.slug}`,
      fallbackElement: this.systemSoftware.name,
      values: { version: version.data.externalVersion },
    };
  }

  public getIcon(small?: boolean): IconLookup {
    switch (this.systemSoftware.slug) {
      case SystemSoftwareNames.php:
        return faPhp;
      case SystemSoftwareNames.zip:
        return faFileZipper;
      case SystemSoftwareNames.node:
        return faNodeJs;
      case SystemSoftwareNames.git:
        return faGit;
      case SystemSoftwareNames.mysql:
        return small ? <MySqlIconSmall /> : <MySqlIcon />;
      case SystemSoftwareNames.rediscli:
        return <RedisIcon />;
      case SystemSoftwareNames.composer:
        return <ComposerIcon />;
      case SystemSoftwareNames.curl:
        return small ? <CurlIconSmall /> : <CurlIcon />;
      case SystemSoftwareNames.ghostscript:
        return <GhostScriptIcon />;
      case SystemSoftwareNames.imagemagick:
        return <ImageMagickIcon />;
      case SystemSoftwareNames.nano:
        return <NanoIcon />;
      case SystemSoftwareNames.webp:
        return <WebPIcon />;
      case SystemSoftwareNames.wpcli:
        return <WpCliIcon />;
      case SystemSoftwareNames.graphicsmagick:
        return small ? <GraphicsMagickIconSmall /> : <GraphicsMagickIcon />;
      case SystemSoftwareNames.python:
        return <PythonIcon />;
      case SystemSoftwareNames.midnightCommander:
        return <MidnightCommanderIcon />;
      case SystemSoftwareNames.perl:
        return <PerlIcon />;
      case SystemSoftwareNames.pdftools:
        return faFilePdf;
      default:
        return iconApp;
    }
  }
}

export default SystemSoftwareUI;
