import { Box } from "@mittwald/flow-components/dist/components/Box";
import { DateTime as DatetimeComponent } from "@mittwald/flow-components/dist/components/DateTimeText";
import { Icon } from "@mittwald/flow-components/dist/components/Icon";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { iconInfoCircle } from "@mittwald/flow-icons/dist/infoCircle";
import { DateTime } from "luxon";
import React, { FC } from "react";
import { getAvatarImageWidth } from "@mittwald/flow-components/dist/components/Avatar/lib";
import SystemSoftwareVersion from "../../../../../../model/app/SystemSoftwareVersion";

interface Props {
  systemSoftwareVersion: SystemSoftwareVersion;
}

export const PhpInfo: FC<Props> = (props) => {
  const { systemSoftwareVersion } = props;

  const iconWidth = getAvatarImageWidth("m");

  const expiryDate = systemSoftwareVersion.data.expiryDate;

  const cancellationPeriod = DateTime.now().plus({ day: 7 });

  return (
    <Box _flexDirection="row" _itemGap="m">
      <Box _flexDirection="row" _justifyContent="center" _width={iconWidth}>
        <Icon _color="brand-primary-dark" _size="m" icon={iconInfoCircle} />
      </Box>

      <Box _flexDirection="column">
        <Text i18n="phpInfo" strong />
        <Text
          i18n={{
            id: "phpCancellationPeriod",
            values: {
              date: (
                <DatetimeComponent format="date" value={cancellationPeriod} />
              ),
            },
          }}
        />
        {expiryDate && (
          <Text
            i18n={{
              id: "phpExpiry",
              values: {
                date: <DatetimeComponent format="date" value={expiryDate} />,
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default PhpInfo;
