import axios, { AxiosRequestConfig } from "axios";
import { AsyncResource, getAsyncResource } from "@mittwald/react-use-promise";

type GetAsyncResourceOptions = Parameters<typeof getAsyncResource>[2];

const getHttpResponse = async <T>(
  url: string,
  conf?: AxiosRequestConfig<T>,
): Promise<T | undefined> => {
  try {
    const response = await axios.get(url, conf);
    return response.data;
  } catch (error) {
    // Skip errors
    return;
  }
};

export const getHttpResponseResource = <T>(
  url?: string,
  conf?: AxiosRequestConfig<T>,
  resourceOptions?: GetAsyncResourceOptions,
): AsyncResource<T | undefined> =>
  getAsyncResource(getHttpResponse, url ? [url, conf] : null, {
    tags: [`GET:${url}`],
    ...resourceOptions,
  });

export const useHttpResponse = <T>(
  url?: string,
  conf?: AxiosRequestConfig<T>,
  resourceOptions?: GetAsyncResourceOptions,
): T | undefined => getHttpResponseResource(url, conf, resourceOptions).watch();
