import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import { iconUndo } from "@mittwald/flow-icons/dist/undo";
import { useFeatureEnabled } from "@mittwald/flow-lib/dist/hooks/useFeatureEnabled";
import React from "react";
import SystemSoftwareTitle from "../../../pages/app/app/components/SystemSoftwareTitle";
import ConnectDatabase from "../../../pages/app/app/modals/ConnectDatabase/ConnectDatabase";
import CopyAppInstallation from "../../../pages/app/app/modals/CopyAppInstallation/CopyAppInstallation";
import DeleteAppInstallation from "../../../pages/app/app/modals/DeleteAppInstallation/DeleteAppInstallation";
import InstallApp from "../../../pages/app/app/modals/InstallApp/InstallApp";
import InstallSystemSoftware from "../../../pages/app/app/modals/InstallSystemSoftware/InstallSystemSoftware";
import UpdateAppInstallationVersion from "../../../pages/app/app/modals/UpdateAppInstallationVersion/UpdateAppInstallationVersion";
import UpdateInstalledSystemSoftwareVersion from "../../../pages/app/app/modals/UpdateInstalledSystemSoftwareVersion/UpdateInstalledSystemSoftwareVersion";
import UpdatePrimaryDatabase from "../../../pages/app/app/modals/UpdatePrimaryDatabase/UpdatePrimaryDatabase";
import AppInstallation from "../../app/AppInstallation";
import { AppInstallationList } from "../../app/AppInstallationList";
import InstalledSystemSoftware from "../../app/InstalledSystemSoftware";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultAppRoles = [Role.anybody];

ModelActionBuilderRegistry.register({
  model: AppInstallation,
  roles: defaultAppRoles,
  method: "delete",
  altAction: (action) => () =>
    showModal(
      "deleteAppInstallation",
      <DeleteAppInstallation appInstallation={action.model} />,
    ),
})
  .register({
    model: AppInstallation,
    roles: defaultAppRoles,
    method: "disconnectDatabase",
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "disconnectDatabase",
        required: true,
      },
    }),
  })
  .register({
    model: AppInstallationList,
    roles: [Role.projectAdmin, Role.projectDeveloper],
    method: "useLoadAllByProjectId",
  })
  .register({
    model: AppInstallation,
    roles: defaultAppRoles,
    method: "updateUserInputs",
    isAvailable: (installation) =>
      installation.useUserInputsForReconfigure().items.length > 0,
  })
  .register({
    model: AppInstallation,
    roles: defaultAppRoles,
    method: "connectDatabase",
    isAvailable: (appInstallation) =>
      appInstallation.useAvailableDatabases().length > 0,
    altAction: (action) => () =>
      showModal(
        "connectDatabase",
        <ConnectDatabase appInstallation={action.model} />,
      ),
  })
  .register({
    model: AppInstallation,
    roles: defaultAppRoles,
    method: "updatePrimaryDatabase",
    isAvailable: (appInstallation) =>
      appInstallation.useAvailableDatabases().length > 0,
    altAction: (action) => () =>
      showModal(
        "updatePrimaryDatabase",
        <UpdatePrimaryDatabase appInstallation={action.model} />,
      ),
  })
  .register({
    model: AppInstallation,
    roles: defaultAppRoles,
    method: "updateVersion",
    isAvailable: (appInstallation) => appInstallation.useUpdateAvailable(),
    altAction: (action) => () =>
      showModal(
        "updateAppInstallationVersion",
        <UpdateAppInstallationVersion appInstallation={action.model} />,
      ),
  })
  .register({
    model: AppInstallation,
    roles: defaultAppRoles,
    method: "create",
    altAction: () => () => showModal("createAppInstallation", <InstallApp />),
  })
  .register({
    model: AppInstallation,
    roles: defaultAppRoles,
    isAvailable: (appInstallation) =>
      appInstallation.useInstallableSystemSoftwares().length > 0,
    method: "installSystemSoftware",
    altAction: (action) => () =>
      showModal(
        "installSystemSoftware",
        <InstallSystemSoftware appInstallation={action.model} />,
      ),
  })
  .register({
    model: InstalledSystemSoftware,
    roles: defaultAppRoles,
    isAvailable: (software) => !software.useIsRequired(),
    method: "delete",
    altAction: (action) => ({
      action,
      confirmation: {
        required: true,
        modalTranslationKey: "uninstallSystemSoftware",
        modalTranslationValues: {
          systemSoftware: (
            <SystemSoftwareTitle systemSoftware={action.model.useSoftware()} />
          ),
        },
        modalProps: {
          headlineIcon: iconDelete,
        },
      },
    }),
  })
  .register({
    model: InstalledSystemSoftware,
    roles: defaultAppRoles,
    isAvailable: (software) => software.useUpdateAvailable(),
    method: "updateVersion",
    altAction: (action) => () =>
      showModal(
        "updateInstalledSystemSoftwareVersion",
        <UpdateInstalledSystemSoftwareVersion
          installedSystemSoftware={action.model}
        />,
      ),
  })
  .register({
    model: AppInstallation,
    roles: defaultAppRoles,
    isAvailable: (appInstallation) => {
      const appActionsEnabled = useFeatureEnabled("showAppActions");
      return appInstallation.useApp().canRestart && appActionsEnabled;
    },
    method: "restart",
    altAction: (action) => ({
      action,
      confirmation: {
        required: true,
        modalTranslationKey: "restartApp",
        modalProps: {
          headlineIcon: iconUndo,
          primary: { ok: true, destructive: false },
        },
      },
    }),
  })
  .register({
    model: AppInstallation,
    roles: defaultAppRoles,
    method: "copy",
    altAction: (action) => () =>
      showModal(
        "copyAppInstallation",
        <CopyAppInstallation appInstallation={action.model} />,
      ),
  });
