import { MittwaldApi } from "../../api/Mittwald";
import { NotificationSeverity } from "../../model/notification/Notification";
import { sortUrlsBySpecificity } from "./misc/sorting";

export type NotificationReferenceType = Omit<
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Messaging_AggregateReference,
  "id"
>;

export interface NotificationReferenceTypeWithId
  extends NotificationReferenceType {
  id: string;
}

export interface AutoNotificationReaderConfigurationItem {
  path: string;
  // key where the id exists in the url
  referenceIdKey: string;
  references: NotificationReferenceType[];
}

export const autoNotificationReaderSeverityConfig: NotificationSeverity[] = [
  "info",
  "success",
];

export const autoNotificationReaderConfiguration: AutoNotificationReaderConfigurationItem[] =
  sortUrlsBySpecificity([
    {
      path: "/app/projects/:projectId/domain/domains/:ingressId",
      referenceIdKey: "ingressId",
      references: [
        {
          domain: "ingress",
          aggregate: "ingress",
        },
        {
          domain: "domain",
          aggregate: "domain",
        },
      ],
    },
    {
      path: "/app/projects/:projectId",
      referenceIdKey: "projectId",
      references: [
        {
          domain: "project",
          aggregate: "project",
        },
      ],
    },
    {
      path: "/app/projects/:projectId/backup/backup/:backupId",
      referenceIdKey: "backupId",
      references: [
        {
          domain: "backup",
          aggregate: "projectbackup",
        },
      ],
    },
    {
      path: "/app/projects/:projectId/apps/:appInstallationId",
      referenceIdKey: "appInstallationId",
      references: [
        {
          domain: "app",
          aggregate: "appinstallation",
        },
      ],
    },
    {
      path: "/app/support/conversations/:conversationId",
      referenceIdKey: "conversationId",
      references: [
        {
          domain: "conversation",
          aggregate: "conversation",
        },
      ],
    },
    {
      path: "/app/servers/:serverId",
      referenceIdKey: "serverId",
      references: [
        {
          domain: "project",
          aggregate: "placementgroup",
        },
      ],
    },
  ]);
