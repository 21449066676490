import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";
import AccessUI from "../../../../../model/ui/access/AccessUI";

export const AccessTypeSelectBox: FC = () => {
  const accessOptions = AccessUI.sftpAccessLevelSelectBoxOptions();
  return (
    <TranslationProvider name="accessSelect" type="component">
      <SelectBox name="accessLevel" options={accessOptions} />
    </TranslationProvider>
  );
};

export default AccessTypeSelectBox;
