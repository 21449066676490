import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";

export type SessionApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Signup_UserSession;

export class Session {
  public readonly id: string;
  public readonly data: SessionApiData;
  public readonly lastAccess?: string;
  public readonly isMobile: boolean;

  private constructor(data: SessionApiData) {
    this.id = data.tokenId;
    this.data = Object.freeze(data);
    this.lastAccess = data.lastAccess;
    this.isMobile = data.device.type === "mobile";
  }

  public static fromApiData(data: SessionApiData): Session {
    return new Session(data);
  }

  public static useLoadById(tokenId: string): Session {
    const data = mittwaldApi.userGetSession
      .getResource({
        path: { tokenId },
      })
      .useWatchData();

    return Session.fromApiData(data);
  }

  public static useLoadByPathParam(): Session {
    const { sessionId } = usePathParams("sessionId");

    return Session.useLoadById(sessionId);
  }

  public async closeSession(): Promise<void> {
    const response = await mittwaldApi.userTerminateSession.request({
      path: {
        tokenId: this.id,
      },
    });

    assertStatus(response, 204);
  }

  public async closeSessionAndChangePassword(): Promise<void> {
    await this.closeSession();
  }

  public useIsActive(): boolean {
    return Session.useCurrentTokenId() === this.id;
  }

  public static useCurrentTokenId(): string {
    return mittwaldApi.userCheckToken.getResource({}).useWatchData().id;
  }
}

export default Session;
