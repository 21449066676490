import { SelectBoxOptions } from "@mittwald/flow-components/dist/components/SelectBox";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import AppInstallationRelation from "../../app/AppInstallationRelation";
import { CustomerList } from "../../customer/CustomerList";
import CustomerModelRelation from "../../customer/CustomerModelRelation";
import ExtensionInstanceModelRelation from "../../marketplace/ExtensionInstanceModelRelation";
import ModelRelationType from "../../misc/modelRelation/ModelRelationType";
import { ProjectList } from "../../project";
import ProjectModelRelation from "../../project/ProjectModelRelation";
import ServerList from "../../server/ServerList";
import ServerModelRelation from "../../server/ServerModelRelation";

export class ConversationRelationUI {
  public static readonly relationTypes = [
    AppInstallationRelation.type,
    ProjectModelRelation.type,
    ServerModelRelation.type,
    CustomerModelRelation.type,
    ExtensionInstanceModelRelation.type,
    ModelRelationType.unknown,
  ];

  public static readonly visibleRelationTypes = [
    ProjectModelRelation.type,
    ServerModelRelation.type,
    CustomerModelRelation.type,
    ModelRelationType.unknown,
  ];

  public getRelationSelectOptions(): SelectBoxOptions {
    return ConversationRelationUI.visibleRelationTypes.map((relation) => ({
      value: relation.aggregate,
      title: joinTranslationKeys(
        "conversationRelationType",
        relation.aggregate,
      ),
      disabled:
        (relation.aggregate === "project" && ProjectList.useLoad().isEmpty) ||
        (relation.aggregate === "placementgroup" &&
          ServerList.useLoad().isEmpty) ||
        (relation.aggregate === "customer" && CustomerList.useAll().isEmpty),
    }));
  }

  public relationTypeByName(aggregate: string): ModelRelationType {
    return (
      ConversationRelationUI.relationTypes.find(
        (r) => r.aggregate === aggregate,
      ) ?? ModelRelationType.unknown
    );
  }
}
