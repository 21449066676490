import { useHttpResponse } from "../../hooks/useHttpResponse";

interface BlogArticle {
  title: string;
  timestamp: string;
  description: string;
  images?: Array<{
    url?: string;
    width?: number;
  }>;
  link: string;
  identifier: string;
}

export class Blog {
  public static useLatestArticle(): BlogArticle | undefined {
    const articles = useHttpResponse<BlogArticle[]>(
      "https://www.mittwald.de/api/blog/list?limit=1&imagesizes=800",
    );
    return articles?.[0];
  }
}

export default Blog;
