import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import invariant from "invariant";
import React, { FC } from "react";
import isNonEmptyArray from "../../../../../lib/isNonEmptyArray";
import {
  ArticleTemplateName,
  ArticleFactory,
  SpaceServerArticle,
} from "../../../../../model/article";
import Bytes from "../../../../../model/misc/Bytes";
import Server from "../../../../../model/server/Server";
import { AdjustServerFormFields } from "../../types";
import OverviewStep from "../OrderServerWizard/components/OverviewStep";
import ServerStep from "./components/ServerStep";

interface Props {
  serverId: string;
}

export const AdjustServerWizard: FC<Props> = (props) => {
  const { serverId } = props;
  const server = Server.useLoadById(serverId);
  const serverContract = server.useContract();
  const currentArticle = server.useArticle();
  const spaceServerArticles = ArticleFactory.useLoadAllByTemplate(
    ArticleTemplateName.spaceServerHosting,
  ).map((a) => a.asType(SpaceServerArticle));

  invariant(isNonEmptyArray(spaceServerArticles), "No articles found");

  const form = useForm<AdjustServerFormFields>({
    defaultValues: {
      articleId: currentArticle.id,
      storageSizeInBytes: server.storage.bytes.bytes,
    },
    onSubmit: async (fields) => {
      const { articleId, storageSizeInBytes } = fields;
      const article = await ArticleFactory.loadById(articleId);

      await server.adjust({
        machineType: article
          .asType(SpaceServerArticle)
          .machineType.mustGetName(),
        diskspaceInGiB: Bytes.of(storageSizeInBytes).in("GiB"),
      });
    },
  });

  const [selectedArticleId, storageSizeInBytes] = form.watch([
    "articleId",
    "storageSizeInBytes",
  ]);
  const selectedArticle = spaceServerArticles.find(
    (a) => a.id === selectedArticleId,
  );
  invariant(selectedArticle !== undefined, "Article not set");
  const selectedStorageSize = Bytes.of(storageSizeInBytes);

  const serverStep = (
    <ServerStep
      currentArticle={currentArticle}
      selectedArticle={selectedArticle}
      selectedStorageSize={selectedStorageSize}
      server={server}
      serverContract={serverContract}
    />
  );

  const overviewStep = (
    <OverviewStep
      contractItem={serverContract.baseItem}
      customerId={serverContract.data.customerId}
      selectedArticle={selectedArticle}
      storageSize={selectedStorageSize}
    />
  );

  return (
    <TranslationProvider name="ModifyServer" type="modal">
      <WizardModal _size="l" form={form} steps={["server", "overview"]}>
        {serverStep}
        {overviewStep}
      </WizardModal>
    </TranslationProvider>
  );
};

export default AdjustServerWizard;
