import { Select } from "@mittwald/flow-components/dist/components/Select";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";
import { CustomerList } from "../../../../model/customer/CustomerList";

interface CustomerSelectProps {
  name?: string;
  optional?: boolean;
}

export const CustomerSelect: FC<CustomerSelectProps> = (props) => {
  const { name = "customer", optional } = props;
  const options = CustomerList.useAll().getSelectOptions(optional);

  return (
    <TranslationProvider name="customerSelect" type="component">
      <Select
        isSearchable
        label="customer"
        name={name}
        options={options}
        placeholder="customer"
        rules={{ required: !optional }}
      />
    </TranslationProvider>
  );
};
