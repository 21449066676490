import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import AccessTypeSelectBox from "../../../sftp/components/AccessTypeSelectBox";
import DirectoryHeavyList from "../../../sftp/components/DirectoryHeavyList";

interface Props {
  noDirectories?: boolean;
  projectId: string;
}

export const PermissionStep: FC<Props> = (props) => {
  return (
    <WizardStep id="permissions" indicatorText="setRightDetails">
      <Section.Layout>
        <Section.Item headline="detailsOptions">
          <Text i18n="detailsOptionsDescription" />
        </Section.Item>
        <Section.Item headline="accessRights">
          <AccessTypeSelectBox />
        </Section.Item>
        <DirectoryHeavyList
          noDirectories={props.noDirectories}
          projectId={props.projectId}
        />
      </Section.Layout>
    </WizardStep>
  );
};

export default PermissionStep;
