import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { useMemo } from "react";
import { mittwaldApi } from "../../api/Mittwald";
import Ingress from "../domain/Ingress";
import ListModel from "../misc/ListModel";
import { DnsZoneUI, Ttl } from "../ui/domain/DnsZoneUI";
import { DnsZone, DnsZoneApiData } from "./DnsZone";
import { TxtRecordSet } from "./TxtRecordSet";

export interface TxtRecordItem {
  name: string;
  hostname: string;
  txt: string;
  ttl: number;
}
export interface TxtRecordDataForList extends Omit<TxtRecordItem, "ttl"> {
  markForDeletion?: boolean;
  new?: boolean;
}

export class TxtRecordsList extends ListModel<TxtRecordSet> {
  public readonly ingress: Ingress;
  private readonly dnsZones: DnsZoneApiData[];
  public readonly ttl: Ttl;
  public readonly recordCount: number;

  public constructor(
    ingress: Ingress,
    dnsZones: DnsZoneApiData[],
    items: TxtRecordSet[],
    ttl: Ttl = "auto",
  ) {
    super(items);

    this.ingress = ingress;
    this.dnsZones = dnsZones;
    this.ttl = ttl;
    this.recordCount = this.items.reduce<number>(
      (previousValue, currentValue) =>
        previousValue + currentValue.records.length,
      0,
    );
  }

  public static fromTxtRecordApiData = (
    data: DnsZoneApiData,
    ingress: Ingress,
  ): TxtRecordsList => {
    return new TxtRecordsList(
      ingress,
      [data],
      [TxtRecordSet.fromDnsZoneApiData(data)],
    );
  };

  private static readonly useGetAllZonesForIngress = (
    ingress: Ingress,
  ): DnsZoneApiData[] => {
    const listAllDnsZonesRequest = mittwaldApi.dnsListDnsZones.getResource({
      path: { projectId: ingress.data.projectId },
    });
    return listAllDnsZonesRequest.useWatchData();
  };

  public static useLoadAllByIngress = (ingress: Ingress): TxtRecordsList => {
    const zonesForIngress = TxtRecordsList.useGetAllZonesForIngress(ingress);
    const txtRecordZonesForIngress =
      TxtRecordsList.filterTxtRecordZonesForIngress(zonesForIngress, ingress);

    const items = useMemo(
      () =>
        txtRecordZonesForIngress.map((r) => TxtRecordSet.fromDnsZoneApiData(r)),
      [txtRecordZonesForIngress],
    );

    const itemWithTtl = items.find((r) => r.ttl);

    return new TxtRecordsList(
      ingress,
      zonesForIngress,
      items,
      itemWithTtl?.ttl,
    );
  };

  private static filterTxtRecordZonesForIngress(
    dnsZones: DnsZoneApiData[],
    ingress: Ingress,
  ): DnsZoneApiData[] {
    return dnsZones.filter((d) => {
      return (
        d.domain === ingress.hostname ||
        ("entries" in d.recordSet.txt &&
          d.recordSet.txt.entries.length >= 1 &&
          d.domain.includes(`.${ingress.hostname}`) &&
          d.domain.includes("_"))
      );
    });
  }

  public async createTxtDnsZone(name: string): Promise<string> {
    const zone = await DnsZone.getByIngress(this.ingress);

    const response = await mittwaldApi.dnsCreateDnsZone.request({
      requestBody: {
        name: `${name}`,
        parentZoneId: zone.id,
      },
    });
    assertStatus(response, 201);

    return response.content.id;
  }

  public async saveDataItems(
    items: TxtRecordDataForList[],
    ttl: Ttl,
  ): Promise<void> {
    const groupByServiceAndProtocol: Record<string, TxtRecordDataForList[]> =
      items.reduce((r, a) => {
        const groupKey = `${
          !a.name
            ? this.ingress.hostname
            : a.name === this.ingress.hostname
              ? a.name
              : `${a.name}.${this.ingress.hostname}`
        }`;

        if (!r[groupKey]) {
          r[groupKey] = [];
        }

        if (a.markForDeletion) {
          r[groupKey].push({});
        } else {
          r[groupKey].push(a);
        }

        return r;
      }, Object.create(null));

    for (const [fqdnWithHostname, txtRecordList] of Object.entries(
      groupByServiceAndProtocol,
    )) {
      const existingTxtZone = this.dnsZones.find((z) => {
        return z.domain === fqdnWithHostname;
      });
      let zoneId = existingTxtZone?.id;

      if (!zoneId) {
        const cleanSubDomain = fqdnWithHostname.replace(
          `.${this.ingress.hostname}`,
          "",
        );
        zoneId = await this.createTxtDnsZone(cleanSubDomain);
      }

      const txtRecord = TxtRecordSet.fromList(
        zoneId,
        fqdnWithHostname,
        ttl,
        txtRecordList.filter((r) => Object.keys(r).length >= 1),
      );

      await txtRecord.saveRecords();
    }
  }

  public getItems(): TxtRecordItem[] {
    const items = this.items;
    const dataItems: TxtRecordItem[] = [];

    items.forEach((i) => {
      i.records.forEach((r) => {
        dataItems.push({
          name: r.host.replace(
            // eslint-disable-next-line no-useless-escape
            new RegExp(`(\.)?${this.ingress.hostname}$`),
            "",
          ),
          hostname: r.host,
          txt: r.value,
          ttl: DnsZoneUI.ttlToSeconds(this.ttl),
        });
      });
    });

    return dataItems;
  }

  public useDataItems(): TxtRecordDataForList[] {
    const items = this.useItems();
    const dataItems: TxtRecordDataForList[] = [];

    items.forEach((i) => {
      i.records.forEach((r) => {
        dataItems.push({
          name: r.host.replace(
            // eslint-disable-next-line no-useless-escape
            new RegExp(`(\.)?${this.ingress.hostname}$`),
            "",
          ),
          hostname: r.host,
          txt: r.value,
        });
      });
    });

    return useMemo(() => dataItems, [items]);
  }
}
