import Box from "@mittwald/flow-components/dist/components/Box/Box";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import TextWithIconLayout from "@mittwald/flow-components/dist/components/TextWithIconLayout/TextWithIconLayout";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import { iconPlus } from "@mittwald/flow-icons/dist/plus";
import React, { FC, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { ContainerImageConfigExposedPort } from "../../../../../model/container/Container";
import { AiGradientText } from "../AiGradientText/AiGradientText";
import HeavyListPlaceholder from "../HeavyListPlaceholder";
import { PortHeavyListEntry } from "./PortHeavyListEntry";

interface Props {
  suggestedPorts?: ContainerImageConfigExposedPort[];
}

export const PortHeavyList: FC<Props> = (props) => {
  const form = useFormContext();
  const { suggestedPorts = [] } = props;
  const { fields, append, remove } = useFieldArray({
    name: "ports",
    control: form.control,
  });
  const [aiGeneratedStates, setAiGeneratedStates] = useState<boolean[]>([]);
  const hasAiGeneratedData = aiGeneratedStates.some((s) => s);

  const appendItem = (): void => {
    append({ port: "" });
  };

  return (
    <TranslationProvider name="portHeavyList" type="component">
      {fields.length > 0 && (
        <Box
          _flexDirection="row"
          _justifyContent={hasAiGeneratedData ? "space-between" : "flex-end"}
        >
          {hasAiGeneratedData && <AiGradientText i18n="aiAssisted" withIcon />}
          <TextWithIconLayout icon={iconPlus}>
            <TextLink action={appendItem} i18n="addPort" />
          </TextWithIconLayout>
        </Box>
      )}
      {fields.length === 0 && (
        <HeavyListPlaceholder
          action={appendItem}
          actionTitle="addPort"
          description="noPortsDescription"
          headline="noPorts"
        />
      )}
      {fields.map((s, index) => (
        <PortHeavyListEntry
          index={index}
          key={s.id}
          remove={remove}
          setAiGeneratedStates={setAiGeneratedStates}
          suggestedPorts={suggestedPorts}
        />
      ))}
    </TranslationProvider>
  );
};
