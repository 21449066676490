import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

export type StateReturnContext<S extends Record<string, any>> = [
  S,
  setState: Dispatch<SetStateAction<S>>,
];

export interface ContextState {
  createState: <S extends Record<string, any>>(
    initialState: S,
    name: string,
  ) => StateReturnContext<S>;
}

const StateContext = React.createContext<ContextState>({
  createState: (initialState) => [initialState, () => {}],
});

export const CreateContextStateProvider: FC<{ children: ReactNode }> = (
  props,
) => {
  const { children } = props;
  const [createDomainContextState, setCreateDomainContextState] = useState<
    Record<string, Record<string, any>> | undefined
  >(undefined);

  const contextValue: ContextState = {
    createState: (initialState, name) => {
      const currentState =
        (createDomainContextState && createDomainContextState[name]) ??
        initialState;

      return [
        currentState as any,
        (stateOrStateFunction) => {
          if (typeof stateOrStateFunction === "function") {
            setCreateDomainContextState((old) => {
              return {
                ...old,
                [name]: stateOrStateFunction(
                  old ? (old[name] as any) : initialState,
                ),
              };
            });
          } else if (typeof stateOrStateFunction === "object") {
            setCreateDomainContextState((old) => ({
              ...old,
              [name]: stateOrStateFunction,
            }));
          }
        },
      ];
    },
  };

  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
};

export const useContextState: ContextState["createState"] = (
  stepName,
  initialState,
) => {
  const context = useContext(StateContext);
  return context.createState(stepName, initialState);
};

export default useContextState;
