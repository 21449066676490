import { faClipboardList } from "@fortawesome/pro-regular-svg-icons/faClipboardList";
import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { Avatar } from "@mittwald/flow-components/dist/components/Avatar";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { H2 } from "@mittwald/flow-components/dist/components/H2";
import { Icon } from "@mittwald/flow-components/dist/components/Icon";
import { NavigationItem } from "@mittwald/flow-components/dist/components/Navigation/components/NavigationItem";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { UniversalBoundary } from "@mittwald/flow-components/dist/components/UniversalBoundary";
import { useCompactMode } from "@mittwald/flow-components/dist/hooks/useCompactMode";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { iconAvatar } from "@mittwald/flow-icons/dist/avatar";
import { iconLogout } from "@mittwald/flow-icons/dist/logout";
import { iconSettings } from "@mittwald/flow-icons/dist/settings";
import { iconSupport } from "@mittwald/flow-icons/dist/support";
import { UserProfileNavigation } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/components/UserProfileNavigation";
import { useRendererContext } from "@mittwald/flow-renderer/dist/context/rendererContext";
import React, { FC } from "react";
import { mittwaldApi } from "../../api/Mittwald";
import { useImageUrlBuilder } from "../../hooks/useFileServiceUrlBuilder";
import { useImpersonated } from "../../hooks/useImpersonated";
import { useRequiredAccessToken } from "../../hooks/useRequiredAccessToken";
import { ModelActionBuilder } from "../../model/actions/ModelActionBuilder";
import User from "../../model/user/User";
import ChangelogModal from "../../pages/app/profile/components/ChangelogModal/ChangelogModal";
import sessionStore from "../../store/session";
import ActionBox from "../ActionBox";
import IncomingNotifications from "../Notifications/IncomingNotifications";
import { NotificationBell } from "./components/NotificationBell";
import { SearchButton } from "./components/SearchButton";
import { HelpdeskButton } from "./components/HelpdeskButton/HelpdeskButton";
import * as Styled from "./styled";

export const HeaderNavigation: FC = () => {
  const accessToken = useRequiredAccessToken();
  const impersonated = useImpersonated();
  const compactMode = useCompactMode();

  const { navigationMenuVisibility } = useRendererContext();

  const profileAction = [
    { link: { id: "profile-index" } },
    navigationMenuVisibility.hide,
  ];

  const changelogAction = [
    () => showModal("changelog", <ChangelogModal />),
    navigationMenuVisibility.hide,
  ];

  const supportAction = [
    { link: { id: "support" } },
    navigationMenuVisibility.hide,
  ];

  const logoutAction: AnyAction = [
    async () => {
      if (!accessToken) {
        return;
      }

      const result = await mittwaldApi.userLogout.request({});

      assertStatus(result, 204);

      if (impersonated) {
        sessionStore.revokeLastImpersonation();
        return;
      }

      sessionStore.logout();
      document.cookie = "x-access-token=";
    },
    navigationMenuVisibility.hide,
  ];

  const user = User.useMe();

  const getImageUrl = useImageUrlBuilder();

  const name = {
    id: "userProfileName",
    values: { ...user.data.person },
  };

  const avatar = user.data.avatarRef
    ? {
        url: getImageUrl(user.data.avatarRef),
      }
    : { initials: user.fullName };

  if (compactMode) {
    return (
      <Box _flexDirection="column" _itemGap="m">
        <Styled.NavigationItem
          action={changelogAction}
          icon={faClipboardList}
          inverse
          title="changelog"
        />
        <Styled.NavigationItem
          action={supportAction}
          icon={iconSupport}
          inverse
          title="support"
        />
        <Box _flexDirection="row" _itemGap="s">
          <ActionBox _flexDirection="row" _itemGap="s" action={profileAction}>
            <Avatar {...avatar} _noShrink _size="m" />
            <H2 _alignSelf="center" _color="inverse" ellipsis>
              {user.fullName}
            </H2>
          </ActionBox>
          <Icon
            _alignSelf="center"
            _color="inverse"
            _ml={{ raw: "auto" }}
            _noShrink
            _size="m"
            action={logoutAction}
            icon={iconLogout}
          />
        </Box>
      </Box>
    );
  }

  return (
    <TranslationProvider name="HeaderNavigation" type="component">
      <Box _alignItems="center" _flexDirection="row" _itemGap="l">
        <Box _position="relative">
          <UniversalBoundary>
            <Box _flexDirection="row" _itemGap="m">
              <SearchButton />
              <HelpdeskButton />
              <NotificationBell />
            </Box>
          </UniversalBoundary>
          <UniversalBoundary>
            <IncomingNotifications />
          </UniversalBoundary>
        </Box>
        <UserProfileNavigation
          avatar={avatar}
          avatarAction={ModelActionBuilder.build(user, "requestAvatarUpload")}
          avatarActionIcon={iconAvatar}
          email={user.email}
          name={name}
        >
          <NavigationItem
            action={profileAction}
            icon={iconSettings}
            title="profile"
          />
          <NavigationItem
            action={changelogAction}
            icon={faClipboardList}
            title="changelog"
          />
          <NavigationItem
            action={logoutAction}
            icon={iconLogout}
            title={impersonated ? "revokeImpersonation" : "logout"}
          />
        </UserProfileNavigation>
      </Box>
    </TranslationProvider>
  );
};

export default HeaderNavigation;
