import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import React, { FC } from "react";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import DomainUI from "../../../../../../model/ui/domain/DomainUI";

interface Props {
  projectId: string;
}

export const TypeStep: FC<Props> = (props) => {
  const options = DomainUI.useTypeSelectBoxOptions(props.projectId);
  return (
    <WizardIntroStep
      description="typeDescription"
      headline="type"
      headlineIcon={iconDomain}
      id="type"
      indicatorText="typeStep"
    >
      <Section.Item headline="typeOptions">
        <SelectBox minOptionWidth={300} name="domainType" options={options} />
      </Section.Item>
    </WizardIntroStep>
  );
};

export default TypeStep;
