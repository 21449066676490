import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import { LinkList } from "@mittwald/flow-components/dist/components/LinkList";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconPassword } from "@mittwald/flow-icons/dist/password";

import React, { FC } from "react";
import { SftpUser } from "../../../../../model/access/SftpUser";
import { SshUser } from "../../../../../model/access/SshUser";
import { UpdatePasswordInputs } from "../../../../../model/access/types";
import { ModelActionBuilder } from "../../../../../model/actions/ModelActionBuilder";

interface Props {
  sftpOrSshUser: SftpUser | SshUser;
}

export const UpdatePassword: FC<Props> = (props) => {
  const visibility = useVisibilityController();
  const isAddMode = !props.sftpOrSshUser.hasPassword;

  const form = useForm<UpdatePasswordInputs>({
    translationKey: "changePassword",
    onSubmit: async (values) => {
      await props.sftpOrSshUser.updatePassword(values);
    },
  });

  return (
    <DefaultModal
      _size="s"
      description={{
        id: "description",
        values: {
          type: props.sftpOrSshUser instanceof SftpUser ? "SFTP" : "SSH",
        },
      }}
      headline={isAddMode ? "addMode" : "changeMode"}
      headlineIcon={iconPassword}
      primary={{
        action: [{ form }, visibility.hide, form.reset],
        ok: true,
      }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Item>
          <TextField
            label="password"
            name="password"
            rules={{ required: true, minLength: 12, maxLength: 72 }}
            type="passwordWithGenerator"
          />
          {props.sftpOrSshUser.hasPassword &&
            !props.sftpOrSshUser.publicKeyList.isEmpty && (
              <LinkList>
                <LinkList.Item
                  action={[
                    ModelActionBuilder.build(
                      props.sftpOrSshUser,
                      "deletePassword",
                    ),
                    visibility.hide,
                  ]}
                  destructive
                  i18n="deletePassword"
                />
              </LinkList>
            )}

          {props.sftpOrSshUser.publicKeyList.isEmpty && (
            <Text i18n="noPublicKeys" />
          )}
        </Section.Item>
      </Form>
    </DefaultModal>
  );
};

export default UpdatePassword;
