import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import Domain from "../../../../../../model/domain/Domain";
import Section from "@mittwald/flow-components/dist/components/Section";
import Text from "@mittwald/flow-components/dist/components/Text";

interface Props {
  domain: Domain;
  owner: string | undefined;
}

export const OverviewStep: FC<Props> = (props) => {
  const { domain, owner } = props;

  return (
    <WizardStep
      destructivePrimaryAction
      id="overview"
      indicatorText="overviewStep"
    >
      <Section.Item headline="headline">
        <Text i18n={{ id: "description" }} />
        <ColumnLayout medium={[1, 1]}>
          <StaticInformation label="name" text={{ text: domain.data.domain }} />
          <StaticInformation label="terminationDate" text="now" />
          <StaticInformation
            label="article"
            text={{
              text: domain.useArticleName(),
            }}
          />
          {owner && (
            <StaticInformation
              label="domainOwner"
              text={{
                text: owner,
              }}
            />
          )}
        </ColumnLayout>
        <CheckBox
          name="confirmationDomainDeletion"
          rules={{ required: true }}
          selectableBoxProps={{ style: { alignSelf: "stretch" } }}
          title={{
            id: "confirm",
          }}
        />
      </Section.Item>
    </WizardStep>
  );
};

export default OverviewStep;
