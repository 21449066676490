import AutoCompleteField from "@mittwald/flow-components/dist/components/AutoCompleteField/AutoCompleteField";
import React, { FC, useEffect, useState } from "react";
import {
  AiMetaInfo,
  ContainerImageConfigExposedPort,
} from "../../../../../model/container/Container";
import {
  ContainerPort,
  ContainerPortFormData,
  portRegExp,
} from "../../../../../model/container/ContainerPort";
import { UseFieldArrayRemove } from "react-hook-form";
import { HeavyListItemProps } from "@mittwald/flow-components/dist/components/List/components/HeavyListItem/types";
import List from "@mittwald/flow-components/dist/components/List";
import ColumnLayout from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import { SuggestionsFactory } from "@mittwald/flow-components/dist/components/AutoCompleteField";
import { AiDescription } from "../AiDescription";

interface Props extends HeavyListItemProps {
  index: number;
  suggestedPorts?: ContainerImageConfigExposedPort[];
  remove: UseFieldArrayRemove;
  setAiGeneratedStates: React.Dispatch<React.SetStateAction<boolean[]>>;
}

export const PortHeavyListEntry: FC<Props> = (props) => {
  const { index, suggestedPorts = [], remove, setAiGeneratedStates } = props;
  const [metaInfo, setMetaInfo] = useState<AiMetaInfo | undefined>();
  const form = useFormContext();

  const portSuggestions: SuggestionsFactory<unknown> = (value) => {
    const cleanedValue = value.trim().toLowerCase();
    const suggestions = suggestedPorts
      .map((p) => ({
        value: p.port,
        label: { text: p.port },
      }))
      .filter(
        (s) =>
          !form
            .getValues("ports")
            .find((f: ContainerPortFormData) => f.port === s.value),
      );

    if (cleanedValue === "") {
      return suggestions;
    }

    return suggestions.filter((s) =>
      s.value.toLowerCase().includes(cleanedValue),
    );
  };

  const updateAiInfo = (value: string): void => {
    const matchingPort = suggestedPorts.find((p) => p.port === value);
    setMetaInfo({
      description: matchingPort?.description,
      isAiGenerated: matchingPort?.isAiGenerated,
    });

    setAiGeneratedStates((prev) => {
      prev[index] = !!matchingPort?.isAiGenerated;
      return prev;
    });
  };

  useEffect(() => {
    updateAiInfo(form.getValues(`ports.${index}.port`));
  }, []);

  const removeItem = (index: number): void => {
    remove(index);
    updateAiInfo(form.getValues(`ports.${index}.port`));
  };

  return (
    <List.HeavyItem closeAction={() => removeItem(index)}>
      <ColumnLayout medium={[1, 1]}>
        <AutoCompleteField
          gradientBorder={metaInfo?.isAiGenerated}
          label="port"
          name={`ports.${index}.port`}
          rules={{
            required: true,
            pattern: portRegExp,
            validate: { validPort: ContainerPort.validatePortNumbers },
          }}
          suggestOnEmptyValue
          suggestions={portSuggestions}
          onChange={updateAiInfo}
        />
      </ColumnLayout>
      {metaInfo?.description && (
        <AiDescription description={metaInfo.description} />
      )}
    </List.HeavyItem>
  );
};
