import { iconApp } from "@mittwald/flow-icons/dist/app";
import ModelRelationType from "../misc/modelRelation/ModelRelationType";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import AppInstallation from "./AppInstallation";

export class AppInstallationRelation extends ModelRelation {
  public static type = new ModelRelationType("app", "appinstallation", iconApp);

  public constructor(appInstallationId: string) {
    super(appInstallationId, AppInstallationRelation.type);
  }

  public useAppInstallation(): AppInstallation {
    return AppInstallation.useLoadById(this.id);
  }

  public useOptionalAppInstallation(): AppInstallation | undefined {
    return AppInstallation.useTryLoadById(this.id);
  }
}

export default AppInstallationRelation;
