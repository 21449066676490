import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import React, { FC } from "react";
import BackupSchedule, {
  BackupScheduleInputs,
} from "../../../../../../model/backup/BackupSchedule";
import DescriptionStep from "./components/DescriptionStep";
import IntervalStep from "./components/IntervalStep";

export const CreateBackupSchedule: FC = () => {
  const { projectId } = usePathParams("projectId");

  const form = useForm<BackupScheduleInputs>({
    translationKey: "createBackupSchedule",
    defaultValues: {
      description: "",
      schedule: "0 2 * * *",
      ttl: "7d",
      interval: "1d",
      time: "2",
    },
    onSubmit: async (values) => {
      await BackupSchedule.createNew(values, projectId);
    },
  });

  return (
    <WizardModal form={form} steps={["description", "interval"]}>
      <DescriptionStep />
      <IntervalStep />
    </WizardModal>
  );
};

export default CreateBackupSchedule;
