import { IconLookup } from "@mittwald/flow-components/dist/components/Icon";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Image } from "@mittwald/flow-components/dist/components/Image";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { useCompactMode } from "@mittwald/flow-components/dist/hooks/useCompactMode";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { ChildrenProps } from "@mittwald/flow-lib/dist/types/ChildrenProps";
import React, { FC } from "react";

export interface OnboardingSectionProps extends ChildrenProps {
  headline?: I18nDefinition;
  headlineIcon?: IconLookup;
  image?: string;
}

export const OnboardingSection: FC<OnboardingSectionProps> = (props) => {
  const { children, headline, headlineIcon, image } = props;
  const isCompact = useCompactMode();

  const hasImage = image && !isCompact;

  return (
    <Section.Item headline={headline} headlineIcon={headlineIcon}>
      <ColumnLayout medium={hasImage ? [2, 1] : undefined}>
        <Box _itemGap="m">{children}</Box>
        {hasImage && (
          <Image _height="auto" _width="100%" loading="lazy" url={image} />
        )}
      </ColumnLayout>
    </Section.Item>
  );
};
