import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { SftpUser } from "./SftpUser";

export class SftpUserList extends ListModel<SftpUser> {
  public static useLoadByProjectId(projectId: string): SftpUserList {
    return new SftpUserList(
      mittwaldApi.sftpUserListSftpUsers
        .getResource({ path: { projectId }, query: {} })
        .useWatchData()
        .map((sftpUserData) => SftpUser.fromApiData(sftpUserData)),
    );
  }

  public static useLoadByPathParam(): SftpUserList {
    const { projectId } = usePathParams("projectId");
    return SftpUserList.useLoadByProjectId(projectId);
  }
}
