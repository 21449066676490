import { Box } from "@mittwald/flow-components/dist/components/Box";
import {
  ResourceList,
  SecondaryAction,
  useResourceList,
} from "@mittwald/flow-components/dist/components/ResourceList";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { VisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import React, { FC } from "react";
import { iconBell } from "@mittwald/flow-icons/dist/bell";
import { iconCheck } from "@mittwald/flow-icons/dist/check";
import { showDetails } from "../../../../../lib/showDetails";
import Notification from "../../../../../model/notification/Notification";
import { NotificationList } from "../../../../../model/notification/NotificationList";
import NotificationUI from "../../../../../model/ui/notification/NotificationUI";
import { DateColumn } from "./components/DateColumn";
import { NameColumn } from "./components/NameColumn";
import { RelationColumn } from "./components/RelationColumn";

export interface NotificationResourceListProps {
  notificationList: NotificationList;
  visibility: VisibilityController;
}

export const NotificationResourceList: FC<NotificationResourceListProps> = (
  props,
) => {
  const { notificationList, visibility } = props;

  const notificationResourceList = useResourceList({
    resources: notificationList.useItems(),
    nameColumn: NameColumn,
    tooltip: "notification",
    mainActionMapper: (notification) => [
      NotificationUI.of(notification).buildAction(),
      visibility.hide,
    ],
    icon: iconBell,
    bodyColumns: [
      { id: "dateColumn", renderer: DateColumn },
      { id: "relationColumn", renderer: RelationColumn },
    ],
    secondaryActions: (notification) => {
      const notificationUi = NotificationUI.of(notification);
      const actions: Array<SecondaryAction<any>> = [
        {
          id: "markAsRead",
          icon: iconCheck,
          action: () => notification.markAsRead(),
          title: "markAsRead",
        },
      ];

      if (notificationUi.isActionAvailable()) {
        actions.unshift(
          ...[showDetails([notificationUi.buildAction(), visibility.hide])],
        );
      }

      return actions;
    },
    primaryStatusIcon: (resource) => ({
      tooltip: resource.data.severity,
      type: resource.data.severity,
    }),
    defaultSortingRule: { id: "date", desc: true },
    columnAccessors: [
      {
        id: "date",
        accessor: (resource: Notification) => resource.createdAt,
      },
    ],
  });

  return (
    <TranslationProvider name="notificationRessourceList" type="component">
      <Box _noOverflow>
        <ResourceList
          hideTilesView
          {...notificationResourceList}
          columnLayout={{
            body: [
              { key: "dateColumn", maxWidth: 120 },
              { key: "relationColumn", maxWidth: 200 },
            ],
          }}
        />
      </Box>
    </TranslationProvider>
  );
};
