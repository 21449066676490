import Banner from "@mittwald/flow-components/dist/components/Banner";
import { DateTime } from "@mittwald/flow-components/dist/components/DateTimeText";
import React, { FC } from "react";
import SystemSoftwareVersion, {
  FeePeriod,
} from "../../../../../../model/app/SystemSoftwareVersion";

interface Props {
  selectedSoftwareVersion: SystemSoftwareVersion;
  currentFee: FeePeriod;
}

export const CurrentFeeBanner: FC<Props> = (props) => {
  const { selectedSoftwareVersion, currentFee } = props;

  return (
    <Banner
      description={{
        id: "currentFeeDescription",
        values: {
          version: selectedSoftwareVersion.data.externalVersion,
          price: currentFee.monthlyPrice,
          expiryDate: (
            <DateTime
              format="date"
              value={selectedSoftwareVersion.data.expiryDate}
            />
          ),
        },
      }}
      headline="currentFee"
      textLink={{
        title: "moreInformation",
        action: import.meta.env.VITE_PHP_EXTENDED_LINK,
      }}
    />
  );
};
