import { Box } from "@mittwald/flow-components/dist/components/Box";
import { H3 } from "@mittwald/flow-components/dist/components/H3";
import PriceBox from "@mittwald/flow-components/dist/components/PriceBox";
import { PriceTable } from "@mittwald/flow-components/dist/components/PriceTable";
import React, { FC } from "react";
import Extension from "../../../../../model/marketplace/Extension";

interface Props {
  extension: Extension;
}

export const PriceTableContent: FC<Props> = (props) => {
  const { extension } = props;

  return (
    <Box _flexDirection="column" _itemGap="s">
      <Box _px="m">
        <H3 i18n="overview" />
      </Box>
      <Box _flexDirection="column" _itemGap="xl">
        <Box _px="m">
          <PriceTable twoColumns>
            <PriceTable.Row title="extension" />
            <PriceTable.Row
              monthlyPrice={0}
              strong={false}
              title={{ text: extension.data.name }}
            />
          </PriceTable>
        </Box>
        <PriceBox additionalTitle="vatInfo" monthlyPrice={0} title="total" />
      </Box>
    </Box>
  );
};

export default PriceTableContent;
