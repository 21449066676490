import Box, { BoxProps } from "@mittwald/flow-components/dist/components/Box";
import { useAction } from "@mittwald/flow-components/dist/lib/actions/actionFactory";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import React, { FC } from "react";
import styled from "styled-components";

const LinkStyleBox = styled(Box)`
  cursor: pointer;
`;

interface Props extends BoxProps {
  action: AnyAction;
}

export const ActionBox: FC<Props> = ({ action: anyAction, ...boxProps }) => {
  const action = useAction(anyAction);
  return (
    <>
      <LinkStyleBox {...boxProps} onClick={action.trigger} />
    </>
  );
};

export default ActionBox;
