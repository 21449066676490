import { types } from "mobx-state-tree";
import { changelogStoreModel } from "./changelog";
import { dashboardSettingsStoreModel } from "./dashboardSettings";
import { hiddenBannersStoreModel } from "./hiddenBanners";
import { onboardingStoreModel } from "./onboarding";
import { recentVisitsStoreModel } from "./recentVisits";
import { statsDisplayModel } from "./statsDisplay";

export const userSettingsStoreModel = types.model({
  onboarding: onboardingStoreModel,
  recentVisits: recentVisitsStoreModel,
  changelogs: types.optional(changelogStoreModel, {}),
  dashboardSettings: dashboardSettingsStoreModel,
  hiddenBanners: hiddenBannersStoreModel,
  statsLogarithmicDisplay: statsDisplayModel,
});

export const userSettingsStore = userSettingsStoreModel.create({
  onboarding: {},
  recentVisits: {},
  changelogs: {},
  dashboardSettings: {},
  hiddenBanners: {},
  statsLogarithmicDisplay: { logarithmicDisplay: true },
});

export default userSettingsStore;
