import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import { Select } from "@mittwald/flow-components/dist/components/Select";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC, useEffect } from "react";
import { AppInstallationList } from "../../../../../../model/app/AppInstallationList";
import ModelRelationType from "../../../../../../model/misc/modelRelation/ModelRelationType";
import { getDefaultRelatedToChild } from "../../../misc/getDefaultRelatedToChild";
import { CreateConversationFormType } from "../CreateConversation";

interface AppInstallationSelectProps {
  relationType: ModelRelationType;
}

const appCategoryId = "b1c735fd-185b-4e50-bb68-36daf6778c85";

export const AppInstallationSelect: FC<AppInstallationSelectProps> = (
  props,
) => {
  const { relationType } = props;
  const form = useFormContext<CreateConversationFormType>();
  const projectId = form.getValues("relatedTo.id");
  const appInstallations =
    AppInstallationList.useTryLoadAllByProjectId(projectId);
  const options = appInstallations?.getSelectOptions(true);
  const selectedCategoryId = form.watch("categoryId");
  const isAppsCategorySelected = selectedCategoryId === appCategoryId;

  const defaultRelatedToChild = getDefaultRelatedToChild();

  useEffect(() => {
    if (relationType.aggregate === "project") {
      if (isAppsCategorySelected) {
        form.setValue("relatedToChild", {
          id: form.getValues("relatedToChild.id"),
          domain: "app",
          aggregate: "appinstallation",
        });
      }
    } else {
      form.setValue("relatedToChild", {
        id: "",
        domain: defaultRelatedToChild.domain,
        aggregate: defaultRelatedToChild.aggregate,
      });
    }
  }, [relationType, selectedCategoryId]);

  useEffect(() => {
    const appInstallationFound = appInstallations?.items.find(
      (appInstallation) =>
        appInstallation.id === form.getValues("relatedToChild.id"),
    );

    if (!appInstallationFound) {
      form.setValue("relatedToChild.id", "");
    }
  }, [projectId]);

  if (!isAppsCategorySelected || projectId === "" || options === undefined) {
    return null;
  }

  return (
    <TranslationProvider name="appInstallationSelect" type="component">
      <Select
        isSearchable
        label="apps"
        name="relatedToChild.id"
        options={options}
        placeholder="apps"
      />
    </TranslationProvider>
  );
};
