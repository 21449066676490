import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { DatePicker } from "@mittwald/flow-components/dist/components/DatePicker";
import { DateTimeText } from "@mittwald/flow-components/dist/components/DateTimeText";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import { DateTime } from "luxon";
import React, { FC } from "react";
import { TerminationType } from "../../../../../../model/contract/Contract";
import { TerminationUI } from "../../../../../../model/ui/contract/TerminationUI";

interface TerminationDateSectionProps {
  nextTerminationDate?: string;
  terminationType: TerminationType;
}

export const TerminationDateSection: FC<TerminationDateSectionProps> = (
  props,
) => {
  const { terminationType, nextTerminationDate } = props;

  return (
    <Section.Item>
      <SelectBox
        name="terminationType"
        options={TerminationUI.getTerminationTypeOptions()}
      />
      {terminationType === "nextDate" && (
        <StaticInformation
          label="terminationDate"
          text={<DateTimeText format="date" value={nextTerminationDate} />}
        />
      )}
      {terminationType === "customDate" && (
        <ColumnLayout medium={[1, 1]}>
          <DatePicker
            clearable={false}
            label="terminationDate"
            name="terminationTargetDate"
            rules={{ required: true }}
            whitelist={(date: DateTime) =>
              date >=
                (nextTerminationDate
                  ? DateTime.fromISO(nextTerminationDate)
                  : DateTime.local()) &&
              date.day ===
                (nextTerminationDate
                  ? DateTime.fromISO(nextTerminationDate).day
                  : DateTime.local().plus({ months: 1 }).day)
            }
          />
        </ColumnLayout>
      )}
    </Section.Item>
  );
};
