import { iconSupport } from "@mittwald/flow-icons/dist/support";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import BacksideRenderer from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import Conversation from "../../../model/support/Conversation";

export const supportPages = PageTree.build(BacksideRenderer, {
  "/app/support": {
    props: { tabNav: true, hideInMenu: true },
  },
  "/app/support/conversations": {
    views: {
      pageTitle: lazy(() => import("./components/SupportAreaTitle")),
      main: lazy(() => import("./overview")),
    },
    props: {
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(Conversation, "createNew"),
          },
          title: "createConversation",
          icon: iconSupport,
        },
      ],
    },
    linkId: "support",
  },
  "/app/support/emergency-hotline": {
    linkId: "emergencyHotlines",
    views: {
      pageTitle: lazy(() => import("./components/SupportAreaTitle")),
      main: lazy(() => import("./emergencyHotlines/emergencyHotlines")),
    },
  },
  "/app/support/conversations/:conversationId": {
    linkId: "conversationDetails",
    views: {
      pageTitle: lazy(() => import("./components/ConversationTitle")),
      main: lazy(() => import("./conversationDetails/details")),
    },
  },
});
