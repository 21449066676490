import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Select } from "@mittwald/flow-components/dist/components/Select";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import React, { FC } from "react";
import { iconDownload } from "@mittwald/flow-icons/dist/download";
import Backup, {
  DownloadRequestInputs,
} from "../../../../../../model/backup/Backup";
import BackupUI from "../../../../../../model/ui/backup/BackupUI";

interface Props {
  backup: Backup;
}

export const RequestBackupDownload: FC<Props> = (props) => {
  const { backup } = props;
  const visibility = useVisibilityController();

  const form = useForm<DownloadRequestInputs>({
    translationKey: "requestDownload",
    defaultValues: { format: "zip" },
    onSubmit: async (values) => {
      await backup.requestDownload(values);
    },
  });

  return (
    <DefaultModal
      description="description"
      headline="requestDownload"
      headlineIcon={iconDownload}
      primary={{
        action: [
          { form },
          visibility.hide,
          { link: { id: "backupOverview", replace: true } },
        ],
        ok: true,
      }}
      visibility={visibility}
    >
      <Form controller={form}>
        <ColumnLayout medium={[1, 1]}>
          <Select
            label="format"
            name="format"
            options={BackupUI.formatSelectOptions}
            rules={{ required: true }}
          />
          <TextField
            label="password"
            name="password"
            type="passwordWithGenerator"
          />
        </ColumnLayout>
      </Form>
    </DefaultModal>
  );
};

export default RequestBackupDownload;
