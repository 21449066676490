import { Text } from "@mittwald/flow-components/dist/components/Text";
import { iconCatchAllAddress } from "@mittwald/flow-icons/dist/catchAllAddress";
import React, { ComponentType } from "react";
import { ActionConfirmationModalProps } from "@mittwald/flow-components/dist/lib/actions/ConfirmAction";
import DefaultConfirmationModal from "@mittwald/flow-components/dist/lib/actions/DefaultConfirmationModal";

export const CatchAllExistsConfirmation: ComponentType<
  ActionConfirmationModalProps
> = (props) => {
  return (
    <DefaultConfirmationModal headlineIcon={iconCatchAllAddress} {...props}>
      <Text i18n="consequences" strong />
    </DefaultConfirmationModal>
  );
};

export default CatchAllExistsConfirmation;
