import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import slugify from "slugify";
import { IngressList } from "../../domain/IngressList";
import UserInputDataSource, {
  UserUserInputDataSourceName,
} from "../../misc/userInput/UserInputDataSource";
import { ParsedValue } from "../../misc/userInput/UserInputRecord";
import User from "../../user/User";
import { IngressListUI } from "../domain/IngressListUI";

export class UserInputDataSourceUI {
  private readonly dataSource: UserInputDataSource;

  private constructor(userInputDataSource: UserInputDataSource) {
    this.dataSource = userInputDataSource;
  }

  public static of(
    userInputDataSource: UserInputDataSource,
  ): UserInputDataSourceUI {
    return new UserInputDataSourceUI(userInputDataSource);
  }

  public static generateUserName(user: User): string {
    const firstName = user.data.person.firstName;
    const lastName = user.data.person.lastName;
    return slugify(`${firstName[0]}${lastName}`, {
      lower: true,
      locale: "de",
      strict: true,
    });
  }

  public useDefaultValue(): ParsedValue | undefined {
    if (this.dataSource.name === UserUserInputDataSourceName.firstName) {
      return User.useMe().data.person.firstName;
    }
    if (this.dataSource.name === UserUserInputDataSourceName.lastName) {
      return User.useMe().data.person.lastName;
    }
    if (this.dataSource.name === UserUserInputDataSourceName.email) {
      return User.useMe().data.email;
    }
    if (this.dataSource.name === UserUserInputDataSourceName.username) {
      return UserInputDataSourceUI.generateUserName(User.useMe());
    }
  }

  public useSelectOptions(): SelectOptions | undefined {
    const { projectId } = usePathParams("projectId");
    if (this.dataSource.name === UserUserInputDataSourceName.ingressPath) {
      return IngressListUI.of(
        IngressList.useLoadAllByProjectId(projectId),
      ).getPathSelectOptions();
    }
    return;
  }
}

export default UserInputDataSourceUI;
