import ListModel from "../misc/ListModel";
import { PublicKey } from "./PublicKey";
import { PublicKeyApiData } from "./types";

export class PublicKeyList extends ListModel<PublicKey> {
  public static fromApiData(publicKeys: PublicKeyApiData[]): PublicKeyList {
    return new PublicKeyList(
      publicKeys.map((publicKeyData) => PublicKey.fromApiData(publicKeyData)),
    );
  }
}
