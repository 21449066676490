import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import React, { FC } from "react";
import { AppInstallationList } from "../../../../../model/app/AppInstallationList";
import { Domain, NewDomainInputs } from "../../../../../model/domain/Domain";
import Ingress from "../../../../../model/domain/Ingress";
import { ProjectMembership } from "../../../../../model/project";
import {
  DomainTypes,
  DomainUI,
  TargetTypes,
} from "../../../../../model/ui/domain/DomainUI";
import AdminStep from "./components/AdminStep";
import BookDomainStep from "./components/BookDomainStep";
import MoveDomainStep from "./components/MoveDomainStep";
import OverviewStep from "./components/OverviewStep";
import OwnerStep from "./components/OwnerStep";
import SubdomainStep from "./components/SubdomainStep";
import TargetStep from "./components/TargetStep";
import TypeStep from "./components/TypeStep";
import VHostStep from "./components/VHostStep";

export interface PreviewDomainState {
  domain: string;
  authCode?: string;
}

export interface CreateDomainProps {
  presetDomainForMove?: string;
}

export const CreateDomain: FC<CreateDomainProps> = (props) => {
  const { presetDomainForMove } = props;
  const { projectId } = usePathParams("projectId");

  const appInstallations =
    AppInstallationList.useLoadAllByProjectId(projectId).useItems();

  const currentRole = ProjectMembership.useLoadOwn(projectId);

  const form = useForm<NewDomainInputs>({
    defaultValues: {
      domain: presetDomainForMove ?? "",
      authCode: "",
      hostname: "",
      domainType: currentRole.role.is("owner")
        ? presetDomainForMove
          ? DomainTypes.moveDomain
          : DomainTypes.bookDomain
        : DomainTypes.vHost,
      targetType: TargetTypes.default,
      directory: "/",
      installationId: appInstallations[0]?.id,
      url: "",
      containerName: "",
      containerPort: "",
      adminIsOwner: true,
      ownerC: {
        type: "ORG",
        xItEntityType: "naturalPerson",
      },
      adminC: {
        type: "ORG",
        xItEntityType: "naturalPerson",
      },
      selectedDomain: "",
      domainCheckerUsed: "false",
      generatedDomains: [],
    },
    onSubmit: async (values) => {
      if (
        values.domainType === DomainTypes.vHost ||
        values.domainType === DomainTypes.subdomain
      ) {
        await Ingress.createNew(values, projectId);
      } else {
        if (values.domainType === DomainTypes.moveDomain && values.authCode) {
          return await Domain.order(
            { ...values, authCode: values.authCode },
            projectId,
            values.domain,
          );
        }
        const domain =
          values.domainCheckerUsed === "true"
            ? values.selectedDomain
            : values.domain;
        await Domain.order(values, projectId, domain);
      }
    },
  });

  const [
    domainType,
    targetType,
    watchedHostname,
    adminIsOwner,
    domainOwner,
    enteredDomain,
    domainCheckerUsed,
    selectedDomain,
  ] = form.watch([
    "domainType",
    "targetType",
    "hostname",
    "adminIsOwner",
    "ownerC",
    "domain",
    "domainCheckerUsed",
    "selectedDomain",
  ]);

  const isBookDomain = domainType === DomainTypes.bookDomain;
  const isMoveDomain = domainType === DomainTypes.moveDomain;
  const isVHost = domainType === DomainTypes.vHost;
  const isSubdomain = domainType === DomainTypes.subdomain;
  const isPurchase = isBookDomain || isMoveDomain;

  const domain = domainCheckerUsed === "true" ? selectedDomain : enteredDomain;

  const hostname = DomainUI.normalizeDashes(
    isPurchase ? domain : watchedHostname,
  );
  const visibility = useVisibilityController();

  if (presetDomainForMove) {
    return (
      <WizardModal
        form={form}
        steps={["moveDomain", "owner", "admin", "overview"]}
        visibility={visibility}
      >
        <MoveDomainStep form={form} presetDomain={presetDomainForMove} />
        <OwnerStep form={form} hostname={hostname} />
        {!adminIsOwner && <AdminStep form={form} hostname={hostname} />}
        <OverviewStep domainOwner={domainOwner} hostname={hostname} />
      </WizardModal>
    );
  }

  return (
    <WizardModal
      form={form}
      steps={[
        "type",
        "bookDomain",
        "moveDomain",
        "vHost",
        "subdomain",
        "target",
        "owner",
        "admin",
        "overview",
      ]}
      visibility={visibility}
    >
      <TypeStep projectId={projectId} />
      {isBookDomain && (
        <BookDomainStep form={form} modalVisibility={visibility} />
      )}
      {isMoveDomain && <MoveDomainStep form={form} />}
      {isVHost && <VHostStep />}
      {isSubdomain && (
        <WizardStep id="subdomain" indicatorText="subdomainStep">
          <SubdomainStep />
        </WizardStep>
      )}
      {!isPurchase && <TargetStep targetType={targetType} />}
      {isPurchase && <OwnerStep form={form} hostname={hostname} />}
      {isPurchase && !adminIsOwner && (
        <AdminStep form={form} hostname={hostname} />
      )}
      {isPurchase && (
        <OverviewStep domainOwner={domainOwner} hostname={hostname} />
      )}
    </WizardModal>
  );
};

export default CreateDomain;
