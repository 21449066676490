import { faClipboardList } from "@fortawesome/pro-regular-svg-icons/faClipboardList";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { useCompactMode } from "@mittwald/flow-components/dist/hooks/useCompactMode";
import React, { FC } from "react";
import ChangelogEntriesList from "../../../../../components/ChangelogEntriesList/ChangelogEntriesList";
import ChangelogEntryList from "../../../../../model/changelog/ChangelogEntryList";

export const ChangelogModal: FC = () => {
  const isCompact = useCompactMode();
  const entries = ChangelogEntryList.useLoad().entries;

  return (
    <TranslationProvider name="Changelog" type="modal">
      <DefaultModal
        _size="m"
        description="description"
        headline="headline"
        headlineIcon={faClipboardList}
        maxExpectedButtonSize={isCompact ? 450 : undefined}
      >
        <ChangelogEntriesList changelogs={entries} />
      </DefaultModal>
    </TranslationProvider>
  );
};

export default ChangelogModal;
