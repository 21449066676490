import { faCommentAltLines } from "@fortawesome/pro-regular-svg-icons/faCommentAltLines";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { Null } from "@mittwald/flow-components/dist/components/Null";
import combine from "@mittwald/flow-lib/dist/access/combine";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import BacksideRenderer from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { PageSkeleton } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/components/PageSkeleton";
import { OpenAPIV3 } from "openapi-types";
import React, { lazy } from "react";
import { mittwaldApi } from "../api/Mittwald";
import ErrorView from "../components/ErrorView";
import { HeaderNavigation } from "../components/HeaderNavigation";
import { NotificationBell } from "../components/HeaderNavigation/components/NotificationBell";
import { SearchButton } from "../components/HeaderNavigation/components/SearchButton";
import { HelpdeskButton } from "../components/HeaderNavigation/components/HelpdeskButton/HelpdeskButton";
import IfLoggedIn from "../components/IfLogged/IfLoggedIn";
import useCheckApiSpecPath from "../hooks/useCheckApiSpecPath";
import { useCheckAuthenticated } from "../hooks/useCheckAuthenticated";
import useCheckIsEmployee from "../hooks/useCheckIsEmployee";
import LazyNewIssueModal from "./app/components/LazyNewIssueModal";

export const backsidePages = PageTree.build(BacksideRenderer, {
  "/app": {
    views: {
      pageTitle: Null,
      main: () => <PageSkeleton redirectTo="dashboard" />,
      error: ErrorView,
      headerNavigation: () => (
        <IfLoggedIn>
          <HeaderNavigation />
        </IfLoggedIn>
      ),
      additionalCompactHeaderContent: () => (
        <Box _flexDirection="row" _itemGap="m" _noShrink>
          <SearchButton />
          <HelpdeskButton />
          <NotificationBell />
        </Box>
      ),
      bottom: lazy(() => import("../components/AppBottomComponents")),
      mainNavigation: lazy(
        () => import("../components/MainNavigationBottom/MainNavigationBottom"),
      ),
    },
    linkId: "app",
    props: {
      headerLogoLinkId: "dashboard",
      floatingButton: {
        action: {
          fn: () => {
            showModal("newIssue", <LazyNewIssueModal />);
          },
          accessCheck: combine.and(
            useCheckAuthenticated,
            useCheckIsEmployee,
            () =>
              useCheckApiSpecPath(
                mittwaldApi.userCreateFeedback.descriptor.path,
                {
                  method: OpenAPIV3.HttpMethods.POST,
                },
              ),
          ),
        },
        icon: faCommentAltLines,
      },
    },
    accessCheck: useCheckAuthenticated,
  },
});
