import { LinkIdAction } from "@mittwald/flow-components/dist/lib/actions/LinkIdAction";
import { Role } from "../acl/Role";
import ProjectInvite from "../../project/ProjectInvite";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

ModelActionBuilderRegistry.register({
  model: ProjectInvite,
  method: "acceptInvite",
  roles: [Role.anybody],
  altAction: (action) => [
    action,
    new LinkIdAction("project", {
      pathParams: { projectId: action.model.data.projectId },
    }),
  ],
}).register({
  model: ProjectInvite,
  method: "declineInvite",
  roles: [Role.anybody],
});
