import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import { BackgroundColor } from "@mittwald/flow-components/dist/mixins/backgroundColor";
import { iconExternalLink } from "@mittwald/flow-icons/dist/externalLink";
import classNames from "classnames";
import React, { FC } from "react";
import Status from "../../model/status/Status";
import { Severity } from "../../resources/statusPageResource";
import { RootPortal } from "../RootPortal";
import * as Styled from "./styled";

const getSeverityColor = (severity: Severity): BackgroundColor => {
  switch (severity) {
    case Severity.scheduled:
      return "status-info";
    case Severity.minor:
      return "status-warning";
    case Severity.major:
      return "status-error";
  }
};

export const StatusBanderole: FC = () => {
  const severity = Status.useFind()?.findMostSevereActiveIncident()?.severity;

  if (!severity) {
    return null;
  }

  const className = classNames({
    info: severity === Severity.scheduled,
    warning: severity === Severity.minor,
    error: severity === Severity.major,
  });

  return (
    <RootPortal>
      <TranslationProvider name="statusBanderole" type="component">
        <Styled.Banderole
          _alignItems="center"
          _bgColor={getSeverityColor(severity)}
          _flexDirection="row"
          _justifyContent="center"
          _p="s"
          _position="sticky"
          className={className}
          onClick={() => window.open(import.meta.env.VITE_STATUS_PAGE_URI)}
        >
          <TextWithIconLayout icon={iconExternalLink} iconPosition="right">
            <Text
              _color="inverse"
              i18n={`status.${Severity[severity]}`}
              strong
            />
          </TextWithIconLayout>
        </Styled.Banderole>
      </TranslationProvider>
    </RootPortal>
  );
};

export default StatusBanderole;
