import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form/hooks/useForm";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC } from "react";

export const RegistryLoginInputs: FC = () => {
  const form = useFormContext();
  const loginType = form.watch("loginType");

  return (
    <>
      <SelectBox
        name="loginType"
        options={[
          { title: "anonymous", value: "anonymous" },
          { title: "passwordLogin", value: "password" },
          { title: "tokenLogin", value: "token" },
        ]}
      />
      {loginType === "password" && (
        <ColumnLayout medium={[1, 1]}>
          <TextField
            label="username"
            name="username"
            rules={{ required: true }}
          />
          <TextField
            label="password"
            name="password"
            rules={{ required: true }}
            type="password"
          />
        </ColumnLayout>
      )}
      {loginType === "token" && (
        <ColumnLayout medium={[1, 1]}>
          <TextField
            label="token"
            name="token"
            rules={{ required: true }}
            type="password"
          />
        </ColumnLayout>
      )}
    </>
  );
};
