import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import {
  TextField,
  TextFieldProps,
} from "@mittwald/flow-components/dist/components/TextField";
import React, { forwardRef } from "react";

export interface StaticTextFieldProps extends TextFieldProps {
  staticValue: string;
  name: string;
}

const StaticTextField = forwardRef<HTMLInputElement, StaticTextFieldProps>(
  (props, ref) => {
    const { staticValue, name, ...restProps } = props;

    const form = useFormContext();
    const currentValue = form.watch(name);

    if (currentValue !== staticValue) {
      form.setValue(name, staticValue);
    }

    return (
      <>
        <TextField {...restProps} hidden={true} inputRef={ref} name={name} />
        <StaticInformation text={staticValue} />
      </>
    );
  },
);

StaticTextField.displayName = "StaticTextField";
export default StaticTextField;
