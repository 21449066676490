import { IconLookup } from "@mittwald/flow-components/dist/components/Icon";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import React, { FC } from "react";

export interface IconListItemProps {
  icon: IconLookup;
  text?: string;
  i18n?: I18nDefinition;
}

export const IconListItem: FC<IconListItemProps> = (props) => {
  const { icon, i18n, text } = props;

  return (
    <TextWithIconLayout icon={icon}>
      <Text i18n={i18n} text={text} />
    </TextWithIconLayout>
  );
};
