import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import { iconEmail } from "@mittwald/flow-icons/dist/email";
import React, { FC } from "react";
import Ingress from "../../../../../model/domain/Ingress";

interface Props {
  ingress: Ingress;
}

export const DeleteIngress: FC<Props> = (props) => {
  const { ingress } = props;
  const hasEmailAddresses = ingress.useEmailAddressesExist();
  const visibility = useVisibilityController();

  const form = useForm<{ confirm: boolean }>({
    defaultValues: { confirm: false },
    onSubmit: async () => {
      await ingress.delete();
    },
  });

  return (
    <DefaultModal
      _size={hasEmailAddresses ? "m" : "s"}
      primary={{
        action: [
          { form },
          visibility.hide,
          { link: { id: "domainOverview", options: { replace: true } } },
        ],
        text: ingress.isSubdomain ? "deleteSubdomain" : "deleteIngress",
        destructive: true,
      }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Layout>
          <Section.Item
            headline={ingress.isSubdomain ? "deleteSubdomain" : "deleteIngress"}
            headlineIcon={iconDomain}
          >
            <Text
              i18n={{
                id: "description",
                values: { hostname: ingress.hostname },
              }}
            />
          </Section.Item>

          {hasEmailAddresses && (
            <Section.Item headline="dependencies">
              <Text i18n="description" />

              <TextWithIconLayout icon={iconEmail}>
                <Text i18n="emails" />
              </TextWithIconLayout>

              <CheckBox
                name="confirm"
                rules={{ required: true }}
                title="confirm"
              />
            </Section.Item>
          )}
        </Section.Layout>
      </Form>
    </DefaultModal>
  );
};
