import Select from "@mittwald/flow-components/dist/components/Select/Select";
import {
  I18nDefinition,
  useTranslation,
} from "@mittwald/flow-components/dist/hooks/useTranslation";
import React, { FC } from "react";
import SystemSoftwareVersion from "../../../../model/app/SystemSoftwareVersion";

interface SoftwareVersionSelectProps {
  versions: SystemSoftwareVersion[];
  name: string;
  label?: I18nDefinition;
}

export const SystemSoftwareVersionSelect: FC<SoftwareVersionSelectProps> = (
  props,
) => {
  const { versions, name, label } = props;
  const translate = useTranslation();

  const versionOptions = versions.map((v) => ({
    label: {
      text: v.checkCurrentFee()
        ? `${v.data.externalVersion} (${translate("chargeable")})`
        : v.data.externalVersion,
    },
    value: v.data.id,
  }));

  return (
    <Select
      label={label ?? "version"}
      name={name}
      options={versionOptions}
      placeholder="version"
      rules={{ required: true }}
    />
  );
};

export default SystemSoftwareVersionSelect;
