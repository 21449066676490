import Box from "@mittwald/flow-components/dist/components/Box";
import { Portal } from "@mittwald/flow-components/dist/components/Portal";
import UniversalBoundary from "@mittwald/flow-components/dist/components/UniversalBoundary";
import { spacing } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import { AnimatePresence } from "framer-motion";
import React, { FC, useState } from "react";
import IncomingNotificationListener from "../../model/notification/IncomingNotificationListener";
import Notification from "../../model/notification/Notification";
import NotificationToast from "./components/NotificationToast";

export const IncomingNotifications: FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const removeNotification = (notification: Notification) => () => {
    setNotifications((existing) =>
      existing.filter((e) => e.id !== notification.id),
    );
  };

  const addNotification = (newNotification: Notification): void => {
    setNotifications((existing) => [newNotification, ...existing]);
  };

  IncomingNotificationListener.useHandleNotification(addNotification);

  return (
    <Portal>
      <Box
        _flexDirection="column"
        _position="absolute"
        _right={spacing.inset.m}
        _top={spacing.inset.m}
      >
        <AnimatePresence>
          {notifications.map((notification) => (
            <UniversalBoundary key={notification.id}>
              <NotificationToast
                notification={notification}
                remove={removeNotification(notification)}
              />
            </UniversalBoundary>
          ))}
        </AnimatePresence>
      </Box>
    </Portal>
  );
};

export default IncomingNotifications;
