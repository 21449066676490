import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column";
import React from "react";
import ModelRelationTarget from "../../../../../../components/ModelRelationTarget/ModelRelationTarget";
import { Notification } from "../../../../../../model/notification/Notification";

export const RelationColumn: ResourceListColumnRenderer<Notification> = ({
  resource,
}) => {
  return (
    <Column
      icon={resource.relation.type.icon}
      text={<ModelRelationTarget relation={resource.relation} />}
      tooltip={resource.relation.type.aggregate}
    />
  );
};
