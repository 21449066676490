import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import Section from "@mittwald/flow-components/dist/components/Section";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation/StaticInformation";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import React, { FC } from "react";

export interface ShowAuthCodeProps {
  authCode: string;
}

export const ShowAuthCode: FC<ShowAuthCodeProps> = (props) => {
  const { authCode } = props;
  const visibility = useVisibilityController();
  return (
    <DefaultModal
      _size="s"
      abort={{ action: visibility.hide }}
      description="description"
      headline="moveDomain"
      headlineIcon={iconDomain}
      visibility={visibility}
    >
      <Section.Item>
        <StaticInformation
          copyContent={authCode}
          label="authCode"
          text={{ text: authCode }}
        />
      </Section.Item>
    </DefaultModal>
  );
};
