import { SecondaryAction } from "@mittwald/flow-components/dist/components/ResourceList";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { iconInfoCircle } from "@mittwald/flow-icons/dist/infoCircle";

export const showDetails = (action: AnyAction): SecondaryAction<any> => ({
  id: "details",
  icon: iconInfoCircle,
  action,
  title: "details",
});

export default showDetails;
