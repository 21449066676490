import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import BackendSyncer, {
  PullSettingsFn,
  PushSettingsFn,
} from "@mittwald/flow-lib/dist/store/BackendSyncer";
import { mittwaldApi } from "../api/Mittwald";
import autorunWithCleanup from "../lib/autorunWithCleanup";
import sessionStore from "../store/session";
import userSettingsStore from "../store/userSettings";

const updateUserSettings: PushSettingsFn = async (userSettings) => {
  if (sessionStore.impersonated) {
    return;
  }

  await mittwaldApi.userUpdatePersonalizedSettings.request({
    requestBody: { settingsString: userSettings },
    path: { userId: "self" },
  });
};

const fetchUserSettings: PullSettingsFn = async () => {
  const response = await mittwaldApi.userGetPersonalizedSettings.request({
    path: { userId: "self" },
  });

  assertStatus(response, 200);
  return response.content.settingsString;
};

export const autorunSyncUserSettings = (): void => {
  const syncer = new BackendSyncer(
    userSettingsStore,
    updateUserSettings,
    fetchUserSettings,
  );

  // fetch from backend
  autorunWithCleanup(() => {
    const accessToken = sessionStore.accessToken;

    if (accessToken) {
      void syncer.start();
    }

    return () => {
      syncer.stop();
    };
  });
};
