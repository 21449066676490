import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { SelectBoxOptions } from "@mittwald/flow-components/dist/components/SelectBox";
import { iconCronjob } from "@mittwald/flow-icons/dist/cronjob";
import { mittwaldApi } from "../../../api/Mittwald";

export type CronjobInterval = "1m" | "5m" | "30m" | "1h" | "1d" | "custom";

export class CronjobUI {
  public static readonly icon = iconCronjob;
  public static readonly typeSelectBoxOptions: SelectBoxOptions = [
    { title: "command", value: "command" },
    { title: "url", value: "url" },
  ];
  public static readonly defaultInterpreterSelectOptions: SelectOptions = [
    {
      value: "/usr/bin/bash",
      label: { text: "Bash" },
      payload: { extensions: [".sh"] },
    },
  ];
  public static readonly interpreterSelectOptions: SelectOptions = [
    {
      value: "/usr/bin/php",
      label: { text: "PHP" },
      payload: { extensions: [".php", ".phtml"] },
    },
    {
      value: "/usr/local/bin/python",
      label: { text: "Python" },
      payload: { extensions: [".py"] },
    },
  ];

  public static readonly cronjobIntervals: CronjobInterval[] = [
    "1m",
    "5m",
    "30m",
    "1h",
    "1d",
    "custom",
  ];

  public static getInterpreterSelectOptions(appId?: string): SelectOptions {
    const response = mittwaldApi.appGetInstalledSystemsoftwareForAppinstallation
      .getResource(
        appId
          ? {
              path: { appInstallationId: appId },
              query: { tagFilter: "interpreter" },
            }
          : null,
      )
      .useWatchData();

    return [
      ...CronjobUI.defaultInterpreterSelectOptions,
      ...CronjobUI.interpreterSelectOptions.filter((option) =>
        response?.find((sw) => option.value.includes(sw.name)),
      ),
    ];
  }
}
