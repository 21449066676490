import { Box } from "@mittwald/flow-components/dist/components/Box";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import Extension from "../../../../../../model/marketplace/Extension";
import { CustomerInfoBox } from "../../../../server/modals/OrderServerWizard/components/CustomerInfoBox";
import ConfirmationStepIntro from "../../components/ConfirmationStepIntro";
import PriceTableContent from "../../components/PriceTableContent";
import { ExtensionConditionsCheckbox } from "./ExtensionConditionsCheckbox/ExtensionConditionsCheckbox";

interface Props {
  extension?: Extension;
  customerId: string;
}

const ConfirmationStep: FC<Props> = (props) => {
  const { extension, customerId } = props;

  return (
    <WizardStep id="confirmation" indicatorText="confirmationStep">
      <Section.Layout>
        {extension && <ConfirmationStepIntro extension={extension} />}
        <Section.Item>
          <Box _itemGap="xl">
            <ColumnLayout medium={[1, 1]}>
              <CustomerInfoBox customerId={customerId} />
              {extension && <PriceTableContent extension={extension} />}
            </ColumnLayout>
          </Box>
          <ExtensionConditionsCheckbox />
        </Section.Item>
      </Section.Layout>
    </WizardStep>
  );
};

export default ConfirmationStep;
