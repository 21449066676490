import { mittwaldApi } from "../api/Mittwald";
import { useAccessToken } from "./useAccessToken";

const requiredEmailEndings = ["@mittwald.de"];

/**
 * Check if the logged in user has a @mittwald.de email address
 */
export const useCheckIsEmployee = (): boolean => {
  const accessToken = useAccessToken();

  const email = mittwaldApi.userGetOwnAccount
    .getResource(accessToken ? {} : null)
    .useWatchData()?.email;

  return requiredEmailEndings.some((ending) => !!email?.endsWith(ending));
};

export default useCheckIsEmployee;
