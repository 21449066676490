import { Banner } from "@mittwald/flow-components/dist/components/Banner/Banner";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import { LinkList } from "@mittwald/flow-components/dist/components/LinkList";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { RejectionAnimation } from "@mittwald/flow-components/dist/components/RejectionAnimation";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useAnimationController } from "@mittwald/flow-components/dist/hooks/useAnimationController";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { DateTime } from "luxon";
import React, { FC, useState } from "react";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { CodeInput } from "../../../../../../components/CodeInput";
import { useCodeInputAutoSubmit } from "../../../../../../hooks/useCodeInputAutoSubmit";
import Profile, {
  DeleteProfileInputs,
} from "../../../../../../model/signup/Profile";
import { Signup } from "../../../../../../model/signup/Signup";
import sessionStore from "../../../../../../store/session";

export const DeleteProfile: FC = () => {
  const goto = useGotoLink();
  const [recovery, setRecovery] = useState(false);
  const [isLastOwner, setIsLastOwner] = useState(false);
  const visibility = useVisibilityController();

  const mfaConfirmed = Signup.useMfaConfirmed();
  const currentWeekday = DateTime.local().weekdayLong;

  const rejectionAnimation = useAnimationController();

  const form = useForm<DeleteProfileInputs>({
    showSuccessFeedback: false,
    onSubmit: async (values) => {
      if (
        values.currentWeekday.toLowerCase() !== currentWeekday.toLowerCase()
      ) {
        form.setError("currentWeekday", { message: "wrong" });
        return false;
      }
      const response = await Profile.delete(values);

      if (response === 409) {
        setIsLastOwner(true);
        return false;
      }

      if (response === 400) {
        rejectionAnimation.start();
        return false;
      }

      visibility.hide();
    },
  });

  useCodeInputAutoSubmit(form);

  const linkAction: AnyAction = {
    fn: () => setRecovery((old) => !old),
  };

  return (
    <DefaultModal
      headline="deleteProfile"
      headlineIcon={iconDelete}
      primary={{
        action: [{ form }, sessionStore.logout, "profile-deleted"],
        destructive: true,
      }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Layout>
          <Section.Item headline="1fa">
            <Text i18n="description" />
            <ColumnLayout medium={[1, 1]}>
              <RejectionAnimation animationController={rejectionAnimation}>
                <TextField
                  autoFocus
                  label="password"
                  name="password"
                  rules={{ required: true }}
                  type="password"
                />
              </RejectionAnimation>
              <TextField
                label="currentWeekday"
                name="currentWeekday"
                placeholder={{ text: currentWeekday }}
                rules={{ required: true }}
              />
            </ColumnLayout>
          </Section.Item>
          {mfaConfirmed && (
            <Section.Item headline="2fa">
              <Text i18n="description" />
              {!recovery && (
                <ColumnLayout medium={[1, 1]}>
                  <CodeInput
                    label="2facode"
                    maxLength={6}
                    rejectionAnimation={rejectionAnimation}
                  />
                </ColumnLayout>
              )}
              {recovery && (
                <ColumnLayout medium={[1, 1]}>
                  <TextField
                    autoFocus
                    label="recoveryCode"
                    name="multiFactorCode"
                    rules={{ required: true, minLength: 16, maxLength: 16 }}
                  />
                </ColumnLayout>
              )}
              <LinkList>
                <LinkList.Item
                  action={linkAction}
                  i18n={recovery ? "link.totp" : "link.recovery"}
                />
              </LinkList>
            </Section.Item>
          )}
          {isLastOwner && (
            <Section.Item>
              <Banner
                description="canNotDeleteLastOwner"
                error
                headline="canNotDeleteProfile"
                textLink={{
                  action: () => goto("customersOverview"),
                  title: "gotoCustomers",
                }}
              />
            </Section.Item>
          )}
        </Section.Layout>
      </Form>
    </DefaultModal>
  );
};

export default DeleteProfile;
