import { faSitemap } from "@fortawesome/pro-regular-svg-icons/faSitemap";
import { faPersonDigging } from "@fortawesome/pro-regular-svg-icons/faPersonDigging";
import { IconLookup } from "@mittwald/flow-components/dist/components/Icon";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { iconCustomerRole } from "@mittwald/flow-icons/dist/customerRole";
import { iconMfa } from "@mittwald/flow-icons/dist/mfa";
import { iconProjectRole } from "@mittwald/flow-icons/dist/projectRole";
import { iconPublicKey } from "@mittwald/flow-icons/dist/publicKey";
import { iconRoleCustomerOwner } from "@mittwald/flow-icons/dist/roleCustomerOwner";
import { iconSupport } from "@mittwald/flow-icons/dist/support";
import { iconUser } from "@mittwald/flow-icons/dist/user";

import userSettingsStore from "../../store/userSettings";
import { ModelActionBuilder } from "../actions/ModelActionBuilder";

export interface OnboardingData {
  title: string;
  description: string;
  category: string;
  categoryIcon: IconLookup;
  icon: IconLookup;
  action: AnyAction;
}

export class Onboarding {
  public static completeStructure(): void {
    userSettingsStore.onboarding.complete("structure");
  }
  public static completeCustomerRoles(): void {
    userSettingsStore.onboarding.complete("customerRoles");
  }
  public static completeMfa(): void {
    userSettingsStore.onboarding.complete("mfa");
  }
  public static completeProjectRoles(): void {
    userSettingsStore.onboarding.complete("projectRoles");
  }
  public static completeSsh(): void {
    userSettingsStore.onboarding.complete("ssh");
  }
  public static completeSupport(): void {
    userSettingsStore.onboarding.complete("support");
  }

  public static completeStatus(): void {
    userSettingsStore.onboarding.complete("status");
  }
}

export const onboardingData: OnboardingData[] = [
  {
    title: "mfa",
    description: "mfaDescription",
    category: "general",
    categoryIcon: iconUser,
    icon: iconMfa,
    action: {
      useValue: () => ModelActionBuilder.build(Onboarding, "completeMfa"),
    },
  },
  {
    title: "structure",
    description: "structureDescription",
    category: "general",
    categoryIcon: iconUser,
    icon: faSitemap,
    action: {
      useValue: () => ModelActionBuilder.build(Onboarding, "completeStructure"),
    },
  },
  {
    title: "support",
    description: "supportDescription",
    category: "general",
    categoryIcon: iconUser,
    icon: iconSupport,
    action: {
      useValue: () => ModelActionBuilder.build(Onboarding, "completeSupport"),
    },
  },
  {
    title: "projectRoles",
    description: "projectRolesDescription",
    category: "permissions",
    categoryIcon: iconRoleCustomerOwner,
    icon: iconProjectRole,
    action: {
      useValue: () =>
        ModelActionBuilder.build(Onboarding, "completeProjectRoles"),
    },
  },
  {
    title: "customerRoles",
    description: "customerRolesDescription",
    category: "permissions",
    categoryIcon: iconRoleCustomerOwner,
    icon: iconCustomerRole,
    action: {
      useValue: () =>
        ModelActionBuilder.build(Onboarding, "completeCustomerRoles"),
    },
  },
  {
    title: "ssh",
    description: "sshDescription",
    category: "general",
    categoryIcon: iconUser,
    icon: iconPublicKey,
    action: {
      useValue: () => ModelActionBuilder.build(Onboarding, "completeSsh"),
    },
  },
  {
    title: "status",
    description: "statusDescription",
    category: "general",
    categoryIcon: iconUser,
    icon: faPersonDigging,
    action: {
      useValue: () => ModelActionBuilder.build(Onboarding, "completeStatus"),
    },
  },
];

export default Onboarding;
