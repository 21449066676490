import { iconSignOut } from "@mittwald/flow-icons/dist/signOut";
import CustomerMembership from "../../customer/CustomerMembership";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

ModelActionBuilderRegistry.register({
  model: CustomerMembership,
  roles: [Role.customerOwner, Role.customerMember, Role.customerAccountant],
  method: "leaveCustomer",
  isAvailable: (membership) =>
    membership.useIsMe() && membership.useCheckUserIsNotLastOwnerOfCustomer(),
  altAction: (action) => ({
    action,
    confirmation: {
      modalTranslationKey: "leaveCustomer",
      required: true,
      modalProps: { headlineIcon: iconSignOut },
    },
  }),
}).register({
  model: CustomerMembership,
  roles: [Role.customerOwner],
  method: "removeMember",
  isAvailable: (membership) => !membership.useIsMe(),
  altAction: (action) => ({
    action,
    confirmation: {
      modalTranslationKey: "removeCustomerMember",
      required: true,
      modalProps: { headlineIcon: iconSignOut },
    },
  }),
});
