import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import React, { FC } from "react";
import { iconDatabase } from "@mittwald/flow-icons/dist/database";
import DatabaseUI from "../../../../../../model/ui/database/DatabaseUI";

export const TypeStep: FC = () => {
  return (
    <WizardIntroStep
      description="databaseText"
      headline="database"
      headlineIcon={iconDatabase}
      id="type"
      indicatorText="databaseStep"
    >
      <Section.Item>
        <SelectBox
          name="type"
          options={DatabaseUI.useDatabaseTypeOptions()}
          rules={{ required: true }}
        />
      </Section.Item>
    </WizardIntroStep>
  );
};

export default TypeStep;
