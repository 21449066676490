import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import AutoCompleteField from "@mittwald/flow-components/dist/components/AutoCompleteField/AutoCompleteField";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { FormController } from "@mittwald/flow-components/dist/components/Form";
import { H3 } from "@mittwald/flow-components/dist/components/H3";
import { InlineCode } from "@mittwald/flow-components/dist/components/InlineCode";
import Select from "@mittwald/flow-components/dist/components/Select/Select";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation/StaticInformation";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { useOnChange } from "@mittwald/flow-lib/dist/hooks/useOnChange";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import React, { FC } from "react";
import { mittwaldApi } from "../../../../api/Mittwald";
import { useProjectDirectoryListingCompletion } from "../../../../hooks/useProjectDirectoryListingCompletion";
import { CronjobDestinationInputs } from "../../../../model/cronjob/Cronjob";
import { CronjobUI } from "../../../../model/ui/cronjob/CronjobUI";

export interface CronjobFileCommandFormProps {
  controller: FormController<CronjobDestinationInputs>;
  appId?: string;
}

export const CronjobFileCommandForm: FC<CronjobFileCommandFormProps> = (
  props,
) => {
  const [interpreter, path, parameters] = props.controller.watch([
    "destination.interpreter",
    "destination.path",
    "destination.parameters",
  ]);
  const generatedCommand = [interpreter, path, parameters]
    .filter((v) => !!v)
    .join(" ");

  const interpreterSelectOptions = CronjobUI.getInterpreterSelectOptions(
    props.appId,
  );

  const { projectId } = usePathParams("projectId");
  const projectDirectoryListingCompletion =
    useProjectDirectoryListingCompletion(projectId);

  useOnChange(() => {
    const fileExtension = path.slice(((path.lastIndexOf(".") - 1) >>> 0) + 2);
    const interpreter = interpreterSelectOptions.find((i) =>
      i.payload?.extensions.includes(`.${fileExtension}`),
    );

    if (interpreter) {
      props.controller.setValue("destination.interpreter", interpreter.value);
    }
  }, [path]);

  const validateFileExists = async (value: string): Promise<boolean> => {
    const res = await mittwaldApi.projectFileSystemListFiles.request({
      path: { projectId },
      query: { file: value },
    });
    assertStatus(res, 200);
    return !("error" in res.content);
  };

  return (
    <TranslationProvider name="cronjobFileForm" type="section">
      <ColumnLayout medium={[2, 1]}>
        <Select
          label="interpreter"
          name="destination.interpreter"
          options={interpreterSelectOptions}
          placeholder="interpreterPlaceholder"
          rules={{ required: true }}
        />
      </ColumnLayout>
      <ColumnLayout medium={[2, 1]}>
        <AutoCompleteField
          label="path"
          name="destination.path"
          rules={{
            required: true,
            validate: {
              startsWith: (value: string) => `${value}`.startsWith("/"),
              endsWith: (value: string) => !`${value}`.endsWith("/"),
              fileExists: validateFileExists,
            },
          }}
          {...projectDirectoryListingCompletion}
        />
        <TextField label="parameter" name="destination.parameters" />
      </ColumnLayout>

      <H3 _mt="l" i18n="command" />
      <Text i18n="commandDescription" />
      {generatedCommand.length > 0 && (
        <StaticInformation
          copyContent={generatedCommand}
          label="generatedCommand"
          text={<InlineCode>{generatedCommand}</InlineCode>}
        />
      )}
    </TranslationProvider>
  );
};

export default CronjobFileCommandForm;
