import { ColumnLayoutContainer } from "@mittwald/flow-components/dist/components/ColumnLayoutContainer";
import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import useWizardState from "@mittwald/flow-components/dist/components/Wizard/hooks/useWizardState";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useAnimationController } from "@mittwald/flow-components/dist/hooks/useAnimationController";
import { iconSync } from "@mittwald/flow-icons/dist/sync";
import React, { FC, useState } from "react";
import { CodeInput } from "../../../../../../components/CodeInput";
import { useCodeInputAutoSubmit } from "../../../../../../hooks/useCodeInputAutoSubmit";
import { Signup } from "../../../../../../model/signup/Signup";
import DownloadRecoveryCodes from "../ActivateMfa/components/downloadRecoveryCodes";

export const ResetRecoveryCodes: FC = () => {
  const [recoveryCodeList, setRecoveryCodeList] = useState<string[]>([""]);
  const rejectionAnimation = useAnimationController();
  const wizardState = useWizardState();

  const form = useForm({
    translationKey: "resetRecoveryCodes",
    defaultValues: {
      multiFactorCode: "",
    },
    onSubmit: async (values) => {
      const recoveryCodes = await Signup.resetRecoveryCodes(
        values.multiFactorCode,
        rejectionAnimation,
      );

      setRecoveryCodeList(recoveryCodes);
      wizardState.gotoNextStep();
    },
  });

  useCodeInputAutoSubmit(form);

  const authenticationStep = (
    <WizardIntroStep
      description="generateRecoveryCodesDescription"
      headline="generateRecoveryCodes"
      headlineIcon={iconSync}
      id="authentication"
      indicatorText="recoveryCodeAuthenticate"
      stepWillSubmitForm
    >
      <Section.Item>
        <ColumnLayoutContainer _pb="l" _pt="s">
          <CodeInput label="2fa" rejectionAnimation={rejectionAnimation} />
        </ColumnLayoutContainer>
      </Section.Item>
    </WizardIntroStep>
  );

  const downloadStep = (
    <WizardStep id="download" indicatorText="recoveryCodes" noAbort>
      <DownloadRecoveryCodes
        recoveryCodes={recoveryCodeList}
        singlePartDescription
      />
    </WizardStep>
  );
  return (
    <WizardModal
      _size="s"
      form={form}
      steps={["authentication", "download"]}
      wizardState={wizardState}
    >
      {authenticationStep}
      {downloadStep}
    </WizardModal>
  );
};

export default ResetRecoveryCodes;
