import { Subscription } from "centrifuge";
import { useEffect } from "react";
import z from "zod";
import websocketGateway from "../../api/websocketGateway";
import User from "../user/User";
import Notification from "./Notification";

const notificationDataSchema = z.object({
  message: z.string(),
});

type NotificationData = z.infer<typeof notificationDataSchema>;

type OnNewNotification = (notification: Notification) => void;

export class IncomingNotificationListener {
  private static readonly subscription: Subscription | null = null;

  private static readonly onNewNotificationHandler =
    new Set<OnNewNotification>();

  private static useStartListen(): void {
    const user = User.tryUseMe();

    useEffect(() => {
      if (!user) {
        return;
      }

      return websocketGateway.subscribe<NotificationData>({
        channel: "notification",
        userId: user.id,
        dataSchema: notificationDataSchema,
        handler: (data) =>
          IncomingNotificationListener.handleNotificationReceived(data),
      });
    }, [user?.id]);
  }

  public static useHandleNotification(handler: OnNewNotification): void {
    useEffect(
      () => IncomingNotificationListener.onNewNotification(handler),
      [handler, IncomingNotificationListener],
    );
    IncomingNotificationListener.useStartListen();
  }

  private static onNewNotification(handler: OnNewNotification): () => void {
    this.onNewNotificationHandler.add(handler);

    return () => {
      this.onNewNotificationHandler.delete(handler);
    };
  }

  private static handleNotificationReceived(data: NotificationData): void {
    const notification = Notification.fromApiData(JSON.parse(data.message));

    for (const handler of Array.from(
      IncomingNotificationListener.onNewNotificationHandler.values(),
    )) {
      handler(notification);
    }
  }
}

export default IncomingNotificationListener;
