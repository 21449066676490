import { Banner } from "@mittwald/flow-components/dist/components/Banner";
import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC, useEffect, useState } from "react";
import { TldTransferAuthenticationType } from "../../../../../model/domain/Tld";
import { TldList } from "../../../../../model/domain/TldList";

interface DomainAuthenticationProps {
  fieldNameAuthCode: string;
  domain: string;
}

export const DomainTransferAuthentication: FC<DomainAuthenticationProps> = (
  props,
) => {
  const { fieldNameAuthCode, domain } = props;

  const [authType, setAuthType] =
    useState<TldTransferAuthenticationType>("code");

  useEffect(() => {
    void TldList.getTldByDomain(domain).then((tld) => {
      if (tld) {
        setAuthType(tld.transferAuthType);
      }
    });
  }, [domain]);

  let authComponent = undefined;
  if (authType === "code") {
    authComponent = (
      <TextField
        label="auth.code.label"
        name={fieldNameAuthCode}
        rules={{ required: true }}
      />
    );
  }

  if (authType === "push") {
    authComponent = (
      <>
        <Banner
          description="auth.push.description"
          headline="auth.push.headline"
          info
        />
        <CheckBox
          name="authPushInformationRead"
          rules={{ required: true }}
          title="auth.push.title"
        />
      </>
    );
  }

  if (authType === "email") {
    authComponent = (
      <Banner
        description="auth.email.description"
        headline="auth.email.headline"
        info
      />
    );
  }

  if (!authComponent) {
    return null;
  }

  return (
    <TranslationProvider name="domainTransferAuthentication" type="component">
      {authComponent}
    </TranslationProvider>
  );
};
