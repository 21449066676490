import React, { FC } from "react";
import SystemSoftware from "../../../../model/app/SystemSoftware";
import SystemSoftwareUI from "../../../../model/ui/app/SystemSoftwareUI";
import { Translate } from "@mittwald/flow-components/dist/components/Translate";

interface Props {
  systemSoftware: SystemSoftware;
}

export const SystemSoftwareTitle: FC<Props> = (props) => {
  return (
    <Translate i18n={SystemSoftwareUI.of(props.systemSoftware).getTitle()} />
  );
};

export default SystemSoftwareTitle;
