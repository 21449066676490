import { Box } from "@mittwald/flow-components/dist/components/Box";
import { H3 } from "@mittwald/flow-components/dist/components/H3";
import PriceBox from "@mittwald/flow-components/dist/components/PriceBox";
import { PriceTable } from "@mittwald/flow-components/dist/components/PriceTable";
import React, { FC } from "react";
import SystemSoftwareVersion, {
  FeePeriod,
} from "../../../../../../model/app/SystemSoftwareVersion";

interface Props {
  systemSoftwareVersion: SystemSoftwareVersion;
  currentFee: FeePeriod;
}

export const OrderOverview: FC<Props> = (props) => {
  const { systemSoftwareVersion, currentFee } = props;

  return (
    <Box _itemGap="s">
      <H3 _mb="m" i18n="orderOverview" />
      <PriceTable twoColumns>
        <PriceTable.Row strong title="phpExtendedSupport" />
        <PriceTable.Row
          monthlyPrice={currentFee.monthlyPrice}
          strong={false}
          title={{ text: `PHP ${systemSoftwareVersion.data.externalVersion}` }}
        />
      </PriceTable>

      <Box _mt="m">
        <PriceBox
          monthlyPrice={currentFee.monthlyPrice}
          title={{
            id: "total",
          }}
        />
      </Box>
    </Box>
  );
};

export default OrderOverview;
