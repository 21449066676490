import { Box } from "@mittwald/flow-components/dist/components/Box";
import { color } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import styled from "styled-components";

export const Banderole = styled(Box)`
  &:hover {
    cursor: pointer;
    &.info {
      background-color: ${color.status.info.dark};
    }
    &.warning {
      background-color: ${color.status.warning.dark};
    }
    &.error {
      background-color: ${color.status.error.dark};
    }
  }
`;
