import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { DnsZoneUI, Ttl } from "../ui/domain/DnsZoneUI";
import { DnsZoneApiData } from "./DnsZone";

export type CnameRecordSetComponentApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordCnameComponent;

export type CnameRecordSetApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordCname;

export type CnameRecord = string;

interface CnameRecordWithHostAndValueApiData {
  fqdn: CnameRecord;
  settings: MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordSettings;
}

export class CnameRecordSet {
  public readonly data: CnameRecordSetApiData;
  public readonly ttl: Ttl;
  public readonly id: string;
  public readonly domain: string;
  public readonly fqdn: string;

  public constructor(
    id: string,
    domain: string,
    records: CnameRecordSetApiData | CnameRecordWithHostAndValueApiData,
    data: CnameRecordSetApiData,
  ) {
    this.data = Object.freeze(data);
    this.ttl = DnsZoneUI.ttlFromRecord(data);

    this.fqdn = "fqdn" in records ? records.fqdn : "";

    this.domain = domain;
    this.id = id;
  }

  public static fromDnsZoneApiData = (data: DnsZoneApiData): CnameRecordSet => {
    return new CnameRecordSet(
      data.id,
      data.domain,
      data.recordSet.cname,
      data.recordSet.cname,
    );
  };

  public static fromList = (
    id: string,
    domain: string,
    ttl: Ttl,
    fqdn: string,
  ): CnameRecordSet => {
    const cnameData: CnameRecordSetComponentApiData = {
      fqdn,
      settings: {
        ...DnsZoneUI.ttlToRecordSettings(ttl),
      },
    };

    return new CnameRecordSet(id, domain, cnameData, cnameData);
  };

  public async saveRecords(): Promise<void> {
    const requestBody: Partial<CnameRecordSetComponentApiData> = {
      fqdn: this.fqdn,
      settings: {
        ...DnsZoneUI.ttlToRecordSettings(this.ttl),
      },
    };

    await mittwaldApi.dnsUpdateRecordSet.request({
      path: {
        dnsZoneId: this.id,
        recordSet: "cname",
      },
      requestBody: this.fqdn ? requestBody : {},
    });
  }
}

export default CnameRecordSet;
