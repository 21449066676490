import { Box } from "@mittwald/flow-components/dist/components/Box";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { iconMembers } from "@mittwald/flow-icons/dist/members";
import React, { FC } from "react";
import Customer from "../../../../../../model/customer/Customer";
import CustomerMembershipList from "../../../../../../model/customer/CustomerMembershipList";
import { CustomerMemberList } from "../../../../customer/members/components/CustomerMemberList/MemberList";

interface Props {
  customer: Customer;
}

export const MembersWithPermission: FC<Props> = (props) => {
  const { customer } = props;

  return (
    <DefaultModal _size="s">
      <Section.Item headline="members" headlineIcon={iconMembers}>
        <Text i18n="membersDescription" />
        <Box _noOverflow>
          <CustomerMemberList
            columns={[{ key: "role" }]}
            members={CustomerMembershipList.useLoadByCustomer(
              customer,
            ).items.filter(
              (member) =>
                member.data.role === "owner" || member.data.role === "member",
            )}
          />
        </Box>
      </Section.Item>
    </DefaultModal>
  );
};

export default MembersWithPermission;
