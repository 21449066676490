import AutoCompleteField from "@mittwald/flow-components/dist/components/AutoCompleteField/AutoCompleteField";
import { SuggestionsFactory } from "@mittwald/flow-components/dist/components/AutoCompleteField/types";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useAsyncDebounce } from "@mittwald/flow-components/dist/hooks/useAsyncDebounce";
import { isEmail } from "@mittwald/flow-lib/dist/validation";
import React, { FC } from "react";
import { ListInputField } from "@mittwald/flow-components/dist/components/ListInputField";
import { I18nTextDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { iconAt } from "@mittwald/flow-icons/dist/at";

export interface EmailListInputProps {
  name: string;
  label?: I18nTextDefinition;
  placeholder?: I18nTextDefinition;
  currentEmailAddress?: string;
  disabled?: boolean;
  autocompleteEmails?: string[];
  allowWildcards?: boolean;
}

export const EmailListInput: FC<EmailListInputProps> = (props) => {
  const {
    name,
    label,
    placeholder = { text: "max.mustermann@example.com" },
    currentEmailAddress,
    disabled,
    autocompleteEmails,
    allowWildcards = false,
  } = props;

  const getSuggestions: SuggestionsFactory<string> = (value) => {
    const emails = autocompleteEmails ?? [];
    return emails
      .map((mail) => ({ value: mail, label: { text: mail } }))
      .filter((mail) => mail.value.startsWith(value));
  };

  const getSuggestion = useAsyncDebounce(getSuggestions, 250);

  const rules = {
    required: true,
    validate: {
      forwardEqualsCurrentEmail: (value: string) => {
        const isNotCurrent = value !== currentEmailAddress;
        if (!allowWildcards) {
          return isNotCurrent && isEmail(value);
        }

        // for validation, we replace wildcards with an actual ascii char
        return isNotCurrent && isEmail(value.replace(/\*/g, "a"));
      },
    },
  };

  return (
    <ListInputField listItemIcon={iconAt} name={name}>
      {({ fieldName, addItemFormAction }) => {
        if (autocompleteEmails !== undefined) {
          return (
            <AutoCompleteField
              _mb="l"
              buttonRight={{
                text: "add",
                action: addItemFormAction,
                disabled,
              }}
              disabled={disabled}
              label={label}
              name={fieldName}
              placeholder={placeholder}
              rules={rules}
              suggestions={getSuggestion}
              type="text"
            />
          );
        }
        return (
          <TextField
            _mb="l"
            buttonRight={{
              text: "add",
              action: addItemFormAction,
              disabled,
            }}
            disabled={disabled}
            label={label}
            name={fieldName}
            placeholder={placeholder}
            rules={rules}
            type="text"
          />
        );
      }}
    </ListInputField>
  );
};
