import { SuggestionsFactory } from "@mittwald/flow-components/dist/components/AutoCompleteField/types";
import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { useCallback, useMemo } from "react";
import DomainName from "../../domain/DomainName";
import Ingress from "../../domain/Ingress";
import { IngressList } from "../../domain/IngressList";

export interface HostNameSelectOptions {
  project?: {
    id: string;
    sortByProject?: boolean;
  };
  showAll?: boolean;
}

export interface DomainSuggestion {
  hostname: string;
  domain: DomainName;
}

export class IngressListUI {
  private readonly ingressList: IngressList;

  private constructor(ingressList: IngressList) {
    this.ingressList = ingressList;
  }

  public static of(ingressList: IngressList): IngressListUI {
    return new IngressListUI(ingressList);
  }

  public getPathSelectOptions(): SelectOptions {
    const paths = [];
    for (const ingress of this.ingressList.items) {
      paths.push(
        ...ingress.getPaths().map((path) => {
          const fullPath = `https://${ingress.hostname}${path.stringify(true)}`;
          return {
            value: fullPath,
            label: { text: fullPath },
          };
        }),
      );
    }
    return paths;
  }

  public useSubdomainSuggestions = (): SuggestionsFactory<string> => {
    const domains = this.ingressList.items.map((ingress) => ({
      domain: ingress.name,
      hostname: ingress.hostname,
    }));
    const domainsMemo = useMemo(() => domains, [domains]);
    const sortByName = (a: DomainSuggestion, b: DomainSuggestion): number => {
      if (a.domain.domain < b.domain.domain) {
        return -1;
      }
      if (a.domain.domain > b.domain.domain) {
        return 1;
      }
      return 0;
    };

    return useCallback(
      (value: string) => {
        const input = value.endsWith(".") ? value.slice(0, -1) : value;
        const splitInputs = value.split(".");
        if (splitInputs.length > 1) {
          return domainsMemo
            .filter((ingress) =>
              ingress.hostname.startsWith(
                splitInputs[splitInputs.length - 1] ?? "",
              ),
            )
            .sort(sortByName)
            .slice(0, 20)
            .map((ingress) => {
              const hostname = `${splitInputs.slice(0, -1).join(".")}.${ingress.hostname}`;
              return { value: hostname, label: { text: hostname } };
            });
        }
        return domainsMemo
          .sort(sortByName)
          .slice(0, 20)
          .map((ingress) => ({
            value: `${input}.${ingress.hostname}`,
            label: { text: `${input}.${ingress.hostname}` },
          }));
      },
      [domainsMemo],
    );
  };

  public getHostnameSelectOptions(
    options?: HostNameSelectOptions,
  ): SelectOptions {
    const {
      project: { sortByProject, id: projectId } = {
        sortByProject: false,
        id: "",
      },
      showAll = false,
    } = options ?? {};

    const selectOptions: SelectOptions = [];

    const sortByName = (a: Ingress, b: Ingress): number => {
      if (a.name.domain < b.name.domain) {
        return -1;
      }
      if (a.name.domain > b.name.domain) {
        return 1;
      }
      return 0;
    };

    const defaultIngress = this.ingressList.items.find(
      (i) => i.isEnabled && i.isDefault && i.projectId === projectId,
    );
    const ingresses = this.ingressList.items.filter((i) => {
      if (!i.isEnabled) {
        return false;
      }

      if (!showAll && i.isDefault) {
        return (
          defaultIngress &&
          i.hostname.toLowerCase().endsWith(defaultIngress.hostname)
        );
      }

      return true;
    });

    let ingressForProject: Ingress[] = [];
    let ingressOther: Ingress[] = ingresses;

    if (projectId && sortByProject) {
      ingressForProject = ingresses
        .filter((i) => i.projectId === projectId)
        .sort(sortByName);
      ingressOther = ingresses
        .filter((i) => i.projectId !== projectId)
        .sort(sortByName);
    }

    ingressForProject.concat(ingressOther).forEach((i) => {
      if (!selectOptions.find((o) => o.value === i.hostname)) {
        selectOptions.push({
          label: { text: i.hostname },
          value: i.hostname,
        });
      }
    });

    return selectOptions;
  }
}
