import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { PlainTopPageRenderer } from "@mittwald/flow-renderer/dist/components/PlainTopPageRenderer";
import { accessPages } from "./app/accesses/accessPages";
import { appPages } from "./app/app/appPages";
import { globalAppPages } from "./app/app/globalAppPages";
import { containerPages } from "./app/container/containerPages";
import { cronjobPages } from "./app/cronjob/cronjobPages";
import { customerPages } from "./app/customer/customerPages";
import { customerDashboardPages } from "./app/customer/dashboard/customerDashboardPages";
import { dashboardPages } from "./app/dashboard/dashboardPages";
import { databasePages } from "./app/database/databasePages";
import { domainPages } from "./app/domain/domainPages";
import { emailPages } from "./app/mail/emailPages";
import { performancePages } from "./app/performance/performancePages";
import { profilePages } from "./app/profile/profilePages";
import { projectDashboardPages } from "./app/project/dashboard/projectDashboardPages";
import { projectPages } from "./app/project/projectPages";
import { searchPages } from "./app/search/searchPages";
import { serverPages } from "./app/server/serverPages";
import { supportPages } from "./app/support/supportPages";
import { backsidePages } from "./backsidePages";
import { invitePages } from "./authorization/invite/invitePages";
import { loginPages } from "./authorization/login/loginPages";
import { profileDeletedPages } from "./authorization/profileDeleted/profileDeletedPages";
import { registerPages } from "./authorization/register/registerPages";
import { oAuthPages } from "./authorization/OAuth/oAuthPages";
import { backupPages } from "./app/backup/backupPages";
import StartPage from "./start";
import { widgetsPages } from "./widgets/widgetsPages";

export const pages = PageTree.build(PlainTopPageRenderer, {
  "/": {
    views: {
      main: StartPage,
    },
    linkId: "start",
  },
})
  .extend(widgetsPages)
  .extend(registerPages)
  .extend(loginPages)
  .extend(oAuthPages)
  .extend(supportPages)
  .extend(globalAppPages)
  .extend(customerPages)
  .extend(serverPages)
  .extend(projectPages)
  .extend(backsidePages)
  .extend(profilePages)
  .extend(profileDeletedPages)
  .extend(accessPages)
  .extend(invitePages)
  .extend(cronjobPages)
  .extend(backupPages)
  .extend(searchPages)
  .extend(databasePages)
  .extend(emailPages)
  .extend(domainPages)
  .extend(dashboardPages)
  .extend(containerPages)
  .extend(appPages)
  .extend(performancePages)
  .extend(projectDashboardPages)
  .extend(customerDashboardPages);

export default pages;
