export const printEmploymentAd = (): void => {
  console.log(
    "%cHey du! " +
      "%c\n" +
      "\n" +
      "Hast du einen Bug gefunden oder fehlt dir ein Feature? Dann freuen wir uns über deine Nachricht: " +
      "\n" +
      "\n" +
      "🐛 Bugs: https://studio.mittwald.de/app/support/conversations \n" +
      "🔎 Feature-Requests: https://github.com/mittwald/feature-requests \n" +
      "🤝 Mach's besser und bewirb dich bei uns: https://www.mittwald.de/karriere",
    "font-size: 30px;",
    "font-size: inherit;",
  );
};
