import { color } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import styled from "styled-components";

export const RestAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color.base.hostingBlue.$25};
  color: ${color.base.hostingBlue.$900};
  font-size: 16px;
  word-break: break-word;
  white-space: nowrap;
  flex-shrink: 0;
  line-height: 20px;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: bold;
`;
