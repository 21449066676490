import { Banner } from "@mittwald/flow-components/dist/components/Banner";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { Placeholder } from "@mittwald/flow-components/dist/components/PlaceholderBox";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { VisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconExtension } from "@mittwald/flow-icons/dist/extension";
import React, { FC } from "react";
import Extension from "../../../../../../model/marketplace/Extension";
import ExtensionList from "../../../../../../model/marketplace/ExtensionList";
import { AnyProject } from "../../../../../../model/project";
import MarketplaceUI from "../../../../../../model/ui/marketplace/MarketplaceUI";
import AdditionalExtensionsInCustomerInfo from "../../../pages/ExtensionInstanceOverview/components/AdditionalExtensionsInCustomerInfo";
import InfoBoxContent from "../../components/InfoBoxContent";
import MembersWithPermission from "./MembersWithPermission";

interface Props {
  extension?: Extension;
  availableExtensions: ExtensionList;
  project: AnyProject;
  visibility: VisibilityController;
}

const ExtensionStep: FC<Props> = (props) => {
  const { extension, availableExtensions, project, visibility } = props;

  const extensionOptions =
    MarketplaceUI.getExtensionOptions(availableExtensions);

  const role = project.useMyRole().name;

  return (
    <WizardStep
      disabledPrimaryAction={
        role !== "owner" || availableExtensions.items.length === 0
      }
      id="extension"
      indicatorText="extensionStep"
      infoBoxContent={extension && <InfoBoxContent extension={extension} />}
    >
      <Section.Item>
        {role !== "owner" && (
          <Banner
            description="projectPermissionsRequiredDescription"
            headline="permissionsRequired"
            textLink={
              role === "emailadmin"
                ? undefined
                : {
                    title: "showMembers",
                    action: () =>
                      showModal(
                        "membersWithPermission",
                        <MembersWithPermission projectId={project.id} />,
                      ),
                  }
            }
          />
        )}
        {extensionOptions.length > 0 ? (
          <SelectBox
            minOptionWidth={200}
            name="extensionId"
            options={extensionOptions}
            rules={{ required: true }}
          />
        ) : (
          <Placeholder headline="noExtensions" icon={iconExtension} />
        )}
        <AdditionalExtensionsInCustomerInfo
          project={project}
          visibilityController={visibility}
        />
      </Section.Item>
    </WizardStep>
  );
};

export default ExtensionStep;
