import { faSparkles } from "@fortawesome/pro-regular-svg-icons/faSparkles";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import React, { FC } from "react";

interface Props {
  description: string;
}

export const AiDescription: FC<Props> = (props) => {
  return (
    <TextWithIconLayout
      icon={faSparkles}
      iconProps={{ _color: "hostingBlue-$600" }}
    >
      <Text _mt="m" i18n={{ text: props.description }} small />
    </TextWithIconLayout>
  );
};
