import { iconMembers } from "@mittwald/flow-icons/dist/members";
import { allProjectRoles } from "../../project/ProjectRole";

export class ProjectMembershipUI {
  public static readonly icon = iconMembers;

  public static roleSelectBoxOptions = allProjectRoles
    .filter((role) => role !== "notset")
    .map((role) => ({
      title: `${role}ProjectRole`,
      value: role,
    }));
}

export default ProjectMembershipUI;
