import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import { iconProject } from "@mittwald/flow-icons/dist/project";
import { iconServer } from "@mittwald/flow-icons/dist/server";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";

import React, { FC } from "react";
import { IconList } from "../../../../../components/IconList/IconList";
import Customer from "../../../../../model/customer/Customer";

interface Props {
  customer: Customer;
}

export const DeleteCustomer: FC<Props> = (props) => {
  const goToLink = useGotoLink();
  const visibility = useVisibilityController();
  const customer = props.customer;
  const hasServers = !customer.useServers().isEmpty;
  const hasProjects = !customer.useProjects().isEmpty;
  const hasInvoices = customer.useHasOpenInvoices();
  const isBankrupt = customer.useIsBankrupt();

  const hasDependencies =
    hasInvoices || hasServers || hasProjects || isBankrupt;

  const primaryAction: AnyAction = [
    async () => {
      goToLink("customersOverview");
      await customer.delete();
      visibility.hide();
    },
    visibility.hide,
    {
      link: {
        id: "customersOverview",
        options: { pathParams: { customerId: customer.id } },
      },
    },
  ];

  return (
    <DefaultModal
      _size={hasDependencies ? "m" : "s"}
      description={{
        id: "description",
        values: { customer: customer.name },
      }}
      headline={hasDependencies ? "canNotDelete" : "delete"}
      headlineIcon={iconDelete}
      primary={{
        action: primaryAction,
        disabled: hasDependencies,
        destructive: true,
        text: "deleteCustomer",
      }}
      visibility={visibility}
    >
      {(hasServers || hasProjects) && (
        <Section.Item>
          <Text i18n="componentsDescription" />

          <IconList>
            {hasServers && <IconList.Item i18n="servers" icon={iconServer} />}
            {hasProjects && (
              <IconList.Item i18n="projects" icon={iconProject} />
            )}
          </IconList>
        </Section.Item>
      )}
      {hasInvoices && (
        <Section.Item>
          <Text i18n="invoicesDescription" />
        </Section.Item>
      )}
    </DefaultModal>
  );
};

export default DeleteCustomer;
