import { DateTime } from "@mittwald/flow-components/dist/components/DateTimeText";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";

import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import React, { FC } from "react";
import Backup from "../../../../../../model/backup/Backup";

interface Props {
  backup: Backup;
}

export const DeleteBackup: FC<Props> = (props) => {
  const { backup } = props;
  const goto = useGotoLink();
  const visibility = useVisibilityController();

  const deleteBackup = async (): Promise<void> => {
    await backup.delete();
    goto("backupOverview", undefined, { replace: true });
  };

  return (
    <DefaultModal
      _size="s"
      description={
        backup.data.deletable
          ? {
              id: "description",
              values: {
                backup: backup.description,
                date: <DateTime value={backup.data.createdAt} />,
              },
            }
          : "description"
      }
      headline={backup.data.deletable ? "deleteBackup" : "canNotDeleteBackup"}
      headlineIcon={iconDelete}
      primary={{
        action: [deleteBackup, visibility.hide],
        destructive: true,
        disabled: !backup.data.deletable,
      }}
      visibility={visibility}
    />
  );
};

export default DeleteBackup;
