import { Box } from "@mittwald/flow-components/dist/components/Box";
import {
  FileDropzone,
  useFileDropzone,
} from "@mittwald/flow-components/dist/components/FileDropzone";
import {
  MultiFileUploadList,
  useMultiFileUploadController,
} from "@mittwald/flow-components/dist/components/MultiFileUploadList";
import React, { useEffect } from "react";
import styled from "styled-components";
import { mittwaldApi } from "../../../../../../api/Mittwald";
import Conversation from "../../../../../../model/support/Conversation";

interface FileDropzoneComponentProps {
  conversation: Conversation;
  onFilesUploaded: (fileIds: string[]) => void;
}

const StyledFileDropzone = styled(FileDropzone)`
  height: 128px;

  & > div {
    margin: 0;
  }
`;

export const FileDropzoneComponent: React.FC<FileDropzoneComponentProps> = (
  props,
) => {
  const { conversation, onFilesUploaded } = props;

  const fileUploadController = useMultiFileUploadController({
    fieldName: "file",
    targetUrl: `${import.meta.env.VITE_APP_API_URI}/v2/files`,
    uploadTokenFn: () => conversation.requestFileUploadToken(),
  });

  const rules = mittwaldApi.fileGetFileUploadTypeRules
    .getResource({
      path: { fileUploadType: "conversation" },
    })
    .useWatchData();

  const acceptedTypes = Object.fromEntries(
    rules.mimeTypes.map((type) => [type, []]),
  );

  /* eslint-disable @typescript-eslint/naming-convention */
  const fileDropController = useFileDropzone({
    maxSize: 5000 * 1000,
    maxFiles: 6,
    accept: acceptedTypes,
  });

  useEffect(() => {
    fileUploadController.upload(...fileDropController.acceptedFiles);
  }, [fileDropController.acceptedFiles]);

  useEffect(() => {
    const filesUploaded = fileUploadController.getFilesByState("done");
    const fileIds = filesUploaded.map((f) => f.uploadToken).map(String);
    onFilesUploaded(fileIds);
  }, [fileUploadController.files]);

  return (
    <Box _display="flex" _itemGap="m">
      {fileUploadController.files.length > 0 && (
        <MultiFileUploadList
          _flexDirection="row"
          _flexWrap
          uploadController={fileUploadController}
        />
      )}
      <StyledFileDropzone dropController={fileDropController} />
    </Box>
  );
};
