import { H3 } from "@mittwald/flow-components/dist/components/H3";
import { InlineCode } from "@mittwald/flow-components/dist/components/InlineCode";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation/StaticInformation";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";

export interface CronjobFileUrlFormProps {
  url: string;
}

export const CronjobFileUrlForm: FC<CronjobFileUrlFormProps> = (props) => {
  const generatedUrlCommand = "curl".concat(" ", props.url);

  return (
    <TranslationProvider name="cronjobFileForm" type="section">
      <TextField
        label="url"
        name="destination.url"
        placeholder={{ text: "https://" }}
        rules={{ required: true }}
        type="url"
      />

      <H3 _mt="l" i18n="command" />

      <Text i18n="commandDescription" />
      {generatedUrlCommand.length > 0 && (
        <StaticInformation
          copyContent={generatedUrlCommand}
          label="generatedCommand"
          text={<InlineCode>{generatedUrlCommand}</InlineCode>}
        />
      )}
    </TranslationProvider>
  );
};

export default CronjobFileUrlForm;
