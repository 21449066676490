import { PublicKeyApiData } from "./types";

export class PublicKey {
  public readonly data: PublicKeyApiData;
  public readonly key: string;
  public readonly comment: string;

  private constructor(data: PublicKeyApiData) {
    this.data = Object.freeze(data);
    this.key = data.key;
    this.comment = data.comment;
  }

  public static fromApiData(data: PublicKeyApiData): PublicKey {
    return new PublicKey(data);
  }
}
