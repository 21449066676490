import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import React, { FC } from "react";
import { iconContractPartner } from "@mittwald/flow-icons/dist/contractPartner";
import Customer, {
  CustomerUpdateInputs,
} from "../../../../../model/customer/Customer";
import CustomerContact from "../../../../../model/customer/CustomerContact";
import ContactFormInputs from "../../contractPartner/components/ContactFormInputs";

interface Props {
  customer: Customer;
}

export const CreateContractPartner: FC<Props> = (props) => {
  const visibility = useVisibilityController();

  const ownerDefaultValues = CustomerContact.useGetContactDefaultValues();

  const form = useForm<CustomerUpdateInputs>({
    translationKey: "newContractPartner",
    showSuccessFeedback: true,
    defaultValues: { owner: ownerDefaultValues },
    onSubmit: async (values) => {
      await props.customer.createContractPartner(values);
    },
  });

  return (
    <DefaultModal
      description="description"
      headline="createContractPartner"
      headlineIcon={iconContractPartner}
      primary={{
        ok: true,
        action: [{ form }, visibility.hide],
      }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Item>
          <ContactFormInputs type="owner" />
        </Section.Item>
      </Form>
    </DefaultModal>
  );
};

export default CreateContractPartner;
