import { DateTime } from "@mittwald/flow-components/dist/components/DateTimeText";
import Translate from "@mittwald/flow-components/dist/components/Translate";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";
import TariffChange from "../../../../../model/contract/TariffChange";

interface Props {
  tariffChange: TariffChange;
}

export const TariffChangeInfoText: FC<Props> = (props) => {
  const { tariffChange } = props;

  return (
    <TranslationProvider name="TariffChangeInfoText" type="component">
      <Translate
        i18n={{
          id: "text",
          values: {
            date: <DateTime format="date" value={tariffChange.targetDate} />,
          },
        }}
      />
    </TranslationProvider>
  );
};

export default TariffChangeInfoText;
