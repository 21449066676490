import { faNewspaper } from "@fortawesome/pro-regular-svg-icons/faNewspaper";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import SubscribeToNewsletter from "../../../pages/app/profile/personalData/modals/SubscribeToNewsletter/SubscribeToNewsletter";
import Newsletter from "../../newsletter/Newsletter";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";
import React from "react";

const defaultNewsletterRoles = [Role.anybody];

ModelActionBuilderRegistry.register({
  model: Newsletter,
  method: "subscribe",
  roles: defaultNewsletterRoles,
  altAction: () => () =>
    showModal("subscribeToNewsletter", <SubscribeToNewsletter />),
}).register({
  model: Newsletter,
  method: "unsubscribe",
  roles: defaultNewsletterRoles,
  altAction: (action) => ({
    action,
    confirmation: {
      modalTranslationKey: "unsubscribeFromNewsletter",
      required: true,
      modalProps: { headlineIcon: faNewspaper },
    },
  }),
});
