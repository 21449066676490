import { useLinkId } from "@mittwald/flow-lib/dist/hooks/useLinkId";
import React, { useEffect } from "react";
import { mittwaldApi } from "../../api/Mittwald";
import useImpersonated from "../../hooks/useImpersonated";
import NotificationList from "../../model/notification/NotificationList";
import { autoNotificationReaderSeverityConfig } from "./AutoNotificationReaderConfiguration";
import {
  notificationRelationFilter,
  notificationSeverityFilter,
} from "./misc/filter";
import { getAggregatesFromUrl } from "./misc/getAggregatesFromUrl";

interface AutoNotificationReaderProps {}

export const AutoNotificationReader: React.FC<
  AutoNotificationReaderProps
> = () => {
  const notifications = NotificationList.useUnread().useItems();
  const linkId = useLinkId();
  const isImpersonated = useImpersonated();

  useEffect(() => {
    if (isImpersonated) {
      return;
    }

    const path = new URL(window.location.href).pathname;
    getAggregatesFromUrl(path).forEach((reference) => {
      const filteredNotifications = notifications
        .filter(notificationSeverityFilter)
        .filter((n) => notificationRelationFilter(n, reference));

      if (filteredNotifications.length === 0) {
        return;
      }

      void mittwaldApi.notificationsReadAllNotifications.request({
        query: {
          referenceId: reference.id,
          referenceAggregate: reference.aggregate,
          referenceDomain: reference.domain,
          severities: autoNotificationReaderSeverityConfig,
        },
      });
    });
  }, [linkId]);

  return null;
};
