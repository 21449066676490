import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import React, { FC } from "react";

export const ExtensionConditionsCheckbox: FC = () => {
  const conditionsLink = (
    <TextLink
      action={import.meta.env.VITE_CONDITIONS_LINK}
      i18n="conditions"
      target="_blank"
    />
  );

  const privacySaasLink = (
    <TextLink
      action={import.meta.env.VITE_PRIVACY_SAAS_LINK}
      i18n="privacySaas"
      target="_blank"
    />
  );

  const checkboxTitle = (
    <Text
      i18n={{
        id: "extensionCheckbox",
        values: {
          conditions: conditionsLink,
          privacySaas: privacySaasLink,
        },
      }}
      small
    />
  );

  return (
    <CheckBox
      name="conditions"
      rules={{ required: true }}
      title={checkboxTitle}
    />
  );
};
