import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import React from "react";
import DeleteProfile from "../../../pages/app/profile/personalData/modals/DeleteProfile/DeleteProfile";
import UpdateProfileAvatar from "../../../pages/app/profile/personalData/modals/UpdateProfileAvatar/UpdateProfileAvatar";
import UpdateProfileEmailAddress from "../../../pages/app/profile/personalData/modals/UpdateProfileEmailAddress/UpdateProfileEmailAddress";
import UpdateProfilePhoneNumber from "../../../pages/app/profile/personalData/modals/UpdateProfilePhoneNumber/UpdateProfilePhoneNumber";
import ActivateMfa from "../../../pages/app/profile/security/modals/ActivateMfa/ActivateMfa";
import DeactivateMfa from "../../../pages/app/profile/security/modals/DeactivateMfa/DeactivateMfa";
import ResetRecoveryCodes from "../../../pages/app/profile/security/modals/ResetRecoveryCodes/ResetRecoveryCodes";
import { Role } from "../acl/Role";
import Profile from "../../signup/Profile";
import { Signup } from "../../signup/Signup";
import User from "../../user/User";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultProfileRoles = [Role.anybody];

ModelActionBuilderRegistry.register({
  model: Profile,
  method: "delete",
  roles: defaultProfileRoles,
  altAction: () => () => showModal("deleteProfile", <DeleteProfile />),
})
  .register({
    model: User,
    method: "deletePhoneNumber",
    roles: defaultProfileRoles,
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deletePhoneNumber",
        required: true,
      },
    }),
  })
  .register({
    model: Signup,
    method: "updateEmailAddress",
    roles: defaultProfileRoles,
    altAction: () => () =>
      showModal("updateProfileEmailAddress", <UpdateProfileEmailAddress />),
  })
  .register({
    model: Signup,
    method: "confirmMfa",
    roles: defaultProfileRoles,
    altAction: () => () => showModal("activateMfa", <ActivateMfa />),
  })
  .register({
    model: Signup,
    method: "removeMfa",
    roles: defaultProfileRoles,
    altAction: () => () => showModal("deactivateMfa", <DeactivateMfa />),
  })
  .register({
    model: Signup,
    method: "resetRecoveryCodes",
    roles: defaultProfileRoles,
    altAction: () => () =>
      showModal("resetRecoveryCodes", <ResetRecoveryCodes />),
  })
  .register({
    model: User,
    method: "addPhoneNumber",
    roles: defaultProfileRoles,
    altAction: () => () =>
      showModal("updateProfilePhoneNumber", <UpdateProfilePhoneNumber />),
  })
  .register({
    model: User,
    method: "requestAvatarUpload",
    roles: defaultProfileRoles,
    altAction: () => () =>
      showModal("updateProfileAvatar", <UpdateProfileAvatar />),
  });
