import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import invariant from "invariant";
import React, { FC, useEffect, useState } from "react";
import Extension from "../../../../../model/marketplace/Extension";
import ExtensionInstance from "../../../../../model/marketplace/ExtensionInstance";
import ExtensionList from "../../../../../model/marketplace/ExtensionList";
import { Project } from "../../../../../model/project";
import ScopesStep from "../components/ScopesStep";
import ConfirmationStep from "./components/ConfirmationStep";
import ExtensionStep from "./components/ExtensionStep";

export interface AddExtensionToContextFormValues {
  extensionId: string;
  conditions: boolean;
}

const AddExtensionToProjectContext: FC = () => {
  const [extension, setExtension] = useState<Extension>();
  const goto = useGotoLink();

  const visibility = useVisibilityController();

  const projectId = usePathParams("projectId").projectId;

  const project = Project.useLoadById(projectId);

  const availableExtensions = ExtensionList.useLoadAllAvailable(
    "project",
    projectId,
  );

  const form = useForm<AddExtensionToContextFormValues>({
    defaultValues: { extensionId: availableExtensions.items[0]?.id },
    onSubmit: async (values) => {
      invariant(extension, "extension not found");

      const response = await ExtensionInstance.addToContext({
        extensionId: values.extensionId,
        consentedScopes: extension.data.scopes,
        contextId: projectId,
        context: "project",
      });

      goto("projectExtensionInstance", {
        extensionInstanceId: response,
      });
    },
  });

  const watchedExtensionId = form.watch("extensionId");

  useEffect(() => {
    if (watchedExtensionId) {
      setExtension(availableExtensions.findExtension(watchedExtensionId));
    } else {
      setExtension(undefined);
    }
  }, [watchedExtensionId]);

  return (
    <WizardModal
      _size="l"
      form={form}
      steps={["extension", "scopes", "confirmation"]}
      visibility={visibility}
    >
      <ExtensionStep
        availableExtensions={availableExtensions}
        extension={extension}
        project={project}
        visibility={visibility}
      />
      <ScopesStep extension={extension} />
      <ConfirmationStep customerId={project.customerId} extension={extension} />
    </WizardModal>
  );
};

export default AddExtensionToProjectContext;
