import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import List from "@mittwald/flow-components/dist/components/List";
import Section from "@mittwald/flow-components/dist/components/Section";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import ValidationErrorMessage from "@mittwald/flow-components/dist/components/ValidationErrorMessage";
import { iconPlus } from "@mittwald/flow-icons/dist/plus";
import React, { FC } from "react";
import { useFieldArray } from "react-hook-form";
import { NewSftpUserInputs } from "../../../../../model/access/types";
import { ProjectDir } from "../../../../../model/project";
import { ProjectFileSystemPathField } from "../../../components/ProjectFileSystemPathField";

interface Props {
  noDirectories?: boolean;
  projectId: string;
}

export const DirectoryHeavyList: FC<Props> = (props) => {
  const form = useFormContext<NewSftpUserInputs>();

  const { fields, append, remove } = useFieldArray<any>({
    name: "directories",
    control: form.control,
  });

  const noWatchedDirectories = fields.length <= 0;

  const appendButton = (
    <TextWithIconLayout icon={iconPlus}>
      <TextLink action={() => append({ path: "/" })} i18n="addPath" />
    </TextWithIconLayout>
  );

  return (
    <TranslationProvider name="directoryHeavyList" type="component">
      <Section.Item
        headline="directorySelection"
        headlineContent={appendButton}
      >
        {fields.map((s, index) => (
          <List.HeavyItem
            closeAction={fields.length > 1 ? () => remove(index) : undefined}
            key={s.id}
          >
            <ProjectFileSystemPathField
              label="path"
              name={`directories.${index}.path`}
              onlyDirectories
              projectId={props.projectId}
              restrictToProjectDirectory={ProjectDir.home}
            />
          </List.HeavyItem>
        ))}
        {props.noDirectories && noWatchedDirectories && (
          <ValidationErrorMessage i18n="noDirectories" />
        )}
      </Section.Item>
    </TranslationProvider>
  );
};
export default DirectoryHeavyList;
