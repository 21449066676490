import Box from "@mittwald/flow-components/dist/components/Box/Box";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import TextWithIconLayout from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { iconPlus } from "@mittwald/flow-icons/dist/plus";
import React, { FC, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { ContainerImageConfigEnv } from "../../../../../model/container/Container";
import { AiGradientText } from "../AiGradientText/AiGradientText";
import HeavyListPlaceholder from "../HeavyListPlaceholder";
import { EnvironmentHeavyListEntry } from "./EnvironmentHeavyListEntry";

interface Props {
  suggestedEnvs?: ContainerImageConfigEnv[];
}

export const EnvironmentHeavyList: FC<Props> = (props) => {
  const { suggestedEnvs = [] } = props;
  const form = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: "environments",
    control: form.control,
  });
  const [aiGeneratedStates, setAiGeneratedStates] = useState<boolean[]>([]);
  const hasAiGeneratedData = aiGeneratedStates.some((s) => s);

  const appendItem = (): void => {
    append({ key: "", value: "" });
  };

  return (
    <TranslationProvider name="environmentHeavyList" type="component">
      {fields.length > 0 && (
        <Box
          _flexDirection="row"
          _justifyContent={hasAiGeneratedData ? "space-between" : "flex-end"}
          _mt="m"
        >
          {hasAiGeneratedData && <AiGradientText i18n="aiAssisted" withIcon />}
          <TextWithIconLayout icon={iconPlus}>
            <TextLink action={appendItem} i18n="addEnvironment" />
          </TextWithIconLayout>
        </Box>
      )}
      {fields.length === 0 && (
        <HeavyListPlaceholder
          action={appendItem}
          actionTitle="addEnv"
          description="noEnvsDescription"
          headline="noEnvs"
        />
      )}
      {fields.map((s, index) => (
        <EnvironmentHeavyListEntry
          index={index}
          key={s.id}
          remove={remove}
          setAiGeneratedStates={setAiGeneratedStates}
          suggestedEnvs={suggestedEnvs}
          {...props}
        />
      ))}
    </TranslationProvider>
  );
};
