import { Box } from "@mittwald/flow-components/dist/components/Box";
import { Switch } from "@mittwald/flow-components/dist/components/Switch";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import React, { FC } from "react";

interface Props {
  name: string;
  link?: AnyAction;
  description?: I18nDefinition;
}

const DashboardSetting: FC<Props> = (props) => {
  const { link, description, name } = props;

  return (
    <Box _alignItems="flex-start" _flexDirection="row" _itemGap="m">
      <Box _noShrink>
        <Switch name={name} noLabel />
      </Box>
      <Box _alignItems="flex-start" _flexDirection="column" _itemGap="xs">
        <Text i18n={name} strong />
        <Text i18n={description ?? joinTranslationKeys(name, "description")} />
        {link && (
          <TextLink action={link} i18n={joinTranslationKeys(name, "link")} />
        )}
      </Box>
    </Box>
  );
};

export default DashboardSetting;
