import { useForm } from "@mittwald/flow-components/dist/components/Form";
import React, { FC } from "react";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { CreateContextStateProvider } from "../../../../../../hooks/useContextState";
import SSLCertificate from "../../../../../../model/domain/ssl/SSLCertificate";
import { DifferencesStep } from "./components/DifferencesStep/DifferencesStep";
import {
  PrivateKeyStep,
  usePrivateKeyState,
} from "./components/PrivateKeyStep/PrivateKeyStep";
import {
  CertificateStep,
  useCertificateStepState,
} from "./components/CertificateStep/CertificateStep";

export interface EditCertificateProps {
  certificate: SSLCertificate;
}

const EditCertificateWrapper: FC<EditCertificateProps> = (props) => {
  const { certificate } = props;

  const [privateKeyStepState] = usePrivateKeyState();
  const [certificateStepState] = useCertificateStepState();

  const form = useForm({
    onSubmit: async () => {
      await certificate.editCertificate(
        certificateStepState.certificateData,
        privateKeyStepState.privateKeyData,
      );
    },
  });

  return (
    <WizardModal
      form={form}
      steps={["certificate", "privateKey", "differences"]}
    >
      <CertificateStep certificate={certificate} />
      <PrivateKeyStep certificate={certificate} />
      <DifferencesStep certificate={certificate} />
    </WizardModal>
  );
};

const EditCertificate: FC<EditCertificateProps> = (props) => {
  return (
    <CreateContextStateProvider>
      <EditCertificateWrapper {...props} />
    </CreateContextStateProvider>
  );
};

export default EditCertificate;
