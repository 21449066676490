import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import React from "react";
import { CreateDatabaseWizard } from "../../../pages/app/database/modals/CreateDatabase/CreateDatabase";
import { CreateDatabaseUserWizard } from "../../../pages/app/database/modals/CreateDatabaseUser/CreateDatabaseUser";
import { CanNotDeleteDatabaseModal } from "../../../pages/app/database/modals/DeleteDatabase/CanNotDeleteDatabase";
import { DeleteDatabaseModal } from "../../../pages/app/database/modals/DeleteDatabase/DeleteDatabase";
import { UpdateDatabaseUserPasswordModal } from "../../../pages/app/database/modals/UpdateDatabaseUserPassword/UpdateDatabaseUserPassword";
import { Database } from "../../database/Database";
import { DatabaseList } from "../../database/DatabaseList";
import { MySqlDatabase } from "../../database/MySqlDatabase";
import { MySqlUser } from "../../database/MySqlUser";
import RedisDatabase from "../../database/RedisDatabase";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultDatabaseRoles = [Role.projectAdmin, Role.projectDeveloper];

ModelActionBuilderRegistry.register({
  model: DatabaseList,
  roles: defaultDatabaseRoles,
  method: "useLoadAllDatabasesByProjectId",
})
  .register({
    model: RedisDatabase,
    roles: defaultDatabaseRoles,
    method: "delete",
    altAction: (action) => () =>
      showModal(
        "deleteDatabase",
        action.model.linkedAppInstallationId ? (
          <CanNotDeleteDatabaseModal database={action.model} />
        ) : (
          <DeleteDatabaseModal database={action.model} />
        ),
      ),
  })
  .register({
    model: MySqlDatabase,
    roles: defaultDatabaseRoles,
    method: "delete",
    altAction: (action) => () =>
      showModal(
        "deleteDatabase",
        action.model.linkedAppInstallationId ? (
          <CanNotDeleteDatabaseModal database={action.model} />
        ) : (
          <DeleteDatabaseModal database={action.model} />
        ),
      ),
  })
  .register({
    model: MySqlUser,
    roles: defaultDatabaseRoles,
    method: "deleteDatabaseUser",
    isAvailable: (databaseUser) => !databaseUser.isMainUser,
    altAction: (action) => ({
      action,
      confirmation: {
        required: true,
        modalTranslationKey: "deleteDatabaseUser",
        modalProps: {
          headlineIcon: iconDelete,
        },
        modalTranslationValues: {
          databaseUser: action.model.data.description,
        },
      },
    }),
  })
  .register({
    model: MySqlDatabase,
    roles: defaultDatabaseRoles,
    method: "createUser",
    altAction: (action) => () =>
      showModal(
        "createDatabaseUser",
        <CreateDatabaseUserWizard database={action.model} />,
      ),
  })
  .register({
    model: MySqlDatabase,
    roles: defaultDatabaseRoles,
    method: "openPhpMyAdmin",
  })
  .register({
    model: Database,
    roles: defaultDatabaseRoles,
    method: "createNewDatabase",
    altAction: () => () =>
      showModal("createDatabase", <CreateDatabaseWizard />),
  })
  .register({
    model: MySqlUser,
    roles: defaultDatabaseRoles,
    method: "updatePassword",
    altAction: (action) => () =>
      showModal(
        "updateDatabaseUserPassword",
        <UpdateDatabaseUserPasswordModal databaseUser={action.model} />,
      ),
  })
  .register({
    model: MySqlUser,
    roles: defaultDatabaseRoles,
    method: "checkIsMainUser",
    isAvailable: (databaseUser) => !databaseUser.isMainUser,
  });
