import { Translate } from "@mittwald/flow-components/dist/components/Translate";
import React, { FC } from "react";
import AppInstallation from "../../../../model/app/AppInstallation";

interface Props {
  appInstallation: AppInstallation;
}

const AppTitleWithVersion: FC<Props> = ({ appInstallation }) => {
  const app = appInstallation.useApp();
  const appVersion = appInstallation.useVersion();

  return (
    <Translate
      i18n={{
        id: `app.titleWithVersion.${app.slug}`,
        fallbackElement: appVersion.name,
        values: { version: appVersion.data.externalVersion },
      }}
    />
  );
};

export default AppTitleWithVersion;
