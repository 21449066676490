import { faDirections } from "@fortawesome/pro-regular-svg-icons/faDirections";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import { combine } from "@mittwald/flow-lib/dist/access/combine";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import React, { lazy } from "react";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import DnsZone from "../../../model/dns/DnsZone";
import Ingress from "../../../model/domain/Ingress";
import { IngressList } from "../../../model/domain/IngressList";
import SSLCertificate from "../../../model/domain/ssl/SSLCertificate";
import Project from "../../../model/project/Project";
import UploadCertificate from "./ssl/modals/UploadCertificate/UploadCertificate";

export const domainPages = PageTree.build(BacksideRenderer, {
  "/app/projects/:projectId/domain": {
    sector: "basics",
    icon: iconDomain,
    props: {
      navigationSection: "component",
      tabNav: true,
      new: true,
    },
    linkId: "domains",
    accessCheck: () =>
      Project.useLoadByPathParam().useCheckMyRoleIsIn(["owner", "external"]),
  },
  "/app/projects/:projectId/domain/domains": {
    views: {
      main: lazy(() => import("./overview")),
    },
    props: {
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                Project.useLoadByPathParam(),
                "createIngress",
              ),
          },
          title: "createDomain",
          icon: iconDomain,
        },
      ],
    },
    linkId: "domainOverview",
  },
  "/app/projects/:projectId/domain/domains/:ingressId": {
    views: {
      pageTitle: lazy(() => import("./components/domainTitle")),
    },
    props: {
      tabNav: true,
    },
    linkId: "domain",
  },
  "/app/projects/:projectId/domain/domains/:ingressId/details": {
    views: {
      main: lazy(() => import("./general/general")),
    },
    linkId: "domainDetails",
    hooks: {
      useCheckState: () => {
        const ingressState =
          Ingress.useLoadByPathParam().getCurrentGeneralState();
        return ingressState?.type ?? null;
      },
    },
  },
  "/app/projects/:projectId/domain/domains/:ingressId/dnsRecommendations": {
    views: {
      main: lazy(() => import("./dns/components/DnsRecommendations")),
    },
    linkId: "dnsRecommendations",
    accessCheck: combine.and(
      () => !DnsZone.useIsZonePresentForIngress(Ingress.useLoadByPathParam()),
      () => {
        const ingress = Ingress.useLoadByPathParam();
        return !IngressList.useLoadAllByProjectId(
          ingress.projectId,
        ).useIsDefaultIngress(ingress);
      },
    ),
    hooks: {
      useCheckState: () => {
        const dnsState = Ingress.useLoadByPathParam().getCurrentDnsState();
        return dnsState?.type ?? null;
      },
    },
  },
  "/app/projects/:projectId/domain/domains/:ingressId/dns": {
    views: {
      main: lazy(
        () => import("./dns/components/DnsResourceList/DnsResourceList"),
      ),
    },
    linkId: "dns",
    accessCheck: () =>
      DnsZone.useIsZonePresentForIngress(Ingress.useLoadByPathParam()),
  },
  "/app/projects/:projectId/domain/domains/:ingressId/dns/a-record": {
    views: {
      main: lazy(() => import("./dns/ARecord")),
    },
    linkId: "aRecord",
  },
  "/app/projects/:projectId/domain/domains/:ingressId/dns/mx-record": {
    views: {
      main: lazy(() => import("./dns/MxRecord")),
    },
    linkId: "mxRecord",
  },
  "/app/projects/:projectId/domain/domains/:ingressId/dns/txt-record": {
    views: {
      main: lazy(() => import("./dns/TxtRecord")),
    },
    linkId: "txtRecord",
  },
  "/app/projects/:projectId/domain/domains/:ingressId/dns/cname-record": {
    views: {
      main: lazy(() => import("./dns/CnameRecord")),
    },
    linkId: "cnameRecord",
  },
  "/app/projects/:projectId/domain/domains/:ingressId/dns/srv-record": {
    views: {
      main: lazy(() => import("./dns/SrvRecord")),
    },
    linkId: "srvRecord",
  },
  "/app/projects/:projectId/domain/domains/:ingressId/paths": {
    views: {
      main: lazy(() => import("./paths/paths")),
    },
    linkId: "domainPaths",
    props: {
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                Ingress.useLoadByPathParam(),
                "updatePaths",
              ),
          },
          title: "createPath",
          icon: faDirections,
        },
      ],
    },
  },
  "/app/projects/:projectId/domain/domains/:ingressId/paths/:domainPathIndex": {
    views: {
      pageTitle: lazy(() => import("./components/domainTitle")),
      main: lazy(() => import("./paths/pathDetails")),
    },
    linkId: "domainPath",
  },
  "/app/projects/:projectId/domain/domains/:ingressId/owner": {
    views: {
      main: lazy(() => import("./owner/owner")),
    },
    accessCheck: () => !!Ingress.useLoadByPathParam().domain,
    hooks: {
      useCheckState: () => {
        const contactDetailsState =
          Ingress.useLoadByPathParam().getCurrentContactDetailsState();
        return contactDetailsState?.type ?? null;
      },
    },
    linkId: "domainOwner",
  },

  "/app/projects/:projectId/domain/ssl": {
    views: {
      main: lazy(() => import("./ssl/overview")),
    },
    props: {
      ctaPages: [
        {
          action: () => showModal("uploadCertificate", <UploadCertificate />),
          title: "createCertificate",
          icon: iconDomain,
        },
      ],
      new: true,
    },
    linkId: "sslCertificatesOverview",
  },
  "/app/projects/:projectId/domain/ssl/:certificateId": {
    views: {
      pageTitle: lazy(
        () => import("./ssl/details/components/SSLCertificateTitle"),
      ),
    },
    props: {
      tabNav: true,
    },
  },
  "/app/projects/:projectId/domain/ssl/:certificateId/details": {
    views: {
      main: lazy(() => import("./ssl/details/details")),
    },
    hooks: {
      useCheckState: () => {
        const generalState =
          SSLCertificate.useLoadByPathParam().getCurrentGeneralState();
        return generalState?.type ?? null;
      },
    },
    linkId: "sslCertificate",
  },
  "/app/projects/:projectId/domain/ssl/:certificateId/linked": {
    views: {
      main: lazy(() => import("./ssl/details/linked/linked")),
    },
    hooks: {
      useCheckState: () => {
        const generalState =
          SSLCertificate.useLoadByPathParam().useCurrentLinkedState();
        return generalState?.type ?? null;
      },
    },
    linkId: "sslCertificateLinkedIngresses",
  },
});
