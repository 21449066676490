import { useTranslation } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import usePopover from "@mittwald/flow-components/dist/hooks/usePopover";
import { iconSupport } from "@mittwald/flow-icons/dist/support";
import React, { FC, Suspense, useState } from "react";
import * as Styled from "../../styled";
import CreateSupportRequestModal from "./components/CreateSupportRequestModal";
import { HelpdeskContextMenu } from "./components/HelpdeskContextMenu/HelpdeskContextMenu";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";

export const HelpdeskButton: FC = () => {
  const popover = usePopover();
  const [currentSupportPin, setCurrentSupportPin] = useState<
    string | undefined
  >(undefined);
  const translate = useTranslation();

  const createSupportRequestModalVisibility = useVisibilityController();

  return (
    <TranslationProvider name="helpdesk" type="component">
      <Suspense fallback={null}>
        <CreateSupportRequestModal
          visibility={createSupportRequestModalVisibility}
        />

        <HelpdeskContextMenu
          currentSupportPin={currentSupportPin}
          popover={popover}
          updateCurrentSupportPin={(pin) => {
            setCurrentSupportPin(pin);
          }}
          onOpenCreateSupportRequestModal={() => {
            createSupportRequestModalVisibility.show();
            popover.visibility.hide();
          }}
        />
      </Suspense>

      <Styled.HeaderButton
        action={() => popover.visibility.toggle()}
        aria-label={translate("support")}
        icon={iconSupport}
        ref={popover.referenceElement}
      />
    </TranslationProvider>
  );
};
