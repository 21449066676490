import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { VisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import sleep from "@mittwald/flow-lib/dist/lib/sleep";
import React, { FC } from "react";
import { AnyProject } from "../../../../../../model/project";

interface Props {
  visibilityController?: VisibilityController;
  center?: boolean;
  project: AnyProject;
}

export const AdditionalExtensionsInCustomerInfo: FC<Props> = (props) => {
  const { visibilityController, center, project } = props;

  const buildLink = useLinkBuilder();
  const goto = useGotoLink();

  const hasAccess = project.useMyMembership().data.inherited;

  if (!hasAccess) {
    return null;
  }

  return (
    <TranslationProvider name="additionalExtensionsInCustomer" type="component">
      <Text
        _alignSelf={center ? "center" : "flex-end"}
        center={center}
        i18n={{
          id: "text",
          values: {
            link: (
              <TextLink
                action={
                  visibilityController
                    ? async () => {
                        visibilityController.hide();
                        // avoids missing path parameter error
                        await sleep(0.5);
                        goto("customerExtensions", {
                          customerId: project.customerId,
                        });
                      }
                    : buildLink("customerExtensions", {
                        customerId: project.customerId,
                      })
                }
                i18n="link"
              />
            ),
          },
        }}
      />
    </TranslationProvider>
  );
};

export default AdditionalExtensionsInCustomerInfo;
