import { faSparkles } from "@fortawesome/pro-regular-svg-icons/faSparkles";
import {
  Text,
  TextProps,
} from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";
import styled from "styled-components";
import { aiGradient } from "./aiGradient";

const GradientText = styled(Text)`
  & > * {
    ${aiGradient}
  }
`;

interface Props extends TextProps {
  withIcon?: boolean;
}

export const AiGradientText: FC<Props> = (props) => {
  const { withIcon, ...rest } = props;
  const textElement = <GradientText {...rest} _noShrink squished strong />;
  if (withIcon) {
    return (
      <TranslationProvider name="aiText" type="component">
        <TextWithIconLayout
          icon={faSparkles}
          iconProps={{ _color: "hostingBlue-$600" }}
        >
          {textElement}
        </TextWithIconLayout>
      </TranslationProvider>
    );
  }
  return textElement;
};
