import { Box } from "@mittwald/flow-components/dist/components/Box";
import { DateTime as DateTimeComponent } from "@mittwald/flow-components/dist/components/DateTimeText";
import { MoneyValue } from "@mittwald/flow-components/dist/components/MoneyValue";
import ResourceList, {
  ResourceListColumnRenderer,
  useResourceList,
} from "@mittwald/flow-components/dist/components/ResourceList";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { DateTime } from "luxon";
import React, { FC } from "react";
import ContractItemArticle from "../../../../../model/contract/ContractItemArticle";
import ContractItemArticleUi from "../../../../../model/ui/contract/ContractItemArticleUi";
import ArticleAttributeInfo from "../../../components/ArticleAttributeInfo";

interface Props {
  articles: ContractItemArticle[];
  freeTrialUntil?: DateTime;
}

const AdditionalInfoColumn: ResourceListColumnRenderer<ContractItemArticle> = (
  props,
) => {
  const { resource: itemArticle } = props;

  const article = itemArticle.useArticle();

  return (
    <Box _flexDirection="row" _itemGap="m">
      {article.attributes.map((a) => (
        <ArticleAttributeInfo
          articleAmount={itemArticle.amount}
          articleAttribute={a}
          key={a.key}
        />
      ))}
    </Box>
  );
};

export const ContractItemArticleList: FC<Props> = (props) => {
  const { articles, freeTrialUntil } = props;

  const PriceColumn: ResourceListColumnRenderer<ContractItemArticle> = (
    props,
  ) => (
    <Column
      text={
        freeTrialUntil
          ? {
              id: "priceWithFreeTrial",
              values: {
                price: (
                  <MoneyValue
                    value={props.resource.totalPrice.toMoneyValueInput()}
                  />
                ),
                date: (
                  <DateTimeComponent format="date" value={freeTrialUntil} />
                ),
              },
            }
          : props.resource.contractItem.isDomain
            ? {
                id: "yearlyPrice",
                values: {
                  price: (
                    <MoneyValue
                      value={props.resource.totalPrice.toYearlyMoneyValueInput()}
                    />
                  ),
                },
              }
            : {
                id: "monthlyPrice",
                values: {
                  price: (
                    <MoneyValue
                      value={props.resource.totalPrice.toMoneyValueInput()}
                    />
                  ),
                },
              }
      }
    />
  );

  const list = useResourceList<ContractItemArticle>({
    icon: (item) => ContractItemArticleUi.of(item).useIcon(),
    resources: articles,
    bodyColumns: [
      {
        id: "additionalInfoColumn",
        renderer: AdditionalInfoColumn,
      },
      {
        id: "priceColumn",
        renderer: PriceColumn,
      },
    ],
    primaryStatusIcon: () =>
      freeTrialUntil
        ? {
            type: "info",
            tooltip: {
              id: "freeTrial",
              values: {
                date: (
                  <DateTimeComponent format="date" value={freeTrialUntil} />
                ),
              },
            },
          }
        : undefined,
  });

  return (
    <TranslationProvider name="ContractItemArticlesList" type="component">
      <ResourceList
        {...list}
        columnLayout={{
          name: { maxWidth: 200 },
          body: [
            {
              key: "additionalInfoColumn",
            },
            {
              key: "priceColumn",
              responsivePriority: 1,
              minWidth: 150,
              maxWidth: 230,
            },
          ],
        }}
        hideTilesView
      />
    </TranslationProvider>
  );
};

export default ContractItemArticleList;
