import {
  Column,
  ResourceListColumnRenderer,
} from "@mittwald/flow-components/dist/components/ResourceList";
import React from "react";
import CustomerMembership from "../../../../../../../model/customer/CustomerMembership";
import CustomerUI from "../../../../../../../model/ui/customer/CustomerUI";

export const MemberRoleColumn: ResourceListColumnRenderer<
  CustomerMembership
> = ({ resource }) => (
  <Column
    icon={CustomerUI.getRoleIcon(resource.role.name)}
    text={`${resource.role.name}CustomerRole`}
    tooltip="role"
  />
);
