import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";
import React, { FC } from "react";
import { ProSpaceArticle } from "../../../../../../model/article";
import Bytes from "../../../../../../model/misc/Bytes";
import ConfigurationSections from "./ConfigurationSections";
import OrderPreviewBox from "./OrderPreviewBox/OrderPreviewBox";

interface Props {
  selectedProSpaceArticles: ProSpaceArticle[];
  selectedArticle: ProSpaceArticle;
  watchedStorageSize: Bytes;
  freeTrial: boolean;
}

export const ConfigurationStep: FC<Props> = (props) => {
  const {
    selectedArticle,
    watchedStorageSize,
    selectedProSpaceArticles,
    freeTrial,
  } = props;

  return (
    <TranslationProvider
      name="proSpaceConfigurationStepContent"
      type="component"
    >
      <WizardStep
        id="configuration"
        indicatorText="configurationStep"
        infoBoxContent={
          <Suspense loadingView={<LoadingView _height={250} />}>
            <OrderPreviewBox
              freeTrial={freeTrial}
              selectedArticle={selectedArticle}
              watchedStorageSize={watchedStorageSize}
            />
          </Suspense>
        }
      >
        <Suspense loadingView={<LoadingView _height={250} />}>
          <ConfigurationSections
            selectedArticle={selectedArticle}
            selectedProSpaceArticles={selectedProSpaceArticles}
          />
        </Suspense>
      </WizardStep>
    </TranslationProvider>
  );
};

export default ConfigurationStep;
