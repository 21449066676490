import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import React, { FC } from "react";
import {
  Cronjob,
  NewCronjobInputs,
} from "../../../../../model/cronjob/Cronjob";
import { cronjobDefaultTimeout } from "../../../../../model/cronjob/CronjobExecution";
import GeneralStep from "./components/GeneralStep";
import IntervalStep from "./components/IntervalStep";
import SettingsStep from "./components/SettingsStep";
import TypeStep from "./components/TypeStep";

export const CreateCronjob: FC = () => {
  const goto = useGotoLink();
  const { projectId } = usePathParams("projectId");

  const form = useForm<NewCronjobInputs>({
    translationKey: "createCronjob",
    showSuccessFeedback: true,
    defaultValues: {
      interval: "0 * * * *",
      description: "",
      email: "",
      timeout: cronjobDefaultTimeout.toString(),
      destination: {
        type: "command",
        parameters: "",
        interpreter: "",
        path: "/",
      },
    },
    onSubmit: async (values) => {
      const cronjobId = await Cronjob.createNew(values, projectId);

      goto("cronjob-general", { projectId, cronjobId }, { replace: true });
    },
  });

  return (
    <WizardModal
      form={form}
      steps={["general", "type", "interval", "settings"]}
    >
      <GeneralStep />
      <TypeStep />
      <IntervalStep />
      <SettingsStep />
    </WizardModal>
  );
};

export default CreateCronjob;
