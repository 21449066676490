import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Select } from "@mittwald/flow-components/dist/components/Select";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import React, { FC } from "react";
import { iconCalendar } from "@mittwald/flow-icons/dist/calendar";
import BackupUI from "../../../../../../../model/ui/backup/BackupUI";

export const DescriptionStep: FC = () => {
  return (
    <WizardIntroStep
      description="createScheduleDescription"
      headline="createSchedule"
      headlineIcon={iconCalendar}
      id="description"
      indicatorText="descriptionStep"
    >
      <Section.Item>
        <ColumnLayout medium={[1, 1]}>
          <TextField
            autoFocus
            label="description"
            name="description"
            rules={{ required: true }}
          />
          <Select
            label="ttl"
            name="ttl"
            options={BackupUI.ttlOptions}
            rules={{ required: true }}
          />
        </ColumnLayout>
      </Section.Item>
    </WizardIntroStep>
  );
};

export default DescriptionStep;
