import { PriceBox } from "@mittwald/flow-components/dist/components/PriceBox";
import React, { FC } from "react";
import MoneyValue from "../../../../../../../model/misc/MoneyValue";

interface Props {
  yearlyPrices?: MoneyValue[];
  monthlyPrices?: MoneyValue[];
}

export const ContractItemsPriceBox: FC<Props> = (props) => {
  const { yearlyPrices, monthlyPrices } = props;

  return (
    <PriceBox
      monthlyPrice={
        monthlyPrices?.length
          ? monthlyPrices
              .reduce((sum, price) => sum.add(price), MoneyValue.empty())
              .toMoneyValueInput()
          : undefined
      }
      title="priceBoxTitle"
      yearlyPrice={
        yearlyPrices?.length
          ? yearlyPrices
              .reduce((sum, price) => sum.add(price), MoneyValue.empty())
              .toMoneyValueInput()
          : undefined
      }
    />
  );
};

export default ContractItemsPriceBox;
