import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { DateTime } from "luxon";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { isInLessThan7Days } from "../../pages/app/profile/lib";

export type ApiTokenApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Signup_ApiToken;

export interface NewApiTokenInputs {
  description: string;
  expiresAt?: string;
  writeAccess: boolean;
}

export interface UpdateApiTokenInputs {
  description: string;
  expiresAt?: string;
}

export class ApiToken {
  public readonly id: string;
  public readonly data: ApiTokenApiData;
  public readonly description: string;
  public readonly expires: boolean;
  public readonly expiresSoon: boolean;
  public readonly expiresAt: DateTime | undefined;

  private constructor(data: ApiTokenApiData) {
    this.id = data.apiTokenId;
    this.data = Object.freeze(data);
    this.description = data.description;
    this.expires = !!data.expiresAt;
    this.expiresAt = data.expiresAt
      ? DateTime.fromISO(data.expiresAt)
      : undefined;
    this.expiresSoon = !!this.expiresAt && isInLessThan7Days(this.expiresAt);
  }

  public static fromApiData(data: ApiTokenApiData): ApiToken {
    return new ApiToken(data);
  }

  public static useLoadById(apiTokenId: string): ApiToken {
    const data = mittwaldApi.userGetApiToken
      .getResource({
        path: {
          apiTokenId,
        },
      })
      .useWatchData();

    return ApiToken.fromApiData(data);
  }

  public static useLoadByPathParam(): ApiToken {
    const { apiTokenId } = usePathParams("apiTokenId");

    return ApiToken.useLoadById(apiTokenId);
  }

  public static async createNew(values: NewApiTokenInputs): Promise<string> {
    const response = await mittwaldApi.userCreateApiToken.request({
      requestBody: {
        description: values.description,
        expiresAt: values.expiresAt,
        roles: values.writeAccess ? ["api_read", "api_write"] : ["api_read"],
      },
    });

    assertStatus(response, 201);

    return response.content.token;
  }

  public async update(values: UpdateApiTokenInputs): Promise<void> {
    const response = await mittwaldApi.userEditApiToken.request({
      path: {
        apiTokenId: this.id,
      },
      requestBody: {
        description: values.description,
        expiresAt: values.expiresAt,
      },
    });

    assertStatus(response, 204);
  }

  public async delete(): Promise<void> {
    const response = await mittwaldApi.userDeleteApiToken.request({
      path: {
        apiTokenId: this.id,
      },
    });

    assertStatus(response, 204);
  }
}

export default ApiToken;
