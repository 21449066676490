import { faSquareS } from "@fortawesome/pro-solid-svg-icons/faSquareS";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import { Icon } from "@mittwald/flow-components/dist/components/Icon";
import {
  DefaultModalProps,
  modalPropsType,
} from "@mittwald/flow-components/dist/components/Modal";
import { modalContentPropsType } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent/types";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { useCompactMode } from "@mittwald/flow-components/dist/hooks/useCompactMode";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import React, { FC } from "react";
import GlassModal from "./GlassModal";
import GlassModalContent from "./styled/GlassModalContent";
import GlassModalFooter from "./styled/GlassModalFooter";

export const DefaultGlassModal: FC<
  Omit<DefaultModalProps, "preContent" | "postContent">
> = (props) => {
  const { children, visibility: visibilityFromProps, ...restProps } = props;

  const modalProps = modalPropsType.pickFrom(restProps);
  const modalContentProps = modalContentPropsType.pickFrom(restProps);

  const fallbackVisibility = useVisibilityController(true);
  const visibility = visibilityFromProps ?? fallbackVisibility;

  const isCompact = useCompactMode();

  return (
    <GlassModal {...modalProps} visibility={visibility}>
      {!isCompact && (
        <Box _alignItems="center" _flexDirection="column">
          <Box _position="absolute" _top="-33px">
            <Text
              _alignItems="center"
              _color="white"
              _flexDirection="row"
              _itemGap="s"
              i18n={{
                id: "openSearchBarInfo",
                values: {
                  icon: <Icon icon={faSquareS} />,
                },
              }}
            />
          </Box>
        </Box>
      )}
      <GlassModalContent {...modalContentProps}>{children}</GlassModalContent>
      {isCompact && (
        <GlassModalFooter
          abort={{
            action: visibility.hide,
            text: "close",
          }}
          maxExpectedButtonSize={450}
          visibility={visibility}
        />
      )}
    </GlassModal>
  );
};

export default GlassModal;
