import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import styled from "styled-components";

export const UserInputLayout = styled(ColumnLayout)`
  & > div > div:has([data-user-input-layout="full-width"]) {
    grid-column: 1 / -1;
  }
`;

export default UserInputLayout;
