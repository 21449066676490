import { faContao } from "@fortawesome/free-brands-svg-icons/faContao";
import { faDrupal } from "@fortawesome/free-brands-svg-icons/faDrupal";
import { faGrav } from "@fortawesome/free-brands-svg-icons/faGrav";
import { faJoomla } from "@fortawesome/free-brands-svg-icons/faJoomla";
import { faMagento } from "@fortawesome/free-brands-svg-icons/faMagento";
import { faNodeJs } from "@fortawesome/free-brands-svg-icons/faNodeJs";
import { faPhp } from "@fortawesome/free-brands-svg-icons/faPhp";
import { faShopware } from "@fortawesome/free-brands-svg-icons/faShopware";
import { faTypo3 } from "@fortawesome/free-brands-svg-icons/faTypo3";
import { faWordpress } from "@fortawesome/free-brands-svg-icons/faWordpress";
import { faFileCode } from "@fortawesome/pro-regular-svg-icons/faFileCode";
import { IconLookup } from "@mittwald/flow-components/dist/components/Icon";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { iconApp } from "@mittwald/flow-icons/dist/app";
import React from "react";
import { App, AppNames } from "../../app/App";
import { ReactComponent as MatomoIcon } from "./img/matomo.svg";
import { ReactComponent as NextCloudIcon } from "./img/nextcloud.svg";
import { ReactComponent as HumHubIcon } from "./img/humhub.svg";
import { ReactComponent as MoodleIcon } from "./img/moodle.svg";
import { ReactComponent as NeosIcon } from "./img/neos.svg";
import { ReactComponent as PrestaShopIcon } from "./img/prestaShop_small.svg";
import { ReactComponent as PythonIcon } from "./img/python.svg";

export class AppUI {
  public readonly app: App;
  public readonly appIcon: IconLookup;

  private constructor(app: App) {
    this.app = app;
    this.appIcon = AppUI.getIcon(app);
  }

  public static of(app: App): AppUI {
    return new AppUI(app);
  }

  public getTitle(): I18nDefinition {
    return {
      id: `app.title.${this.app.slug}`,
      fallbackElement: this.app.name,
    };
  }

  private static getIcon(app: App): IconLookup {
    switch (app.name) {
      case AppNames.wordpress:
        return faWordpress;
      case AppNames.typo3:
        return faTypo3;
      case AppNames.shopware5:
      case AppNames.shopware6:
        return faShopware;
      case AppNames.php:
        return faPhp;
      case AppNames.joomla:
        return faJoomla;
      case AppNames.contao:
        return faContao;
      case AppNames.node:
        return faNodeJs;
      case AppNames.matomo:
        return <MatomoIcon />;
      case AppNames.staticapp:
        return faFileCode;
      case AppNames.nextcloud:
        return <NextCloudIcon />;
      case AppNames.magento:
        return faMagento;
      case AppNames.grav:
        return faGrav;
      case AppNames.drupal:
        return faDrupal;
      case AppNames.neos:
        return <NeosIcon />;
      case AppNames.moodle:
        return <MoodleIcon />;
      case AppNames.prestashop:
        return <PrestaShopIcon />;
      case AppNames.humhub:
        return <HumHubIcon />;
      case AppNames.python:
        return <PythonIcon />;
    }
    return iconApp;
  }
}

export default AppUI;
