import { AutoNotificationReaderConfigurationItem } from "../AutoNotificationReaderConfiguration";

const calculateUrlSpecificity = (
  item: AutoNotificationReaderConfigurationItem,
): number => {
  return item.path
    .split("/")
    .filter(Boolean)
    .filter((segment) => !segment.startsWith(":")).length;
};

export const sortUrlsBySpecificity = (
  items: AutoNotificationReaderConfigurationItem[],
): AutoNotificationReaderConfigurationItem[] => {
  const urlsWithScores = items.map((item) => ({
    ...item,
    specificityScore: calculateUrlSpecificity(item),
  }));

  return (
    urlsWithScores
      .sort((a, b) => {
        return b.specificityScore - a.specificityScore;
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ specificityScore, ...item }) => item)
  );
};
