import React, { FC } from "react";
import { SpaceServerProject } from "../../../../../../model/project";
import { Select } from "@mittwald/flow-components/dist/components/Select";
import Server from "../../../../../../model/server/Server";

interface Props {
  project: SpaceServerProject;
}

const SelectProject: FC<Props> = (props) => {
  const availableProjects = Server.useProjects(
    props.project.serverId,
  ).getSelectOptions();

  return (
    <Select
      infoMessage={{ message: "sameServer" }}
      label="project"
      name="targetProjectId"
      options={availableProjects}
      rules={{ required: true }}
    />
  );
};

export default SelectProject;
