import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import { DateTime } from "luxon";
import { allArchiveFormats, Backup } from "../../backup/Backup";

export type BackupTtl = "7d" | "14d" | "30d" | "182d" | "365d";

export const allBackupTtls: BackupTtl[] = ["7d", "14d", "30d", "182d", "365d"];

export type BackupInterval = "1h" | "1d" | "7d" | "14d" | "30d" | "custom";

export class BackupUI {
  public readonly backup: Backup;

  public static readonly formatSelectOptions: SelectOptions =
    allArchiveFormats.map((f) => ({ value: f, label: { text: f } }));

  public static readonly ttlOptions: SelectOptions = allBackupTtls.map(
    (ttl) => ({
      value: ttl,
      label: joinTranslationKeys("backupTtl", ttl),
    }),
  );

  public static readonly backupIntervals: BackupInterval[] = [
    "1h",
    "1d",
    "7d",
    "14d",
    "30d",
    "custom",
  ];

  private constructor(backup: Backup) {
    this.backup = backup;
  }

  public static of(backup: Backup): BackupUI {
    return new BackupUI(backup);
  }

  public static formatExpirationTime(duration: string): string {
    if (duration.includes("d")) {
      return DateTime.now()
        .plus({
          day: parseInt(duration.replace("d", "")),
        })
        .endOf("day")
        .toISO();
    }
    if (duration.includes("M")) {
      return DateTime.now()
        .plus({
          month: parseInt(duration.replace("M", "")),
        })
        .endOf("month")
        .endOf("day")
        .toISO();
    } else {
      throw new Error("invalid expiration time");
    }
  }
}

export default BackupUI;
