import { ContentContainer } from "@mittwald/flow-components/dist/components/Box/styled";
import { ModalContent } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent";
import styled from "styled-components";
import { spacing } from "@mittwald/flow-styles/dist/tokens/ts/variables";

export const GlassModalContent = styled(ModalContent)`
  padding: 0;

  &${ContentContainer} {
    .preserveTrackSpace {
      right: 0px;
    }

    margin: ${spacing.base.m};
  }
`;

export default GlassModalContent;
