import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { DnsZoneUI, Ttl } from "../ui/domain/DnsZoneUI";
import { RecordUnset } from "./DnsZone";

export type MxRecordApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordMx;

export type MxRecordRecordApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordMxRecord;

type MxRecordCustom =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordMxCustom;

export class MxRecord {
  public readonly data: MxRecordApiData;
  public readonly zoneId: string;
  public readonly isManaged: boolean;
  public readonly ttl: Ttl;
  public readonly records: MxRecordRecordApiData[];

  public constructor(data: MxRecordApiData, zoneId: string) {
    this.data = Object.freeze(data);
    this.zoneId = zoneId;
    this.isManaged = "managed" in data;
    this.ttl = DnsZoneUI.ttlFromRecord(data);
    this.records = "records" in data ? data.records : [];
  }

  public static fromApiData = (
    data: MxRecordApiData,
    zoneId: string,
  ): MxRecord => {
    return new MxRecord(data, zoneId);
  };

  public async setRecords(values: MxRecordCustom | RecordUnset): Promise<void> {
    await mittwaldApi.dnsUpdateRecordSet.request({
      path: { dnsZoneId: this.zoneId, recordSet: "mx" },
      requestBody: values,
    });
  }

  public async setManaged(): Promise<void> {
    await mittwaldApi.dnsSetRecordSetManaged.request({
      path: { dnsZoneId: this.zoneId, recordSet: "mx" },
    });
  }
}

export default MxRecord;
