export interface SSHPublicKey {
  publicKey: string;
  comment: string;
}

export const sshPublicKeyRegex =
  /^(sk-ssh|sk-ecdsa-sha2|ssh|ecdsa-sha2)-(sha2|rsa|dss|dsa|nistp256|nistp384|nistp521|ed25519)(@openssh.com)? [0-9A-Za-z+/]+[=]{0,3}( (?<comment>.+))?$/;

export const splitSshKey = (
  input: string | undefined,
): SSHPublicKey | undefined => {
  if (!input) {
    return undefined;
  }

  const sshKey = sshPublicKeyRegex.exec(input);
  if (!sshKey) {
    return undefined;
  }

  const sshGroup = sshKey.groups;
  if (sshGroup && sshGroup.comment) {
    return {
      publicKey: input.substring(0, input.indexOf(sshGroup.comment) - 1),
      comment: sshGroup.comment,
    };
  }

  return {
    publicKey: input,
    comment: "",
  };
};
export default splitSshKey;
