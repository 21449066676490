import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { useMemo } from "react";
import { mittwaldApi } from "../../api/Mittwald";
import Ingress from "../domain/Ingress";
import ListModel from "../misc/ListModel";
import { DnsZoneUI, Ttl } from "../ui/domain/DnsZoneUI";
import { DnsZone, DnsZoneApiData } from "./DnsZone";
import SrvRecordSet, { RecordSrvRecordSetApiData } from "./SrvRecordSet";

export interface SrvRecordItem extends RecordSrvRecordSetApiData {
  service: string;
  protocol: string;
  ttl: number;
}

export interface SrvRecordDataForList extends Omit<SrvRecordItem, "ttl"> {
  markForDeletion?: boolean;
}

export class SrvRecordsList extends ListModel<SrvRecordSet> {
  private readonly ingress: Ingress;
  private readonly dnsZones: DnsZoneApiData[];
  public readonly ttl: Ttl;
  public readonly recordCount: number;

  public constructor(
    ingress: Ingress,
    dnsZones: DnsZoneApiData[],
    items: SrvRecordSet[],
    ttl: Ttl = "auto",
  ) {
    super(items);

    this.ingress = ingress;
    this.dnsZones = dnsZones;
    this.ttl = ttl;
    this.recordCount = this.items.reduce<number>(
      (previousValue, currentValue) =>
        previousValue + currentValue.records.length,
      0,
    );
  }

  public static fromSrvRecordApiData = (
    data: DnsZoneApiData,
    ingress: Ingress,
  ): SrvRecordsList => {
    return new SrvRecordsList(
      ingress,
      [data],
      [SrvRecordSet.fromDnsZoneApiData(data)],
    );
  };

  private static readonly useGetAllZonesForIngress = (
    ingress: Ingress,
  ): DnsZoneApiData[] => {
    const listAllDnsZonesRequest = mittwaldApi.dnsListDnsZones.getResource({
      path: { projectId: ingress.data.projectId },
    });
    return listAllDnsZonesRequest.useWatchData();
  };

  public static useLoadAllByIngress = (ingress: Ingress): SrvRecordsList => {
    const zonesForIngress = SrvRecordsList.useGetAllZonesForIngress(ingress);
    const srvRecordZonesForIngress =
      SrvRecordsList.filterSrvRecordZonesForIngress(zonesForIngress, ingress);

    const items = useMemo(
      () =>
        srvRecordZonesForIngress.map((r) => SrvRecordSet.fromDnsZoneApiData(r)),
      [srvRecordZonesForIngress],
    );

    const itemWithTtl = items.find((r) => r.ttl);

    return new SrvRecordsList(
      ingress,
      zonesForIngress,
      items,
      itemWithTtl?.ttl,
    );
  };

  private static filterSrvRecordZonesForIngress(
    dnsZones: DnsZoneApiData[],
    ingress: Ingress,
  ): DnsZoneApiData[] {
    return dnsZones.filter((d) => {
      const domainParts = d.domain.split(".");
      const service = domainParts[0];
      delete domainParts[0];
      const protocol = domainParts[1];
      delete domainParts[1];

      return (
        service?.startsWith("_") &&
        protocol?.startsWith("_") &&
        domainParts.filter((p) => p).join(".") === ingress.hostname
      );
    });
  }

  public async createSrvDnsZone(
    service: string,
    protocol: string,
  ): Promise<string> {
    const zone = await DnsZone.getByIngress(this.ingress);
    const response = await mittwaldApi.dnsCreateDnsZone.request({
      requestBody: {
        name: `${service}.${protocol}`,
        parentZoneId: zone.id,
      },
    });
    assertStatus(response, 201);

    return response.content.id;
  }

  public async saveDataItems(
    items: SrvRecordDataForList[],
    ttl: Ttl,
  ): Promise<void> {
    const groupByServiceAndProtocol: Record<string, SrvRecordDataForList[]> =
      items.reduce((r, a) => {
        const groupKey = `${a.service}.${a.protocol}.${this.ingress.hostname}`;
        r[groupKey] = r[groupKey] || [];

        if (a.markForDeletion) {
          r[groupKey].push({});
        } else {
          r[groupKey].push(a);
        }

        return r;
      }, Object.create(null));

    for (const [fqdnWithHostname, srvRecordList] of Object.entries(
      groupByServiceAndProtocol,
    )) {
      const { service, protocol } =
        this.getServiceAndProtocol(fqdnWithHostname);
      const existingSrvZone = this.dnsZones.find((z) => {
        return z.domain === fqdnWithHostname;
      });
      let zoneId = existingSrvZone?.id;

      if (!zoneId) {
        zoneId = await this.createSrvDnsZone(service, protocol);
      }

      const srvRecord = SrvRecordSet.fromList(
        zoneId,
        fqdnWithHostname,
        ttl,
        srvRecordList.filter((r) => Object.keys(r).length >= 1),
      );
      await srvRecord.saveRecords();
    }
  }

  public getItems(): SrvRecordItem[] {
    const items = this.items;
    const dataItems: SrvRecordItem[] = [];

    items.forEach((i) => {
      const domain = i.domain;
      const { service, protocol } = this.getServiceAndProtocol(domain);

      i.records.forEach((r) => {
        dataItems.push({
          ...r,
          service,
          protocol,
          ttl: DnsZoneUI.ttlToSeconds(this.ttl),
        });
      });
    });

    return dataItems;
  }

  public useDataItems(): SrvRecordDataForList[] {
    const items = this.useItems();
    const dataItems: SrvRecordDataForList[] = [];

    items.forEach((i) => {
      const domain = i.domain;
      const { service, protocol } = this.getServiceAndProtocol(domain);

      i.records.forEach((r) => {
        dataItems.push({
          service,
          protocol,
          fqdn: r.fqdn,
          priority: r.priority,
          weight: r.weight,
          port: r.port,
        });
      });
    });

    return useMemo(() => dataItems, [items]);
  }

  private getServiceAndProtocol(hostname: string): {
    service: string;
    protocol: string;
    hostname: string;
  } {
    const [service, protocol, ...rest] = hostname.split(".");

    if (!service || !protocol) {
      throw new Error("missing srv and protocol from hostname");
    }

    return {
      service,
      protocol,
      hostname: rest.join("."),
    };
  }
}
