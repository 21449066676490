import { hasValue } from "@mittwald/awesome-node-utils/types/isDefined";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import React from "react";
import SupportPinModel from "../../model/supportCode/SupportCode";
import { SupportPinText } from "./components/SupportPinText";
import { useCookies } from "react-cookie";
import { DateTime } from "luxon";

export const SupportPin: React.FC = () => {
  const [cookies, setCookie] = useCookies<
    string,
    {
      mStudioSupportPin?: string | undefined | null;
    }
  >(["mStudioSupportPin"]);

  const currentSupportPin = cookies["mStudioSupportPin"];

  return currentSupportPin ? (
    <SupportPinText>{currentSupportPin}</SupportPinText>
  ) : (
    <TextLink
      action={() => {
        if (hasValue(currentSupportPin)) {
          return;
        }

        void SupportPinModel.load().then((result) => {
          if (!result.supportCode) {
            return;
          }

          const expirationDate = DateTime.now().plus({ minute: 15 }).toJSDate();
          setCookie("mStudioSupportPin", result.supportCode, {
            expires: expirationDate,
          });
        });
      }}
      i18n="generate.supportPin"
    />
  );
};
