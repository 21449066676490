import { DateTime } from "@mittwald/flow-components/dist/components/DateTimeText";
import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column";
import { iconDate } from "@mittwald/flow-icons/dist/date";

import React from "react";
import { Notification } from "../../../../../../model/notification/Notification";

export const DateColumn: ResourceListColumnRenderer<Notification> = ({
  resource,
}) => (
  <Column
    icon={iconDate}
    text={<DateTime short value={resource.createdAt} />}
    tooltip="date"
  ></Column>
);
