import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import React, { FC } from "react";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";

export const DomainDependencyInfo: FC = () => (
  <>
    <ColumnLayout _mt="m" medium={[1, 1]}>
      <TextWithIconLayout icon={iconDomain}>
        <Text i18n={{ id: "domains" }} />
      </TextWithIconLayout>
    </ColumnLayout>
    <CheckBox
      name="confirmationDomain"
      rules={{ required: true }}
      selectableBoxProps={{ style: { alignSelf: "stretch" } }}
      title={{
        id: "confirmDomains",
      }}
    />
  </>
);

export default DomainDependencyInfo;
