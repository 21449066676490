import {
  AutoCompleteField,
  AutoCompleteFieldProps,
} from "@mittwald/flow-components/dist/components/AutoCompleteField";
import { FormComponentRules } from "@mittwald/flow-components/dist/components/Form";
import React, { FC } from "react";
import { useProjectDirectoryListingCompletion } from "../../../hooks/useProjectDirectoryListingCompletion";
import Project from "../../../model/project/Project";
import { ProjectDir } from "../../../model/project";

export interface ProjectFileSystemPathFieldProps
  extends AutoCompleteFieldProps {
  name: string;
  projectId: string;
  onlyDirectories?: boolean;
  onlyDirectoriesAndSymlinks?: boolean;
  restrictToProjectDirectory?: ProjectDir;
  appDirectory?: string;
  rules?: FormComponentRules;
}

export const ProjectFileSystemPathField: FC<ProjectFileSystemPathFieldProps> = (
  props,
) => {
  const {
    name,
    onlyDirectories,
    onlyDirectoriesAndSymlinks,
    projectId,
    restrictToProjectDirectory,
    appDirectory,
    rules,
    ...rest
  } = props;

  const project = Project.useLoadById(projectId);
  const rootDirectory = project.getRootDirectory(restrictToProjectDirectory);

  const projectDirectoryListingCompletion =
    useProjectDirectoryListingCompletion(
      projectId,
      onlyDirectories,
      onlyDirectoriesAndSymlinks,
      rootDirectory,
      appDirectory,
    );

  return (
    <AutoCompleteField
      forceDirectorySlash
      label="directory"
      name={name}
      rules={{
        required: true,
        validate: { startsWith: (value: string) => `${value}`.startsWith("/") },
        ...rules,
      }}
      {...rest}
      {...projectDirectoryListingCompletion}
    />
  );
};
