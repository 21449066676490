import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { MittwaldApi, mittwaldApi } from "../../api/Mittwald";
import { refresh, usePromise } from "@mittwald/react-use-promise";

type SubscribeInputs =
  MittwaldApi.Paths.V2_Newsletter_Subscriptions.Post.Parameters.RequestBody;

class Newsletter {
  public static useSubscriptionStatus(): "active" | "registered" | "inactive" {
    const response = usePromise(mittwaldApi.newsletterGetInfo.request, [{}], {
      tags: ["newsletter-subscriptions/self"],
      autoRefresh: { seconds: 10 },
    });

    assertStatus(response, 200);

    return response.content.active
      ? "active"
      : response.content.registered
        ? "registered"
        : "inactive";
  }

  public static async subscribe(inputs: SubscribeInputs): Promise<void> {
    const response = await mittwaldApi.newsletterSubscribeUser.request({
      requestBody: inputs,
    });
    assertStatus(response, 200);

    refresh({
      tag: "newsletter-subscriptions/self",
    });
  }

  public static async unsubscribe(): Promise<void> {
    const response = await mittwaldApi.newsletterUnsubscribeUser.request({});
    assertStatus(response, 204);

    refresh({
      tag: "newsletter-subscriptions/self",
    });
  }
}

export default Newsletter;
