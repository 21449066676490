import { Box } from "@mittwald/flow-components/dist/components/Box";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import {
  cronjobMaxTimeout,
  cronjobMinTimeout,
} from "../../../../../../model/cronjob/CronjobExecution";

export const SettingsStep: FC = () => {
  return (
    <WizardStep id="settings" indicatorText="settings">
      <Box _itemGap="m">
        <Section.Item headline="settings">
          <Text i18n="settings" />
        </Section.Item>
        <Section.Item>
          <ColumnLayout medium={[1, 1]}>
            <TextField
              label="timeout"
              name="timeout"
              rules={{
                min: cronjobMinTimeout,
                max: cronjobMaxTimeout,
                required: true,
              }}
              type="number"
            />
          </ColumnLayout>
          <ColumnLayout medium={[1, 1]}>
            <TextField clear label="email" name="email" type="email" />
          </ColumnLayout>
        </Section.Item>
      </Box>
    </WizardStep>
  );
};

export default SettingsStep;
