import AutoCompleteField from "@mittwald/flow-components/dist/components/AutoCompleteField/AutoCompleteField";
import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  AiMetaInfo,
  ContainerImageConfigVolume,
  containerPathRegex,
  projectPathOrVolumeRegex,
} from "../../../../../model/container/Container";
import { ContainerVolumeRelationFormData } from "../../../../../model/container/ContainerVolumeRelation";
import { ProjectFileSystemPathField } from "../../../components/ProjectFileSystemPathField";
import { UseFieldArrayRemove } from "react-hook-form";
import { HeavyListItemProps } from "@mittwald/flow-components/dist/components/List/components/HeavyListItem/types";
import List from "@mittwald/flow-components/dist/components/List";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import ColumnLayout from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import Select from "@mittwald/flow-components/dist/components/Select/Select";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import { SuggestionsFactory } from "@mittwald/flow-components/dist/components/AutoCompleteField";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { AiDescription } from "../AiDescription";

interface Props extends HeavyListItemProps {
  index: number;
  suggestedPaths?: ContainerImageConfigVolume[];
  remove: UseFieldArrayRemove;
  volumeOptions: SelectOptions;
  setAiGeneratedStates: React.Dispatch<React.SetStateAction<boolean[]>>;
}

export const VolumeHeavyListEntry: FC<Props> = (props) => {
  const {
    index,
    suggestedPaths = [],
    remove,
    volumeOptions,
    setAiGeneratedStates,
  } = props;
  const { projectId } = usePathParams("projectId");
  const [metaInfo, setMetaInfo] = useState<AiMetaInfo | undefined>();
  const form = useFormContext();
  const selectedType = form.watch(`volumes.${index}.type`);

  const volumeTypeOptions = [
    { title: "volume", value: "volume" },
    { title: "projectDirectory", value: "directory" },
  ];

  const pathSuggestions: SuggestionsFactory<unknown> = (value) => {
    const cleanedValue = value.trim().toLowerCase();
    const suggestions = suggestedPaths
      .map((p) => ({
        value: p.volume,
        label: { text: p.volume },
      }))
      .filter(
        (s) =>
          !form
            .getValues("volumes")
            .find(
              (f: ContainerVolumeRelationFormData) =>
                f.containerPath === s.value,
            ),
      );

    if (cleanedValue === "") {
      return suggestions;
    }

    return suggestions.filter((s) =>
      s.value.toLowerCase().includes(cleanedValue),
    );
  };

  const updateAiInfo = (value: string): void => {
    const matchingPath = suggestedPaths.find((p) => p.volume === value);
    setMetaInfo({
      description: matchingPath?.description,
      isAiGenerated: matchingPath?.isAiGenerated,
    });

    setAiGeneratedStates((prev) => {
      prev[index] = !!matchingPath?.isAiGenerated;
      return prev;
    });
  };

  useEffect(() => {
    updateAiInfo(form.getValues(`volumes.${index}.containerPath`));
  }, []);

  const removeItem = (index: number): void => {
    remove(index);
    updateAiInfo(form.getValues(`volumes.${index}.containerPath`));
  };

  const containerPathField = (index: number): ReactElement => (
    <AutoCompleteField
      gradientBorder={metaInfo?.isAiGenerated}
      label="containerPath"
      name={`volumes.${index}.containerPath`}
      rules={{ required: true, pattern: containerPathRegex }}
      suggestOnEmptyValue
      suggestions={pathSuggestions}
      onChange={updateAiInfo}
    />
  );

  return (
    <List.HeavyItem closeAction={() => removeItem(index)}>
      <SelectBox name={`volumes.${index}.type`} options={volumeTypeOptions} />
      <ColumnLayout _mt="m" medium={[1, 1]}>
        {selectedType === "volume" && (
          <Select
            label="volume"
            name={`volumes.${index}.volume`}
            options={volumeOptions}
            rules={{ required: true }}
          />
        )}
        {selectedType === "directory" && (
          <ProjectFileSystemPathField
            _mb={undefined}
            label="projectDirectory"
            name={`volumes.${index}.projectDirectory`}
            projectId={projectId}
            rules={{ pattern: projectPathOrVolumeRegex }}
            suggestOnEmptyValue
          />
        )}
        {containerPathField(index)}
      </ColumnLayout>
      {metaInfo?.description && (
        <AiDescription description={metaInfo.description} />
      )}
    </List.HeavyItem>
  );
};
