import { iconId } from "@mittwald/flow-icons/dist/id";
import { iconSecurity } from "@mittwald/flow-icons/dist/security";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import BacksideRenderer from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";

import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import ApiToken from "../../../model/signup/ApiToken";
import SshKey from "../../../model/signup/SshKey";
import ApiTokenUI from "../../../model/ui/signup/ApiTokenUI";
import SessionUI from "../../../model/ui/signup/SessionUI";
import SshKeyUI from "../../../model/ui/signup/SshKeyUI";
import ProfileSectionHeader from "./components/ProfileSectionHeader";
import { SshKeyTitle } from "./sshKeys/components/SshKeyTitle";

export const profilePages = PageTree.build(BacksideRenderer, {
  "/app/profile": {
    sector: "addons",
    views: {
      sidebarSectionHeader: ProfileSectionHeader,
    },
    props: {
      sideNav: true,
      hideInMenu: true,
    },
    linkId: "profile-index",
  },

  "/app/profile/personal-data": {
    views: {
      main: lazy(() => import("./personalData/personalData")),
    },
    props: {
      navigationSection: "settings",
    },
    icon: iconId,
    linkId: "personal-data",
  },

  "/app/profile/security": {
    sector: "basics",
    props: {
      navigationSection: "settings",
      tabNav: true,
    },
    linkId: "security",
    icon: iconSecurity,
  },
  "/app/profile/security/general": {
    views: {
      main: lazy(() => import("./security/security")),
    },
    props: {
      navigationSection: "settings",
    },
    icon: iconSecurity,
    linkId: "security",
  },

  "/app/profile/security/sessions": {
    views: {
      main: lazy(() => import("./security/sessions/sessions")),
    },
    props: {
      tabNav: true,
    },
    icon: SessionUI.icon,
    linkId: "sessions",
  },
  "/app/profile/security/sessions/:sessionId": {
    views: {
      main: lazy(() => import("./security/sessions/session")),
      pageTitle: lazy(
        () => import("./security/sessions/components/SessionTitle"),
      ),
    },
    props: {
      hideInMenu: true,
      tabNav: false,
    },
    linkId: "session",
  },
  "/app/profile/ssh-keys": {
    views: {
      main: lazy(() => import("./sshKeys/sshKeys")),
    },
    props: {
      navigationSection: "settings",
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(SshKey, "createNew"),
          },
          title: "CreateProfileSshKey",
          icon: SshKeyUI.icon,
        },
      ],
    },
    icon: SshKeyUI.icon,
    linkId: "ssh-keys",
  },
  "/app/profile/ssh-keys/:sshKeyId": {
    views: {
      pageTitle: SshKeyTitle,
      main: lazy(() => import("./sshKeys/sshKeyDetails")),
    },
    linkId: "ssh-key-details",
  },
  "/app/profile/api-tokens": {
    views: {
      main: lazy(() => import("./apiTokens/apiTokens")),
    },
    props: {
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(ApiToken, "createNew"),
          },
          title: "createApiToken",
          icon: ApiTokenUI.icon,
        },
      ],
      navigationSection: "settings",
    },
    icon: ApiTokenUI.icon,
    linkId: "api-tokens",
  },

  "/app/profile/api-tokens/:apiTokenId": {
    views: {
      pageTitle: lazy(() => import("./apiTokens/components/ApiTokenTitle")),
      main: lazy(() => import("./apiTokens/details")),
    },
    linkId: "api-token-details",
  },
});
