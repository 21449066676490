import { iconDatabase } from "@mittwald/flow-icons/dist/database";
import { iconUser } from "@mittwald/flow-icons/dist/user";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import { Database } from "../../../model/database/Database";
import { DatabaseList } from "../../../model/database/DatabaseList";
import { MySqlDatabase } from "../../../model/database/MySqlDatabase";
import { MySqlUser } from "../../../model/database/MySqlUser";
import RedisDatabase from "../../../model/database/RedisDatabase";
import DatabaseUserUI from "../../../model/ui/database/DatabaseUserUI";

export const databasePages = PageTree.build(BacksideRenderer, {
  "/app/projects/:projectId/database": {
    sector: "basics",
    views: {
      main: lazy(() => import("./overview")),
    },
    props: {
      navigationSection: "component",
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(Database, "createNewDatabase"),
          },
          title: "createDatabase",
          icon: iconDatabase,
        },
      ],
    },
    icon: iconDatabase,
    linkId: "databaseOverview",
    accessCheck: () =>
      ModelActionBuilder.build(DatabaseList, "useLoadAllDatabasesByProjectId")
        .hasAccess,
  },
  "/app/projects/:projectId/database/:databaseId": {
    views: {
      pageTitle: lazy(() => import("./components/DatabaseTitle")),
    },
    props: {
      tabNav: true,
    },
    linkId: "database",
  },

  "/app/projects/:projectId/database/:databaseId/details": {
    views: {
      main: lazy(() => import("./DatabaseDetails")),
    },
    linkId: "databaseDetails",
  },

  "/app/projects/:projectId/database/:databaseId/users": {
    views: {
      main: lazy(() => import("./mySql/users/overview")),
    },
    props: {
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                MySqlDatabase.useLoadByPathParam(),
                "createUser",
              ),
          },
          title: "createDatabaseUser",
          icon: DatabaseUserUI.icon,
        },
      ],
    },
    linkId: "mySqlUsers",
    accessCheck: () =>
      DatabaseList.useFindDatabaseByPathParams() instanceof MySqlDatabase,
  },
  "/app/projects/:projectId/database/:databaseId/users/:databaseUserId": {
    views: {
      pageTitle: lazy(() => import("./mySql/components/DatabaseUserTitle")),
    },
    props: {
      tabNav: true,
    },
    linkId: "mySqlUser",
  },
  "/app/projects/:projectId/database/:databaseId/users/:databaseUserId/details":
    {
      views: {
        main: lazy(() => import("./mySql/users/details")),
      },
      icon: iconUser,
      linkId: "mySqlUserDetails",
    },
  "/app/projects/:projectId/database/:databaseId/users/:databaseUserId/permissions":
    {
      views: {
        main: lazy(() => import("./mySql/users/permissions")),
      },
      icon: iconUser,
      linkId: "mySqlUserPermissions",
      accessCheck: () =>
        ModelActionBuilder.build(
          MySqlUser.useLoadByPathParam(),
          "checkIsMainUser",
        ).hasAccess,
    },

  "/app/projects/:projectId/database/:databaseId/configuration": {
    views: {
      main: lazy(() => import("./redis/details/configuration")),
    },
    linkId: "redisDatabaseConfiguration",
    accessCheck: () =>
      DatabaseList.useFindDatabaseByPathParams() instanceof RedisDatabase,
  },
});
