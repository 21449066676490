import { Box } from "@mittwald/flow-components/dist/components/Box";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import Section from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import useOptionalPathParams from "@mittwald/flow-lib/dist/hooks/useOptionalPathParams";
import React, { FC } from "react";
import { IngressList } from "../../../../../model/domain/IngressList";
import SpaceServerProject from "../../../../../model/project/SpaceServerProject";
import User from "../../../../../model/user/User";
import userSettingsStore from "../../../../../store/userSettings";
import DomainDependencyInfo from "../../../customer/contractItems/termination/components/DomainDependencyInfo";
import ProjectDependencyInfo from "../../../customer/contractItems/termination/components/ProjectDependencyInfo";

interface Props {
  project: SpaceServerProject;
}

interface FormFields {
  confirmDomain: boolean;
  confirmComponents: boolean;
}

export const DeleteProject: FC<Props> = (props) => {
  const { projectId } = useOptionalPathParams();
  const project = props.project;
  const visibility = useVisibilityController();
  const ingresses = IngressList.useLoadAllWithDomainByProjectId(
    project.id,
  ).items;
  const myUserId = User.useMe().id;
  const goto = useGotoLink();

  const mainDomains = ingresses.filter((ingress) => !ingress.isSubdomain);

  // server can be optional, because project members don't have access to the server
  // but they should still be allowed to delete the project
  const server = project.useServer();

  const form = useForm<FormFields>({
    onSubmit: async () => {
      await project.delete();

      userSettingsStore.recentVisits.deleteRecord({
        type: "project",
        id: project.id,
        userId: myUserId,
      });

      if (projectId) {
        goto("projects", undefined, { replace: true });
      }
    },
  });

  return (
    <DefaultModal
      description={
        server
          ? {
              id: "description",
              values: {
                project: project.description,
                server: server.data.description,
              },
            }
          : {
              id: "noServerDescription",
              values: { project: project.description },
            }
      }
      headline="deleteProject"
      headlineIcon={iconDelete}
      primary={{
        action: [{ form }, visibility.hide],
        destructive: true,
        text: "deleteProject",
      }}
      visibility={visibility}
    >
      <Section.Item headline={{ id: "dependencies.title" }}>
        <Text i18n={{ id: "dependencies.description" }} />
        <Form controller={form}>
          <Box _itemGap="m">
            {mainDomains.length > 1 && <DomainDependencyInfo />}
            <ProjectDependencyInfo />
          </Box>
        </Form>
      </Section.Item>
    </DefaultModal>
  );
};

export default DeleteProject;
