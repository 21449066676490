import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Select } from "@mittwald/flow-components/dist/components/Select";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC } from "react";
import Customer from "../../../../../../model/customer/Customer";
import Server from "../../../../../../model/server/Server";
import ServerList from "../../../../../../model/server/ServerList";

interface Props {
  customer?: Customer;
  server?: Server;
  spaceServerSelected: boolean;
}

export const ArticleTypeProjectDetailsSection: FC<Props> = (props) => {
  const servers = ServerList.useLoadByOptionalCustomerId(props.customer?.id);
  const serverOptions = servers.getSelectOptions();

  return (
    <Section.Item headline="projectDetails">
      <ColumnLayout medium={[1, 1]}>
        <TextField
          label="description"
          name="description"
          rules={{ required: true }}
        />
        {!props.server?.id && props.spaceServerSelected && (
          <Select
            label="server"
            name="serverId"
            options={serverOptions}
            rules={{ required: true }}
          />
        )}
      </ColumnLayout>
    </Section.Item>
  );
};

export default ArticleTypeProjectDetailsSection;
