import Box from "@mittwald/flow-components/dist/components/Box";
import Toast from "@mittwald/flow-components/dist/components/Toast";
import { duration } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import { motion } from "framer-motion";
import React, { FC, useEffect } from "react";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import Notification from "../../../model/notification/Notification";
import NotificationUI from "../../../model/ui/notification/NotificationUI";
import { config } from "../config";

const MotionBox = motion(Box);

interface Props {
  remove: () => void;
  notification: Notification;
}

export const NotificationToast: FC<Props> = (props) => {
  const { notification, remove } = props;

  useEffect(() => {
    const timeout = setTimeout(remove, 10 * 1000);
    return () => clearTimeout(timeout);
  }, []);

  const notificationUI = NotificationUI.of(notification);

  const clickAction: AnyAction = [notificationUI.buildAction(), remove];

  return (
    <MotionBox
      _pb="s"
      _width={config.toastWidth}
      animate={{ x: 0, opacity: 1 }}
      exit={{ opacity: 0, x: config.toastWidth, height: 0 }}
      initial={{ x: config.toastWidth, opacity: 0 }}
      transition={{ duration: duration.animation.slow / 1000 }}
    >
      <Toast
        action={clickAction}
        description={notificationUI.getTranslation("description")}
        error={notification.data.severity === "error"}
        headline={notificationUI.getTranslationKey("title")}
        icon={notification.relation.type.icon}
        key={notification.id}
        success={notification.data.severity === "success"}
        warning={notification.data.severity === "warning"}
        onClose={remove}
      />
    </MotionBox>
  );
};

export default NotificationToast;
