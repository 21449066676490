import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC, useState } from "react";
import TextArea from "@mittwald/flow-components/dist/components/TextArea/TextArea";
import FileDropzone, {
  useFileDropzone,
} from "@mittwald/flow-components/dist/components/FileDropzone";
import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import {
  ContainerImageConfigEnv,
  EnvironmentInputs,
} from "../../../../../model/container/Container";
import { ContainerUtils } from "../../../../../model/container/ContainerUtils";
import { EnvironmentHeavyList } from "../EnvironmentHeavyList/EnvironmentHeavyList";

interface Props {
  suggestedEnvs?: ContainerImageConfigEnv[];
}

export const EnvironmentForm: FC<Props> = (props) => {
  const [isLoading, setLoading] = useState(false);
  const form = useFormContext<EnvironmentInputs>();
  const definitionType = form.watch("envDefinitionType");

  const handleImport = async (files: File[]): Promise<void> => {
    setLoading(true);
    if (files.length > 0) {
      if (files[0] !== undefined) {
        const fileContent = await files[0].text();
        form.setValue(
          "environments",
          ContainerUtils.mapEnvTextToEntries(fileContent),
        );
        form.setValue("environmentText", fileContent);
        form.setValue("envDefinitionType", "variables");
        setLoading(false);
      }
    }
  };

  const fileDropController = useFileDropzone({
    maxSize: 5000 * 1000,
    maxFiles: 1,
    validator: (file) => {
      if (!file.name.includes(".env") || file.type) {
        return { code: "not-env-file", message: "Not an .env file" };
      }
      return null;
    },
    onDropAccepted: (files) => {
      void handleImport(files);
    },
  });

  return (
    <TranslationProvider name="environmentForm" type="component">
      <SelectBox
        name="envDefinitionType"
        options={[
          { title: "variables", value: "variables" },
          { title: "console", value: "console" },
          { title: "upload", value: "upload" },
        ]}
      />
      {definitionType === "variables" && (
        <EnvironmentHeavyList suggestedEnvs={props.suggestedEnvs} />
      )}
      {definitionType === "console" && (
        <TextArea
          name="environmentText"
          placeholder="envPlaceholder"
          onChange={(value) => {
            form.setValue(
              "environments",
              ContainerUtils.mapEnvTextToEntries(value),
            );
          }}
        />
      )}
      {definitionType === "upload" && (
        <>
          {!isLoading && <FileDropzone dropController={fileDropController} />}
          {isLoading && <LoadingView _height="100%" />}
        </>
      )}
    </TranslationProvider>
  );
};

export default EnvironmentForm;
