import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";

import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import React, { FC } from "react";
import { AnyDatabase } from "../../../../../model/database/Database";
import InstallationTitle from "../../../app/components/InstallationTitle";

interface Props {
  database: AnyDatabase;
}

export const DeleteDatabaseModal: FC<Props> = (props) => {
  const { database } = props;
  const visibility = useVisibilityController();
  const goto = useGotoLink();

  const deleteDatabase = async (): Promise<void> => {
    await database.delete();
    goto("databaseOverview", undefined, { replace: true });

    visibility.hide();
  };

  return (
    <TranslationProvider name="deleteDatabase" type="modal">
      <DefaultModal
        _size="s"
        description={{
          id: "description",
          values: {
            type: database.type,
            database: database.description,
            appInstallation: (
              <InstallationTitle.WithSkeletonLoader
                appInstallationId={database.linkedAppInstallationId}
              />
            ),
          },
        }}
        headline="deleteDatabase"
        headlineIcon={iconDelete}
        primary={{
          destructive: true,
          action: deleteDatabase,
        }}
        visibility={visibility}
      ></DefaultModal>
    </TranslationProvider>
  );
};

export default DeleteDatabaseModal;
