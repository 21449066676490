import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { DnsZoneUI, Ttl } from "../ui/domain/DnsZoneUI";
import { DnsZoneApiData } from "./DnsZone";
import { SrvRecordDataForList } from "./SrvRecordsList";

// workaround since the api gateway will not return 0 values
type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type SrvRecordSetComponentApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordSrvComponent;

type PatchedRecordSrvRecordSetApiData = Optional<
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordSrvRecord,
  "weight" | "priority"
>;

export type RecordSrvRecordSetApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordSrvRecord;

export type SrvRecordSetApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordSrv;

export class SrvRecordSet {
  public readonly data: SrvRecordSetApiData;
  public readonly ttl?: Ttl;
  public readonly id: string;
  public readonly domain: string;
  public records: RecordSrvRecordSetApiData[];

  public constructor(
    id: string,
    domain: string,
    records: SrvRecordSetApiData,
    data: SrvRecordSetApiData,
  ) {
    this.data = Object.freeze(data);
    this.ttl = DnsZoneUI.ttlFromRecord(data);

    this.records =
      "records" in records
        ? records.records.map<RecordSrvRecordSetApiData>(
            (r: PatchedRecordSrvRecordSetApiData) => ({
              ...r,
              priority: r.priority ?? 0,
              weight: r.weight ?? 0,
            }),
          )
        : [];
    this.domain = domain;
    this.id = id;
  }

  public static fromDnsZoneApiData = (data: DnsZoneApiData): SrvRecordSet => {
    return new SrvRecordSet(
      data.id,
      data.domain,
      data.recordSet.srv,
      data.recordSet.srv,
    );
  };

  public static fromList = (
    id: string,
    domain: string,
    ttl: Ttl,
    data: SrvRecordDataForList[],
  ): SrvRecordSet => {
    const srvData: SrvRecordSetApiData = {
      records: data,
      settings: {
        ttl: {
          seconds: ttl !== "auto" ? ttl : undefined,
          auto: ttl === "auto",
        },
      },
    };

    return new SrvRecordSet(id, domain, srvData, srvData);
  };

  public async saveRecords(): Promise<void> {
    const requestBody: Partial<SrvRecordSetComponentApiData> = {
      records: this.records,
      settings: {
        ttl: {
          seconds: this.ttl !== "auto" ? this.ttl : undefined,
          auto: this.ttl === "auto",
        },
      },
    };

    await mittwaldApi.dnsUpdateRecordSet.request({
      path: {
        dnsZoneId: this.id,
        recordSet: "srv",
      },
      requestBody: this.records.length === 0 ? {} : requestBody,
    });
  }
}

export default SrvRecordSet;
