import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconContainerStorage } from "@mittwald/flow-icons/dist/containerStorage";
import React, { FC } from "react";
import {
  Volume,
  CreateVolumeInputs,
  volumeNameRegExp,
} from "../../../../model/container/Volume";
import ColumnLayout from "@mittwald/flow-components/dist/components/ColumnLayout";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";

export const CreateVolume: FC = () => {
  const visibility = useVisibilityController();
  const goto = useGotoLink();
  const { projectId } = usePathParams("projectId");

  const form = useForm<CreateVolumeInputs>({
    defaultValues: { name: "" },
    translationKey: "createVolume",
    mode: "onBlur",
    reValidateMode: "onBlur",
    onSubmit: async (values) => {
      const volume = await Volume.createNew(values, projectId);

      goto("volumeDetails", { volumeId: volume.id }, { replace: true });
    },
  });

  return (
    <DefaultModal
      description="description"
      headline="createVolume"
      headlineIcon={iconContainerStorage}
      primary={{ action: [{ form }, visibility.hide], ok: true }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Item>
          <ColumnLayout medium={[1, 1]}>
            <TextField
              label="description"
              name="name"
              rules={{ required: true, pattern: volumeNameRegExp }}
            />
          </ColumnLayout>
          <CheckBox
            name="noBackup"
            rules={{ required: true }}
            title="noBackupHint"
          />
        </Section.Item>
      </Form>
    </DefaultModal>
  );
};

export default CreateVolume;
