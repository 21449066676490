import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { DatePicker } from "@mittwald/flow-components/dist/components/DatePicker";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { DateTime } from "luxon";
import React, { FC } from "react";
import ApiTokenUI from "../../../../../../../model/ui/signup/ApiTokenUI";

export const GeneralStep: FC = () => {
  return (
    <WizardIntroStep
      description="description"
      headline="create"
      headlineIcon={ApiTokenUI.icon}
      id="intro"
      indicatorText="generalStep"
      stepWillSubmitForm
    >
      <Section.Layout>
        <Section.Item headline="subheadline">
          <Text i18n="subdescription" />
          <ColumnLayout large={[1, 1]}>
            <TextField
              autoFocus
              label="tokenDescription"
              name="description"
              rules={{ required: true }}
            />
            <DatePicker
              label="expiresAt"
              name="expiresAt"
              whitelist={{ fromDate: DateTime.local().plus({ days: 1 }) }}
            />
          </ColumnLayout>
        </Section.Item>
        <Section.Item headline="permissions">
          <Text i18n="description" />
          <CheckBox name="writeAccess" title="accessLevel" />
        </Section.Item>
      </Section.Layout>
    </WizardIntroStep>
  );
};

export default GeneralStep;
