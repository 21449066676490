import AutoCompleteField from "@mittwald/flow-components/dist/components/AutoCompleteField/AutoCompleteField";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { isSubdomain } from "@mittwald/flow-lib/dist/validation/domain";
import noDuplicate from "@mittwald/flow-lib/dist/validation/noDuplicate";
import React, { FC, useDeferredValue } from "react";
import { IngressList } from "../../../../../../model/domain/IngressList";
import { IngressListUI } from "../../../../../../model/ui/domain/IngressListUI";

export const SubdomainStep: FC = () => {
  const accessibleIngressList =
    IngressList.useLoadAllAccessible().useEnabledIngressList();
  const ingresses = accessibleIngressList.useItems();

  const ingressListUI = IngressListUI.of(accessibleIngressList);

  const hostnames = ingresses.map((i) => i.hostname);
  const getSuggestion = useDeferredValue(
    ingressListUI.useSubdomainSuggestions(),
  );

  return (
    <Section.Layout>
      <Section.Item headline="subdomain">
        <Text i18n="subdomainDescription" />
      </Section.Item>
      <Section.Item>
        <AutoCompleteField
          label="subdomain"
          name="subdomain"
          rules={{
            required: true,
            validate: {
              noDuplicate: (subdomain: string) =>
                noDuplicate(hostnames, {
                  convertPunycode: true,
                })(subdomain),
              isDkimSubdomain: (subdomain: string) => !subdomain.includes("_"),
              noSubdomain: (subdomain: string) => {
                if (!subdomain || subdomain.length === 0) {
                  return true;
                }
                const isSubdomainParse = isSubdomain(subdomain);
                return typeof isSubdomainParse === "boolean"
                  ? isSubdomainParse
                  : false;
              },
            },
          }}
          suggestions={getSuggestion}
        />
      </Section.Item>
    </Section.Layout>
  );
};

export default SubdomainStep;
