import { color } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import { css } from "styled-components";

export const aiGradient = css`
  background: linear-gradient(
    90deg,
    ${color.base.hostingBlue.$600},
    ${color.base.softContrastViolet.$300}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
