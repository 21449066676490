import { allAvailableCustomerRoles } from "../../customer/CustomerRole";

export class CustomerMembershipUI {
  public static roleSelectBoxOptions = allAvailableCustomerRoles.map(
    (role) => ({
      title: `${role}CustomerRole`,
      value: role,
    }),
  );
}

export default CustomerMembershipUI;
