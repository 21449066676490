import { useInitFunctionalModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { FunctionalModalContextReset } from "@mittwald/flow-components/dist/components/FunctionModalsContainer/FunctionalModalContext";
import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import { ModalProps } from "@mittwald/flow-components/dist/components/Modal";
import { modalMaxHeight } from "@mittwald/flow-components/dist/components/Modal/config";
import { OverlayAnimation } from "@mittwald/flow-components/dist/components/OverlayAnimation";
import { useCompactMode } from "@mittwald/flow-components/dist/hooks/useCompactMode";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import React, { FC, Suspense, useEffect } from "react";
import { color } from "@mittwald/flow-styles/dist/tokens/ts/variables";

/**
 * This component shows a modal
 *
 * Use the `<ModalContent />` and `<ModalFooter />` to structure the content.
 */
export const Modal: FC<ModalProps> = (props) => {
  const {
    closeOnEsc = false,
    closeOnClickOut = false,
    visibility: visibilityFromProps,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _size = "m",
    children,
    ...rest
  } = props;
  const fallbackVisibility = useVisibilityController();
  const visibility = visibilityFromProps ?? fallbackVisibility;
  const compactMode = useCompactMode();
  const root = document.getElementById("root");
  if (root) {
    root.inert = visibility.visible;
  }
  useInitFunctionalModal(visibility);

  useEffect(() => {
    visibility.setHideOnClickOut(closeOnClickOut);
    visibility.setHideOnEsc(closeOnEsc);
  }, [closeOnClickOut, closeOnEsc]);

  const width = _size === "s" ? 660 : _size === "m" ? 900 : 1050;

  return (
    <FunctionalModalContextReset>
      <OverlayAnimation
        {...rest}
        _alignSelf={compactMode ? undefined : "flex-start"}
        _bgColor="white"
        _bgOpacity={0.4}
        _borderRadius
        _flexDirection={compactMode ? undefined : "column"}
        _grow
        _maxH={modalMaxHeight}
        _maxW={width}
        _mt={96}
        _position="relative"
        aria-modal
        role="dialog"
        speed={0.5}
        style={{
          backdropFilter: "blur(2px)",
          border: `2px solid ${color.base.grey.$25}40`,
        }}
        visibility={visibility}
      >
        <Suspense fallback={<LoadingView _height={200} />}>{children}</Suspense>
      </OverlayAnimation>
    </FunctionalModalContextReset>
  );
};

export default Modal;
