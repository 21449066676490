import { iconDashboard } from "@mittwald/flow-icons/dist/dashboard";
import { iconProject } from "@mittwald/flow-icons/dist/project";
import { iconServer } from "@mittwald/flow-icons/dist/server";
import { iconSettings } from "@mittwald/flow-icons/dist/settings";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import BacksideRenderer from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import Server from "../../../model/server/Server";
import { ServerSidebarHeader } from "./components/ServerSidebarHeader";

export const serverPages = PageTree.build(BacksideRenderer, {
  "/app/servers": {
    sector: "basics",
    views: {
      main: lazy(() => import("./pages/overview/overview")),
    },
    props: {
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(Server, "createNew"),
          },
          icon: iconServer,
          title: "createServer",
        },
      ],
    },
    linkId: "servers",
    icon: iconServer,
  },
  "/app/servers/:serverId": {
    props: {
      sideNav: true,
    },
    linkId: "server",
    views: { sidebarSectionHeader: ServerSidebarHeader },
  },
  "/app/servers/:serverId/dashboard": {
    views: {
      main: lazy(() => import("./pages/dashboard/ServerDashboardPage")),
    },
    linkId: "serverDashboard",
    icon: iconDashboard,
    props: {
      navigationSection: "management",
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(Server.useLoadByPathParam(), "adjust"),
          },
          icon: iconServer,
          title: "adjustServer",
        },
      ],
    },
  },

  "/app/servers/:serverId/projects": {
    views: {
      main: lazy(() => import("./pages/projectsOverview/projectsOverview")),
    },
    linkId: "serverProjects",
    icon: iconProject,
    props: {
      navigationSection: "management",
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                Server.useLoadByPathParam(),
                "createProject",
              ),
          },
          icon: iconProject,
          title: "createProject",
        },
      ],
    },
  },

  "/app/servers/:serverId/settings": {
    views: {
      main: lazy(() => import("./pages/settings/settings")),
    },
    linkId: "serverSettings",
    icon: iconSettings,
    props: {
      navigationSection: "config",
    },
  },
});
