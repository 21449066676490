import Notification from "../../../model/notification/Notification";
import {
  autoNotificationReaderSeverityConfig,
  NotificationReferenceTypeWithId,
} from "../AutoNotificationReaderConfiguration";

export const notificationRelationFilter = (
  notification: Notification,
  reference: NotificationReferenceTypeWithId,
): boolean => {
  return (
    notification.relation.type.matches(reference) &&
    notification.relation.id === reference.id
  );
};

export const notificationSeverityFilter = (
  notification: Notification,
): boolean => {
  return autoNotificationReaderSeverityConfig.includes(
    notification.data.severity,
  );
};
