import { Box } from "@mittwald/flow-components/dist/components/Box";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import { RegistryLoginInputs } from "../../../components/RegistryLoginInputs";

export const LoginDataStep: FC = () => {
  return (
    <WizardStep id="loginData" indicatorText="loginData">
      <Box _itemGap="m">
        <Section.Item headline="loginData">
          <Text i18n="description" />
          <RegistryLoginInputs />
        </Section.Item>
      </Box>
    </WizardStep>
  );
};

export default LoginDataStep;
