import { Section } from "@mittwald/flow-components/dist/components/Section";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import React, { FC, useEffect } from "react";
import { downloadRecoveryCodes, joinRecoveryCodes } from "../../../../lib";
import { DownloadRecoveryCodesProps } from "../../../../types";

const DownloadRecoveryCodes: FC<DownloadRecoveryCodesProps> = (props) => {
  const { recoveryCodes, singlePartDescription } = props;

  useEffect(() => downloadRecoveryCodes(joinRecoveryCodes(recoveryCodes)), []);

  return (
    <Section.Layout>
      <Section.Item headline="recoveryCodes">
        <Text i18n="description.part1" />
        {!singlePartDescription && <Text i18n="description.part2" />}
      </Section.Item>
      <Section.Item>
        <StaticInformation
          label="recoveryCodes"
          text={
            <TextLink
              action={() =>
                downloadRecoveryCodes(joinRecoveryCodes(recoveryCodes))
              }
              i18n={{ text: "mittwald-recovery-codes.txt" }}
            />
          }
        />
      </Section.Item>
    </Section.Layout>
  );
};

export default DownloadRecoveryCodes;
