import { Box } from "@mittwald/flow-components/dist/components/Box";
import { spacing } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import styled from "styled-components";

export const MarkdownContainer = styled(Box)`
  li {
    margin: 0 0 ${spacing.base.s};
  }
`;

export default MarkdownContainer;
