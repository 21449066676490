import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import SelectBox, {
  SelectBoxOptions,
} from "@mittwald/flow-components/dist/components/SelectBox";
import React, { FC, useEffect } from "react";
import AppInstallation, {
  InstallSystemSoftwareInput,
} from "../../../../../../model/app/AppInstallation";
import SystemSoftwareUI from "../../../../../../model/ui/app/SystemSoftwareUI";

interface Props {
  appInstallation: AppInstallation;
}

export const SystemSoftwareStepContent: FC<Props> = (props) => {
  const systemSoftwares = props.appInstallation.useInstallableSystemSoftwares();

  const options: SelectBoxOptions = systemSoftwares.map((s) => {
    return {
      title: SystemSoftwareUI.of(s).getTitle(),
      value: s.id,
      logo: SystemSoftwareUI.of(s).getIcon(),
    };
  });

  const form = useFormContext<InstallSystemSoftwareInput>();

  useEffect(() => {
    form.setValue("systemSoftwareVersionId", "");
    form.setValue("systemSoftwareId", "");
  }, []);

  return (
    <SelectBox
      minOptionWidth={175}
      name="systemSoftwareId"
      options={options}
      rules={{ required: true }}
    />
  );
};

export default SystemSoftwareStepContent;
