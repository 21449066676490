import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { Registry, RegistryApiData } from "./Registry";

export class RegistryList extends ListModel<Registry> {
  public static fromApiData(data: RegistryApiData[]): RegistryList {
    return new RegistryList(data.map((d) => Registry.fromApiData(d)));
  }

  public static useLoadAllByProjectId(projectId: string): RegistryList {
    const registries = mittwaldApi.containerListRegistries
      .getResource({ path: { projectId } })
      .useWatchData()
      .map((r) => Registry.fromApiData(r));

    return new RegistryList(registries);
  }

  public validateDuplicate(hostname: string): boolean {
    return !this.items.find((r) => r.url === hostname);
  }
}
