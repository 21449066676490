import { types } from "mobx-state-tree";

export const onboardingStoreModel = types
  .model({
    completed: types.array(types.string),
  })
  .actions((self) => ({
    complete: (onboardingTopic: string) => {
      if (!self.completed.includes(onboardingTopic))
        self.completed.push(onboardingTopic);
    },
  }))
  .views((self) => ({
    isCompleted: (topic: string): boolean => {
      return self.completed.includes(topic);
    },
  }));
