import { useForm } from "@mittwald/flow-components/dist/components/Form";
import useWizardState from "@mittwald/flow-components/dist/components/Wizard/hooks/useWizardState";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import React, { FC, useEffect } from "react";
import {
  DatabaseTypes,
  NewDatabaseInputs,
} from "../../../../../model/database/Database";
import { MySqlDatabase } from "../../../../../model/database/MySqlDatabase";
import { MySqlVersionList } from "../../../../../model/database/MySqlVersionList";
import { RedisDatabase } from "../../../../../model/database/RedisDatabase";
import { RedisVersionList } from "../../../../../model/database/RedisVersionList";
import GeneralStep from "./components/GeneralStep";
import TypeStep from "./components/TypeStep";
import UserStep from "./components/UserStep";

export const CreateDatabaseWizard: FC = () => {
  const { projectId } = usePathParams("projectId");
  const wizardState = useWizardState();

  const mySqlVersionList = MySqlVersionList.useLoadAllByProjectId(projectId);
  const redisVersionList = RedisVersionList.useLoadAllByProjectId(projectId);

  const form = useForm<NewDatabaseInputs, string>({
    translationKey: "addDatabase",
    showSuccessFeedback: true,
    defaultValues: {
      user: {
        password: "",
      },
      database: {
        description: "",
        version: mySqlVersionList.latestVersion,
      },
      description: "",
      version: redisVersionList.latestVersion,
      projectId,
    },
    onSubmit: async (values) => {
      values.type === DatabaseTypes.mySql
        ? await MySqlDatabase.createNew(values)
        : await RedisDatabase.createNew(values);
    },
  });

  const watchedType = form.watch("type");

  const mySqlSelected = watchedType === DatabaseTypes.mySql;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (watchedType) {
      wizardState.gotoNextStep();
    }
  }, [watchedType]);

  return (
    <WizardModal
      form={form}
      steps={["type", "general", "user"]}
      wizardState={wizardState}
    >
      <TypeStep />
      <GeneralStep projectId={projectId} />
      {mySqlSelected && <UserStep />}
    </WizardModal>
  );
};

export default CreateDatabaseWizard;
