import { AvatarSource } from "@mittwald/flow-components/dist/components/Avatar";
import { useImageUrlBuilder } from "../../../hooks/useFileServiceUrlBuilder";
import Server from "../../server/Server";

export class ServerUI {
  public readonly server: Server;

  private constructor(server: Server) {
    this.server = server;
  }

  public static of = (server: Server): ServerUI => {
    return new ServerUI(server);
  };

  public useServerAvatarSource(): AvatarSource {
    const imageUrl = useImageUrlBuilder();

    const initials = this.server.data.description;
    const url = imageUrl(this.server.data.imageRefId);

    return {
      url,
      initials,
    };
  }
}

export default ServerUI;
