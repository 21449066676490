import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";
import UserInputInformation from "../../../../../../components/UserInputInformation/UserInputInformation";
import { App } from "../../../../../../model/app/App";
import { AppInstallationCreationInputWithAppId } from "../InstallApp";

export const ConfirmationStepContent: FC = () => {
  const form = useFormContext<AppInstallationCreationInputWithAppId>();

  const { description, appVersionId, appId, userInputs } = form.getValues();
  const app = App.useLoadById(appId);
  const version = app.useVersions(true).findById(appVersionId);

  const shownUserInputs = version?.userInputs;

  return (
    <Section.Layout>
      <Section.Item
        headline={{ id: "confirmation", values: { app: app.name } }}
      >
        <Text i18n="confirmationDescription" />
        <ColumnLayout large={[1, 1, 1]} medium={[1, 1]}>
          <StaticInformation
            key="description"
            label="description"
            text={{ text: description }}
          />
          {!app.isCustomApp && (
            <StaticInformation
              key="version"
              label="version"
              text={{ text: version?.data.externalVersion }}
            />
          )}
        </ColumnLayout>
      </Section.Item>
      {!shownUserInputs?.isEmpty && (
        <Section.Item>
          <ColumnLayout large={[1, 1, 1]} medium={[1, 1]}>
            {shownUserInputs?.items.map((input) => (
              <UserInputInformation
                key={input.name}
                userInput={input}
                value={userInputs?.[input.name]}
              />
            ))}
          </ColumnLayout>
        </Section.Item>
      )}
    </Section.Layout>
  );
};
