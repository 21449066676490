import { Role } from "../acl/Role";
import NotificationList from "../../notification/NotificationList";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

ModelActionBuilderRegistry.register({
  model: NotificationList,
  roles: [Role.anybody],
  method: "markAllAsRead",
  altAction: (action) => ({
    action,
    confirmation: { modalTranslationKey: "markAllAsRead", required: true },
    successFeedback: "always",
  }),
});
