import { Placeholder } from "@mittwald/flow-components/dist/components/PlaceholderBox";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import { iconLock } from "@mittwald/flow-icons/dist/lock";
import { RedirectToLink } from "@mittwald/flow-lib/dist/components/RedirectToLink";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import React, { FC } from "react";
import useAutoLogoutOnExpiredSession from "../../hooks/useAutoLogoutOnExpiredSession";
import { useCheckAuthenticated } from "../../hooks/useCheckAuthenticated";

export const NoAccess: FC = () => {
  const linkBuilder = useLinkBuilder();
  const authenticated = useCheckAuthenticated();

  useAutoLogoutOnExpiredSession();

  return (
    <Section.Item>
      {authenticated ? (
        <TranslationProvider name="noAccess" type="page">
          <Placeholder
            action={linkBuilder("dashboard")}
            actionTitle="action"
            description="description"
            error
            headline="headline"
            icon={iconLock}
          />
        </TranslationProvider>
      ) : (
        <RedirectToLink linkId="login" />
      )}
    </Section.Item>
  );
};

export default NoAccess;
