import ChangelogEntry from "./ChangelogEntry";
import { data } from "./data";

export class ChangelogEntryList {
  public readonly entries: ChangelogEntry[];

  public constructor(entries: ChangelogEntry[]) {
    this.entries = entries;
  }

  public static useLoad(): ChangelogEntryList {
    const allEntries = data
      .map((d) => new ChangelogEntry(d))
      .sort((l, r) => (l < r ? 1 : -1));

    return new ChangelogEntryList(allEntries);
  }

  public useNewEntries(): ChangelogEntry[] {
    return this.entries.filter((e) => e.useIsNew());
  }

  public useOldEntries(): ChangelogEntry[] {
    return this.entries.filter((e) => !e.useIsNew());
  }
}

export default ChangelogEntryList;
