import { useForm } from "@mittwald/flow-components/dist/components/Form";
import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import Section from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import useWizardState from "@mittwald/flow-components/dist/components/Wizard/hooks/useWizardState";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { iconApp } from "@mittwald/flow-icons/dist/app";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";
import React, { FC, useEffect } from "react";
import DisableForm from "../../../../../components/DisableForm";
import { App } from "../../../../../model/app/App";
import AppInstallation, {
  AppInstallationCreationInput,
} from "../../../../../model/app/AppInstallation";
import AppList from "../../../../../model/app/AppList";
import { SetUserInputFieldDefaultValues } from "../../components/SetUserInputFieldDefaultValues";
import UserInputFields from "../../components/UserInputFields";
import { AppStepContent } from "./components/AppStepContent";
import ConfigurationStepContent from "./components/ConfigurationStepContent";
import { ConfirmationStepContent } from "./components/ConfirmationStepContent";

const userInputsFieldPrefix = "userInputs";

export type AppInstallationCreationInputWithAppId =
  AppInstallationCreationInput & { appId: string };

export const InstallApp: FC = () => {
  const { projectId } = usePathParams("projectId");
  const wizardState = useWizardState();

  const form = useForm<AppInstallationCreationInputWithAppId, string>({
    defaultValues: {
      appId: "",
      appVersionId: "",
      projectId,
      userInputs: undefined,
      description: "",
    },
    translationKey: "createAppInstallation",
    onSubmit: async (values) => {
      await AppInstallation.create(values);
    },
  });

  const [watchedAppId, watchedAppVersionId] = form.watch([
    "appId",
    "appVersionId",
  ]);

  const allApps = AppList.useLoadAll().useItems();
  const app = allApps.find((a) => a.id === watchedAppId);
  const appVersions =
    watchedAppVersionId && app ? app.useVersions(true) : App.useEmptyVersions();
  const appVersion = watchedAppVersionId
    ? appVersions.findById(watchedAppVersionId)
    : undefined;

  useEffect(() => {
    if (watchedAppId) {
      wizardState.gotoNextStep();
    }
  }, [watchedAppId]);

  const appStep = (
    <WizardIntroStep
      description="appDescription"
      headline="app"
      headlineIcon={iconApp}
      id="app"
      indicatorText="appStep"
    >
      <Suspense
        loadingView={
          <DisableForm>
            <LoadingView _height={100} />
          </DisableForm>
        }
      >
        <AppStepContent apps={allApps} projectId={projectId} />
      </Suspense>
    </WizardIntroStep>
  );

  const configurationStep = (
    <WizardStep id="configuration" indicatorText="configurationStep">
      <Suspense
        loadingView={
          <DisableForm>
            <LoadingView _height={100} />
          </DisableForm>
        }
      >
        <ConfigurationStepContent />
      </Suspense>
    </WizardStep>
  );

  const userInputSteps = appVersion?.userInputs.getAdditionalSteps() ?? [];
  const getUserInputStepId = (step: string): string => `userInput.${step}`;

  const userInputStepElements = appVersion?.userInputs
    .getAdditionalSteps()
    ?.map((step) => {
      return (
        <WizardStep
          id={getUserInputStepId(step)}
          indicatorText={[`${app?.slug}.${step}Step`, `${step}Step`]}
          key={step}
        >
          <Suspense
            loadingView={
              <DisableForm>
                <LoadingView _height={100} />
              </DisableForm>
            }
          >
            <Section.Item headline={{ id: step, values: { app: app?.name } }}>
              <Text
                i18n={[`${app?.slug}.${step}Description`, `${step}Description`]}
              />
              <UserInputFields
                fieldNamePrefix={userInputsFieldPrefix}
                step={step}
                userInputs={appVersion.userInputs}
              />
            </Section.Item>
          </Suspense>
        </WizardStep>
      );
    });

  const confirmationStep = (
    <WizardStep id="confirmation" indicatorText="confirmationStep">
      <ConfirmationStepContent />
    </WizardStep>
  );

  return (
    <>
      {appVersion && (
        <SetUserInputFieldDefaultValues
          appVersion={appVersion}
          fieldNamePrefix={userInputsFieldPrefix}
          form={form}
        />
      )}
      <WizardModal
        form={form}
        steps={[
          "app",
          "configuration",
          ...userInputSteps.map(getUserInputStepId),
          "confirmation",
        ]}
        wizardState={wizardState}
      >
        {appStep}
        {configurationStep}
        <>{userInputStepElements ?? []}</>
        {confirmationStep}
      </WizardModal>
    </>
  );
};

export default InstallApp;
