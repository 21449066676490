import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import DatePicker from "@mittwald/flow-components/dist/components/DatePicker";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import TextArea from "@mittwald/flow-components/dist/components/TextArea";
import TextField from "@mittwald/flow-components/dist/components/TextField";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { DateTime } from "luxon";
import React, { FC, useEffect } from "react";
import splitSshKey, {
  sshPublicKeyRegex,
} from "../../../../../../lib/splitSshKey";
import SshKey, { NewSshKeyInputs } from "../../../../../../model/signup/SshKey";
import SshKeyUI from "../../../../../../model/ui/signup/SshKeyUI";

export const CreateProfileSshKey: FC = () => {
  const visibility = useVisibilityController();

  const form = useForm<NewSshKeyInputs>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    translationKey: "addSshKey",
    defaultValues: {
      publicKey: "",
      comment: "",
      expiresAt: undefined,
    },
    onSubmit: async (values) => {
      await SshKey.createNew({
        ...values,
      });
    },
  });

  const { publicKey } = form.watch();

  useEffect(() => {
    // separate comment and public key into their input fields, if a ssh key with a comment is added
    const sshKey = splitSshKey(publicKey);
    if (sshKey) {
      form.setValue("publicKey", sshKey.publicKey, { shouldValidate: false });
      if (sshKey.comment) {
        form.setValue("comment", sshKey.comment);
      } else {
        form.setFocus("comment");
      }
    }
  }, [publicKey]);

  return (
    <DefaultModal
      description="description"
      headline="createProfileSshKey"
      headlineIcon={SshKeyUI.icon}
      primary={{ action: [{ form }, visibility.hide], ok: true }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Item>
          <TextArea
            _height={150}
            autoFocus
            infoMessage={{ message: "keyHint" }}
            label="publicKey"
            name="publicKey"
            placeholder="placeholder"
            rules={{
              validate: {
                invalidPublicKey: (value: string) =>
                  sshPublicKeyRegex.test(value),
              },
              required: true,
            }}
          />
          <ColumnLayout medium={[1, 1]}>
            <TextField
              label="comment"
              name="comment"
              rules={{
                required: true,
              }}
            />
            <DatePicker
              infoMessage={{ message: "expiresAt" }}
              label="label"
              name="expiresAt"
              whitelist={{ fromDate: DateTime.local().plus({ days: 1 }) }}
            />
          </ColumnLayout>
        </Section.Item>
      </Form>
    </DefaultModal>
  );
};

export default CreateProfileSshKey;
