import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import { iconEmail } from "@mittwald/flow-icons/dist/email";
import { iconFileCertificate } from "@mittwald/flow-icons/dist/fileCertificate";
import { iconSubdomain } from "@mittwald/flow-icons/dist/subdomain";
import React, { FC } from "react";
import { Text } from "@mittwald/flow-components/dist/components/Text/Text";
import Section from "@mittwald/flow-components/dist/components/Section";
import Domain from "../../../../../../model/domain/Domain";

interface Props {
  domain: Domain;
}

export const DependencyStep: FC<Props> = (props) => {
  const { domain } = props;

  return (
    <WizardIntroStep
      description={{
        id: "intro.description",
        values: { domain: domain.data.domain },
      }}
      headline={{ id: "intro.headline" }}
      headlineIcon={iconDelete}
      id="dependencies"
      indicatorText="dependencies"
    >
      <Section.Item headline={{ id: "headline" }}>
        <Text i18n={{ id: "description" }} />
        <ColumnLayout medium={[1, 1]}>
          <TextWithIconLayout icon={iconSubdomain}>
            <Text i18n={{ id: "subdomains" }} />
          </TextWithIconLayout>
          <TextWithIconLayout icon={iconEmail}>
            <Text i18n={{ id: "email" }} />
          </TextWithIconLayout>
          <TextWithIconLayout icon={iconFileCertificate}>
            <Text i18n={{ id: "ssl" }} />
          </TextWithIconLayout>
        </ColumnLayout>
        <CheckBox
          name="confirmationDomainDependencies"
          rules={{ required: true }}
          selectableBoxProps={{ style: { alignSelf: "stretch" } }}
          title={{
            id: "confirmDependencies",
          }}
        />
      </Section.Item>
    </WizardIntroStep>
  );
};

export default DependencyStep;
