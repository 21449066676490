import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import React from "react";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import ModelRelationTarget from "../../../components/ModelRelationTarget/ModelRelationTarget";
import Notification from "../../notification/Notification";
import { ModelRelationUI } from "../misc/ModelRelationUI";

export class NotificationUI {
  public readonly notification: Notification;

  private constructor(notification: Notification) {
    this.notification = notification;
  }

  public static of(notification: Notification): NotificationUI {
    return new NotificationUI(notification);
  }

  public getTranslationKey(type: "title" | "description"): string {
    return joinTranslationKeys(
      "notification",
      this.notification.data.type,
      type,
    );
  }

  public getTranslation(type: "title" | "description"): I18nDefinition {
    return {
      id: this.getTranslationKey(type),
      values: {
        ref: <ModelRelationTarget relation={this.notification.relation} />,
      },
    };
  }

  public isActionAvailable(): boolean {
    return !!ModelRelationUI.of(this.notification.relation).useDetailsLink();
  }

  public buildAction(): AnyAction {
    return {
      useValue: () => {
        const link = ModelRelationUI.of(
          this.notification.relation,
        ).useDetailsLink();

        if (!link) {
          return;
        }

        return [
          link,
          () => {
            void this.notification.markAsRead();
          },
        ];
      },
    };
  }
}

export default NotificationUI;
