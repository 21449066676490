import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";

type ApiSchema =
  MittwaldApi.Paths.V2_Notification_Unread_Counts.Get.Responses.$200.Content.Application_Json;

export class UnreadNotificationCount {
  public readonly data: ApiSchema;

  private constructor(data: ApiSchema) {
    this.data = Object.freeze(data);
  }

  public static useLoad(): UnreadNotificationCount | undefined {
    const data = mittwaldApi.notificationsCountUnreadNotifications
      .getResource({})
      .useWatchData({ optional: true, throwOnError: false });

    return data ? new UnreadNotificationCount(data) : undefined;
  }
}
