import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { FC, useEffect } from "react";
import {
  AppInstallation,
  InstallSystemSoftwareInput,
} from "../../../../../../model/app/AppInstallation";
import SystemSoftware from "../../../../../../model/app/SystemSoftware";
import SystemSoftwareUI from "../../../../../../model/ui/app/SystemSoftwareUI";
import SystemSoftwareVersionSelect from "../../../components/SystemSoftwareVersionSelect";
import SystemSoftwareTitle from "../../../components/SystemSoftwareTitle";

interface Props {
  appInstallation: AppInstallation;
}

export const ConfigurationStepContent: FC<Props> = (props) => {
  const form = useFormContext<InstallSystemSoftwareInput>();
  const systemSoftwareId = form.watch("systemSoftwareId");

  const systemSoftware = SystemSoftware.useLoadById(systemSoftwareId);
  const systemSoftwareUI = SystemSoftwareUI.of(systemSoftware);
  const appVersion = props.appInstallation.useVersion();
  const systemSoftwareVersionsInRange =
    systemSoftware.useVersionsInRange(appVersion);

  useEffect(() => {
    const latestVersion = systemSoftwareVersionsInRange.latest();
    form.setValue("systemSoftwareVersionId", latestVersion.data.id);
  }, [systemSoftwareId]);

  return (
    <Section.Item
      headline={{
        id: "configuration",
        values: {
          app: <SystemSoftwareTitle systemSoftware={systemSoftware} />,
        },
      }}
      headlineIcon={systemSoftwareUI.getIcon(true)}
    >
      <Text i18n="configurationDescription" />
      <ColumnLayout large={[1, 1]} medium={[2, 1]}>
        <SystemSoftwareVersionSelect
          name="systemSoftwareVersionId"
          versions={systemSoftwareVersionsInRange.useItems()}
        />
      </ColumnLayout>
    </Section.Item>
  );
};

export default ConfigurationStepContent;
