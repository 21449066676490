import { useEffect } from "react";
import { mittwaldApi } from "../api/Mittwald";
import sessionStore from "../store/session";
import { useAccessToken } from "./useAccessToken";

export const useAutoLogoutOnExpiredSession = (): void => {
  const accessToken = useAccessToken();

  const checkApiToken = mittwaldApi.userCheckToken
    .getResource(accessToken ? {} : null)
    .useWatchData({
      optional: true,
    });

  const sessionIsExpired = !!accessToken && !checkApiToken;

  useEffect(() => {
    if (sessionIsExpired) {
      sessionStore.logout();
    }
  }, [sessionIsExpired]);
};

export default useAutoLogoutOnExpiredSession;
