import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import { iconBellSolid } from "@mittwald/flow-icons/dist/bellSolid";
import React, { FC } from "react";
import { UnreadNotificationCount } from "../../../model/notification/UnreadNotificationCount";
import NotificationCenter from "../../../pages/app/notificationCenter/NotificationCenter";
import * as Styled from "../styled";
import { useTranslation } from "@mittwald/flow-components/dist/hooks/useTranslation";

export const NotificationBell: FC = () => {
  const notificationCount = UnreadNotificationCount.useLoad()?.data;
  const hasNotifications = notificationCount
    ? notificationCount.total > 0
    : false;
  const translate = useTranslation();

  return (
    <Box _position="relative">
      <Styled.HeaderButton
        action={() =>
          showModal("showNotificationCenter", <NotificationCenter />)
        }
        aria-label={translate("notifications")}
        icon={iconBellSolid}
        ok={hasNotifications}
      />
      {hasNotifications && (
        <Styled.Bobbale
          count={notificationCount ? notificationCount.total : 0}
          success={true}
        />
      )}
    </Box>
  );
};
