import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import Customer from "../../../../../../model/customer/Customer";

const CustomerEmailInformation: FC<{ customerId: string }> = ({
  customerId,
}) => {
  const customer = Customer.useLoadById(customerId);
  const customerEmail = customer.contact?.data.emailAddress;

  return (
    <TranslationProvider name="order.customer.information" type="component">
      <Text
        i18n={{
          id: "yearlyInvoice",
          values: { email: customerEmail },
        }}
      />
    </TranslationProvider>
  );
};

export default CustomerEmailInformation;
