import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { ColumnLayoutContainer } from "@mittwald/flow-components/dist/components/ColumnLayoutContainer";
import { InlineCode } from "@mittwald/flow-components/dist/components/InlineCode";
import { LinkList } from "@mittwald/flow-components/dist/components/LinkList";
import { OptionsSection } from "@mittwald/flow-components/dist/components/OptionsSection";
import { QRCode } from "@mittwald/flow-components/dist/components/QRCode/QRCode";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Skeleton } from "@mittwald/flow-components/dist/components/Skeleton";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation/StaticInformation";
import { AnimationController } from "@mittwald/flow-components/dist/hooks/useAnimationController";
import { ResourceState } from "@mittwald/flow-lib/dist/resources/types";
import React, { FC, useEffect } from "react";
import { mittwaldApi } from "../../../../../../../api/Mittwald";
import { CodeInput } from "../../../../../../../components/CodeInput";

export const ScanStep: FC<{ rejectionAnimation: AnimationController }> = (
  props,
) => {
  const initMfaResource = mittwaldApi.userInitMfa.getResource({});
  const initMfa = initMfaResource.useWatchData();
  const secret = initMfa.url.split("secret=")[1];

  const generateNewCode = (): void => {
    initMfaResource.expire();
  };

  const isLoading = initMfaResource.useWatchState() === ResourceState.LOADING;

  const secretCode = <InlineCode>{secret}</InlineCode>;

  useEffect(() => {
    generateNewCode();
  }, []);

  return (
    <Section.Layout>
      <Section.Item>
        <ColumnLayout medium={[1, 1]}>
          <QRCode
            _width={160}
            imageData={initMfa.barcode}
            imageType="jpg"
            skeleton={isLoading}
          />
          <ColumnLayoutContainer>
            {isLoading && (
              <StaticInformation
                label="secretKey"
                text={<Skeleton _width={75} />}
              />
            )}
            {!isLoading && (
              <StaticInformation
                copyContent={secret}
                label="secretKey"
                text={secretCode}
              />
            )}
            <CodeInput
              label="2fa"
              rejectionAnimation={props.rejectionAnimation}
            />
          </ColumnLayoutContainer>
        </ColumnLayout>
      </Section.Item>
      <OptionsSection>
        <LinkList.Item action={generateNewCode} i18n="generateNewCode" />
      </OptionsSection>
    </Section.Layout>
  );
};
