const forceDownload = (content: string, name: string): void => {
  const element = document.createElement("a");
  const file = new Blob([content], { type: "text/plain" });
  element.setAttribute("style", "display:none;");
  element.href = URL.createObjectURL(file);
  element.download = name;
  document.body.appendChild(element); // Required for this to work in Firefox
  element.click();
  element.remove();
};

export { forceDownload };
