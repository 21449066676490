import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { Translate } from "@mittwald/flow-components/dist/components/Translate";
import React from "react";
import { Notification } from "../../../../../../model/notification/Notification";
import NotificationUI from "../../../../../../model/ui/notification/NotificationUI";

export const NameColumn: ResourceListColumnRenderer<Notification> = ({
  resource,
}) => (
  <Translate i18n={NotificationUI.of(resource).getTranslationKey("title")} />
);
