import { Select } from "@mittwald/flow-components/dist/components/Select";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";
import { AppInstallationList } from "../../../../../model/app/AppInstallationList";
import { AppInstallationUI } from "../../../../../model/ui/app/AppInstallationUI";

interface Props {
  name?: string;
}

const AppInstallationSelect: FC<Props> = (props) => {
  const { name = "appInstallationId" } = props;
  const appInstallationList = AppInstallationList.useLoadAllByPathParam();
  const selectOptions =
    AppInstallationUI.getAppInstallationSelectOptions(appInstallationList);

  return (
    <TranslationProvider name="appInstallationSelect" type="component">
      <Select
        label="appInstallation"
        name={name}
        options={selectOptions}
        placeholder="appInstallationPlaceholder"
        rules={{ required: true }}
      />
    </TranslationProvider>
  );
};

export default AppInstallationSelect;
