import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { Select } from "@mittwald/flow-components/dist/components/Select";
import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import {
  TextField,
  TextFieldTypes,
} from "@mittwald/flow-components/dist/components/TextField";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import { passwordValidationSchema } from "@mittwald/flow-lib/dist/lib/generatePassword";
import React, { FC } from "react";
import UserInputDataSourceUI from "../../model/ui/misc/UserInputDataSourceUI";
import UserInputUI from "../../model/ui/misc/UserInputUI";
import CountrySelect from "../../pages/app/components/CountrySelect";
import { EmailAddressInput } from "../../pages/app/components/EmailAddressInput";
import FullNameInput from "../../pages/app/components/FullNameInput";
import StreetAndHouseNumberInput from "../../pages/app/components/StreetAndHouseNumberInput";
import StaticFormField from "./components/StaticTextField";
import { SharedUserInputFieldProps, UserInputFieldProps } from "./types";

export const UserInputField: FC<UserInputFieldProps> = (props) => {
  const { userInput, fieldNamePrefix, label, rules, disabled = false } = props;
  const schema = userInput.schema;
  const dataType = userInput.dataType;
  const inputUi = UserInputUI.of(userInput);
  const dataSourceUi = userInput.dataSource
    ? UserInputDataSourceUI.of(userInput.dataSource)
    : undefined;

  const fieldName = inputUi.getFieldName({ prefix: fieldNamePrefix });

  const mergedRules = {
    required: userInput.data.required || !!userInput.schema.required,
    minLength: userInput.schema.minLength,
    maxLength: userInput.schema.maxLength,
    pattern: userInput.schema.pattern
      ? new RegExp(userInput.schema.pattern)
      : undefined,
    ...rules,
  };

  const sharedFieldProps: SharedUserInputFieldProps = {
    name: fieldName,
    label: inputUi.getLabelText({}, label ?? userInput.schema.title),
    rules: mergedRules,
    disabled: disabled,
  };

  const dataSourceSelectOptions = dataSourceUi?.useSelectOptions();

  if (dataSourceSelectOptions) {
    return <Select {...sharedFieldProps} options={dataSourceSelectOptions} />;
  }

  if (dataType === "boolean") {
    return <CheckBox {...sharedFieldProps} title={sharedFieldProps.label} />;
  }

  if (
    userInput.name.toLowerCase().includes("country") ||
    userInput.name.toLowerCase().includes("nationality")
  ) {
    const countries: string[] | undefined =
      userInput.schema.enum !== undefined
        ? userInput.schema.enum.map((e) => String(e))
        : undefined;
    return (
      <CountrySelect allowedCountryCodes={countries} {...sharedFieldProps} />
    );
  }

  if (userInput.name === "state" && userInput.schema.enum !== undefined) {
    const states: SelectOptions = userInput.schema.enum.map((value) => {
      if (value.length > 2) {
        return { value, label: { text: value }, searchValue: value };
      }
      return {
        value,
        label: inputUi.getLabelText({ suffix: value }),
        searchValue: value,
      };
    });

    return (
      <Select
        isSearchable
        options={states}
        placeholder={joinTranslationKeys("placeholder", fieldName)}
        {...sharedFieldProps}
      />
    );
  }

  if (userInput.name === "street") {
    return <StreetAndHouseNumberInput {...sharedFieldProps} />;
  }

  if (userInput.name === "name") {
    return <FullNameInput {...sharedFieldProps} />;
  }

  if (userInput.format === "email") {
    return <EmailAddressInput {...sharedFieldProps} />;
  }

  if (userInput.format === "password") {
    return (
      <TextField
        type="passwordWithGenerator"
        {...sharedFieldProps}
        rules={{
          required: true,
          validate: {
            invalidPasswordSchema: (value: string) =>
              !passwordValidationSchema.test(value),
          },
        }}
      />
    );
  }

  if (schema.const !== undefined) {
    return <StaticFormField {...sharedFieldProps} staticValue={schema.const} />;
  }

  if (
    (dataType === "text" ||
      dataType === "select" ||
      schema.type === "string") &&
    schema.enum !== undefined
  ) {
    if (schema.enum.length <= 1) {
      return (
        <StaticFormField {...sharedFieldProps} staticValue={schema.enum[0]} />
      );
    }

    if (schema.enum.length <= 2) {
      return (
        <SelectBox
          {...sharedFieldProps}
          options={inputUi.getSchemaSelectBoxOptions()}
        />
      );
    }

    return (
      <Select
        {...sharedFieldProps}
        options={inputUi.getSchemaSelectOptions()}
        placeholder={joinTranslationKeys("placeholder", fieldName)}
      />
    );
  }

  return (
    <TextField
      type={userInput.data.format as TextFieldTypes}
      {...sharedFieldProps}
    />
  );
};

export default UserInputField;
