import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconPublicKey } from "@mittwald/flow-icons/dist/publicKey";

import React, { FC, useEffect } from "react";
import splitSshKey from "../../../../../lib/splitSshKey";
import { SftpUser } from "../../../../../model/access/SftpUser";
import { SshUser } from "../../../../../model/access/SshUser";
import { AddPublicKeyInputs } from "../../../../../model/access/types";
import PublicKeyFields from "../../components/publicKeyFields";

interface Props {
  sftpOrSshUser: SftpUser | SshUser;
}

export const CreatePublicKey: FC<Props> = (props) => {
  const visibility = useVisibilityController();

  const form = useForm<AddPublicKeyInputs>({
    defaultValues: { key: "", comment: "" },
    translationKey: "addPublicKey",
    mode: "onBlur",
    reValidateMode: "onBlur",
    onSubmit: async (values) => {
      const result = await props.sftpOrSshUser.addPublicKey(values);

      if (result === false) {
        form.setError("key", {
          message: "invalidKey",
        });
        return false;
      }
    },
  });

  const key = form.watch("key");

  useEffect(() => {
    // separate comment and public key into their input fields, if an ssh key with a comment is added
    const sshKey = splitSshKey(key.trimEnd());

    if (sshKey) {
      form.setValue("key", sshKey.publicKey, { shouldValidate: false });
      if (sshKey.comment) {
        form.setValue("comment", sshKey.comment);
      } else {
        form.setFocus("comment");
      }
    }
  }, [key]);

  return (
    <DefaultModal
      description="description"
      headline="createPublicKey"
      headlineIcon={iconPublicKey}
      primary={{ action: [{ form }, visibility.hide], ok: true }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Item>
          <PublicKeyFields />
        </Section.Item>
      </Form>
    </DefaultModal>
  );
};

export default CreatePublicKey;
