import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import React, { FC } from "react";
import { useCertificateStepState } from "../CertificateStep/CertificateStep";

export interface LinkedConnectionsStepProps {}

export const LinkedConnectionsStep: FC<LinkedConnectionsStepProps> = () => {
  const [certificateStepState] = useCertificateStepState();

  const existingIngressHostnames = certificateStepState.linkedIngresses
    .filter((ingress) => ingress.ssl.type === "externalSSL")
    .map((ingress) => ingress.hostname);

  const ingresses = certificateStepState.linkedIngresses.filter(
    (ingress) => !existingIngressHostnames.includes(ingress.hostname),
  );

  const main = ingresses.slice(0, 4);
  const other = ingresses.slice(4, ingresses.length);

  return (
    <WizardIntroStep
      description={{
        id:
          ingresses.length >= 1
            ? "linkedConnections.description"
            : "linkedConnections.emptyDescription",
        values: { count: main.length + other.length },
      }}
      headline="linkedConnections"
      headlineIcon={iconDomain}
      id="linkedConnections"
      indicatorText="linkedConnectionsStep"
    >
      <ColumnLayout large={[1, 1, 1]} medium={[1, 1]} small={[1]}>
        {main.map((i, key) => (
          <TextWithIconLayout
            icon={iconDomain}
            iconProps={{ _size: "m" }}
            key={key}
          >
            <Text _mb="s" i18n={{ text: i.hostname }} />
          </TextWithIconLayout>
        ))}
      </ColumnLayout>
      {other.length >= 1 && (
        <Text
          i18n={{ id: "otherDomains", values: { count: other.length } }}
          strong
        />
      )}
    </WizardIntroStep>
  );
};
