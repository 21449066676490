import { SelectBoxOptions } from "@mittwald/flow-components/dist/components/SelectBox";
import { iconReadAccess } from "@mittwald/flow-icons/dist/readAccess";
import iconWriteAccess from "@mittwald/flow-icons/dist/writeAccess";
import { allAccessAuthTypes } from "../../access/types";

export class AccessUI {
  public static accessAuthTypeSelectBoxOptions(): SelectBoxOptions {
    return allAccessAuthTypes
      .map((option) => ({
        title: option,
        value: option,
      }))
      .reverse();
  }

  public static sftpAccessLevelSelectBoxOptions(): SelectBoxOptions {
    return [
      {
        title: "readAccess",
        value: "read",
        icon: iconReadAccess,
        description: "readAccessDescription",
      },
      {
        title: "fullAccess",
        value: "full",
        icon: iconWriteAccess,
        description: "fullAccessDescription",
      },
    ];
  }
}

export default AccessUI;
