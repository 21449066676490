import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faAndroid } from "@fortawesome/free-brands-svg-icons/faAndroid";
import { faApple } from "@fortawesome/free-brands-svg-icons/faApple";
import { faLinux } from "@fortawesome/free-brands-svg-icons/faLinux";
import { faWindows } from "@fortawesome/free-brands-svg-icons/faWindows";
import { iconDesktop } from "@mittwald/flow-icons/dist/desktop";
import { iconMobile } from "@mittwald/flow-icons/dist/mobile";
import { DateTime } from "luxon";
import { iconSession } from "@mittwald/flow-icons/dist/session";
import { forceDownload } from "../../../lib/download";
import Session from "../../../model/signup/Session";

export const isInLessThan7Days = (date: DateTime): boolean => {
  const in7Days = DateTime.local().plus({ days: 7 });
  return date < in7Days;
};

export const moreThanXDaysAgo = (days: number, date?: string): boolean => {
  const atXDaysAgo = DateTime.local().minus({ days });
  return !!date && DateTime.fromISO(date) < atXDaysAgo;
};

export const sessionToOsIcon = (session: Session): IconDefinition => {
  switch (session.data.device.os) {
    case "Windows":
      return faWindows;
    case "Mac OS":
    case "iOS":
      return faApple;
    case "Linux":
    case "Ubuntu":
      return faLinux;
    case "Android":
      return faAndroid;
  }
  return iconSession;
};

export const sessionToDeviceTypeIcon = (session: Session): IconDefinition =>
  session.isMobile ? iconMobile : iconDesktop;

export const sessionToDeviceTypeIconTooltip = (session: Session): string =>
  session.isMobile ? "mobile" : "desktop";

export const joinRecoveryCodes = (codesToJoin: string[]): string => {
  return codesToJoin.join("\n");
};

export const downloadRecoveryCodes = (text: string): void => {
  forceDownload(text, "mittwald-recovery-codes.txt");
};
