import { FileData } from "@mittwald/flow-components/dist/components/FileInputField";

export const getCertificateData = (value: FileData[] | string): string => {
  if (typeof value !== "string") {
    const certificateData: string[] = [];
    value.forEach((file) => {
      if (file.encoding === "base64") {
        certificateData.push(atob(file.data).trim());
      } else {
        certificateData.push(file.data.trim());
      }
    });

    return certificateData.join("\n");
  }

  return value.trim();
};
