import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import SelectBox, {
  SelectBoxOptions,
} from "@mittwald/flow-components/dist/components/SelectBox";
import React, { FC, useEffect } from "react";
import { App, AppNames } from "../../../../../../model/app/App";
import Project from "../../../../../../model/project/Project";
import ProSpaceProject from "../../../../../../model/project/ProSpaceProject";
import AppUI from "../../../../../../model/ui/app/AppUI";
import { AppInstallationCreationInputWithAppId } from "../InstallApp";

interface Props {
  apps: App[];
  projectId: string;
}

export const AppStepContent: FC<Props> = (props) => {
  const project = Project.useLoadById(props.projectId);
  const isProSpaceLite =
    project instanceof ProSpaceProject && project.useIsProSpaceLite();

  const options: SelectBoxOptions = props.apps.map((app) => {
    const ui = AppUI.of(app);
    return {
      title: ui.getTitle(),
      value: app.id,
      logo: ui.appIcon,
      disabled: app.name === AppNames.node && isProSpaceLite,
      tooltip:
        app.name === AppNames.node && isProSpaceLite
          ? "notAvailableInProSpaceLite"
          : undefined,
    };
  });

  const form = useFormContext<AppInstallationCreationInputWithAppId>();

  useEffect(() => {
    form.setValue("appVersionId", "");
    form.setValue("appId", "");
    form.setValue("userInputs", undefined);
  }, []);

  return (
    <SelectBox
      minOptionWidth={175}
      name="appId"
      options={options}
      rules={{ required: true }}
    />
  );
};
