import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { DnsZoneUI, Ttl } from "../ui/domain/DnsZoneUI";
import { RecordUnset } from "./DnsZone";

export type ARecordApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordCombinedA;

type ARecordCustom =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_CombinedACustom;

export class ARecord {
  public readonly data: ARecordApiData;
  public readonly zoneId: string;
  public readonly isManaged: boolean;
  public readonly ttl: Ttl;
  public readonly records: string[];

  public constructor(data: ARecordApiData, zoneId: string) {
    this.data = Object.freeze(data);
    this.zoneId = zoneId;
    this.isManaged = "managedBy" in data;
    this.ttl = DnsZoneUI.ttlFromRecord(data);
    this.records = "settings" in data ? [...data.a, ...data.aaaa] : [];
  }

  public static fromApiData = (
    data: ARecordApiData,
    zoneId: string,
  ): ARecord => {
    return new ARecord(data, zoneId);
  };

  public async setRecords(values: ARecordCustom | RecordUnset): Promise<void> {
    await mittwaldApi.dnsUpdateRecordSet.request({
      path: { dnsZoneId: this.zoneId, recordSet: "a" },
      requestBody: values,
    });
  }

  public async setManaged(): Promise<void> {
    await mittwaldApi.dnsSetRecordSetManaged.request({
      path: { dnsZoneId: this.zoneId, recordSet: "a" },
    });
  }
}

export default ARecord;
