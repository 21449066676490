import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import React, { FC } from "react";
import { IconList } from "../../../../components/IconList/IconList";
import MarketplaceUI from "../../../../model/ui/marketplace/MarketplaceUI";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";

interface Props {
  scopes: string[];
}

const ScopeElements: FC<Props> = (props) => {
  const { scopes } = props;

  const scopeElements = scopes.map((scope) => (
    <IconList.Item
      i18n={joinTranslationKeys("extensionScope", scope)}
      icon={MarketplaceUI.getScopeIcon(scope)}
      key={scope}
    />
  ));

  return (
    <ColumnLayout large={[1, 1, 1]} medium={[1, 1]}>
      {scopeElements}
    </ColumnLayout>
  );
};

export default ScopeElements;
