import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { iconCronjob } from "@mittwald/flow-icons/dist/cronjob";
import React, { FC } from "react";
import AppInstallationSelect from "../../../../app/components/AppInstallationSelect/AppInstallationSelect";

export const GeneralStep: FC = () => {
  return (
    <WizardIntroStep
      description="cronjobDescription"
      headline="cronjob"
      headlineIcon={iconCronjob}
      id="general"
      indicatorText="description"
    >
      <Section.Item>
        <ColumnLayout medium={[1, 1]}>
          <TextField
            autoFocus
            label="description"
            name="description"
            rules={{ required: true }}
          />
          <AppInstallationSelect name="appId" />
        </ColumnLayout>
      </Section.Item>
    </WizardIntroStep>
  );
};

export default GeneralStep;
