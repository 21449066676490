import { iconBackup } from "@mittwald/flow-icons/dist/backup";
import { iconCalendar } from "@mittwald/flow-icons/dist/calendar";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import BacksideRenderer from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import Backup from "../../../model/backup/Backup";
import { BackupList } from "../../../model/backup/BackupList";
import BackupSchedule from "../../../model/backup/BackupSchedule";

export const backupPages = PageTree.build(BacksideRenderer, {
  "/app/projects/:projectId/backup": {
    props: {
      navigationSection: "component",
      tabNav: true,
    },
    icon: iconBackup,
    accessCheck: () =>
      ModelActionBuilder.build(BackupList, "useLoadAllByProjectId").hasAccess,
  },
  "/app/projects/:projectId/backup/backup": {
    props: {
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(Backup, "createNew"),
          },
          icon: iconBackup,
          title: "createBackup",
        },
      ],
    },
    views: { main: lazy(() => import("./backup/overview")) },
    icon: iconBackup,
    linkId: "backupOverview",
  },
  "/app/projects/:projectId/backup/backup/:backupId": {
    views: {
      pageTitle: lazy(() => import("./backup/components/BackupTitle")),
      main: lazy(() => import("./backup/details")),
    },
    icon: iconBackup,
    linkId: "backup",
  },

  "/app/projects/:projectId/backup/schedule": {
    props: {
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(BackupSchedule, "createNew"),
          },
          title: "createBackupSchedule",
          icon: iconCalendar,
        },
      ],
    },
    views: { main: lazy(() => import("./schedule/overview")) },
    icon: iconBackup,
    linkId: "backupScheduleOverview",
  },

  "/app/projects/:projectId/backup/schedule/:scheduleId": {
    views: {
      pageTitle: lazy(() => import("./schedule/components/ScheduleTitle")),
      main: lazy(() => import("./schedule/details/general")),
    },
    icon: iconCalendar,
    props: { tabNav: true },
  },
  "/app/projects/:projectId/backup/schedule/:scheduleId/details": {
    views: {
      main: lazy(() => import("./schedule/details/general")),
    },
    icon: iconCalendar,
    linkId: "backupScheduleDetails",
  },
  "/app/projects/:projectId/backup/schedule/:scheduleId/interval": {
    views: {
      main: lazy(() => import("./schedule/details/interval")),
    },
    icon: iconCalendar,
    linkId: "backupScheduleInterval",
  },
});
