import { size, spacing } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

export const StyledReactMarkdown = styled(ReactMarkdown)`
  font-size: ${size.font.text};
  line-height: ${size.lineHeight.text.default};

  ul:not(:first-child) {
    margin-top: ${spacing.base.m} 0;
  }

  li {
    margin: ${spacing.base.s} 0;
  }

  && {
    a {
      font-size: inherit;
      font-weight: normal;
    }
  }
`;

export default StyledReactMarkdown;
