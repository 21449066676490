import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";

import React from "react";
import CreateBackup from "../../../pages/app/backup/backup/modals/CreateBackup/CreateBackup";
import DeleteBackup from "../../../pages/app/backup/backup/modals/DeleteBackup/DeleteBackup";
import RequestBackupDownload from "../../../pages/app/backup/backup/modals/RequestBackupDownload/RequestBackupDownload";
import CreateBackupSchedule from "../../../pages/app/backup/schedule/modals/CreateBackupSchedule/CreateBackupSchedule";
import Backup from "../../backup/Backup";
import { BackupList } from "../../backup/BackupList";
import BackupSchedule from "../../backup/BackupSchedule";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultBackupRoles = [Role.projectAdmin, Role.projectDeveloper];

ModelActionBuilderRegistry.register({
  model: BackupList,
  roles: defaultBackupRoles,
  method: "useLoadAllByProjectId",
})
  .register({
    model: Backup,
    roles: defaultBackupRoles,
    method: "delete",
    altAction: (action) => () =>
      showModal("deleteBackup", <DeleteBackup backup={action.model} />),
  })
  .register({
    model: Backup,
    roles: defaultBackupRoles,
    method: "requestDownload",
    isAvailable: (backup) => !backup.hasExport,
    altAction: (action) => () =>
      showModal(
        "requestDownload",
        <RequestBackupDownload backup={action.model} />,
      ),
  })
  .register({
    model: Backup,
    roles: defaultBackupRoles,
    method: "deleteDownload",
    isAvailable: (backup) => backup.hasExport,
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deleteBackupDownload",
        required: true,
        modalProps: { headlineIcon: iconDelete },
      },
    }),
  })
  .register({
    model: BackupSchedule,
    roles: defaultBackupRoles,
    method: "delete",
    isAvailable: (schedule) => !schedule.isSystemBackup,
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deleteBackupSchedule",
        required: true,
        modalTranslationValues: {
          schedule: action.model.data.description,
        },
        modalProps: {
          headlineIcon: iconDelete,
        },
      },
    }),
  })
  .register({
    model: Backup,
    roles: defaultBackupRoles,
    method: "createNew",
    altAction: () => () => showModal("createBackup", <CreateBackup />),
  })
  .register({
    model: BackupSchedule,
    roles: defaultBackupRoles,
    method: "createNew",
    altAction: () => () =>
      showModal("createBackupSchedule", <CreateBackupSchedule />),
  });
