import { AvatarSource } from "@mittwald/flow-components/dist/components/Avatar";
import User from "../model/user/User";
import { useImageUrlBuilder } from "./useFileServiceUrlBuilder";

export const useUserAvatarSource = (userId: string): AvatarSource => {
  const imageUrl = useImageUrlBuilder();

  const user = User.useLoadById(userId);

  if (user.data.avatarRef) {
    return {
      url: imageUrl(user.data.avatarRef),
    };
  }

  return {
    initials: user.fullName,
  };
};

export default useUserAvatarSource;
