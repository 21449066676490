import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Select } from "@mittwald/flow-components/dist/components/Select";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import React, { FC } from "react";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconBackup } from "@mittwald/flow-icons/dist/backup";
import Backup, { NewBackupInputs } from "../../../../../../model/backup/Backup";
import BackupUI from "../../../../../../model/ui/backup/BackupUI";

export const CreateBackup: FC = () => {
  const { projectId } = usePathParams("projectId");
  const visibility = useVisibilityController();

  const form = useForm<NewBackupInputs>({
    translationKey: "createBackup",
    defaultValues: {
      description: "",
      expirationTime: "30d",
    },
    onSubmit: async (values) => {
      await Backup.createNew(
        {
          description: values.description,
          expirationTime: BackupUI.formatExpirationTime(values.expirationTime),
        },
        projectId,
      );
    },
  });

  return (
    <DefaultModal
      description="description"
      headline="createBackup"
      headlineIcon={iconBackup}
      primary={{ action: [{ form }, visibility.hide], ok: true }}
      visibility={visibility}
    >
      <Form controller={form}>
        <ColumnLayout medium={[1, 1]}>
          <TextField label="description" name="description" />
          <Select
            label="backupTtl"
            name="expirationTime"
            options={BackupUI.ttlOptions}
            rules={{ required: true }}
          />
        </ColumnLayout>
      </Form>
    </DefaultModal>
  );
};

export default CreateBackup;
