import { iconSearchEngine } from "@mittwald/flow-icons/dist/searchEngine";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import Project from "../../../model/project/Project";
import ProSpaceProject from "../../../model/project/ProSpaceProject";

export const searchPages = PageTree.build(BacksideRenderer, {
  "/app/projects/:projectId/searchengines": {
    sector: "basics",
    views: {
      main: lazy(() => import("./overview")),
    },
    props: {
      navigationSection: "component",
    },
    icon: iconSearchEngine,
    linkId: "searchEngines",
    accessCheck: () => {
      const project = Project.useLoadByPathParam();
      const isProSpaceLite =
        project instanceof ProSpaceProject
          ? project.useIsProSpaceLite()
          : false;
      return (
        project.useCheckMyRoleIsIn(["owner", "external"]) && !isProSpaceLite
      );
    },
  },
});
