import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";

import React from "react";
import CreateProfileSshKey from "../../../pages/app/profile/sshKeys/modals/CreateProfileSshKey/CreateProfileSshKey";
import { Role } from "../acl/Role";
import SshKey from "../../signup/SshKey";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultSshKeyRoles = [Role.anybody];

ModelActionBuilderRegistry.register({
  model: SshKey,
  method: "delete",
  roles: defaultSshKeyRoles,
  altAction: (action) => ({
    action,
    confirmation: {
      required: true,
      modalTranslationKey: "deleteSshKey",
      modalProps: {
        headlineIcon: iconDelete,
      },
    },
  }),
}).register({
  model: SshKey,
  method: "createNew",
  roles: defaultSshKeyRoles,
  altAction: () => () =>
    showModal("createProfileSshKey", <CreateProfileSshKey />),
});
