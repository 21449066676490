import Box from "@mittwald/flow-components/dist/components/Box";
import { getAvatarImageWidth } from "@mittwald/flow-components/dist/components/Avatar/lib";
import { Icon } from "@mittwald/flow-components/dist/components/Icon";
import { Skeleton } from "@mittwald/flow-components/dist/components/Skeleton";
import { UniversalBoundary } from "@mittwald/flow-components/dist/components/UniversalBoundary";
import { iconGlobe } from "@mittwald/flow-icons/dist/globe";
import React, { FC } from "react";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { UserInputsRecordObject } from "../../../../model/misc/userInput/UserInputRecordList";
import { ParsedValue } from "../../../../model/misc/userInput/UserInputRecord";

interface OwnerInformationProps {
  domainOwner: UserInputsRecordObject;
}

export const OwnerInformation: FC<OwnerInformationProps> = ({
  domainOwner,
}) => {
  const isStringValueSet = (value?: ParsedValue): value is string => {
    return typeof value === "string" && value.length > 0;
  };

  const name = isStringValueSet(domainOwner.name) ? domainOwner.name : "";
  const street = isStringValueSet(domainOwner.street) ? domainOwner.street : "";
  const zip = isStringValueSet(domainOwner.zip) ? domainOwner.zip : "";
  const city = isStringValueSet(domainOwner.city) ? domainOwner.city : "";
  const country = isStringValueSet(domainOwner.country)
    ? domainOwner.country
    : "";
  const email = isStringValueSet(domainOwner.email) ? domainOwner.email : "";

  return (
    <Box _itemGap="s">
      <Box _flexDirection="row" _itemGap="m">
        <Box
          _flexDirection="row"
          _justifyContent="center"
          _width={getAvatarImageWidth("m")}
        >
          <Icon _color="brand-primary-dark" _size="m" icon={iconGlobe} />
        </Box>
        <UniversalBoundary
          loadingView={
            <Text>
              <Skeleton _width={200} count={6} />
            </Text>
          }
        >
          <Box _flexDirection="column">
            <Text i18n="domainOwner" strong />
            <Text text={name} />
            <Text text={street} />
            <Text text={`${zip} ${city}`} />
            <Text text={`${country}`} />
            <Text text={email} />
          </Box>
        </UniversalBoundary>
      </Box>
    </Box>
  );
};
