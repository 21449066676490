import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC } from "react";
import { SharedUserInputFieldProps } from "../../../components/UserInputField/types";

export const FullNameInput: FC<SharedUserInputFieldProps> = (props) => {
  return (
    <TextField
      autoComplete="name"
      disabled={props.disabled}
      label={props.label ?? "fullName"}
      name={props.name}
      rules={props.rules}
    />
  );
};

export default FullNameInput;
