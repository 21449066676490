import { MittwaldApi, mittwaldApi } from "../../../../../api/Mittwald";
import { Project } from "../../../../../model/project";

export type ExternalCertificateOrderPreview =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Order_ExternalCertificateOrderPreviewResponse;

export const useOrderPreview = (
  certificateRequestId: string,
): ExternalCertificateOrderPreview => {
  const project = Project.useLoadByPathParam();

  const data = mittwaldApi.orderPreviewOrder
    .getResource({
      requestBody: {
        orderType: "externalCertificate",
        orderData: {
          certificateRequestId: certificateRequestId,
          projectId: project.id,
        },
      },
    })
    .useWatchData();

  return data as unknown as ExternalCertificateOrderPreview;
};
