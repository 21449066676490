import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { PlainTopPageRenderer } from "@mittwald/flow-renderer/dist/components/PlainTopPageRenderer";
import { lazy } from "react";

const WidgetDevOverview = lazy(
  () => import("../../widgets/dev/WidgetDevOverview"),
);

export const widgetsPages = PageTree.build(PlainTopPageRenderer, {
  "/widgets": {
    views: {
      main: WidgetDevOverview,
    },
  },
  "/widgets/:widget": {
    linkId: "widget",
    views: {
      main: WidgetDevOverview,
    },
  },
});
