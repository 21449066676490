import { faStream } from "@fortawesome/pro-regular-svg-icons/faStream";
import { iconHome } from "@mittwald/flow-icons/dist/home";

import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import BacksideRenderer from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import Dashboard from "../../../model/dashboard/Dashboard";

export const dashboardPages = PageTree.build(BacksideRenderer, {
  "/app/dashboard": {
    sector: "addons",
    views: {
      main: lazy(() => import("./dashboard")),
      pageTitle: lazy(() => import("./components/DashboardTitle")),
    },
    props: {
      toolSettingsPage: {
        useValue: () => ModelActionBuilder.build(Dashboard, "updateSettings"),
      },
    },
    icon: iconHome,
    linkId: "dashboard",
  },
  "/app/dashboard/onboarding": {
    views: {
      main: lazy(() => import("./onboarding/onboarding")),
    },
    icon: faStream,
    linkId: "onboarding",
  },
});
