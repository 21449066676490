import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconPassword } from "@mittwald/flow-icons/dist/password";

import React, { FC } from "react";
import { MySqlDatabase } from "../../../../../model/database/MySqlDatabase";
import {
  MySqlUser,
  UpdatePasswordInputs,
} from "../../../../../model/database/MySqlUser";

interface Props {
  databaseUser: MySqlUser;
}

export const UpdateDatabaseUserPasswordModal: FC<Props> = (props) => {
  const databaseDescription =
    MySqlDatabase.useLoadByPathParam().data.description;
  const { databaseUser } = props;
  const visibility = useVisibilityController();

  const form = useForm<UpdatePasswordInputs>({
    translationKey: "updatePassword",
    defaultValues: {
      password: "",
    },
    onSubmit: async (values) => {
      await databaseUser.updatePassword(values);
    },
  });

  return (
    <DefaultModal
      _size="s"
      description={
        databaseUser.isMainUser
          ? {
              id: "mainUserDescription",
              values: {
                database: databaseDescription,
              },
            }
          : {
              id: "description",
              values: {
                database: databaseDescription,
                databaseUser: databaseUser.data.description,
              },
            }
      }
      headline="updatePassword"
      headlineIcon={iconPassword}
      primary={{ action: [{ form }, visibility.hide], ok: true }}
      visibility={visibility}
    >
      <Form controller={form}>
        <TextField
          label="password"
          name="password"
          rules={{ required: true, minLength: 8 }}
          type="passwordWithGenerator"
        />
      </Form>
    </DefaultModal>
  );
};

export default UpdateDatabaseUserPasswordModal;
