import { Box } from "@mittwald/flow-components/dist/components/Box";
import withSubcomponents from "@mittwald/flow-components/dist/lib/withSubcomponents";
import { FCC } from "@mittwald/flow-lib/dist/lib/functions";
import React from "react";
import { IconListItem } from "./IconListItem";

export const InternalIconList: FCC = (props) => {
  const { children } = props;
  return <Box _itemGap="m">{children}</Box>;
};

export const IconList = withSubcomponents(InternalIconList, {
  Item: IconListItem,
});
