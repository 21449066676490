import { ColumnLayoutContainer } from "@mittwald/flow-components/dist/components/ColumnLayoutContainer";
import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { LinkList } from "@mittwald/flow-components/dist/components/LinkList";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useAnimationController } from "@mittwald/flow-components/dist/hooks/useAnimationController";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconEmail } from "@mittwald/flow-icons/dist/email";

import React, { FC, useRef } from "react";
import { CodeInput } from "../../../../../../components/CodeInput";
import {
  Signup,
  UpdateEmailAddressInputs,
} from "../../../../../../model/signup/Signup";
import User from "../../../../../../model/user/User";

export const UpdateProfileEmailAddress: FC = () => {
  const user = User.useMe();

  const visibility = useVisibilityController();

  const rejectionAnimation = useAnimationController();

  const enteredEmail = useRef<string>("");

  const enterForm = useForm<UpdateEmailAddressInputs>({
    showSuccessFeedback: false,
    defaultValues: {
      email: user.email,
    },
    onSubmit: async (values) => {
      await Signup.updateEmailAddress(values);
      enteredEmail.current = values.email;
    },
  });

  const verificationForm = useForm({
    defaultValues: {
      token: "",
    },
    translationKey: "userEmailVerification",
    onSubmit: async (values) => {
      if (!enteredEmail.current) {
        throw new Error("Email is expected not to be empty");
      }

      await Signup.verifyEmail(
        enteredEmail.current,
        values.token,
        rejectionAnimation,
      );

      visibility.hide();

      setTimeout(() => {
        void Signup.logout();
      }, 500);
    },
  });

  const enterEmailStep = (
    <WizardIntroStep
      description="description"
      form={enterForm}
      headline="enter"
      headlineIcon={iconEmail}
      id="intro"
      indicatorText="enter"
    >
      <Section.Item>
        <TextField
          autoFocus
          label="email"
          name="email"
          rules={{
            required: true,
            validate: { hasChanged: (value: string) => value !== user.email },
          }}
          type="email"
        />
      </Section.Item>
    </WizardIntroStep>
  );

  const verificationStep = (
    <WizardStep form={verificationForm} id="verify" indicatorText="verify">
      <Section.Item headline="verify">
        <Text
          i18n={{ id: "description", values: { email: enteredEmail.current } }}
        />
        <LinkList>
          <LinkList.Item
            action={{
              fn: () =>
                Signup.resendEmail({ email: enteredEmail.current }, user.id),
            }}
            i18n="resendEmailVerificationMail"
          />
        </LinkList>
      </Section.Item>
      <Section.Item>
        <ColumnLayoutContainer>
          <CodeInput
            autoComplete="off"
            label="verificationCode"
            name="token"
            rejectionAnimation={rejectionAnimation}
          />
        </ColumnLayoutContainer>
      </Section.Item>
    </WizardStep>
  );

  return (
    <WizardModal _size="s" steps={["intro", "verify"]} visibility={visibility}>
      {enterEmailStep}
      {verificationStep}
    </WizardModal>
  );
};

export default UpdateProfileEmailAddress;
