import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import React from "react";
import CustomerRolesOnboarding from "../../../pages/app/dashboard/onboarding/modals/CustomerRolesOnboarding/CustomerRolesOnboarding";
import MfaOnboarding from "../../../pages/app/dashboard/onboarding/modals/MfaOnboarding/MfaOnboarding";
import ProjectRolesOnboarding from "../../../pages/app/dashboard/onboarding/modals/ProjectRolesOnboarding/ProjectRolesOnboarding";
import SshOnboarding from "../../../pages/app/dashboard/onboarding/modals/SshOnboarding/SshOnboarding";
import StatusOnboarding from "../../../pages/app/dashboard/onboarding/modals/StatusOnboarding/StatusOnboarding";
import StructureOnboarding from "../../../pages/app/dashboard/onboarding/modals/StructureOnboarding/StructureOnboarding";
import SupportOnboarding from "../../../pages/app/dashboard/onboarding/modals/SupportOnboarding/SupportOnboarding";
import Onboarding from "../../onboarding/Onboarding";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultOnboardingRoles = [Role.anybody];

ModelActionBuilderRegistry.register({
  model: Onboarding,
  roles: defaultOnboardingRoles,
  method: "completeCustomerRoles",
  altAction: () => () =>
    showModal("customerRolesOnboarding", <CustomerRolesOnboarding />),
})
  .register({
    model: Onboarding,
    roles: defaultOnboardingRoles,
    method: "completeStructure",
    altAction: () => () =>
      showModal("structureOnboarding", <StructureOnboarding />),
  })
  .register({
    model: Onboarding,
    roles: defaultOnboardingRoles,
    method: "completeMfa",
    altAction: () => () => showModal("mfaOnboarding", <MfaOnboarding />),
  })
  .register({
    model: Onboarding,
    roles: defaultOnboardingRoles,
    method: "completeSupport",
    altAction: () => () =>
      showModal("supportOnboarding", <SupportOnboarding />),
  })
  .register({
    model: Onboarding,
    roles: defaultOnboardingRoles,
    method: "completeSsh",
    altAction: () => () => showModal("sshOnboarding", <SshOnboarding />),
  })
  .register({
    model: Onboarding,
    roles: defaultOnboardingRoles,
    method: "completeProjectRoles",
    altAction: () => () =>
      showModal("projectRolesOnboarding", <ProjectRolesOnboarding />),
  })
  .register({
    model: Onboarding,
    roles: defaultOnboardingRoles,
    method: "completeStatus",
    altAction: () => () => showModal("statusOnboarding", <StatusOnboarding />),
  });
