import UnexpectedResponseError from "@mittwald/api-client/dist/UnexpectedResponseError";
import { FileData } from "@mittwald/flow-components/dist/components/FileInputField";
import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { iconFileCertificate } from "@mittwald/flow-icons/dist/fileCertificate";
import React from "react";
import { FC } from "react";
import useContextState, {
  StateReturnContext,
} from "../../../../../../../../hooks/useContextState";
import Ingress from "../../../../../../../../model/domain/Ingress";
import { IngressList } from "../../../../../../../../model/domain/IngressList";
import { Project } from "../../../../../../../../model/project";
import { getCertificateData } from "../../../../utils/getCertificateData";
import {
  getSSLCertificateErrorTranslationKey,
  isSSLCertificateKeyError,
} from "../../../../utils/sslCertificateApiErrors";
import { KeyField } from "../../../components/KeyField/KeyField";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";

interface State {
  linkedIngresses: Ingress[];
  certificate: FileData[] | string;
  certificateData: string;
}

export const useCertificateStepState = (): StateReturnContext<State> => {
  return useContextState<State>(
    {
      certificate: [],
      certificateData: "",
      linkedIngresses: [],
    },
    "certificateStepState",
  );
};

export const CertificateStep: FC = () => {
  const project = Project.useLoadByPathParam();
  const [state, setState] = useCertificateStepState();

  const form = useForm<Pick<State, "certificate">>({
    defaultValues: {
      certificate: state.certificate,
    },
    onSubmit: async (values) => {
      const certificateData = getCertificateData(values.certificate);

      try {
        if (certificateData.toLowerCase().includes("private")) {
          throw new Error("containsPrivateKey");
        }

        const response =
          await IngressList.getIngressesCompatibleWithCertificate(
            project.id,
            certificateData,
          );

        setState({
          linkedIngresses: response.items,
          certificate: values.certificate,
          certificateData: certificateData,
        });
      } catch (e) {
        if (e instanceof UnexpectedResponseError) {
          if (isSSLCertificateKeyError(e.errorMessage)) {
            form.setError("certificate", {
              message: getSSLCertificateErrorTranslationKey(e.errorMessage),
            });

            return false;
          }
        }

        form.setError("certificate", {
          message: joinTranslationKeys(
            "modal",
            "uploadCertificate",
            "certificate",
            e instanceof Error ? e.message : "invalidCertificate",
          ),
        });

        return false;
      }
    },
  });

  return (
    <WizardIntroStep
      description="certificate.description"
      form={form}
      headline="certificate"
      headlineIcon={iconFileCertificate}
      id="certificate"
      indicatorText="certificateStep"
    >
      <KeyField
        accept={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "text/plain": [".crt", ".cert", ".txt", ".pem"],
        }}
        multiple
        name="certificate"
        placeholder={{
          text: "-----BEGIN CERTIFICATE-----\n...\n-----END CERTIFICATE-----",
        }}
      />
    </WizardIntroStep>
  );
};
