import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { useCompactMode } from "@mittwald/flow-components/dist/hooks/useCompactMode";
import React from "react";
import DashboardSettings from "../../../pages/app/dashboard/modals/DashboardSettings/DashboardSettings";
import Dashboard from "../../dashboard/Dashboard";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultDashboardRoles = [Role.anybody];

ModelActionBuilderRegistry.register({
  model: Dashboard,
  roles: defaultDashboardRoles,
  method: "updateSettings",
  isAvailable: () => !useCompactMode(),
  altAction: () => () =>
    showModal("updateDashboardSettings", <DashboardSettings />),
});
