import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import React, { FC, useState } from "react";
import {
  Container,
  CreateContainerInputs,
  ImageMeta,
} from "../../../../../model/container/Container";
import EntrypointStep from "./components/EntrypointStep";
import EnvironmentStep from "./components/EnvironmentStep";
import GeneralStep from "./components/GeneralStep";
import { ImageStep } from "./components/ImageStep";
import PortStep from "./components/PortStep";
import VolumeStep from "./components/VolumeStep";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";

export const CreateContainer: FC = () => {
  const goto = useGotoLink();
  const { projectId } = usePathParams("projectId");
  const [imageMeta, setImageMeta] = useState<ImageMeta | undefined>();

  const form = useForm<CreateContainerInputs>({
    translationKey: "createContainer",
    showSuccessFeedback: true,
    defaultValues: {
      description: "",
      imageReference: "",
      entrypoint: "",
      entrypointSelection: "default",
      command: "",
      commandSelection: "default",
      ports: [],
      environments: [],
      environmentText: "",
      envDefinitionType: "variables",
      volumes: [],
    },
    onSubmit: async (values) => {
      const container = await Container.createNew(values, projectId);
      goto(
        "containerDetails",
        { containerId: container.id },
        { replace: true },
      );
    },
  });

  return (
    <WizardModal
      form={form}
      steps={[
        "general",
        "image",
        "entrypoint",
        "volume",
        "environment",
        "ports",
      ]}
    >
      <GeneralStep />
      <ImageStep setImageMeta={setImageMeta} />
      <EntrypointStep imageMeta={imageMeta} />
      <VolumeStep imageMeta={imageMeta} />
      <EnvironmentStep imageMeta={imageMeta} />
      <PortStep imageMeta={imageMeta} />
    </WizardModal>
  );
};

export default CreateContainer;
