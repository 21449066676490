import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC, useEffect, useState } from "react";
import {
  EntrypointMeta,
  ImageMetaProps,
} from "../../../../../../model/container/Container";
import { EntrypointInputFields } from "../../../../app/components/EntrypointInputFields";

export const EntrypointStep: FC<ImageMetaProps> = (props) => {
  const { imageMeta } = props;
  const [relevantMeta, setRelevantMeta] = useState<EntrypointMeta>();

  useEffect(() => {
    setRelevantMeta({
      entrypoint: imageMeta?.entrypoint?.join(" "),
      command: imageMeta?.command?.join(" "),
    });
  }, [imageMeta]);

  return (
    <WizardStep id="entrypoint" indicatorText="entrypoint">
      <EntrypointInputFields relevantMeta={relevantMeta} />
    </WizardStep>
  );
};

export default EntrypointStep;
