import { ImageUpload } from "@mittwald/flow-components/dist/components/ImageUpload";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { useAction } from "@mittwald/flow-components/dist/lib/actions/actionFactory";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { iconAvatar } from "@mittwald/flow-icons/dist/avatar";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import invariant from "invariant";
import React, { FC } from "react";
import { useAvatarUpload } from "../../../../../hooks/useAvatarUpload";
import { useMockFileUploadServer } from "../../../../../hooks/useMockFileUploadServer";
import Customer from "../../../../../model/customer/Customer";

interface Props {
  customer: Customer;
}

export const UpdateCustomerAvatar: FC<Props> = (props) => {
  const visibility = useVisibilityController();
  const customer = props.customer;

  const {
    imageUploadController,
    fileUploadController,
    maxSizeInMb,
    acceptedFilesString,
  } = useAvatarUpload(() => customer.requestAvatarUpload());

  useMockFileUploadServer();

  const hasAvatar = !!customer.data.avatarRefId;

  const uploadStatus = fileUploadController.files[0]?.status;

  const deleteAction: AnyAction = {
    actions: [() => customer.deleteAvatar(), visibility.hide],
    confirmation: {
      required: true,
      modalProps: { headlineIcon: iconDelete },
      modalTranslationKey: "deleteCustomerAvatar",
    },
  };

  const uploadAction = useAction([
    async () => {
      const file = await imageUploadController.buildFile();
      const fileUpload = fileUploadController.upload(file)[0];
      invariant(!!fileUpload, "Upload failed");

      await fileUpload.getRequestPromise();
    },
    visibility.hide,
  ]);

  const uploadActionIsBusy = uploadAction.watch("isBusy");

  const isActive =
    uploadActionIsBusy || imageUploadController.imageIsProcessing;

  return (
    <DefaultModal
      description={{
        id: "description",
        values: { maxSize: maxSizeInMb, accept: acceptedFilesString },
      }}
      headline="updateCustomerAvatar"
      headlineIcon={iconAvatar}
      linkAction={
        hasAvatar
          ? {
              text: "deleteAvatar",
              destructive: true,
              action: deleteAction,
            }
          : undefined
      }
      primary={{
        text: "saveAvatar",
        disabled:
          !imageUploadController.image || uploadStatus?.state === "done",
        ok: true,
        action: uploadAction,
      }}
      visibility={visibility}
    >
      <ImageUpload
        _height={350}
        cropShape="rect"
        disabled={isActive}
        uploadController={imageUploadController}
      />
    </DefaultModal>
  );
};

export default UpdateCustomerAvatar;
