import { Translate } from "@mittwald/flow-components/dist/components/Translate";
import React, { FC } from "react";
import AppInstallation from "../../../../model/app/AppInstallation";
import AppTitleWithVersion from "./AppTitleWithVersion";
import { Skeleton } from "@mittwald/flow-components/dist/components/Skeleton";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";

interface Props {
  appInstallation: AppInstallation;
}

const AppInstallationTitleWithVersion: FC<Props> = ({ appInstallation }) => {
  return (
    <Translate
      i18n={{
        id: "appInstallationTitleWithVersion",
        values: {
          description: appInstallation.data.description,
          version: (
            <Suspense loadingView={<Skeleton _width={100} inline />}>
              <AppTitleWithVersion appInstallation={appInstallation} />
            </Suspense>
          ),
        },
      }}
    />
  );
};

export default AppInstallationTitleWithVersion;
