import React, { FC } from "react";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";

import Extension from "../../../../../model/marketplace/Extension";
interface Props {
  extension: Extension;
}
export const ContributorSection: FC<Props> = (props) => {
  const { extension } = props;

  const contributor = extension.useContributor();

  return (
    <Section.Item headline="contributor">
      <Text
        i18n={{
          id: "description",
          values: { name: contributor.data.name },
        }}
      />
    </Section.Item>
  );
};

export default ContributorSection;
