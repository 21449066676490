import Markdown from "@mittwald/flow-components/dist/components/Markdown";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconExtension } from "@mittwald/flow-icons/dist/extension";
import React, { FC } from "react";
import Extension from "../../../../../model/marketplace/Extension";
import { MarkdownContainer } from "./styled/MarkdownContainer";

interface Props {
  extension: Extension;
}

export const DetailedDescriptionModal: FC<Props> = (props) => {
  const { extension } = props;
  const visibility = useVisibilityController();

  const detailedDescription =
    extension.data.detailedDescriptions?.de.markdown ??
    extension.data.description;

  return (
    <DefaultModal
      description={
        <MarkdownContainer>
          <Markdown markdown={detailedDescription} />
        </MarkdownContainer>
      }
      headline={{ text: extension.data.name }}
      headlineIcon={iconExtension}
      visibility={visibility}
    />
  );
};

export default DetailedDescriptionModal;
