import { faChartLineUp } from "@fortawesome/pro-regular-svg-icons/faChartLineUp";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import BacksideRenderer from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import { Project } from "../../../model/project";

export const performancePages = PageTree.build(BacksideRenderer, {
  "/app/projects/:projectId/performance": {
    props: { navigationSection: "system", tabNav: true },
    icon: faChartLineUp,
    linkId: "performanceOverview",
    accessCheck: () =>
      Project.useLoadByPathParam().useCheckMyRoleIsIn(["owner", "external"]),
  },
  "/app/projects/:projectId/performance/frontend": {
    views: { main: lazy(() => import("./frontend/HomepagesOverview")) },
    icon: faChartLineUp,
    linkId: "performanceHomepagesOverview",
  },
  "/app/projects/:projectId/performance/frontend/:ingressId": {
    views: {
      pageTitle: lazy(() => import("./frontend/components/HomepageTitle")),
    },

    props: {
      tabNav: true,
    },
  },
  "/app/projects/:projectId/performance/frontend/:ingressId/homepage": {
    views: {
      main: lazy(() => import("./frontend/PageDetails")),
    },
    linkId: "performanceHomepageDetails",
  },
  "/app/projects/:projectId/performance/frontend/:ingressId/subpages": {
    views: {
      main: lazy(() => import("./frontend/SubpagesOverview")),
    },
    linkId: "performanceSubpagesOverview",
  },
  "/app/projects/:projectId/performance/frontend/:ingressId/subpages/:path": {
    views: {
      pageTitle: lazy(() => import("./frontend/components/SubpageTitle")),
      main: lazy(() => import("./frontend/PageDetails")),
    },
    linkId: "performanceSubpageDetails",
  },
  "/app/projects/:projectId/performance/ttfb": {
    views: { main: lazy(() => import("./ttfbAnalysis/Overview")) },
    icon: faChartLineUp,
    linkId: "performanceServerOverview",
  },
});
