import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { iconPublicKey } from "@mittwald/flow-icons/dist/publicKey";

import React, { FC } from "react";
import Onboarding from "../../../../../../model/onboarding/Onboarding";
import { OnboardingSection } from "../../components/OnboardingSection";
import { UnorderedList } from "../../components/UnorderedList";
import sshImage1 from "../../img/ssh-1.png";
import sshImage2 from "../../img/ssh-2.png";
import sshImage3 from "../../img/ssh-3.png";

export const SshOnboarding: FC = () => {
  const form = useForm({
    onSubmit: () => {
      Onboarding.completeSsh();
    },
  });

  return (
    <WizardModal form={form} steps={["ssh", "user", "project"]}>
      <WizardStep id="ssh" indicatorText="sshIndicator">
        <OnboardingSection
          headline="ssh"
          headlineIcon={iconPublicKey}
          image={sshImage1}
        >
          <Text i18n="sshDescription" />
        </OnboardingSection>
      </WizardStep>
      <WizardStep id="user" indicatorText="userIndicator">
        <OnboardingSection headline="user" image={sshImage2}>
          <Text i18n="userDescription" />
          <UnorderedList length={2} translationKey="instruction" />
          <Text i18n="userSubline" />
        </OnboardingSection>
      </WizardStep>
      <WizardStep id="project" indicatorText="projectIndicator">
        <OnboardingSection headline="project" image={sshImage3}>
          <Text i18n="projectDescription" />
        </OnboardingSection>
      </WizardStep>
    </WizardModal>
  );
};

export default SshOnboarding;
