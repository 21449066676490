import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import React from "react";
import AddExtensionToCustomerContext from "../../../pages/app/extensions/modals/AddExtensionToCustomerContext/AddExtensionToCustomerContext";
import AddExtensionToProjectContext from "../../../pages/app/extensions/modals/AddExtensionToProjectContext/AddExtensionToProjectContext";
import ExtensionInstance from "../../marketplace/ExtensionInstance";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

ModelActionBuilderRegistry.register({
  model: ExtensionInstance,
  method: "addToCustomerContext",
  roles: [Role.anybody],
  altAction: () => () =>
    showModal("addExtensionToContext", <AddExtensionToCustomerContext />),
})
  .register({
    model: ExtensionInstance,
    method: "addToProjectContext",
    roles: [Role.anybody],
    altAction: () => () =>
      showModal("addExtensionToContext", <AddExtensionToProjectContext />),
  })
  .register({
    model: ExtensionInstance,
    method: "delete",
    roles: [Role.anybody],
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deleteExtensionInstance",
        required: true,
        modalTranslationValues: {
          extension: action.model.useExtension().data.name,
        },
        modalProps: { headlineIcon: iconDelete },
      },
    }),
  })
  .register({
    model: ExtensionInstance,
    method: "enable",
    roles: [Role.anybody],
  })
  .register({
    model: ExtensionInstance,
    method: "disable",
    roles: [Role.anybody],
  });
