import { Box } from "@mittwald/flow-components/dist/components/Box";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { iconMembers } from "@mittwald/flow-icons/dist/members";
import React, { FC } from "react";
import { ProjectMembershipList } from "../../../../../../model/project";
import { ProjectMemberList } from "../../../../project/members/components/ProjectMemberList/ProjectMemberList";

interface Props {
  projectId: string;
}

export const MembersWithPermission: FC<Props> = (props) => {
  const { projectId } = props;

  return (
    <DefaultModal _size="s">
      <Section.Item headline="members" headlineIcon={iconMembers}>
        <Text i18n="membersDescription" />
        <Box _noOverflow>
          <ProjectMemberList
            columns={[{ key: "role" }]}
            hideFilter
            hideSecondaryActions
            hideSecondaryStatusIcons
            members={ProjectMembershipList.useLoadByProjectId(
              projectId,
            ).items.filter((member) => member.data.role === "owner")}
          />
        </Box>
      </Section.Item>
    </DefaultModal>
  );
};

export default MembersWithPermission;
