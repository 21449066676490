import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC, useEffect } from "react";
import splitSshKey from "../../../../lib/splitSshKey";
import AccessUI from "../../../../model/ui/access/AccessUI";
import PublicKeyFields from "./publicKeyFields";
import Section from "@mittwald/flow-components/dist/components/Section";

export const SecuritySection: FC = () => {
  const form = useFormContext();
  const [watchedAuthType, watchedKey] = form.watch(["authType", "key"]);

  useEffect(() => {
    if (!watchedKey) {
      return;
    }

    // separate comment and public key into their input fields, if a ssh key with a comment is added
    const sshKey = splitSshKey(watchedKey);
    if (sshKey) {
      form.setValue("key", sshKey.publicKey, { shouldValidate: false });
      if (sshKey.comment) {
        form.setValue("comment", sshKey.comment);
      } else {
        form.setFocus("comment");
      }
    }
  }, [watchedKey]);

  return (
    <Section.Item headline="security">
      <Text i18n="securityDescription" />
      <SelectBox
        label="authType"
        name="authType"
        options={AccessUI.accessAuthTypeSelectBoxOptions()}
        rules={{ required: true }}
      />
      {watchedAuthType === "publicKey" ? (
        <PublicKeyFields />
      ) : (
        <TextField
          autoComplete="off"
          autoFocus
          label="password"
          name="password"
          rules={{ required: true, minLength: 12, maxLength: 72 }}
          type="passwordWithGenerator"
        />
      )}
    </Section.Item>
  );
};
