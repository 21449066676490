import { Banner } from "@mittwald/flow-components/dist/components/Banner";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";

interface Props {
  overwritingUser: number;
}

export const RootOverwriteBanner: FC<Props> = (props) => {
  return (
    <TranslationProvider name="rootOverwrite" type="component">
      <Banner
        description={{
          id: "rootOverwriteDescription",
          values: { user: props.overwritingUser },
        }}
        headline="rootOverwrite"
        info
      />
    </TranslationProvider>
  );
};
