import { DateTime as DateTimeComponent } from "@mittwald/flow-components/dist/components/DateTimeText";
import { MoneyValueInput } from "@mittwald/flow-components/dist/components/MoneyValue";
import Translate from "@mittwald/flow-components/dist/components/Translate";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { DateTime } from "luxon";
import React, { FC } from "react";
import { MoneyValue } from "@mittwald/flow-components/dist/components/MoneyValue";

interface Props {
  freeTrialUntil: DateTime;
  price: MoneyValueInput;
}

export const PerMonthWithFreeTrialText: FC<Props> = (props) => {
  return (
    <TranslationProvider name="perMonthWithFreeTrial" type="component">
      <Translate
        i18n={{
          id: "price",
          values: { price: <MoneyValue value={props.price} /> },
        }}
      />
      <Translate
        i18n={{
          id: "date",
          values: {
            date: (
              <DateTimeComponent format="date" value={props.freeTrialUntil} />
            ),
          },
        }}
      />
    </TranslationProvider>
  );
};

export default PerMonthWithFreeTrialText;
