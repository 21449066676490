import { MittwaldApi } from "../../api/Mittwald";

export type MySqlVersionApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Database_MySqlVersion;

export class MySqlVersion {
  public readonly id: string;
  public readonly data: MySqlVersionApiData;
  public readonly name: string;
  public readonly number: string;

  private constructor(data: MySqlVersionApiData) {
    this.id = data.id;
    this.data = Object.freeze(data);
    this.name = data.name;
    this.number = data.number;
  }

  public static fromApiData(data: MySqlVersionApiData): MySqlVersion {
    return new MySqlVersion(data);
  }
}

export default MySqlVersion;
