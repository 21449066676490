import { useForm } from "@mittwald/flow-components/dist/components/Form";
import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import useWizardState from "@mittwald/flow-components/dist/components/Wizard/hooks/useWizardState";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { iconApp } from "@mittwald/flow-icons/dist/app";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";
import React, { FC, useEffect } from "react";
import DisableForm from "../../../../../components/DisableForm";
import AppInstallation, {
  InstallSystemSoftwareInput,
} from "../../../../../model/app/AppInstallation";
import SystemSoftwareVersion from "../../../../../model/app/SystemSoftwareVersion";
import { Project } from "../../../../../model/project";
import OverviewStepContent from "../UpdateInstalledSystemSoftwareVersion/components/OverviewStepContent";
import ConfigurationStepContent from "./components/ConfigurationStepContent";
import SystemSoftwareStepContent from "./components/SystemSoftwareStepContent";

interface Props {
  appInstallation: AppInstallation;
}

export const InstallSystemSoftware: FC<Props> = (props) => {
  const wizardState = useWizardState();
  const project = Project.useLoadByPathParam();

  const form = useForm<InstallSystemSoftwareInput>({
    defaultValues: { systemSoftwareId: "", systemSoftwareVersionId: "" },
    onSubmit: async (values) => {
      await props.appInstallation.installSystemSoftware(values);
    },
  });

  const watchedSystemSoftwareId = form.watch("systemSoftwareId");
  const watchedSystemSoftwareVersionId = form.watch("systemSoftwareVersionId");

  const selectedSystemSoftwareVersion = SystemSoftwareVersion.useTryLoadById(
    watchedSystemSoftwareId,
    watchedSystemSoftwareVersionId,
  );

  const currentFee = selectedSystemSoftwareVersion?.checkCurrentFee();

  useEffect(() => {
    if (watchedSystemSoftwareId) {
      wizardState.gotoNextStep();
    }
  }, [watchedSystemSoftwareId]);

  const appStep = (
    <WizardIntroStep
      description="systemSoftwareDescription"
      headline="systemSoftware"
      headlineIcon={iconApp}
      id="app"
      indicatorText="systemSoftwareStep"
    >
      <Suspense
        loadingView={
          <DisableForm>
            <LoadingView _height={100} />
          </DisableForm>
        }
      >
        <SystemSoftwareStepContent appInstallation={props.appInstallation} />
      </Suspense>
    </WizardIntroStep>
  );

  const configurationStep = (
    <WizardStep id="configuration" indicatorText="configurationStep">
      <Suspense
        loadingView={
          <DisableForm>
            <LoadingView _height={100} />
          </DisableForm>
        }
      >
        <ConfigurationStepContent appInstallation={props.appInstallation} />
      </Suspense>
    </WizardStep>
  );

  return (
    <WizardModal
      form={form}
      steps={["app", "configuration", "overview"]}
      wizardState={wizardState}
    >
      {appStep}
      {configurationStep}
      {selectedSystemSoftwareVersion && currentFee && (
        <WizardStep id="overview" indicatorText="overviewIndicator">
          <OverviewStepContent
            currentFee={currentFee}
            project={project}
            systemSoftwareVersion={selectedSystemSoftwareVersion}
          />
        </WizardStep>
      )}
    </WizardModal>
  );
};

export default InstallSystemSoftware;
