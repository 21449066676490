import { types } from "mobx-state-tree";
import { Visit, Visits } from "./types";

const maxEntries = 6;

export const recentVisitsStoreModel = types
  .model({
    recentVisits: types.map(types.number),
    recentVisitedPaths: types.map(types.string),
  })
  .actions((self) => ({
    recordVisit: (
      id: string,
      type: string,
      pathname: string,
      userId: string,
    ) => {
      self.recentVisits.set(`${id}:${type}:${userId}`, Date.now());
      self.recentVisitedPaths.set(`${id}:${type}:${userId}`, pathname);

      if (self.recentVisits.size > maxEntries) {
        const sortedByLastVisit = Array.from(self.recentVisits.entries())
          .sort((left, right) => (left[1] > right[1] ? 1 : -1))
          .map((entry) => entry[0]);

        const oldestVisit = sortedByLastVisit[0];

        if (oldestVisit) {
          self.recentVisits.delete(oldestVisit);
        }
      }
    },
    deleteRecord: (visit: Visit) => {
      const entryId = `${visit.id}:${visit.type}:${visit.userId}`;
      self.recentVisits.delete(entryId);
      self.recentVisitedPaths.delete(entryId);
    },
    reset: (): void => {
      self.recentVisits.clear();
    },
  }))
  .views((self) => ({
    get recentVisitsInfo(): Visits {
      return Array.from(self.recentVisits.keys()).map((key) => {
        const vals = key.split(":");
        return { id: vals[0], type: vals[1], userId: vals[2] };
      });
    },
  }));
