import Contract from "../contract/Contract";
import ContractItem from "../contract/ContractItem";
import ContractItemTermination from "../contract/ContractItemTermination";
import TariffChange from "../contract/TariffChange";
import { InvoiceSettings } from "../customer/InvoiceSettings";
import Domain from "../domain/Domain";
import SSLCertificate from "../domain/ssl/SSLCertificate";
import SpaceServerProject from "../project/SpaceServerProject";
import Server from "../server/Server";
import AclRootModelResolver from "./acl/AclRootModelResolver";

AclRootModelResolver.register(Server, (s) => s.useCustomer());
AclRootModelResolver.register(ContractItem, (pos) => pos.contract);
AclRootModelResolver.register(InvoiceSettings, (settings) => settings.customer);
AclRootModelResolver.register(
  TariffChange,
  (tariffChange) => tariffChange.contractItem,
);
AclRootModelResolver.register(
  ContractItemTermination,
  (term) => term.contractItem,
);
AclRootModelResolver.register(Contract, (c) => c.useCustomer());
AclRootModelResolver.register(SpaceServerProject, (p) => p.useServer());
AclRootModelResolver.register(Domain, (c) => c.useProject());
AclRootModelResolver.register(SSLCertificate, (c) => c.useProject());
