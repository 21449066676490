import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { SidebarSectionHeader } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/components/SidebarSectionHeader";
import React, { FC } from "react";
import { ModelActionBuilder } from "../../../../model/actions/ModelActionBuilder";
import Server from "../../../../model/server/Server";
import ServerUI from "../../../../model/ui/server/ServerUI";

export const ServerSidebarHeader: FC = () => {
  const { serverId } = usePathParams("serverId");

  const server = Server.useLoadById(serverId);
  const avatarSource = ServerUI.of(server).useServerAvatarSource();

  return (
    <SidebarSectionHeader
      avatar={avatarSource}
      imageAction={ModelActionBuilder.build(server, "requestAvatarUpload")}
      subTitle="server"
      title={{ text: server.data.description }}
    />
  );
};
