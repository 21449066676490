import { Select } from "@mittwald/flow-components/dist/components/Select";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";
import { ServerList } from "../../../../model/server/ServerList";

interface ServerSelectProps {
  name?: string;
  optional?: boolean;
}

export const ServerSelect: FC<ServerSelectProps> = (props) => {
  const { name = "server", optional } = props;
  const options = ServerList.useLoad().getSelectOptions(optional);

  return (
    <TranslationProvider name="serverSelect" type="component">
      <Select
        isSearchable
        label="server"
        name={name}
        options={options}
        placeholder="server"
        rules={{ required: !optional }}
      />
    </TranslationProvider>
  );
};
