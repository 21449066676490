import Box from "@mittwald/flow-components/dist/components/Box";
import { getAvatarImageWidth } from "@mittwald/flow-components/dist/components/Avatar/lib";
import { Icon } from "@mittwald/flow-components/dist/components/Icon";
import { Skeleton } from "@mittwald/flow-components/dist/components/Skeleton";
import { UniversalBoundary } from "@mittwald/flow-components/dist/components/UniversalBoundary";
import { iconEnvelopeOpenDollar } from "@mittwald/flow-icons/dist/envelopeOpenDollar";
import React, { FC } from "react";
import { Text } from "@mittwald/flow-components/dist/components/Text";

export const HiddenPaymentInformation: FC = () => {
  return (
    <Box _itemGap="s">
      <Box _flexDirection="row" _itemGap="m">
        <Box
          _flexDirection="row"
          _justifyContent="center"
          _width={getAvatarImageWidth("m")}
        >
          <Icon
            _color="brand-primary-dark"
            _size="m"
            icon={iconEnvelopeOpenDollar}
          />
        </Box>
        <UniversalBoundary
          loadingView={
            <Text>
              <Skeleton _width={200} count={6} />
            </Text>
          }
        >
          <Box _flexDirection="column">
            <Text i18n="invoice" strong />
            <Text i18n="contractOwnerRef" />
          </Box>
        </UniversalBoundary>
      </Box>
    </Box>
  );
};
