import { LinkIdAction } from "@mittwald/flow-components/dist/lib/actions/LinkIdAction";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import CustomerInvite from "../../customer/CustomerInvite";
import { CustomerInviteList } from "../../customer/CustomerInviteList";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

ModelActionBuilderRegistry.register({
  model: CustomerInviteList,
  roles: [Role.customerOwner],
  method: "useLoadAllByCustomerId",
})
  .register({
    model: CustomerInvite,
    roles: [Role.customerOwner],
    method: "revokeInvite",
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "revokeInvite",
        required: true,
        modalProps: { headlineIcon: iconDelete },
      },
    }),
  })
  .register({
    model: CustomerInvite,
    roles: [Role.anybody],
    method: "acceptInvite",
    altAction: (action) => [
      action,
      new LinkIdAction("customerDetails", {
        pathParams: {
          customerId: action.model.data.customerId,
        },
      }),
    ],
  })
  .register({
    model: CustomerInvite,
    roles: [Role.anybody],
    method: "declineInvite",
  });
