import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import useValueController from "@mittwald/flow-components/dist/hooks/useValueController";
import invariant from "invariant";
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Article,
  ArticleTemplateName,
  ArticleFactory,
  SpaceServerArticle,
} from "../../../../../model/article";
import Customer, {
  CustomerUpdateInputs,
} from "../../../../../model/customer/Customer";
import { CustomerList } from "../../../../../model/customer/CustomerList";
import {
  InvoiceSettings,
  InvoiceSettingsInputs,
  InvoiceSettingsPaymentInput,
} from "../../../../../model/customer/InvoiceSettings";
import Bytes from "../../../../../model/misc/Bytes";
import ArticleUI from "../../../../../model/ui/article/ArticleUI";
import CustomerListUI from "../../../../../model/ui/customer/CustomerListUI";
import OrderCompleteConfetti from "../../../components/OrderCompleteConfetti";
import { CustomerStepFields, OrderServerFormFields } from "../../types";
import { ContractPartnerStep } from "./components/ContractPartnerStep";
import { CustomerStep } from "./components/CustomerStep";
import OverviewStep from "./components/OverviewStep";
import { PaymentStep } from "./components/PaymentStep";
import { RecipientStep } from "./components/RecipientStep";
import { ServerStep } from "./components/ServerStep";

interface Props {
  customer?: Customer;
}

export interface PaymentFormFields extends InvoiceSettingsInputs {
  otherRecipient: boolean;
}

export const OrderServerWizard: FC<Props> = (props) => {
  /* order space server */

  const spaceServerArticles = ArticleFactory.useLoadAllByTemplate(
    ArticleTemplateName.spaceServerHosting,
  );
  const defaultSelectedArticle =
    spaceServerArticles.find((a) => ArticleUI.of(a).isPromoted) ??
    spaceServerArticles[0];
  Article.assertType(defaultSelectedArticle, SpaceServerArticle);

  const [showConfetti, setShowConfetti] = useState(false);

  const orderForm = useForm<OrderServerFormFields & { conditions: boolean }>({
    defaultValues: {
      storageSizeInBytes:
        defaultSelectedArticle.baseStorageAttribute.bytes.bytes,
      description: "",
      articleId: defaultSelectedArticle.id,
      conditions: false,
      useFreeTrial: false,
      customerId: props.customer?.id,
    },
    onSubmit: async (values) => {
      const {
        customerId,
        storageSizeInBytes,
        articleId,
        description,
        useFreeTrial,
      } = values;

      await SpaceServerArticle.order({
        customerId: props.customer?.id ?? customerId,
        storageSizeInBytes,
        description,
        articleId,
        useFreeTrial,
      });
    },
    onAfterSubmit: () => {
      setShowConfetti(true);
    },
  });

  const customerList = CustomerList.useAll();
  const customersAllowedToOrder = customerList.getCustomersAllowedToOrder();
  const customerSelectOptions =
    CustomerListUI.of(customerList).useSelectOptions();

  const existingCustomersAvailable = customerSelectOptions.length > 0;
  const customerModeController = useValueController(
    existingCustomersAvailable ? "existing" : "new",
  );
  const customerMode = customerModeController.watch();

  const [freeTrial, customerId] = orderForm.watch([
    "useFreeTrial",
    "customerId",
  ]);

  const customerFromPath = Customer.useTryLoadById(props.customer?.id);

  const customerForm = useForm<CustomerStepFields>({
    showSuccessFeedback: false,
    translationKey: "addCustomer",
    defaultValues: {
      id: "",
      name: "",
    },
    onSubmit: async (values) => {
      const { name, id } = values;

      if (customerModeController.value.current === "new") {
        invariant(name !== undefined, "Invalid state");

        const response = await Customer.createNew({ name });

        orderForm.setValue("customerId", response.id);
        customerModeController.updateValue("existing");
      } else {
        invariant(id !== undefined, "Invalid state");
        orderForm.setValue("customerId", id);
      }
    },
  });

  useEffect(() => {
    customerForm.setValue("id", customerId);
  }, [customerId]);

  const updateContractPartnerForm = useForm<CustomerUpdateInputs>({
    defaultValues: {
      owner: {
        salutation: "other",
      },
    },
    showSuccessFeedback: false,
    translationKey: "updateContractPartner",
    onSubmit: async (values) => {
      const customer = await Customer.loadById(customerId);

      await customer.update(values);
    },
  });

  /* update payment method */

  const paymentForm = useForm<
    InvoiceSettingsInputs & {
      otherRecipient: boolean;
    }
  >({
    defaultValues: {
      paymentSettings: {
        method: "invoice",
        iban: "",
      },
      invoicePeriod: 1,
      invoiceRecipient: "contractPartner",
    },
    onSubmit: async (values) => {
      await InvoiceSettings.update(values, customerId);
    },
  });

  const otherRecipient = paymentForm.watch("otherRecipient");
  const [
    watchedPaymentMethod,
    watchedPaymentIban,
    watchedPaymentBic,
    watchedPaymentAccountHolder,
    watchedPaymentConfirmDebit,
  ] = paymentForm.watch([
    "paymentSettings.method",
    "paymentSettings.iban",
    "paymentSettings.bic",
    "paymentSettings.accountHolder",
    "paymentSettings.confirmDebit",
  ]);
  const watchedPaymentData: InvoiceSettingsPaymentInput = {
    iban: watchedPaymentIban,
    bic: watchedPaymentBic,
    accountHolder: watchedPaymentAccountHolder,
    method: watchedPaymentMethod,
    confirmDebit: watchedPaymentConfirmDebit,
  };

  const selectedArticleId = orderForm.watch("articleId");
  const selectedArticle = spaceServerArticles
    .find((a) => a.id === selectedArticleId)
    ?.asType(SpaceServerArticle);

  invariant(selectedArticle !== undefined, "Article not set");

  const storageSize = Bytes.of(orderForm.watch("storageSizeInBytes"), "byte");

  const selectedCustomerId = customerForm.watch("id");
  const selectedCustomer = customersAllowedToOrder.find(
    (c) => c.id === selectedCustomerId,
  );

  const hideContractPartnerStep = useMemo(() => {
    const customerFromPathHasOwner = !!customerFromPath?.contact;
    const selectedCustomerHasOwner = !!selectedCustomer?.contact;

    return (
      customerFromPathHasOwner ||
      (customerMode === "existing" && selectedCustomerHasOwner)
    );
  }, [selectedCustomerId, customerMode]);

  const serverStep = (
    <ServerStep article={selectedArticle} freeTrial={freeTrial} />
  );

  const customerStep = (
    <CustomerStep
      customerModeController={customerModeController}
      form={customerForm}
    />
  );

  const contractPartnerStep = (
    <ContractPartnerStep form={updateContractPartnerForm} />
  );

  const paymentStep = <PaymentStep form={paymentForm} />;

  const recipientStep = (
    <RecipientStep
      watchedCustomerId={selectedCustomerId!}
      watchedPaymentData={watchedPaymentData}
    />
  );

  const overviewStep = (
    <OverviewStep
      customerId={customerId}
      freeTrial={freeTrial}
      selectedArticle={selectedArticle}
      storageSize={storageSize}
    />
  );

  return (
    <WizardModal
      _size="l"
      form={orderForm}
      steps={[
        "server",
        "customer",
        "contractPartner",
        "payment",
        "recipient",
        "overview",
      ]}
    >
      {showConfetti && <OrderCompleteConfetti />}
      {serverStep}
      {!customerFromPath && customerStep}
      {!hideContractPartnerStep && contractPartnerStep}
      {!hideContractPartnerStep && paymentStep}
      {otherRecipient && recipientStep}
      {overviewStep}
    </WizardModal>
  );
};

export default OrderServerWizard;
