import {
  ImageUploadController,
  useImageUploadController,
} from "@mittwald/flow-components/dist/components/ImageUpload/hooks/useImageUploadController";
import {
  useMultiFileUploadController,
  UseMultiFileUploadControllerInstance,
} from "@mittwald/flow-components/dist/components/MultiFileUploadList";
import { mittwaldApi } from "../api/Mittwald";

interface AvatarUploadData {
  imageUploadController: ImageUploadController;
  fileUploadController: UseMultiFileUploadControllerInstance;
  acceptedFilesString?: string;
  maxSizeInMb?: number;
}

export const useAvatarUpload = (
  uploadTokenFn: () => string | Promise<string>,
): AvatarUploadData => {
  const fileUploadRules = mittwaldApi.fileGetFileUploadTypeRules
    .getResource({ path: { fileUploadType: "avatar" } })
    .useWatchData();

  const imageUploadController = useImageUploadController(
    fileUploadRules.mimeTypes,
    fileUploadRules.maxSizeInKB,
  );

  const fileUploadController = useMultiFileUploadController({
    fieldName: "file",
    targetUrl: `${import.meta.env.VITE_APP_API_URI}/v2/files`,
    uploadTokenFn,
  });

  return {
    imageUploadController,
    fileUploadController,
    acceptedFilesString:
      imageUploadController.fileDropController.getAcceptedFileString(),
    maxSizeInMb: imageUploadController.fileDropController.getMaxSizeInMb(),
  };
};
