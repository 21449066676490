import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import Extension from "../../../../../model/marketplace/Extension";
import ScopeElements from "../../components/ScopeElements";
import ContributorSection from "./ContributorSection";

interface Props {
  extension?: Extension;
}

const ScopesStep: FC<Props> = (props) => {
  const { extension } = props;

  return (
    <WizardStep id="scopes" indicatorText="scopesStep">
      <Section.Layout>
        {extension && <ContributorSection extension={extension} />}
        <Section.Item headline="permissions">
          <Text i18n="description" />
        </Section.Item>
        <Section.Item headline="scopes">
          {extension && <ScopeElements scopes={extension.data.scopes} />}
        </Section.Item>
      </Section.Layout>
    </WizardStep>
  );
};

export default ScopesStep;
