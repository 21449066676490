import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { iconSupport } from "@mittwald/flow-icons/dist/support";

import React, { FC } from "react";
import Onboarding from "../../../../../../model/onboarding/Onboarding";
import { SupportPhoneLink } from "../../../../support/components/SupportPhoneLink";
import { OnboardingSection } from "../../components/OnboardingSection";
import { UnorderedList } from "../../components/UnorderedList";
import supportImage1 from "../../img/support-1.png";
import supportImage2 from "../../img/support-2.png";
import supportImage4 from "../../img/support-4.png";

export const SupportOnboarding: FC = () => {
  const form = useForm({
    onSubmit: () => {
      Onboarding.completeSupport();
    },
  });

  return (
    <WizardModal form={form} steps={["intro", "contract", "hotline", "verify"]}>
      <WizardIntroStep
        description={{
          id: "supportDescription",
          values: { phoneLink: <SupportPhoneLink /> },
        }}
        headline="support"
        headlineIcon={iconSupport}
        id="intro"
        indicatorText="supportIndicator"
      >
        <OnboardingSection image={supportImage1}>
          <Text i18n="tippDescription" />
          <UnorderedList length={4} translationKey="tipp" />
        </OnboardingSection>
      </WizardIntroStep>
      <WizardStep id="contract" indicatorText="contractIndicator">
        <OnboardingSection headline="contract" image={supportImage2}>
          <Text i18n="contractDescription" />
        </OnboardingSection>
      </WizardStep>
      <WizardStep id="hotline" indicatorText="hotlineIndicator">
        <Section.Item headline="hotline">
          <Text i18n="hotlineDescription" />
        </Section.Item>
      </WizardStep>
      <WizardStep id="verify" indicatorText="verificationIndicator">
        <Section.Item headline="verification">
          <Text i18n="verificationDescription" />
        </Section.Item>
        <OnboardingSection
          headline="verificationInstruction"
          image={supportImage4}
        >
          <Text i18n="verificationInstructionDescription" />
        </OnboardingSection>
      </WizardStep>
    </WizardModal>
  );
};

export default SupportOnboarding;
