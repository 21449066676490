import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { useMemo } from "react";
import { mittwaldApi } from "../../api/Mittwald";
import Ingress from "../domain/Ingress";
import ListModel from "../misc/ListModel";
import { Ttl } from "../ui/domain/DnsZoneUI";
import CnameRecordSet from "./CnameRecordSet";
import { DnsZone, DnsZoneApiData } from "./DnsZone";

export interface CnameRecordItem {
  fqdn: string;
  hostname: string;
  domain?: string;
  ttl: number;
}
export interface CnameRecordDataForList extends Omit<CnameRecordItem, "ttl"> {
  markForDeletion?: boolean;
  new?: boolean;
}

export class CnameRecordsList extends ListModel<CnameRecordSet> {
  public readonly ingress: Ingress;
  private readonly dnsZones: DnsZoneApiData[];
  public readonly ttl: Ttl;
  public readonly recordCount: number;

  public constructor(
    ingress: Ingress,
    dnsZones: DnsZoneApiData[],
    items: CnameRecordSet[],
    ttl: Ttl = "auto",
  ) {
    super(items);

    this.ingress = ingress;
    this.dnsZones = dnsZones;
    this.ttl = ttl;
    this.recordCount = this.items.length;
  }

  public static fromCnameRecordApiData = (
    data: DnsZoneApiData,
    ingress: Ingress,
  ): CnameRecordsList => {
    return new CnameRecordsList(
      ingress,
      [data],
      [CnameRecordSet.fromDnsZoneApiData(data)],
    );
  };

  private static readonly useGetAllZonesForIngress = (
    ingress: Ingress,
  ): DnsZoneApiData[] => {
    const listAllDnsZonesRequest = mittwaldApi.dnsListDnsZones.getResource({
      path: { projectId: ingress.data.projectId },
    });
    return listAllDnsZonesRequest.useWatchData();
  };

  public static useLoadAllByIngress = (ingress: Ingress): CnameRecordsList => {
    const zonesForIngress = CnameRecordsList.useGetAllZonesForIngress(ingress);

    const cnameRecordZonesForIngress =
      CnameRecordsList.filterCnameRecordZonesForIngress(
        zonesForIngress,
        ingress,
      );

    const items = useMemo(
      () =>
        cnameRecordZonesForIngress
          .filter((r) => !ingress.isSubdomain || r.domain == ingress.hostname)
          .map((r) => CnameRecordSet.fromDnsZoneApiData(r)),
      [cnameRecordZonesForIngress],
    );

    const itemWithTtl = items.find((r) => r.ttl);

    return new CnameRecordsList(
      ingress,
      zonesForIngress,
      items,
      itemWithTtl?.ttl,
    );
  };

  private static filterCnameRecordZonesForIngress(
    dnsZones: DnsZoneApiData[],
    ingress: Ingress,
  ): DnsZoneApiData[] {
    return dnsZones.filter((d) => {
      if (!("fqdn" in d.recordSet.cname)) {
        return false;
      }

      if (ingress.isSubdomain) {
        if (d.domain.includes("_")) {
          return false;
        }

        return (
          d.domain.endsWith(`.${ingress.hostname}`) ||
          d.domain === ingress.hostname
        );
      }

      return (
        (d.domain.includes("_") && d.domain.endsWith(`.${ingress.hostname}`)) ||
        d.domain === ingress.hostname
      );
    });
  }

  public async createCnameDnsZone(name: string): Promise<string> {
    const zone = await DnsZone.getByIngress(this.ingress);

    const response = await mittwaldApi.dnsCreateDnsZone.request({
      requestBody: {
        name: `${name}`,
        parentZoneId: zone.id,
      },
    });
    assertStatus(response, 201);

    return response.content.id;
  }

  public async saveDataItems(
    items: CnameRecordDataForList[],
    ttl: Ttl,
  ): Promise<void> {
    for (const { hostname: zoneName, fqdn, markForDeletion } of items) {
      const hostnameWithDomain = !this.ingress.isSubdomain
        ? `${zoneName}.${this.ingress.hostname}`
        : this.ingress.hostname;

      const existingCnameZone = this.dnsZones.find((z) => {
        return z.domain === hostnameWithDomain;
      });

      let zoneId = existingCnameZone?.id;

      if (!zoneId) {
        zoneId = await this.createCnameDnsZone(zoneName);
      }

      const cnameRecord = CnameRecordSet.fromList(
        zoneId,
        hostnameWithDomain,
        ttl,
        markForDeletion ? "" : fqdn,
      );

      await cnameRecord.saveRecords();
    }
  }

  public getItems(): CnameRecordDataForList[] {
    const items = this.items;
    const dataItems: CnameRecordDataForList[] = [];

    items.forEach((i) => {
      dataItems.push({
        domain: i.domain,
        hostname: i.domain.replace(
          // eslint-disable-next-line no-useless-escape
          new RegExp(`\.${this.ingress.name.domain}$`),
          "",
        ),
        fqdn: i.fqdn,
      });
    });

    return dataItems;
  }

  public useDataItems(): CnameRecordDataForList[] {
    const items = this.useItems();
    const dataItems: CnameRecordDataForList[] = [];

    items.forEach((i) => {
      dataItems.push({
        domain: i.domain,
        hostname: i.domain.replace(
          // eslint-disable-next-line no-useless-escape
          new RegExp(`\.${this.ingress.name.domain}$`),
          "",
        ),
        fqdn: i.fqdn,
      });
    });

    return useMemo(() => dataItems, [items]);
  }
}
