import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { LinkIdAction } from "@mittwald/flow-components/dist/lib/actions/LinkIdAction";
import { iconCronjob } from "@mittwald/flow-icons/dist/cronjob";
import { iconDatabase } from "@mittwald/flow-icons/dist/database";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import useOptionalPathParams from "@mittwald/flow-lib/dist/hooks/useOptionalPathParams";
import React, { FC } from "react";
import AppInstallation from "../../../../../model/app/AppInstallation";

interface Props {
  appInstallation: AppInstallation;
}

const DeleteAppInstallation: FC<Props> = (props) => {
  const { appInstallation } = props;
  const visibility = useVisibilityController();
  const pathParams = useOptionalPathParams();

  const databasesExist =
    appInstallation.data.linkedDatabases &&
    appInstallation.data.linkedDatabases.length > 0;
  const cronjobsExist = appInstallation.useCronjobs().length > 0;

  return (
    <DefaultModal
      description={{
        id: "deleteAppInstallationDescription",
        values: { appInstallation: appInstallation.description },
      }}
      headline="deleteAppInstallation"
      headlineIcon={iconDelete}
      primary={{
        action: [
          () => appInstallation.delete(),
          visibility.hide,
          new LinkIdAction(pathParams.projectId ? "apps" : "globalApps", {
            replace: true,
          }),
        ],
        destructive: true,
        text: "deleteAppInstallation",
      }}
      visibility={visibility}
    >
      {(databasesExist || cronjobsExist) && (
        <Section.Item>
          <Text i18n="dependenciesDescription" />
          {databasesExist && (
            <TextWithIconLayout icon={iconDatabase}>
              <Text i18n="databases" />
            </TextWithIconLayout>
          )}
          {cronjobsExist && (
            <TextWithIconLayout icon={iconCronjob}>
              <Text i18n="cronjobs" />
            </TextWithIconLayout>
          )}
        </Section.Item>
      )}
    </DefaultModal>
  );
};

export default DeleteAppInstallation;
