import { mittwaldApi } from "../../api/Mittwald";
import ConversationCategory, {
  ConversationCategoryApiSchema,
  ConversationCategoryName,
  ConversationCategoryNameLoose,
} from "./ConversationCategory";

export class ConversationCategoryList {
  public readonly items: ConversationCategory[];
  public readonly isEmpty: boolean;

  private constructor(items: ConversationCategory[]) {
    this.items = items;
    this.isEmpty = items.length === 0;
  }

  public static useList(): ConversationCategoryList {
    const cat = mittwaldApi.conversationListCategories
      .getResource({ query: { includeSubCategories: true } })
      .useWatchData()
      .map((data) => ConversationCategory.fromApiData(data));

    return new ConversationCategoryList(cat);
  }

  public fromData(
    categories: ConversationCategoryApiSchema[],
  ): ConversationCategoryList {
    const conversationCategories = categories.map((data) =>
      ConversationCategory.fromApiData(data),
    );

    return new ConversationCategoryList(conversationCategories);
  }

  public sort(): ConversationCategoryList {
    const sortOrder: ConversationCategoryNameLoose[] = [
      ConversationCategoryName.project,
      ConversationCategoryName.server,
      ConversationCategoryName.customer,
      ConversationCategoryName.general,
    ];
    return new ConversationCategoryList(
      this.items.sort((l, r) => l.compare(r, sortOrder)),
    );
  }
}

export default ConversationCategory;
