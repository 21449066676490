import { faPhp } from "@fortawesome/free-brands-svg-icons/faPhp";
import { Banner } from "@mittwald/flow-components/dist/components/Banner";
import React, { FC } from "react";

export const CurrentFeeBanner: FC = () => {
  return (
    <Banner
      description="currentFeeDescription"
      headline="currentFee"
      icon={faPhp}
      textLink={{
        action: import.meta.env.VITE_PHP_EXTENDED_LINK,
        title: "phpLifecycle",
      }}
    />
  );
};

export default CurrentFeeBanner;
